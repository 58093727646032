import { notification } from 'antd';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  UpdateVehicleInput,
  useUpdateVehicleMutation,
} from '../../../generated/graphqlV2';
import { useVehicleProfileContext } from './VehicleProfileContext';

type VehicleProfileFormContextValue = {
  loading: boolean;
  updateVehicle: (vehicleData: Omit<UpdateVehicleInput, 'id'>) => void;
};

const VehicleProfileFormContext = createContext<VehicleProfileFormContextValue>(
  {} as VehicleProfileFormContextValue,
);

type VehicleProfileFormContextProviderProps = {
  children: ReactNode;
};

function VehicleProfileFormContextProvider(
  props: VehicleProfileFormContextProviderProps,
) {
  const [loading, setLoading] = useState(false);

  const [updateVehicleMutation] = useUpdateVehicleMutation({
    refetchQueries: ['vehicle'],
  });

  const { vehicleInfo } = useVehicleProfileContext();

  const updateVehicle = useCallback(
    async (data: Omit<UpdateVehicleInput, 'id'>) => {
      try {
        const id = vehicleInfo!.id;

        setLoading(true);
        await updateVehicleMutation({
          variables: {
            payload: {
              ...data,
              id,
            },
          },
          awaitRefetchQueries: true,
        });

        notification['success']({
          message: `Dados do ${
            vehicleInfo?.displayName ?? vehicleInfo?.plate
          } atualizado com sucesso`,
          description: 'Confira as informações no perfil do veículos',
        });

        setLoading(false);
      } catch (error) {
        notification['error']({
          message: 'Erro ao atualizar os dados do veículos',
          description: 'Contate o suporte',
        });

        console.error(error);
      }
    },
    [updateVehicleMutation, vehicleInfo],
  );

  const value: VehicleProfileFormContextValue = {
    loading,
    updateVehicle,
  };

  return (
    <VehicleProfileFormContext.Provider value={value}>
      {props.children}
    </VehicleProfileFormContext.Provider>
  );
}

export function useVehicleProfileFormContext() {
  const context = useContext(VehicleProfileFormContext);

  if (typeof context === 'undefined') {
    throw new Error(
      `useVehicleProfileFormContext must be used within a VehicleProfileFormContext`,
    );
  }
  return context;
}

export default VehicleProfileFormContextProvider;
