import { Drawer, Tabs } from 'antd';
import styled from 'styled-components';

export const DrawerContainer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const DrawerHead = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const DrawerTitle = styled.div`
  font-size: 1rem;
`;

export const TabsCustom = styled(Tabs)`
  flex: 1;
  height: 100%;

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.purple};
  }

  .ant-tabs-tab {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .ant-tabs-tab-btn {
    color: #b3bcc9;
    text-align: center;
    margin-left: 0;
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.purple};
  }

  .ant-tabs-nav-list {
    justify-content: center;
    width: 100%;
  }

  .ant-tabs-content-holder {
    display: flex;
  }

  .ant-tabs-tabpane {
    display: flex;
  }

  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    left: 0;
    width: 50%;
  }
`;
