import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import SidebarContainer from './Container';

const Flag = styled.span`
  border: 1px solid ${(props) => props.theme.colors.purple};
  border-radius: 4px;
  font-size: 0.625rem;
  color: ${(props) => props.theme.colors.purple};
  padding: 1px 2px;
  margin-left: 5px;
`;

const IconContainer = styled.span`
  --icon-color: var(--sidebar-icon-color-open);
  display: block;
  grid-area: icon;
  text-align: center;
  font-size: 24px;
  transition: visibility var(--sidebar-collapse-duration) ease-in-out,
    opacity var(--sidebar-collapse-duration) ease-in-out;
  ${SidebarContainer}.collapsed & {
    --icon-color: var(--sidebar-icon-color-close);
  }
  ${SidebarContainer}.collapsed a[aria-current='page'] & {
    --icon-color: var(--sidebar-background-close);
  }
  &,
  &:hover {
    color: var(--icon-color);
  }

  ${SidebarContainer}:not(.collapsed) & {
    color: currentColor;
  }
`;

const Container = styled.li`
  margin: 0;
  padding: 0;

  a {
    display: grid;
    align-items: center;
    grid-template-areas: 'icon label';
    grid-template-columns: 49px auto;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: currentColor;
    min-height: 40px;
    border-radius: 3px;
    margin: 0 8px;
    transition: background-color 0.2s;
    border-radius: 8px;

    &:not(.active):hover {
      background-color: #f1f3f7;

      ${IconContainer} {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${SidebarContainer}:not(.collapsed) & a[aria-current='page'] {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};

    ${Flag} {
      color: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.white};
    }
  }

  ${SidebarContainer}.collapsed & a[aria-current='page'] {
    background-color: #f1f3f7;
  }
`;

const LabelContainer = styled.span`
  display: block;
  position: relative;
  grid-area: label;
  text-align: left;
  transition: visibility var(--sidebar-collapse-duration) ease-in-out,
    opacity var(--sidebar-collapse-duration) ease-in-out;
  ${SidebarContainer}.collapsed & {
    visibility: hidden;
    opacity: 0;
  }
`;

type MenuItemProps = {
  label: string | React.ReactNode;
  flag?: string;
  icon: ReactNode;
  href: string;
  target?: string;
  isExternal?: boolean;
  event?: () => void;
};

export default function MenuItem(props: MenuItemProps) {
  const { label, icon, flag, href, target, isExternal, event } = props;

  return (
    <Container onClick={event}>
      {isExternal ? (
        <a href={href} target="_blank" rel="noreferrer">
          <IconContainer>{icon}</IconContainer>
          <LabelContainer>
            {label}
            {flag ? <Flag>{flag}</Flag> : null}
          </LabelContainer>
        </a>
      ) : (
        <NavLink to={href} target={target}>
          <IconContainer>{icon}</IconContainer>
          <LabelContainer>
            {label}
            {flag ? <Flag>{flag}</Flag> : null}
          </LabelContainer>
        </NavLink>
      )}
    </Container>
  );
}
