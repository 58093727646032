import styled, { css } from 'styled-components';

type InputProps = {
  $side?: 'left' | 'right';
  $isFocused?: boolean;
};

export const Suffix = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.grey};
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
`;

export const Input = styled.input<InputProps>`
  height: 100%;
  width: 100%;
  top: 18px;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 8px;
  padding: 0.5rem 1rem;

  &:focus {
    outline-color: #d6bbfb;
  }
`;

export const InputContainer = styled.div<InputProps>`
  display: flex;
  align-items: center;
  height: 40px;

  border-radius: 8px;

  background-color: #fff;
  min-width: 80px;

  ${Suffix} {
    ${({ $side }) =>
      $side === 'left'
        ? css`
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `
        : css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
          `}

    ${({ $isFocused }) =>
      $isFocused &&
      css`
        border-color: #d6bbfb;
      `}
  }

  ${Input} {
    ${({ $side }) =>
      $side === 'left'
        ? css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          `
        : css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `}
  }
`;

export const Label = styled.label`
  width: 100%;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`;
