import styled, { css } from 'styled-components';
import Icon from '../Icon/Icon';

interface InputProps {
  $hasIcon?: boolean;
  $hasLabel?: boolean;
  $width?: string;
  $disabled?: boolean;
  $activeLabel?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled.div<InputProps>`
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 42px;
  width: ${({ $width }) => ($width ? $width : '100%')};
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${(props) => props.theme.colors.greyLighter};
    `};
`;

export const LoadingContainer = styled.div`
  display: block;
  width: 100%;
`;

export const LeftIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.625rem;
`;

export const RightIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.625rem;
`;

export const TogglePasswordView = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.625rem;
  cursor: pointer;

  color: rgba(0, 0, 0, 0.25);
`;

export const InputContainer = styled.div<InputProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  label {
    position: absolute;
    top: 50%;

    left: 0.8rem;
    cursor: text;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #667085;
    padding: 0 0.1rem;

    transition: all 200ms ease-in;

    ${({ $hasIcon }) =>
      $hasIcon &&
      css`
        left: 32px;
      `}

    ${({ $activeLabel }) =>
      $activeLabel
        ? css`
            font-size: 12px;
            top: -0.4rem;
            background: ${({ theme }) => theme.colors.white};
            font-weight: 500;
          `
        : css`
            transform: translateY(-50%);
          `};
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    display: flex;
    flex: 1;
    background: transparent;
    border-radius: 8px;
    padding: 8px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &:focus {
      outline-color: #d6bbfb;
    }

    &:focus ~ label,
    :not(:placeholder-shown):not(:focus) ~ label {
      font-size: 12px;
      top: -0.4rem;
      background: ${({ theme }) => theme.colors.white};
      font-weight: 500;
      transform: translateY(0);
      left: 0.8rem;
    }

    &:disabled,
    &:disabled ~ label {
      cursor: not-allowed;
    }

    ${({ $hasIcon }) =>
      $hasIcon &&
      css`
        padding-left: 2rem;
      `}
  }
`;
