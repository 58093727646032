export default function formatNumber(
  value?: number | null,
  {
    fractionDigits = 2,
    fallback = '-,--',
  }: { fractionDigits?: number; fallback?: string } = {},
): string {
  if (typeof value === 'undefined' || value === null) {
    return fallback;
  }

  return Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(value);
}
