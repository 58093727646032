import React, { ChangeEventHandler, useMemo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import formatCurrency from '../../helpers/format-currency';
import useMaskedDecimalValue from '../MaskedNumberInput/use-masked-decimal-value';
import { Input, InputContainer, Label, Suffix } from './InputWithSuffix.styles';

export type InputWithSuffixProps = {
  label?: string;
  suffix: string;
  value?: number;
  onChange: (value: number | null) => void;
  placeholder?: string;
  side?: 'left' | 'right';
  formatter?: (value: number | null) => string;
  disabled?: boolean;
};

const defaultFormatter = (value: number | null) => {
  if (value === null || typeof value === 'undefined' || isNaN(value)) {
    return '';
  }

  return value.toFixed(2);
};

function InputWithSuffix(props: InputWithSuffixProps) {
  const {
    label,
    onChange,
    suffix,
    value,
    placeholder,
    side = 'left',
    formatter = defaultFormatter,
    disabled,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const formatterFunction = useMemo(() => {
    switch (suffix) {
      case 'R$' || 'r$':
        return () => formatCurrency(value, '');
      default:
        return formatter;
    }
  }, [formatter, suffix, value]);

  const { formattedValue, unmask } = useMaskedDecimalValue({
    value,
    formatter: formatterFunction,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(unmask(event.target.value));
  };

  useClickAway(inputRef, () => {
    setIsFocused(false);
  });

  return (
    <>
      <Label>{label}</Label>
      <InputContainer $side={side} $isFocused={isFocused}>
        {side === 'left' && <Suffix>{suffix}</Suffix>}
        <Input
          placeholder={placeholder}
          onChange={handleChange}
          value={formattedValue}
          ref={inputRef}
          onFocus={() => setIsFocused(true)}
          type="text"
          disabled={disabled}
        />
        {side === 'right' && <Suffix>{suffix}</Suffix>}
      </InputContainer>
    </>
  );
}

export default InputWithSuffix;
