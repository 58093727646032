import { ApolloProvider } from '@apollo/client/react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'urql';
import AuthContextProvider from '../auth/contexts/AuthContextProvider';
import AuthApp from '../AuthApp';
import GlobalStyle from '../GlobalStyle';
import apolloClient from '../graphql/apollo-client';
import client from '../graphql/client';
import themes from '../themes';

function AppProvider(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <Provider value={client}>
          <ThemeProvider theme={themes.light}>
            <GlobalStyle />
            <ConfigProvider locale={ptBR}>
              <AuthApp>{children}</AuthApp>
            </ConfigProvider>
          </ThemeProvider>
        </Provider>
      </AuthContextProvider>
    </ApolloProvider>
  );
}

export default AppProvider;
