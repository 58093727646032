import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
`;

export const List = styled.div`
  ul {
    padding: 0;
  }
`;

export const GlobalFilterWrapper = styled.div`
  padding: 13px 25px 5px 9px;
  border-bottom: 1px solid #f8f8f8;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  background: #ffffff;
  border-radius: 5px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.theme.colors.white};
  padding: 18px 26px;
  border-radius: 5px;

  p {
    margin: 0;
  }
`;
