import styled, { css } from 'styled-components';

interface SwitchProps {
  $isActive: boolean;
  $size?: string;
  $disabled?: boolean;
  $activeColor?: string;
}

export const Container = styled.label`
  position: relative;
`;

export const SwitchContainer = styled.div<SwitchProps>`
  position: relative;
  display: flex;
  cursor: pointer;
  height: 32px;
  width: 60px;
  border-radius: 100px;
  background-color: #c3c6d1;
  transition: background-color 0.3s ease;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `};

  ${({ $isActive, theme, $activeColor }) =>
    $isActive &&
    css`
      background-color: ${$activeColor ? $activeColor : theme.colors.purple};
    `}

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 4px;
    height: 25px;
    width: 25px;
    border-radius: 45px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.2s ease;

    ${({ $isActive }) =>
      $isActive &&
      css`
        left: calc(100% - 4px);
        transform: translateX(-100%);
      `}
  }

  &:active:before {
    width: 34px;
  }

  ${({ $size, $isActive }) =>
    $size === 'small' &&
    css`
      width: 35px;
      height: 20px;

      &::before {
        top: 2px;
        left: 2px;
        height: 16px;
        width: 16px;

        ${$isActive &&
        css`
          left: calc(100% - 2px);
          transform: translateX(-100%);
        `}
      }
    `}
`;
