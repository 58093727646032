import React, { useEffect, useMemo, useState } from 'react';
import { useFuelTypesQuery } from '../../generated/graphqlV2';
import SelectSearch, { SelectDataType } from '../SelectSearch';

export type SelectFuelTypeProps = {
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
  name?: string;
};

function SelectFuelType(props: SelectFuelTypeProps) {
  const { name = 'fuelType', onChange, disabled, value, error } = props;
  const [selectedFuelType, setSelectedFuelType] = useState<SelectDataType>();

  const { data, loading } = useFuelTypesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const parsedVehicles = useMemo(() => {
    if (!data) return [];

    const parsedData = data?.fuelTypes.map((fuel) => {
      return {
        id: fuel.slugName,
        text: fuel.description,
      };
    });

    return parsedData;
  }, [data]);

  function handleChange(value: SelectDataType) {
    const foundValue = data?.fuelTypes?.find(
      (fuel) => fuel.slugName === value?.id,
    );

    if (!foundValue) return;

    onChange(foundValue.slugName);
  }

  useEffect(() => {
    if (!value) return;
    const foundValue = parsedVehicles?.find((fuel) => fuel.id === value);

    setSelectedFuelType(foundValue);
  }, [parsedVehicles, value]);

  return (
    <SelectSearch
      data={parsedVehicles}
      label="Combustível"
      placeholder={'Tipo de combustível'}
      onChange={handleChange}
      value={selectedFuelType}
      loading={loading}
      disabled={disabled}
      name={name}
      error={error}
    />
  );
}

export default SelectFuelType;
