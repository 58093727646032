import styled from 'styled-components';

const PageContainer = styled.div`
  padding: 2rem;
  display: grid;
  flex-direction: column;
  grid-template-columns: minmax(min-content, max-content) auto auto;
  grid-row-gap: 20px;
  grid-template-rows: minmax(min-content, max-content) 50px auto;
  grid-template-areas:
    'goBack . .'
    'title . actions'
    'content content content';
  min-height: 100%;
`;

export default PageContainer;
