import React, { useEffect } from 'react';

import { useState } from 'react';
import RadioButtonView from './RadioButtonView';

export type OnChangeEventData = {
  checked: ChoicesData;
  index: number;
};

export type ChoicesData = {
  name: string;
  id: string | number;
};

export type RadioButtonProps = {
  buttonPadding?: string | number;
  choices: ChoicesData[];
  color?: string;
  containerPadding?: string | number;
  gridGap?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  onChange: (event: OnChangeEventData) => void;
  value: number;
};

function RadioButton(props: RadioButtonProps) {
  const {
    choices,
    onChange,
    color,
    minWidth,
    maxWidth,
    containerPadding,
    buttonPadding,
    gridGap,
    value,
  } = props;

  const [checked, setChecked] = useState(0);

  function handleClick(choice: ChoicesData, index: number) {
    setChecked(index);
    onChange({ checked: choice, index });
  }

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <RadioButtonView
      choices={choices}
      handleClick={handleClick}
      checked={checked}
      color={color}
      minWidth={minWidth}
      maxWidth={maxWidth}
      containerPadding={containerPadding}
      buttonPadding={buttonPadding}
      gridGap={gridGap}
    />
  );
}

export default RadioButton;
