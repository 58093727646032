import styled from 'styled-components';
import Icon from '../../../../../../components/Icon/Icon';

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-bottom: 16px;

  position: relative;

  border: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  border-radius: 3px;
  max-height: 100px;
  margin-right: 8px;
  cursor: pointer;

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};

    ${Icon} {
      background: #7758c7;
      color: #ffffff;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FormContainer = styled.div`
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Row = styled.section`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    border-radius: 5px;
  }
`;
