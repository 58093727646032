import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth } from '../../auth/contexts/AuthContextProvider';
import DownloadAppModal from '../../components/DownloadAppModal/DownloadAppModal';
import ErrorFallback from '../../components/ErrorFallback/ErrorFallback';
import { useCurrentUserContext } from '../../contexts/CurrentUserContextProvider';
import Announcement from './Announcement';
import Header from './Header';
import { Container, Content } from './MainLayout.styles';
import Sidebar from './Sidebar';

type DashboardPropsProps = {
  children: ReactNode;
};

function MainLayout(props: DashboardPropsProps) {
  const { children } = props;
  const { logout } = useAuth();
  const { user, isSuspended } = useCurrentUserContext();

  const displayName = user.name ?? user.cpf ?? user.email ?? 'Usuário';

  return (
    <Container>
      <DownloadAppModal />
      <Announcement />
      <Header name={displayName} onLogout={logout} />
      {!isSuspended && <Sidebar />}
      <Content>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>
      </Content>
    </Container>
  );
}

export default MainLayout;
