import { devtoolsExchange } from '@urql/devtools';
import { retryExchange } from '@urql/exchange-retry';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  subscriptionExchange,
} from 'urql';
import { session } from '../auth/contexts/AuthContextProvider';
import fetchOptionsExchange from '../helpers/fetch-options-exchange';

const getAuthParameters = async () => {
  // get the authentication token from local storage if it exists
  const headers = {
    authorization: `Bearer ${await session.getAccessToken()}`,
  };

  return {
    headers,
  };
};

const subscriptionClient = new SubscriptionClient(
  `${process.env.REACT_APP_HASURA_ENDPOINT}`.replace(/^(http)/, 'ws'),
  {
    lazy: true,
    reconnect: true,
    connectionParams: getAuthParameters,
    connectionCallback: (err) => {
      if (err) {
        subscriptionClient.close(false, false);
      }
    },
  },
  WebSocket,
);

const client = createClient({
  url: process.env.REACT_APP_HASURA_ENDPOINT as string,
  exchanges: [
    devtoolsExchange,
    dedupExchange,
    cacheExchange,

    // Must be called before fetchExchange and after all others sync exchanges, respecting the rule Synchronous first, Asynchronous last
    fetchOptionsExchange(async (fetchOptions: any) => {
      const headers = await getAuthParameters();
      return {
        ...fetchOptions,
        ...headers,
      };
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) =>
        subscriptionClient.request(operation) as any,
    }),
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (err) => !!(err && err.networkError),
    }),
  ],
});

export default client;
