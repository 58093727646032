import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import Bell from '../icons/Bell';

const Container = styled.div`
  padding: 16px;
  background-color: #ffffff;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  background: #f2f4f7;
  border: 8px solid #f9fafb;
  border-radius: 28px;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #344054;
  align-self: stretch;
`;

export type EmptyNotificationsProps = {};

function EmptyNotifications(props: EmptyNotificationsProps) {
  return (
    <Container>
      <IconWrapper>
        <Icon $size="22px" $color="#545454">
          <Bell />
        </Icon>
      </IconWrapper>

      <Description>Nenhuma notificação encontrada.</Description>
    </Container>
  );
}

export default EmptyNotifications;
