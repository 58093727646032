import { Dropdown, Menu } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserRole } from '../../../generated/graphqlV2';
import Avatar from '../../../components/Avatar/Avatar';
import Chevron from '../../../components/Chevron';
import {
  Container,
  FirstName,
  WelcomeMessage,
  Wrapper,
} from './Profile.styles';
import { useCurrentUserContext } from '../../../contexts/CurrentUserContextProvider';

const LOGOUT_BUTTON = 'logout';
const USERS = 'users';

export interface ProfileProps {
  name: string;
  onLogout: () => void;
}

const StyledAvatar = styled(Avatar)`
  margin-left: 1rem;
`;

function Profile(props: ProfileProps) {
  const { name, onLogout } = props;
  let history = useNavigate();
  const {
    user: { role: userRole },
    isSuspended,
  } = useCurrentUserContext();

  const menu = (
    <Menu
      onClick={({ key }) => {
        if (key === LOGOUT_BUTTON) onLogout();
        if (key === USERS) history('/users');
      }}
    >
      {userRole === UserRole.Manager && !isSuspended && (
        <Menu.Item key={USERS}>Usuários</Menu.Item>
      )}

      <Menu.Item key={LOGOUT_BUTTON}>Sair</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Container>
        <Wrapper>
          <WelcomeMessage>Bem-vindo</WelcomeMessage>
          <FirstName>{name}</FirstName>
        </Wrapper>
        <StyledAvatar
          name={name}
          imgPath={`${process.env.PUBLIC_URL}/images/empty-avatar.svg`}
        />
        <Chevron />
      </Container>
    </Dropdown>
  );
}

export default Profile;
