import styled from 'styled-components';

export const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const ModalContainer = styled.div`
  height: 447px;
  width: 343px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
`;

export const ModalTitle = styled.span`
  margin-top: 16px;
  font-size: 20px;
  color: #1d2939;
  font-weight: 600;
`;

export const ModalDescription = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #667085;
  text-align: center;
  margin: 0px 21px 24px 21px;
`;

export const Link = styled.a`
  margin-bottom: 8px;
`;

export const CloseButton = styled.button`
  text-align: center;
  background-color: #eaecf0;
  width: 311px;
  height: 42px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 32px;
`;
