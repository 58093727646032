import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: minmax(min-content, max-content) var(--header-height) 1fr;
  grid-template-columns: min-content 1fr;
  grid-template-areas:
    'announcement announcement'
    'header  header'
    'sidebar main';
`;

export const Content = styled.main`
  grid-area: main;
  position: relative;
  z-index: 1;
  overflow-y: auto;
`;
