import * as React from 'react';
import { useClickAway, useToggle } from 'react-use';
import styled from 'styled-components';
import Icon from '../../../components/Icon/Icon';
import Bell from '../../../components/icons/Bell';
import Notifications from '../../../components/Notifications/Notifications';
import { useNotificationsSummaryQuery } from '../../../generated/graphqlV2';

const NotificationButton = styled.div`
  height: 33px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const NotificationsCount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  background-color: #fc7a7a;
  padding: 0px 4px;
  border-radius: 21px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
`;

export type NotificationsButtonProps = {};

function NotificationsButton(props: NotificationsButtonProps) {
  const [open, toggle] = useToggle(false);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const { data: { notificationsSummary: { unRead = 0 } = {} } = {}, refetch } =
    useNotificationsSummaryQuery({ fetchPolicy: 'cache-and-network' });

  const handleToggle = React.useCallback(
    (open?: boolean) => {
      if (open === false) {
        refetch();
      }

      toggle(open);
    },
    [toggle, refetch],
  );

  useClickAway(containerRef, () => {
    if (open) handleToggle(false);
  });

  return (
    <div ref={containerRef}>
      <NotificationButton onClick={() => handleToggle(!open)}>
        <Icon $size="22px" $color="#545454">
          <Bell />
        </Icon>
        {unRead > 0 ? (
          <NotificationsCount>
            {unRead >= 100 ? '+99' : unRead}
          </NotificationsCount>
        ) : null}
      </NotificationButton>
      {open ? <Notifications /> : null}
    </div>
  );
}

export default NotificationsButton;
