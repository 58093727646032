import styled, { css } from 'styled-components';
import Icon from '../Icon/Icon';

interface SelectProps {
  $showOptions?: boolean;
  $disabled?: boolean;
  $size?: 'normal' | 'small';
}

interface OptionProps {
  $isSelected?: boolean;
  $disabled?: boolean;
  $size?: 'normal' | 'small';
}

interface LabelProps {
  $hasLabel: boolean;
}

interface ContainerProps {
  $width?: string;
}

export const SelectContainer = styled.div<ContainerProps>`
  width: ${({ $width }) => ($width ? $width : '100%')};
`;

export const Select = styled.input<{ $disabled?: boolean }>`
  box-sizing: border-box;
  padding: 8px 42px 8px 14px;
  gap: 8px;
  width: 100%;
  height: 42px;

  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline-color: #d6bbfb;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background: #f9fafb;
      cursor: not-allowed;
    `}
`;

export const Container = styled.div<SelectProps>`
  width: 100%;
  position: relative;
  transition: 0.5s ease-out;

  ${({ $disabled }) =>
    $disabled &&
    css`
      background: #f9fafb;
      cursor: not-allowed;
    `}

  ${({ $size }) =>
    $size === 'small' &&
    css`
      ${Select} {
        height: 32px;
        padding: 6px;
        font-size: 14px;
      }

      ${Icon} {
        padding: 0;
      }
    `}
`;

export const LoadingContainer = styled.div`
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
  height: 42px;

  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
`;

export const SelectIcon = styled.div<SelectProps>`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease;

  ${({ $showOptions }) =>
    $showOptions &&
    css`
      transform: rotate(180deg) translateY(50%);
    `}

  ${({ $size }) =>
    $size === 'small' &&
    css`
      right: 8px;

      ${Icon} {
        font-size: 12px;
      }
    `}
`;

export const OptionsList = styled.ul<SelectProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  z-index: 1;
  max-height: 242px;

  position: absolute;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  top: 44px;

  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 156px;
  }

  &::-webkit-scrollbar-thumb {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 50%;
    background: #eaecf0;
    border-radius: 8px;
  }

  ${({ $showOptions }) =>
    !$showOptions &&
    css`
      display: none;
    `}
`;

export const Option = styled.li<OptionProps>`
  font-family: 'Inter';
  font-style: normal;
  padding: 8px 14px;
  color: #101828;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    word-break: break-word;
  }

  ${({ $size }) =>
    $size === 'small' &&
    css`
      padding: 4px 6px;
      p {
        font-size: 14px;
      }
    `}

  &:hover {
    background-color: #f9fafb;
  }

  &:focus {
    outline-color: ${({ theme }) => theme.colors.greyLight};
  }

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      background-color: ${theme.colors.greyLighter};
    `}
`;

export const Label = styled.label<LabelProps>`
  position: absolute;
  top: -5px;
  left: 14px;
  background: #ffffff;
  padding: 0 4px 0 4px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;

  color: #667085;

  ${({ $hasLabel }) =>
    !$hasLabel &&
    css`
      display: none;
    `}
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Tag = styled.div`
  background-color: #f2effa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 4px 8px;
  width: fit-content;

  span {
    color: #7758c7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  width: 100%;
`;
