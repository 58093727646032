import React, { useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import themes from '../../themes';
import Empty from '../Empty/Empty';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Icon from '../Icon/Icon';
import Check from '../icons/Check';
import ChevronDown from '../icons/ChevronDown';
import {
  Container,
  EmptyContainer,
  Item,
  Label,
  LoadingContainer,
  Option,
  OptionsList,
  Select,
  SelectContainer,
  SelectIcon,
  Tag,
} from './styles';

export type SelectDataType = {
  id: string;
  text: string;
  subText?: string;
};

function defaultGetOptionDisabled<Item>(_option: Item) {
  return false;
}

interface SelectSearchProps {
  data: SelectDataType[];
  label?: string;
  name?: string;
  placeholder?: string;
  value?: SelectDataType | null;
  onChange: (value: SelectDataType) => void;
  labelStyle?: object;
  newTagText?: string;
  loading?: boolean;
  disabled?: boolean;
  onAddNew?: (text: string) => void;
  error?: string;
  getDisabledOption?: (option: SelectDataType) => boolean;
  size?: 'normal' | 'small';
  width?: string;
}

function SelectSearch(props: SelectSearchProps) {
  const {
    label,
    placeholder,
    data,
    value,
    onChange,
    labelStyle,
    newTagText,
    loading,
    disabled,
    onAddNew,
    error,
    name,
    getDisabledOption = defaultGetOptionDisabled,
    size,
    width,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState(value?.text || '');
  const [search, setSearch] = useState('');
  const [selectedValue, setSelectedValue] = useState<SelectDataType | null>(
    null,
  );
  const ref = useRef([] as any);
  const inputRef = useRef<HTMLInputElement>(null);

  function hideOptions() {
    setTimeout(() => {
      let hasActiveItem = false;
      ref.current.forEach((item: HTMLElement) => {
        if (item === document.activeElement) {
          hasActiveItem = true;
        }
      });
      if (!hasActiveItem) {
        setShowOptions(false);
        !!selectedValue && setInputValue(selectedValue.text);
        setSearch('');
      }
    }, 200);
  }

  function hardCloseOptions() {
    setShowOptions(false);
  }

  function handleClickButton() {
    if (showOptions) {
      inputRef?.current?.blur();
    } else {
      inputRef?.current?.focus();
    }
  }

  function openOptions() {
    setShowOptions(true);
  }

  function onInputTextChange(text: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(text.target.value);
    setSearch(text.target.value);
  }

  function selectItem(item: SelectDataType) {
    setInputValue(item.text ?? '');
    setSelectedValue(item);
    onChange(item);
    hardCloseOptions();
  }

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return (
        item.text.toLowerCase().includes(search.toLowerCase()) ||
        item.subText?.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [data, search]);

  function selectAddNew() {
    hardCloseOptions();
    onAddNew && onAddNew(inputValue);
    onChange({ id: '', text: inputValue });
    setSearch('');
  }

  useEffect(() => {
    const foundData = data.find((data) => data.id === value?.id) || null;
    setSelectedValue(foundData);
    setInputValue(foundData?.text ?? '');
  }, [data, value]);

  return (
    <SelectContainer
      data-testid={name?.toLowerCase().replaceAll(' ', '-')}
      $width={width}
    >
      {loading && !value ? (
        <LoadingContainer>
          <Skeleton
            width={'100%'}
            height={'100%'}
            style={{ display: 'inline-block' }}
          />
        </LoadingContainer>
      ) : (
        <Container
          id="select-block"
          $showOptions={showOptions}
          $disabled={disabled}
          $size={size}
        >
          <Select
            type="search"
            ref={inputRef}
            onFocus={openOptions}
            onBlur={hideOptions}
            placeholder={placeholder || ''}
            value={inputValue}
            onChange={onInputTextChange}
            id={name || label?.toLocaleLowerCase().replaceAll(' ', '-')}
            $disabled={disabled}
          />
          <Label
            htmlFor={name || label?.toLocaleLowerCase().replaceAll(' ', '-')}
            $hasLabel
          >
            <span style={{ ...labelStyle }}>{label}</span>
          </Label>
          <SelectIcon $size={size} $showOptions={showOptions}>
            <Icon onClick={handleClickButton}>
              <ChevronDown />
            </Icon>
          </SelectIcon>

          <OptionsList $showOptions={showOptions}>
            {filteredData?.length > 0 ? (
              filteredData.map((item: SelectDataType, index: number) => (
                <Option
                  ref={(element) => (ref.current[index] = element)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') selectItem(item);
                  }}
                  key={index}
                  tabIndex={0}
                  onBlur={hideOptions}
                  onClick={
                    getDisabledOption(item) ? () => {} : () => selectItem(item)
                  }
                  $isSelected={inputValue === item.text}
                  $disabled={getDisabledOption(item)}
                  $size={size}
                >
                  <Item>
                    <p>{item.text}</p>
                    {item.subText && (
                      <Tag>
                        <span>{item.subText}</span>
                      </Tag>
                    )}
                  </Item>
                  {selectedValue?.id === item.id && (
                    <Icon>
                      <Check color={themes.light.colors.purple} />
                    </Icon>
                  )}
                </Option>
              ))
            ) : onAddNew && inputValue !== '' ? (
              <Option
                ref={(element) => (ref.current[0] = element)}
                tabIndex={0}
                onBlur={hideOptions}
                onClick={selectAddNew}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') selectAddNew();
                }}
                $size={size}
              >
                <p>{inputValue}</p>
                <Tag>
                  <span>{newTagText || 'Adicionar novo'}</span>
                </Tag>
              </Option>
            ) : (
              <EmptyContainer>
                <Empty description="Nenhum dado encontrado" />
              </EmptyContainer>
            )}
          </OptionsList>
        </Container>
      )}

      {error && <ErrorMessage message={error} />}
    </SelectContainer>
  );
}

export default SelectSearch;
