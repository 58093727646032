import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Icon from '../../../../../components/Icon/Icon';

interface PermissionProps {
  $isSelected?: boolean;
}

export const Container = styled.main`
  display: grid;
  grid-template-columns: 352px 1fr;
  column-gap: 24px;
  margin-top: 32px;
  height: 100%;
  max-height: 722px;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled(Link)<PermissionProps>`
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;

  & + & {
    margin-top: 8px;
  }

  ${Icon} {
    margin-right: 8px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  ${(props) =>
    props.$isSelected &&
    css`
      background-color: ${(props) => props.theme.colors.secondaryLight};
    `}

  &:hover {
    background-color: ${(props) => props.theme.colors.secondaryLight};
    color: inherit;
  }

  :visited {
    color: inherit;
  }
`;
