import React from 'react';
import VehicleProfileFormContextProvider from '../../../contexts/VehicleProfileFormContext';
import SettingsActions from './SettingsActions';
import SettingsContent from './SettingsContent';

import { Container } from './styles';

function VehicleSettingsTab() {
  return (
    <VehicleProfileFormContextProvider>
      <Container>
        <SettingsActions />
        <SettingsContent />
      </Container>
    </VehicleProfileFormContextProvider>
  );
}

export default VehicleSettingsTab;
