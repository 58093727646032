import styled from 'styled-components';

export const PageTitle = styled.h1`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #434343;
`;

export const PageActionsContainer = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 15px;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  gap: 12px;
`;
