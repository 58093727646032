import React from 'react';
import AppsIcons from '../icons/AppsIcons';
import {
  Link,
  ModalContainer,
  ModalDescription,
  ModalTitle,
  Screen,
} from './DownloadAppModal.styles';

const DownloadAppModal = () => {
  return (
    <Screen>
      <ModalContainer>
        <AppsIcons />
        <ModalTitle>Baixe o aplicativo</ModalTitle>
        <ModalDescription>
          Para uma melhor experiência com o produto pelo celular, faça o
          download do aplicativo.
        </ModalDescription>
        <Link href="https://play.google.com/store/apps/details?id=com.infleet.horus">
          <img
            src={'./images/google-play-icon.png'}
            alt="GooglePlay download button"
          />
        </Link>
        <Link href="https://apps.apple.com/br/app/infleet-gestor/id1572874568">
          <img
            src={'./images/app-store-icon.png'}
            alt="AppStore download button"
          />
        </Link>
      </ModalContainer>
    </Screen>
  );
};

export default DownloadAppModal;
