import moment from 'moment';
import React, { useCallback } from 'react';
import Button from '../../../../../../components/Button/Button';
import DropdownMenu, {
  DropdownMenuProps,
} from '../../../../../../components/DropdownMenu/DropdownMenu';
import Icon from '../../../../../../components/Icon/Icon';
import MoreVertical from '../../../../../../components/icons/MoreVertical';
import { TrafficInfractionFragment } from '../../../../../../generated/graphqlV2';
import formatCurrency from '../../../../../../helpers/format-currency';
import formatDate from '../../../../../../helpers/format-date';
import { DrawerType } from '../../../../types/TrafficInfractions.types';
import { useDrawerContext } from '../contexts/DrawerContext';
import { useTrafficInfractionFormContext } from '../contexts/TrafficInfractionFormContext';
import { DropdownContainer } from '../TrafficInfractionTable/styles';
import { Address, Column, Item, Title } from './styles';

export type Action<Key> = {
  key: Key;
  label: string;
};
export interface TrafficInfractionItemProps<Key = unknown> {
  actions: readonly Action<Key>[];
  data: TrafficInfractionFragment;
  onClick: () => void;
}

function TrafficInfractionItem(props: TrafficInfractionItemProps) {
  const { actions, data, onClick } = props;

  const { deleteTrafficInfractionHandler } = useTrafficInfractionFormContext();
  const { open } = useDrawerContext();

  const handleDropdownMenu: DropdownMenuProps['onClick'] = useCallback(
    (action, trafficInfraction) => {
      switch (action.key) {
        case 'update':
          open(DrawerType.edit, trafficInfraction);
          return;
        case 'delete':
          return deleteTrafficInfractionHandler([trafficInfraction.id]);
      }
    },
    [deleteTrafficInfractionHandler, open],
  );

  return (
    <Item>
      <Column>
        <Title>Data</Title>
        <p>{formatDate(data.occurredAt)}</p>
      </Column>
      <Column>
        <Title>Condutor</Title>
        <p>{data.driver?.name}</p>
      </Column>
      <Column>
        <Title>Local</Title>
        <Address>{data.address}</Address>
      </Column>
      <Column>
        <Title>Hora</Title>
        <p>{moment(data.occurredAt).format('HH:MM').toString()}</p>
      </Column>
      <Column>
        <Title>Infração</Title>
        <p>{data.infraction.description}</p>
      </Column>
      <Column>
        <Title>Valor</Title>
        <p>{formatCurrency(data.cost)}</p>
      </Column>
      <Button $variant="secondary" onClick={onClick}>
        Pagar
      </Button>
      <DropdownMenu
        actions={actions}
        onClick={(action) => handleDropdownMenu(action, data)}
      >
        <DropdownContainer>
          <Icon>
            <MoreVertical />
          </Icon>
        </DropdownContainer>
      </DropdownMenu>
    </Item>
  );
}

export default TrafficInfractionItem;
