import { TimePicker } from 'antd';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

export const TimePickerContainer = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 8px;
  position: relative;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    top: -8px;
    left: 18px;
    z-index: 2;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const TimePickerWrapper = styled(TimePicker)`
  padding: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  width: 100%;

  .ant-picker-input {
    input {
      text-align: center;
    }
  }

  &.ant-picker:hover {
    border: 1px solid #d0d5dd;
  }

  &.ant-picker-focused,
  &.ant-picker-focused:hover {
    box-shadow: none;
    border: 1px solid #d6bbfb;
  }

  .ant-picker-active-bar {
    height: 3px;
    background: #d6bbfb;
  }

  .ant-picker-suffix {
    color: #434343;
  }

  &.ant-picker-cell-selected {
    background-color: ${(props) => props.theme.colors.purple};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
