import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../../../../components/Button/Button';
import CustomScrollbar from '../../../../../../components/CustomScrollbar/CustomScrollbar';
import Edit from '../../../../../../components/icons/Edit';
import Input from '../../../../../../components/Input';
import MaskedNumberInput from '../../../../../../components/MaskedNumberInput/MaskedNumberInput';
import SelectFuelType from '../../../../../../components/SelectFuelType/SelectFuelType';
import TextArea from '../../../../../../components/TextArea';
import {
  FuelTypeEnum,
  UpdateVehicleInput,
  VehicleTypeEnum,
} from '../../../../../../generated/graphqlV2';
import formatCurrency from '../../../../../../helpers/format-currency';
import getVehicleImage from '../../../../../../helpers/get-vehicle-image';
import { useAvatarModalContext } from '../../../../contexts/AvatarModalContext';
import { useVehicleProfileContext } from '../../../../contexts/VehicleProfileContext';
import { useVehicleProfileFormContext } from '../../../../contexts/VehicleProfileFormContext';
import { AvatarImage, EditAvatar } from '../../../Header/styles';
import {
  AvatarContainer,
  ButtonContainer,
  Col,
  Form,
  FormContainer,
  Row,
} from './styles';

const vehicleSchema = yup.object().shape({
  plate: yup.string().required('Campo obrigatório'),
  displayName: yup.string().nullable(),
  brand: yup.string().nullable(),
  model: yup.string().nullable(),
  year: yup.string().nullable(),
  fuelType: yup.string(),
  document: yup.string(),
  price: yup.number().nullable(),
  observations: yup.string().nullable(),
});

type FormValues = {
  plate: string;
  displayName?: string;
  brand?: string;
  model?: string;
  fuelType?: string;
  document?: string;
  price?: number;
  observation?: string;
  year?: string;
};

function VehicleInfo() {
  const { vehicleInfo: vehicle, gettingVehicleData } =
    useVehicleProfileContext();
  const { loading, updateVehicle } = useVehicleProfileFormContext();
  const { showModal } = useAvatarModalContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<UpdateVehicleInput>({
    resolver: yupResolver(vehicleSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    try {
      const payload = {
        ...data,
        fuelType: data.fuelType as FuelTypeEnum,
      };

      updateVehicle(payload);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!vehicle) return;

    const {
      plate,
      displayName,
      brand,
      fuelType,
      model,
      year,
      document,
      price,
      observation,
    } = vehicle;

    setValue('displayName', displayName ?? undefined);
    setValue('plate', plate ?? undefined);
    setValue('brand', brand ?? undefined);
    setValue('fuelType', fuelType ?? undefined);
    setValue('model', model ?? undefined);
    setValue('price', price ?? undefined);
    setValue('year', year ?? undefined);
    setValue('document', document ?? undefined);
    setValue('observation', observation ?? undefined);
  }, [setValue, vehicle]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AvatarContainer>
        <AvatarImage
          src={getVehicleImage(
            vehicle?.avatarType ?? vehicle?.type ?? VehicleTypeEnum.Truck,
          )}
          alt="Imagem do veículo"
          onClick={showModal}
        />
        <EditAvatar onClick={showModal}>
          <Edit />
        </EditAvatar>
      </AvatarContainer>
      <FormContainer>
        <CustomScrollbar>
          <Row>
            <Col>
              <Controller
                name="plate"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Placa ou identificador"
                    value={value?.toUpperCase() ?? undefined}
                    onChange={onChange}
                    error={errors.plate?.message}
                    disabled
                    name={name}
                    loading={gettingVehicleData}
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                name="displayName"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Apelido"
                    value={value ?? undefined}
                    onChange={onChange}
                    error={errors.displayName?.message}
                    name={name}
                    loading={gettingVehicleData}
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Controller
                name="brand"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Marca"
                    value={value ?? undefined}
                    onChange={onChange}
                    error={errors.displayName?.message}
                    name={name}
                    loading={gettingVehicleData}
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                name="model"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Modelo"
                    value={value ?? undefined}
                    onChange={onChange}
                    error={errors.model?.message}
                    name={name}
                    loading={gettingVehicleData}
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Controller
                name="year"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label="Ano"
                    value={value ?? undefined}
                    onChange={onChange}
                    error={errors.year?.message}
                    name={name}
                    loading={gettingVehicleData}
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                name="fuelType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectFuelType
                    onChange={onChange}
                    value={value ?? FuelTypeEnum.Diesel}
                    name="fuelType"
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Controller
                name="document"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    label={
                      vehicle?.type === VehicleTypeEnum.Equipment
                        ? 'Nº de série'
                        : 'Renavam'
                    }
                    value={value ?? undefined}
                    onChange={onChange}
                    error={errors.document?.message}
                    name={name}
                    loading={gettingVehicleData}
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <MaskedNumberInput
                    formatter={formatCurrency}
                    value={field.value}
                    onChange={field.onChange}
                    label={`Valor do ${
                      vehicle?.type === VehicleTypeEnum.Equipment
                        ? 'equipamento'
                        : 'veículo'
                    }`}
                    error={errors.price?.message}
                    name={field.name}
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Controller
                name="observation"
                control={control}
                render={({ field }) => (
                  <TextArea
                    label="Observações"
                    value={field.value ?? undefined}
                    onChange={field.onChange}
                    name={field.name}
                    loading={gettingVehicleData}
                  />
                )}
              />
            </Col>
          </Row>
        </CustomScrollbar>
      </FormContainer>

      <ButtonContainer>
        <Button disabled={loading}>{loading ? 'Salvando...' : 'Salvar'}</Button>
      </ButtonContainer>
    </Form>
  );
}

export default VehicleInfo;
