import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AddressInput from '../../../../../components/AddressInput';
import Button from '../../../../../components/Button/Button';
import CustomScrollbar from '../../../../../components/CustomScrollbar/CustomScrollbar';
import DateTimePicker from '../../../../../components/DateTimePicker/DateTimePicker';
import { ActionsContainer } from '../../../../../components/Drawer/styles';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import MaskedNumberInput from '../../../../../components/MaskedNumberInput/MaskedNumberInput';
import SelectDriver from '../../../../../components/SelectDriver';
import Switch from '../../../../../components/Switch/Switch';
import { TrafficInfractionFragment } from '../../../../../generated/graphqlV2';
import formatNumber from '../../../../../helpers/format-number';
import { useVehicleProfileContext } from '../../../contexts/VehicleProfileContext';
import { TrafficInfractionFormValues } from '../../../types/Forms.types';
import InfractionSelect from '../../SelectInfraction';
import { useTrafficInfractionFormContext } from '../../Tabs/TrafficInfractionTab/contexts/TrafficInfractionFormContext';
import { trafficInfractionSchema } from '../formValidations';
import {
  Field,
  FieldsContainer,
  Label,
  Row,
  SwitchContainer,
  TrafficInfractionForm,
} from '../styles';

interface TrafficInfractionStepOneProps {
  data?: TrafficInfractionFragment;
}

function TrafficInfractionStepOne(props: TrafficInfractionStepOneProps) {
  const { data } = props;

  const { toStep, setFormValues } = useTrafficInfractionFormContext();
  const { vehicleInfo } = useVehicleProfileContext();
  const [paid, setPaid] = useState(!!data?.paidAt);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TrafficInfractionFormValues>({
    resolver: yupResolver(trafficInfractionSchema),
    defaultValues: {
      cost: data?.cost || undefined,
      vehicle: vehicleInfo,
      driver: data?.driver || undefined,
      location: data?.address
        ? {
            formattedAddress: data?.address || undefined,
            coordinates: {
              lat: data?.location?.latitude,
              lng: data?.location?.longitude,
            },
          }
        : undefined,
      infraction: data?.infraction,
      dueDate: data?.dueDate || moment().toDate(),
      paidCost: data?.paidCost || undefined,
      paidAt: data?.paidAt,
      occurredAt: data?.occurredAt || moment().toDate(),
    },
  });

  const onSubmit = (formData: TrafficInfractionFormValues) => {
    const payload = {
      ...formData,
    };

    setFormValues(!!data ? { ...payload, id: data.id } : payload);
    toStep(2);
  };

  const infraction = watch('infraction');

  useEffect(() => {
    setValue('cost', infraction?.cost || undefined);

    if (paid) {
      setValue('paidCost', infraction?.cost || undefined);
      setValue('paidAt', data?.paidAt || moment().toDate());
    } else {
      setValue('paidCost', undefined);
      setValue('paidAt', undefined);
    }
  }, [data?.paidAt, infraction, paid, setValue]);

  return (
    <>
      <TrafficInfractionForm onSubmit={handleSubmit(onSubmit)}>
        <CustomScrollbar>
          <FieldsContainer>
            <Row>
              <Field>
                <Controller
                  name="occurredAt"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      label="Data e hora da multa"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.occurredAt && (
                  <ErrorMessage message={errors.occurredAt.message} />
                )}
              </Field>
              <Field>
                <Controller
                  name="dueDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      label="Vencimento da multa"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.dueDate && (
                  <ErrorMessage message={errors.dueDate.message} />
                )}
              </Field>
            </Row>
            <Row>
              <Field>
                <Label style={{ marginBottom: '0.5rem' }}>Local</Label>
                <Controller
                  name="location"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AddressInput value={value} onChange={onChange} />
                  )}
                />
                {errors.location && (
                  <ErrorMessage message={(errors.location as any).message} />
                )}
              </Field>
            </Row>
            <Row>
              <Field>
                <Controller
                  name="driver"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <SelectDriver
                      name={name}
                      onChange={onChange}
                      value={value}
                      label="Condutor"
                    />
                  )}
                />
              </Field>
            </Row>
            <Row>
              <Field>
                <Controller
                  name="infraction"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InfractionSelect
                      label="Infração"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.infraction && (
                  <ErrorMessage message={(errors.infraction as any).message} />
                )}
              </Field>
            </Row>
            <Row>
              <Field>
                <Controller
                  name="cost"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MaskedNumberInput
                      label="Valor (R$)"
                      formatter={formatNumber}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Field>
            </Row>
            <SwitchContainer>
              <Switch value={paid} onChange={setPaid} data-testid="switch" />
              {paid
                ? 'Pagamento da multa realizado'
                : 'Pagamento da multa não realizado'}
            </SwitchContainer>
            {paid && (
              <Row>
                <Field>
                  <Controller
                    name="paidAt"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        label="Data do pagamento"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Field>
                <Field>
                  <Controller
                    name="paidCost"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <MaskedNumberInput
                        label="Valor pago (R$)"
                        formatter={formatNumber}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Field>
              </Row>
            )}
          </FieldsContainer>
        </CustomScrollbar>
        <ActionsContainer>
          <Button $fullWidth>Próximo</Button>
        </ActionsContainer>
      </TrafficInfractionForm>
    </>
  );
}

export default TrafficInfractionStepOne;
