import React, { createContext, ReactNode, useContext, useState } from 'react';
import { TrafficInfractionFragment } from '../../../../../../generated/graphqlV2';
import useModalControl from '../../../../../../hooks/use-modal-control';
import useUnmountingDelay from '../../../../../../hooks/use-unmounting-delay';
import { DrawerType } from '../../../../types/TrafficInfractions.types';
import PayTrafficInfractionDrawer from '../../../Drawer/PayTrafficInfraction';
import TrafficInfractionDrawer from '../../../Drawer/TrafficInfraction';
import TrafficInfractionFormContextProvider from './TrafficInfractionFormContext';

type DrawerContextValue = {
  open: (drawerType: DrawerType, data?: any) => void;
  close: () => void;
};

const DrawerContext = createContext<DrawerContextValue>(
  {} as DrawerContextValue,
);

type DrawerContextProviderProps = {
  children: ReactNode;
};

function DrawerContextProvider(props: DrawerContextProviderProps) {
  const modalControl = useModalControl(false);
  const shouldRender = useUnmountingDelay({
    isMounted: modalControl.isOpen,
    delayMS: 500,
  });
  const [drawerType, setDrawerType] = useState<DrawerType>();
  const [data, setData] = useState<TrafficInfractionFragment>();

  const handleOpen = (
    drawerType: DrawerType,
    data?: TrafficInfractionFragment,
  ) => {
    setDrawerType(drawerType);

    if (
      (drawerType === DrawerType.pay || drawerType === DrawerType.edit) &&
      data
    ) {
      setData(data);
    }

    modalControl.open();
  };

  const handleClose = () => {
    modalControl.close();
  };

  const renderForm = () => {
    switch (drawerType) {
      case DrawerType.add:
        return (
          <TrafficInfractionDrawer
            open={modalControl.isOpen}
            close={handleClose}
          />
        );
      case DrawerType.pay:
        return (
          <PayTrafficInfractionDrawer
            open={modalControl.isOpen}
            close={handleClose}
            data={data!}
          />
        );
      case DrawerType.edit:
        return (
          <TrafficInfractionDrawer
            open={modalControl.isOpen}
            close={handleClose}
            data={data}
          />
        );
    }
  };

  const value: DrawerContextValue = {
    open: handleOpen,
    close: handleClose,
  };

  return (
    <DrawerContext.Provider value={value}>
      {props.children}
      <TrafficInfractionFormContextProvider>
        {shouldRender && renderForm()}
      </TrafficInfractionFormContextProvider>
    </DrawerContext.Provider>
  );
}

export function useDrawerContext() {
  const context = useContext(DrawerContext);

  if (typeof context === 'undefined') {
    throw new Error(`useDrawerContext must be used within a DrawerContext`);
  }
  return context;
}

export default DrawerContextProvider;
