import React from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';

export const buttonDropDownStyles: React.CSSProperties = {
  fontSize: '1.625rem',
  borderRadius: 6,
};

export const WrappedMenu = styled(Menu)`
  font-size: 1.625rem;
  border-radius: 6px;
`;

export const WrappedMenuItem = styled(Menu.Item)`
  color: var(--color-purple);
  font-size: 1rem;
`;
