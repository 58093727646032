import { DatePicker } from 'antd';
import { PickerBaseProps } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface CustomProps {
  showTime: { format: string };
}

const CustomPicker = styled(DatePicker)<CustomProps>`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);

  height: 40px;
  text-align: left;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & span.ant-picker-suffix {
    order: -1;
    margin-right: 6px;
    margin-left: 0;
  }

  &.ant-picker:hover {
    border-color: rgba(0, 0, 0, 0.15);
  }

  &.ant-picker-focused,
  &.ant-picker-focused:hover {
    box-shadow: none;
    border: 1px solid #d6bbfb;
  }

  svg {
    color: rgba(0, 0, 0, 0.65);
  }

  input {
    ::placeholder {
      color: rgba(0, 0, 0, 0.65);
    }
  }
`;

export const Container = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 8px;
  position: relative;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    top: -8px;
    left: 18px;
    z-index: 2000;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export type DatePickerProps = {
  value?: Date | string;
  onChange: (value: Date | null) => void;
  allowClear?: boolean;
  disabledDate?: ((date: moment.Moment) => boolean) | undefined;
  name?: string;
  label?: string;
};

function DateTimePicker(props: DatePickerProps) {
  const {
    value,
    onChange,
    allowClear = false,
    disabledDate,
    name,
    label,
  } = props;

  const handleChange: PickerBaseProps<moment.Moment>['onChange'] = (date) => {
    onChange(date?.toDate() ?? null);
  };

  return (
    <Container>
      <label htmlFor="date-range-picker">{label}</label>
      <CustomPicker
        data-testid={name ?? 'date-time-picker'}
        name={name}
        onChange={handleChange}
        value={value ? moment(value) : null}
        allowClear={allowClear}
        format="DD/MM/YYYY HH:mm"
        showTime={{ format: 'HH:mm' }}
        disabledDate={disabledDate}
      />
    </Container>
  );
}

export default DateTimePicker;
