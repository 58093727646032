import React, { useMemo } from 'react';
import { useVehiclesQuery, VehicleFragment } from '../generated/graphqlV2';

export interface VehicleContextValue {
  vehicles: VehicleFragment[];
  loading: boolean;
}

const VehiclesContext = React.createContext<VehicleContextValue>({} as any);

export default function VehiclesContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { data, loading } = useVehiclesQuery();

  const values = useMemo(() => {
    return {
      vehicles: data?.vehicles ?? [],
      loading,
    };
  }, [data?.vehicles, loading]);

  return (
    <VehiclesContext.Provider value={values}>
      {props.children}
    </VehiclesContext.Provider>
  );
}

export function useVehiclesContext() {
  const context = React.useContext(VehiclesContext);

  if (typeof context === 'undefined') {
    throw new Error(`useVehiclesContext must be used within a VehiclesContext`);
  }

  return context;
}
