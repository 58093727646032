import { notification } from 'antd';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import {
  UpdateVehicleInput,
  useUpdateOrganizationMutation,
} from '../../../generated/graphqlV2';

interface OrganizationContextValues {
  updateOrganization: (payload: UpdateVehicleInput) => void;
  updateOrganizationLoading: boolean;
}

const OrganizationContex = createContext<OrganizationContextValues>(
  {} as OrganizationContextValues,
);

interface OrganizationContextProps {
  children: ReactNode;
}

function OrganizationContextProvider(props: OrganizationContextProps) {
  const { children } = props;

  const [updateOrganizationMutation, { loading }] =
    useUpdateOrganizationMutation();

  const updateOrganization = useCallback(
    async (payload: UpdateVehicleInput) => {
      try {
        await updateOrganizationMutation({
          variables: {
            payload,
          },
        });

        notification.success({
          message: 'Dados da organização atualizados com sucesso',
        });
      } catch (e) {
        notification.error({
          message: 'Erro ao salvar os dados da organização',
          description: 'Entre em contato com o suporte no chat.',
        });
        console.error(e);
      }
    },
    [updateOrganizationMutation],
  );

  const values = {
    updateOrganization,
    updateOrganizationLoading: loading,
  };

  return (
    <OrganizationContex.Provider value={values}>
      {children}
    </OrganizationContex.Provider>
  );
}

export function useOrganizationContex() {
  const context = useContext(OrganizationContex);

  if (typeof context === 'undefined') {
    throw new Error(
      `useOrganizationContex must be used within a OrganizationContex`,
    );
  }
  return context;
}

export default OrganizationContextProvider;
