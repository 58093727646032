import { notification } from 'antd';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from 'react';
import Modal from '../../../../../../components/Modal/Modal';
import {
  CreateTrafficInfractionInput,
  PayTrafficInfractionInput,
  TrafficInfractionFragmentDoc,
  UpdateTrafficInfractionInput,
  useCreateTrafficInfractionMutation,
  useDeleteTrafficInfractionMutation,
  usePayTrafficInfractionMutation,
  useUpdateTrafficInfractionMutation,
} from '../../../../../../generated/graphqlV2';
import { TrafficInfractionFormValues } from '../../../../types/Forms.types';
import { useDrawerContext } from './DrawerContext';

enum StepForm {
  One = 1,
  Two,
}

type TrafficInfractionFormContextProps = {
  children: ReactNode;
};

type TrafficInfractionFormContextValue = {
  handleClose: () => void;
  toStep: (step: StepForm) => void;
  currentStep: StepForm;
  setFormValues: Dispatch<React.SetStateAction<TrafficInfractionFormValues>>;
  formValues: TrafficInfractionFormValues;
  createTrafficInfractionHandler: (
    values: CreateTrafficInfractionInput,
  ) => void;
  deleteTrafficInfractionHandler: (trafficInfractionIds: string[]) => void;
  updateTrafficInfractionHandler: (
    payload: UpdateTrafficInfractionInput,
  ) => void;
  payTrafficInfractionHandler: (payload: PayTrafficInfractionInput) => void;
  loading: boolean;
};

const TrafficInfractionFormContext =
  createContext<TrafficInfractionFormContextValue>(
    {} as TrafficInfractionFormContextValue,
  );

function TrafficInfractionFormContextProvider(
  props: TrafficInfractionFormContextProps,
) {
  const { children } = props;
  const [currentStep, setCurrentStep] = useState<StepForm>(StepForm.One);
  const [formValues, setFormValues] = useState<TrafficInfractionFormValues>(
    {} as TrafficInfractionFormValues,
  );

  const [loading, setLoading] = useState<boolean>(false);

  const { close } = useDrawerContext();

  const [createTrafficInfractionMutation] = useCreateTrafficInfractionMutation({
    update(cache, { data }) {
      cache.modify({
        fields: {
          inffractions(prev = []) {
            const newRef = cache.writeFragment({
              data: data?.createTrafficInfraction,
              fragment: TrafficInfractionFragmentDoc,
              fragmentName: data?.createTrafficInfraction.__typename,
            });
            return [newRef, ...prev];
          },
        },
      });
    },
  });

  const [deleteTrafficInfraction] = useDeleteTrafficInfractionMutation();
  const [updateTrafficInfraction] = useUpdateTrafficInfractionMutation();
  const [payTrafficInfraction] = usePayTrafficInfractionMutation();

  const createTrafficInfractionHandler = async (
    payload: CreateTrafficInfractionInput,
  ) => {
    try {
      setLoading(true);

      await createTrafficInfractionMutation({
        variables: {
          payload,
        },
        refetchQueries: ['getTrafficInfractions'],
        awaitRefetchQueries: true,
      });

      notification['success']({
        message: 'Multa cadastrada com sucesso',
        description: 'Confira as informações na listagem de multas',
      });

      setLoading(false);
      handleClose();
    } catch (error) {
      notification['error']({
        message: 'Erro ao cadastrar multa',
        description: 'Contate o suporte',
      });

      setLoading(false);
      console.error(error);
    }
  };

  const deleteTrafficInfractionHandler = async (
    trafficInfractionIds: string[],
  ) => {
    const { confirm } = Modal;

    confirm({
      title: 'Confirmar remoção',
      icon: '',
      content: (
        <>
          <b>
            Tem certeza que desejar remover permanentemente a multa selecionada?
          </b>
        </>
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        try {
          const response = await deleteTrafficInfraction({
            variables: {
              ids: [...trafficInfractionIds],
            },
            update(cache) {
              cache.identify({
                __typename: 'TrafficInfraction',
                ids: trafficInfractionIds,
              });
              cache.gc();
            },
            refetchQueries: ['getTrafficInfractions'],
            awaitRefetchQueries: true,
          });

          if (response.data?.deleteTrafficInfraction) {
            notification['success']({
              message: 'Multa apagada com sucesso',
              description: 'Confira as informações na listagem de multas',
            });
          }
        } catch (error) {
          notification['error']({
            message: 'Erro ao apagar multa',
            description: 'Contate o suporte',
          });

          console.error(error);
        }
      },
    });
  };

  const updateTrafficInfractionHandler = async (
    payload: UpdateTrafficInfractionInput,
  ) => {
    try {
      setLoading(true);

      await updateTrafficInfraction({
        variables: {
          payload,
        },
      });

      notification['success']({
        message: 'Multa atualizada com sucesso',
        description: 'Confira as informações na listagem de multas',
      });

      setLoading(false);
      handleClose();
    } catch (error) {
      notification['error']({
        message: 'Erro ao editar multa',
        description: 'Contate o suporte',
      });

      setLoading(false);
      console.error(error);
    }
  };

  const payTrafficInfractionHandler = async (
    payload: PayTrafficInfractionInput,
  ) => {
    try {
      setLoading(true);

      await payTrafficInfraction({
        variables: {
          payload,
        },
        awaitRefetchQueries: true,
      });

      notification['success']({
        message: 'Multa paga com sucesso',
        description: 'Confira as informações na listagem de multas',
      });

      setLoading(false);
      handleClose();
    } catch (error) {
      notification['error']({
        message: 'Erro ao editar multa',
        description: 'Contate o suporte',
      });

      setLoading(false);
      console.error(error);
    }
  };

  const handleClose = () => {
    toStep(1);
    setFormValues({} as TrafficInfractionFormValues);
    close();
  };

  const toStep = (step: StepForm) => {
    if (step > StepForm.Two) {
      return;
    }
    setCurrentStep(step);
  };

  const value: TrafficInfractionFormContextValue = {
    handleClose,
    toStep,
    currentStep: currentStep,
    setFormValues,
    formValues,
    createTrafficInfractionHandler,
    deleteTrafficInfractionHandler,
    updateTrafficInfractionHandler,
    payTrafficInfractionHandler,
    loading,
  };

  return (
    <TrafficInfractionFormContext.Provider value={value}>
      {children}
    </TrafficInfractionFormContext.Provider>
  );
}

export function useTrafficInfractionFormContext() {
  const context = useContext(TrafficInfractionFormContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useTrafficInfractionFormContext must be used within a TrafficInfractionFormContext',
    );
  }

  return context;
}

export default TrafficInfractionFormContextProvider;
