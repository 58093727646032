import styled from 'styled-components';
import Icon from '../../../../components/Icon/Icon';

export const Container = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 4px;
  padding: 18px 24px;
  max-height: 148px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  border: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  border-radius: 3px;
  max-height: 100px;
  margin-right: 8px;
  cursor: pointer;

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};

    ${Icon} {
      background: #7758c7;
      color: #ffffff;
    }
  }
`;

export const EditAvatar = styled(Icon)`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  padding: 4px;
  background: #f1f3f7;
  border-radius: 50%;
  cursor: pointer;
`;

export const AvatarImage = styled.img`
  max-width: 100px;
`;

export const VehicleInfoContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(min-content, max-content) minmax(min-content, max-content)
    minmax(min-content, 500px);
`;

export const VehicleInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  overflow: hidden;

  p {
    margin: 0 0 12px 0;

    &:last-child {
      margin: 0;
      font-size: 12px;
    }
  }

  & + & {
    border-left: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  }
`;

export const DisplayNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`;

export const VehicleName = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #434343;
  margin: 0;
`;

export const VehiclePlate = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #434343;
  padding: 4px 8px;
  background: #f1f3f7;
  border-radius: 5px;
  margin: 0;
`;

export const MeasureIcon = styled(Icon)`
  padding: 4px;
  background-color: #f1f3f7;
  border-radius: 3px;
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
  flex-wrap: wrap;
  max-width: 350px;

  p {
    margin: 0;
  }
`;

export const ObservationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  b {
    margin-bottom: 8px;
    font-size: 12px;
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

export const VehicleInitialInfos = styled.div`
  padding-left: 23px;
`;

export const VehicleLicensePlate = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #434343;
`;

export const VehicleSpecifications = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  color: #434343;
`;

export const ButtonEditContainer = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
  padding-right: 11px;
`;

export const ButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const VehicleAvatar = styled.div`
  border: 1px solid #f1f3f7;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  > img {
    width: auto;
    max-height: 100%;
  }
`;
