import React, { ReactNode, useState } from 'react';
import ChevronRight from '../icons/ChevronRight';
import {
  Container,
  ContentContainer,
  ExpandIconContainer,
  Flag,
  IconContainer,
  LabelContainer,
  MenuItem,
} from './styles';

type MenuItemExpandProps = {
  label: string | React.ReactNode;
  flag?: string;
  icon: ReactNode;
  children: ReactNode;
};

export default function MenuItemExpand(props: MenuItemExpandProps) {
  const { label, icon, flag, children } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const onExpand = () => setIsExpanded((expanded) => !expanded);

  const shouldExpand = !isExpanded;

  return (
    <Container>
      <MenuItem className={shouldExpand ? 'expanded' : ''} onClick={onExpand}>
        <IconContainer>{icon}</IconContainer>
        <LabelContainer>
          {label}
          {flag ? <Flag>{flag}</Flag> : null}
        </LabelContainer>
        <ExpandIconContainer>
          <ChevronRight className={shouldExpand ? 'expanded' : ''} />
        </ExpandIconContainer>
      </MenuItem>
      <ContentContainer className={shouldExpand ? 'content-expanded' : ''}>
        {children}
      </ContentContainer>
    </Container>
  );
}
