import React, { useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import SelectSearch, {
  SelectDataType,
} from '../../../../components/SelectSearch';
import {
  InfractionFragment,
  useGetInfractionsQuery,
} from '../../../../generated/graphqlV2';

export type SelectInfractionProps = {
  value: InfractionFragment;
  onChange: (value: InfractionFragment) => void;
  error?: string;
  name?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
};

function SelectInfraction(props: SelectInfractionProps) {
  const {
    value,
    onChange,
    error,
    name = 'infractions',
    placeholder,
    disabled,
    label,
  } = props;
  const [selectedInfraction, setSelectedInfraction] =
    useState<SelectDataType>();

  const { data, loading } = useGetInfractionsQuery({
    variables: {
      payload: {},
    },
  });

  const parsedInfractions = useMemo(() => {
    if (!data) return [];

    const parsedData = data?.findInfractions.map((infraction) => {
      return {
        id: infraction.id,
        text: infraction.description,
      };
    });

    return parsedData;
  }, [data]);

  function handleChange(value: SelectDataType) {
    const searchedValue = data?.findInfractions.find(
      (infraction) => infraction.id === value.id,
    );

    if (!searchedValue) return;

    onChange(searchedValue);
    setSelectedInfraction(value);
  }

  useEffectOnce(() => {
    if (!value) return;

    const foundValue = parsedInfractions?.find(
      (provider) => provider.id === value?.id,
    );

    setSelectedInfraction(foundValue);
    onChange(value);
  });

  return (
    <SelectSearch
      data={parsedInfractions}
      label={label || 'Infrações'}
      placeholder={placeholder ?? 'Selecione uma infração'}
      onChange={handleChange}
      value={selectedInfraction}
      loading={loading}
      disabled={disabled}
      name={name}
      error={error}
    />
  );
}

export default SelectInfraction;
