import styled from 'styled-components';

const Divider = styled.div`
  background-color: rgba(241, 243, 247, 1);
  height: 1px;
  width: 100%;
  margin: 0.875rem 0;
`;

export default Divider;
