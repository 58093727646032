import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import Empty from '../../../../../components/Empty/Empty';
import { DrawerType } from '../../../types/TrafficInfractions.types';
import { useDrawerContext } from './contexts/DrawerContext';
import { useTrafficInfractionTableContext } from './contexts/TrafficInfractionTableContext';
import PageActions from './PageActions';
import { Container, ContentContainer, EmptyContainer, List } from './styles';
import TrafficInfractionItem from './TrafficInfractionItem';
import TrafficInfractionTable from './TrafficInfractionTable';

function TrafficInfractionTab() {
  const { trafficInfractions, fetching } = useTrafficInfractionTableContext();
  const { open } = useDrawerContext();

  const actions = useMemo(() => {
    return [
      { key: 'update', label: 'Editar' },
      { key: 'delete', label: 'Excluir' },
    ];
  }, []);

  const pendingTrafficInfractions = useMemo(() => {
    return trafficInfractions?.filter(
      (trafficInfraction) => trafficInfraction.paidAt === null,
    );
  }, [trafficInfractions]);

  return (
    <Container>
      <PageActions />
      <ContentContainer>
        <List>
          <p>Pendentes ({pendingTrafficInfractions?.length})</p>
          <ul>
            {fetching ? (
              <EmptyContainer>
                <Skeleton height={52} />
              </EmptyContainer>
            ) : pendingTrafficInfractions.length ? (
              pendingTrafficInfractions?.map((item) => (
                <TrafficInfractionItem
                  actions={actions}
                  data={item}
                  key={item.id}
                  onClick={() => open(DrawerType.pay, item)}
                />
              ))
            ) : (
              <EmptyContainer>
                <Empty description="Nenhuma multa pendente" />
              </EmptyContainer>
            )}
          </ul>
        </List>
        <TrafficInfractionTable />
      </ContentContainer>
    </Container>
  );
}

export default TrafficInfractionTab;
