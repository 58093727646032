import styled from 'styled-components';
import Icon from '../../../../../../components/Icon/Icon';

type ExpandedProps = {
  $expanded: boolean;
};

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'search basic-filters'
    'all-filters all-filters';
  justify-content: space-between;
  align-items: center;
  > .ReactCollapse--collapse {
    grid-area: all-filters;
  }
`;

export const SearchBar = styled.div`
  grid-area: search;
  width: 331px;
`;

export const ExpandedIcon = styled(Icon)<ExpandedProps>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${(props) => (props.$expanded ? '-180deg' : '0deg')});
`;

export const BasicFilters = styled.div`
  grid-area: basic-filters;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
`;

export const TableActions = styled.div`
  display: flex;
  gap: 12px;
`;
