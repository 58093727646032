import styled from 'styled-components';
import Icon from '../../../../components/Icon/Icon';

type ImageContainerProps = {
  $isSelected?: boolean;
};

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edf1f2;
  padding: 24px 22px;

  h2 {
    margin: 0;
  }

  ${Icon} {
    cursor: pointer;
  }
`;

export const AvatarList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  gap: 46px;
  padding: 44px 24px;
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  border: ${(props) =>
    props.$isSelected
      ? `2px solid ${props.theme.colors.purple}`
      : `1px solid ${props.theme.colors.secondaryLight}`};

  &:hover {
    border: ${(props) =>
      props.$isSelected
        ? `2px solid ${props.theme.colors.purple}`
        : `1px solid ${props.theme.colors.secondaryDark}`};
  }

  img {
    max-width: 100px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  border-top: 1px solid #edf1f2;
  padding: 24px 22px;
`;
