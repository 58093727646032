import { MenuButton, MenuPopover } from '@reach/menu-button';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Icon from '../../../components/Icon/Icon';

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const RecommendationLink = styled.div`
  display: inline-block;
  color: #98a2b3;

  ${Icon} {
    margin-left: 4px;
    color: #d0d5dd;
  }
`;

export const Popup = styled(MenuPopover)`
  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 4px;
  padding: 8px 0;
`;

export const DropdownItem = styled(NavLink)<{ $isActive?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  padding: 8px 16px;
  cursor: pointer;
  position: relative;
  display: flex;

  &::before {
    content: '';
    display: flex;
    width: 4px;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: all ease 0.3s;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &::before {
        height: 100%;
      }
    `}

  &:hover {
    background-color: #f1f3f7;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const DropdownButton = styled(MenuButton)<{ $isActive?: boolean }>`
  color: currentColor;
  padding: 0;
  margin: 0 8px;
  transition: background-color 0.2s;
  border-radius: 8px;
  outline: none;
  background-color: transparent;
  border: 0;
  width: 49px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: #f1f3f7;

      ${Icon} {
        color: ${({ theme }) => theme.colors.primary};
      }
    `}

  &:hover {
    background-color: #f1f3f7;

    ${Icon} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Link = styled(NavLink)<{ $isActive?: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    css`
      &[aria-current='page'] {
        &::before {
          height: 100%;
        }
      }
    `}
`;
