import { notification } from 'antd';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  useUpdateVehicleMutation,
  VehicleTypeEnum,
} from '../../../generated/graphqlV2';
import AvatarModal from '../components/Modal/AvatarModal';

type AvatarModalContextValues = {
  showModal: () => void;
  updateVehicle: (payload: UpdateAvatarData) => Promise<void>;
  loading: boolean;
};

type UpdateAvatarData = {
  id: string;
  avatarType: VehicleTypeEnum;
};

const AvatarModalContext = createContext<AvatarModalContextValues>(
  {} as AvatarModalContextValues,
);

type AvatarModalContextProviderProps = {
  children: ReactNode;
};

function AvatarContextProvider(props: AvatarModalContextProviderProps) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [updateVehicleMutation] = useUpdateVehicleMutation({
    refetchQueries: ['vehicle'],
  });

  const updateVehicle = useCallback(
    async (data: UpdateAvatarData) => {
      try {
        setLoading(true);
        await updateVehicleMutation({
          variables: {
            payload: {
              ...data,
            },
          },
          awaitRefetchQueries: true,
        });

        notification['success']({
          message: 'Avatar atualizado com sucesso',
          description: 'Confira no perfil do veículo',
        });

        setLoading(false);
        setIsOpen(false);
      } catch (error) {
        notification['error']({
          message: 'Erro ao atualizar o avatar',
          description: 'Contate o suporte',
        });

        console.error(error);
      }
    },
    [updateVehicleMutation],
  );

  const closeModal = () => setIsOpen(false);
  const showModal = () => setIsOpen(true);

  const value = {
    showModal,
    updateVehicle,
    loading,
  };

  return (
    <AvatarModalContext.Provider value={value}>
      {children}
      {isOpen && <AvatarModal isOpen={isOpen} setClose={closeModal} />}
    </AvatarModalContext.Provider>
  );
}

export function useAvatarModalContext() {
  const context = useContext(AvatarModalContext);

  if (typeof context === 'undefined') {
    throw new Error(
      `useAvatarModalContext must be used within a AvatarModalContext`,
    );
  }
  return context;
}

export default AvatarContextProvider;
