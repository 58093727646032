import React from 'react';
import styled from 'styled-components';
import { NotificationActionFragment } from '../../generated/graphqlV2';

type ContainerProps = {
  $unRead: boolean;
};

const Container = styled.div<ContainerProps>`
  padding: 16px;
  background-color: ${(props) => (props.$unRead ? '#f2f4f7' : '#ffffff')};
  border-bottom: 1px solid #e7eaf3;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3f3f46;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Time = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #898989;
`;

const Leading = styled.div`
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  background: #f2f4f7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #898989;
  display: flex;
  padding-top: 7px;
  justify-content: space-between;
`;

const Tailing = styled.div`
  flex-grow: 0;
  padding: 5px 29px 0px 6px;
`;

const UnRead = styled.div`
  background: #7758c7;
  border-radius: 50%;
  width: 10px;
  height: 10px;
`;

export type NotificationItemProps = {
  unRead?: boolean;
  title: string;
  imageUrl: string;
  description: string;
  duration: string;
  actions: NotificationActionFragment[];
};

function NotificationItem(props: NotificationItemProps) {
  const {
    unRead = false,
    imageUrl,
    title,
    description,
    duration,
    actions,
  } = props;

  const mainAction = actions.length === 1 ? actions[0] : void 0;

  const content = (
    <Container $unRead={unRead}>
      <Leading>
        <img src={imageUrl} width={20} height={20} alt="Service" />
      </Leading>
      <Content>
        <Title>
          <span>{title}</span>
          <Time>há {duration}</Time>
        </Title>
        <Description>
          <div>{description}</div>
          <Tailing>{unRead && <UnRead />}</Tailing>
        </Description>
      </Content>
    </Container>
  );

  if (mainAction) {
    return (
      <a title={mainAction.title} href={mainAction.url}>
        {content}
      </a>
    );
  }

  return content;
}

export default NotificationItem;
