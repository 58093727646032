import * as React from 'react';
import { Empty as AntdEmpty } from 'antd';
import styled from 'styled-components';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 100%;
`;

export interface EmptyProps {
  description: string;
  retry?: string;
  onRetry?: () => void;
}

function Empty(props: EmptyProps) {
  const { description, retry = 'Tentar novamente', onRetry } = props;

  return (
    <Container>
      <AntdEmpty description={description} />
      {!!onRetry && <PrimaryButton onClick={onRetry}>{retry}</PrimaryButton>}
    </Container>
  );
}

export default Empty;
