import humanizeDuration from 'humanize-duration';

const shortPortugueseHumanizer = humanizeDuration.humanizer({
  language: 'shortPt',
  languages: {
    shortPt: {
      y: () => 'ano',
      mo: () => 'mês',
      w: () => 'semana',
      d: () => 'd',
      h: () => 'h',
      m: () => 'min',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

export default function durationFormat(value: Number) {
  return shortPortugueseHumanizer(Number(value) * 1000, {
    units: ['d', 'h', 'm'],
    language: 'shortPt',
    spacer: '',
    round: true,
  });
}
