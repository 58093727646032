import React, { useCallback } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Input } from './styles';

interface Address {
  formattedAddress?: string;
  coordinates: {
    lat?: number;
    lng?: number;
  };
}

interface AddressInputProps {
  onChange: (address: Address | string) => void;
  value: Address | undefined;
}

function AddressInput(props: AddressInputProps) {
  const { onChange, value } = props;

  const getLocationCoordinates = (place: google.maps.places.PlaceResult) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place.place_id}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    )
      .then((res) => res.json())
      .then((data) => {
        const { lat, lng } = data.results[0].geometry.location;
        const address = {
          formattedAddress: place.formatted_address,
          coordinates: {
            lat,
            lng,
          },
        };

        onChange(address);
      });
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const address = {
        formattedAddress: e.target.value,
        coordinates: {
          lat: undefined,
          lng: undefined,
        },
      };
      onChange(address);
    },
    [onChange],
  );

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => getLocationCoordinates(place),
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'br' },
    },
  });

  return (
    <Input
      ref={ref as any}
      onChange={(event) => handleChange(event)}
      // placeholder="Enter a City"
      value={value?.formattedAddress}
    />
  );
}

export default AddressInput;
