import { DefaultTheme } from 'styled-components';

const light: DefaultTheme = {
  colors: {
    primary: '#0e2c66',
    darkBlue: '#0E2D66',
    primaryLight: '#513a8e',
    purple: '#7758c8',
    purpleDark: '#626AAE',
    purpleLight: '#F2EFFA',
    secondary: '#7758C7',
    secondaryLight: '#f1f3f7',
    secondaryDark: '#434343',
    grey: '#898989',
    greyDark: '#545454',
    greyLight: '#d0d0d0',
    greyLighter: '#F8F8F8',
    alert: '#ff8e00',
    alertLight: '#f5ad53',
    text: '#747678',
    error: '#dd0000',
    neutral: '#C1C1C1',
    white: '#ffffff',
    black: '#000',
  },

  machineEventColors: {
    powerOn: '#6ece7c',
    powerOff: '#fc7a7a',
    powerUnknown: '#d0d0d0',
    outdated: '#F79009',
    driverChange: '#6DBBF3',
    stopped: '#7a96fc',
    motion: '#5fccdb',
    cockpitOpen: '#10C4A0',
    cockpitClose: '#fc7a7a',
    trailerOpen: '#FF6C40',
    deviceSpeeding: '#c41066',
    deviceSpeedingNormalized: '#106EC4',
    routeSpeeding: '#f5789b',
    fault: '#fc7a7a',
    hardBraking: '#f5ad53',
    hardCornering: '#5fccdb',
    hardAcceleration: '#ba9bfe',
    overweight: '#747171',
    unplugged: '#FF8E00',
    plugged: '#6ECE7C',
  },

  bodyBackgroundColor: '#f1f3f7',
  backgroundColor: '#ffffff',
  boxShadowColor: 'rgba(11, 5, 5, 0.1)',
  borderRadius: '10px',

  header: {
    backgroundColor: '#ffffff',
    height: '64px',
  },

  sidebar: {
    widthClose: '64px',
    widthOpen: '224px',
    collapseDuration: '250ms',
    backgroundOpen: '#ffffff',
    backgroundClose: '#0e2c66',
    menuColorOpen: '#0e2c66',
    menuColorClose: '#ffffff',
    iconColorOpen: '#2f3b98',
    iconColorClose: '#fff',
  },

  scrollbar: {
    trackColor: '#ededed',
    thumbColor: '#8473a8',
  },

  sideModal: {
    width: '470px',
    backgroundColor: '#f1f3f7',
  },

  vehicleModalList: {
    width: '322px',
  },
};

const themes = {
  light,
};

export default themes;
