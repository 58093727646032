import React from 'react';
import styled from 'styled-components';
import PageHeaderActions from './PageHeaderActions';

type PageHeaderProps = {
  title: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
};

const Title = styled.h1`
  grid-area: title;
  margin: 0;
  color: ${(props) => props.theme.colors.secondaryDark};
  justify-self: center;
  display: flex;
  align-items: center;
`;

function PageHeader(props: PageHeaderProps) {
  const { title, className, children } = props;
  return (
    <>
      <Title className={className}>{title}</Title>
      {!!children && <PageHeaderActions>{children}</PageHeaderActions>}
    </>
  );
}

export default PageHeader;
