import React, { forwardRef, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Icon from '../Icon/Icon';
import Eye from '../icons/Eye';
import EyeLock from '../icons/EyeLock';
import {
  Container,
  InputContainer,
  LeftIcon,
  LoadingContainer,
  RightIcon,
  TogglePasswordView,
  Wrapper,
} from './style';

export interface InputProps {
  error?: string;
  label?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  width?: string;
  name?: string;
  loading?: boolean;
  onChange: (value: string | null) => void;
  value?: string | null;
  placeholder?: string;
  type?: string;
  id?: string;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  activeLabel?: boolean;
  required?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(function InputV2(
  props,
  ref,
) {
  const {
    placeholder,
    type = 'text',
    rightIcon,
    leftIcon,
    value,
    onChange,
    error,
    name,
    id,
    label,
    width,
    loading,
    disabled,
    minLength,
    maxLength,
    activeLabel,
    required,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const inputType = useMemo(() => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password';
    }

    return type;
  }, [showPassword, type]);

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emptyValue = event.target.value === '';

    if (emptyValue) {
      onChange(null);
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <Wrapper>
      <Container $hasLabel={!!label} $width={width} $disabled={disabled}>
        {leftIcon && (
          <LeftIcon>
            <Icon>{leftIcon}</Icon>
          </LeftIcon>
        )}
        <InputContainer
          $hasIcon={!!leftIcon}
          $activeLabel={activeLabel || (!!label && !!placeholder)}
        >
          {loading ? (
            <LoadingContainer>
              <Skeleton width="100%" height="100%" />
            </LoadingContainer>
          ) : (
            <>
              <input
                min={minLength}
                maxLength={maxLength}
                type={inputType}
                placeholder={placeholder ?? ' '}
                value={value ?? ''}
                onChange={handleChange}
                name={name ?? label?.toLowerCase().replaceAll(' ', '-')}
                id={id ?? name ?? label?.toLowerCase().replaceAll(' ', '-')}
                ref={ref}
                disabled={disabled}
              />
              {label && (
                <label
                  htmlFor={
                    id ?? name ?? label?.toLowerCase().replaceAll(' ', '-')
                  }
                >
                  {label} {required && <b>*</b>}
                </label>
              )}
            </>
          )}
        </InputContainer>
        {type === 'password' && (
          <TogglePasswordView onClick={handleShowPassword}>
            {showPassword ? <Eye /> : <EyeLock />}
          </TogglePasswordView>
        )}
        {rightIcon && type !== 'password' && <RightIcon>{rightIcon}</RightIcon>}
      </Container>
      {error && <ErrorMessage message={error} />}
    </Wrapper>
  );
});

export default Input;
