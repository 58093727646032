import { useEffectOnce } from 'react-use';
import { AuthType, useAuth } from '../auth/contexts/AuthContextProvider';
import { useCurrentUserContext } from '../contexts/CurrentUserContextProvider';
import {
  plugAmplitude,
  plugDelighted,
  plugGoogleAnalytics,
  plugHotJar,
  plugIntercom,
  plugPendo,
} from './third-party-services';

export default function useThirdPartyServices() {
  const { user } = useCurrentUserContext();
  const { loginType } = useAuth();

  useEffectOnce(() => {
    if (process.env.NODE_ENV !== 'production') return;

    if (loginType !== AuthType.Credentials) return;

    plugIntercom(user, () => {
      console.info('Plugged Intercom');
    });

    plugGoogleAnalytics(user, () => {
      console.info('Google Analytics');
    });

    plugAmplitude(user, () => {
      console.info('Plugged amplitude');
    });

    plugHotJar(() => {
      console.info('Plugged hotJar');

      // @ts-ignore
      window.hj('identify', user.id, {
        name: user.name,
        email: user.email,
        cpf: user.cpf,
        organization_id: user.organization.id,
        organization: user.organization.name,
      });
    });

    plugDelighted(() => {
      console.info('Plugged delighted');

      // @ts-ignore
      window.delighted?.survey({
        ...user,
        properties: {
          organizationId: user.organization.id,
          organizationName: user.organization.name,
        },
      });
    });

    plugPendo(() => {
      console.info('Plugged pendo');

      // @ts-ignore
      window.pendo?.initialize({
        visitor: {
          id: user.id,
          email: user.email,
          full_name: user.name,
          role: user.role,
        },
        account: {
          id: user.organization.id,
          name: user.organization.name,
          creationDate: user.organization.createdAt,
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
    });
  });
}
