import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FormContainer = styled.div`
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Row = styled.section`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    border-radius: 5px;
  }
`;
