import styled from 'styled-components';

type ChevronProps = {
  variant?: 'up' | 'down';
};

const Chevron = styled.div<ChevronProps>`
  --margin: -15%;
  margin-left: 1rem;
  position: relative;
  display: inline-block;
  width: 14px;
  height: 8px;
  transform: rotate(${({ variant }) => (variant === 'up' ? 180 : 0)}deg);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: var(--color-grey-dark);
    border-radius: 0.2rem;
    top: 50%;
    display: block;
    height: 2px;
    width: 80%;
  }

  &:before {
    transform: rotate(45deg);
    left: var(--margin);
  }

  &:after {
    transform: rotate(-45deg);
    right: var(--margin);
  }
`;

export default Chevron;
