import moment from 'moment';

export default function formatDate(date?: Date | string | number | null) {
  if (typeof date === 'undefined' || date === null || !date) {
    return '--/--/--';
  }

  moment.locale('pt-BR');

  return moment(date).format('DD/MM/YY');
}
