import { Dropdown } from 'antd';
import React from 'react';
import { useBoolean } from 'react-use';
import { WrappedMenu, WrappedMenuItem } from './DropdownMenu.styles';

export type Action<Key> = {
  key: Key;
  label: string;
};

export interface DropdownMenuProps<Key = unknown> {
  actions: readonly Action<Key>[];
  children: React.ReactNode;
  onClick: (action: Action<Key>, item: any) => void;
  item?: any;
}

function DropdownMenu<Key = string>(props: DropdownMenuProps<Key>) {
  const { actions, children, onClick, item } = props;

  const [dropdownVisible, setDropdownVisible] = useBoolean(false);

  const handleDropdownVisible = (isVisible: boolean) => {
    setDropdownVisible(isVisible);
  };

  const handleClick = (action: Action<Key>, item: any) => {
    handleDropdownVisible(false);
    onClick(action, item);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlay={
          <WrappedMenu>
            {actions.map((action) => (
              <WrappedMenuItem
                key={action.key as any}
                onClick={() => handleClick(action, item)}
                data-testid={action.key}
              >
                {action.label}
              </WrappedMenuItem>
            ))}
          </WrappedMenu>
        }
        placement="bottomRight"
        trigger={['click']}
        onVisibleChange={handleDropdownVisible}
        visible={dropdownVisible}
      >
        {children}
      </Dropdown>
    </div>
  );
}

export default DropdownMenu;
