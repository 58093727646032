(() => {
  if (process.env.NODE_ENV !== 'production') return;

  console.log(
    '%c%s',
    'color: red; background: yellow; font-size: 24px;',
    'AVISO',
  );
  console.log(
    '%c%s',
    'font-size: 18px;',
    'O uso deste console talvez permita que invasores falsifiquem sua identidade e roubem informações por meio de um ataque chamado Self-XSS.\nNão digite nem cole códigos que você não conhece.',
  );
})();

export default void 0;
