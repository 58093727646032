import React, { useEffect, useState } from 'react';
import { session, useAuth } from './auth/contexts/AuthContextProvider';
import AuthRoutes from './auth/routes';
import FullLoading from './components/FullLoading/FullLoading';
import CurrentUserContextProvider from './contexts/CurrentUserContextProvider';
import LayoutContextProvider from './contexts/LayoutContextProvider';
import SettingsContextProvider from './contexts/SettingsContextProvider';
import VehiclesContextProvider from './contexts/VehiclesContextProvider';

interface AuthAppProps {
  children: React.ReactNode;
}

function AuthApp(props: AuthAppProps) {
  const { children } = props;
  const [isMounted, setIsMounted] = useState(false);

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    session.getAccessToken().then(() => setIsMounted(true));
  }, []);

  if (!isAuthenticated && !isMounted) {
    return <FullLoading />;
  } else if (!isAuthenticated) {
    return <AuthRoutes />;
  }

  return (
    <CurrentUserContextProvider>
      <SettingsContextProvider>
        <VehiclesContextProvider>
          <LayoutContextProvider>{children}</LayoutContextProvider>
        </VehiclesContextProvider>
      </SettingsContextProvider>
    </CurrentUserContextProvider>
  );
}

export default AuthApp;
