/* eslint-disable */
import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';

function requireThirdPartyScript(url, callback, id = void 0) {
  const head = document.getElementsByTagName('head')[0];
  const element = document.createElement('script');

  if (id) {
    element.id = id;
  }

  element.src = url;
  element.type = 'text/javascript';
  element.async = 1;
  element.addEventListener('load', callback);

  head.appendChild(element);
}

export function plugGoogleAnalytics(user, callback) {
  ReactGA.initialize('G-Y1290MZ8YP');
  ReactGA.set({
    user_id: user?.id,
    user: user?.name,
    organization_id: user?.organization_id,
    organization: user?.organization?.name,
  });
  callback();
}

export function plugHotJar(callback) {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 2745573, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    r.addEventListener('load', callback);
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

export function plugIntercom(user, callback) {
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'uk31kig3',
    name: user.name,
    email: user.email,
    company: {
      id: user.organization.id,
      name: user.organization.name,
      monthly_spend: user.organization.monthlyPayment,
      industry: user.organization.sector,
      size: user?.organization.capacity,
      custom_attributes: {
        category: user?.organization.category,
        cnpj: user.organization.cnpj,
        signUpFee: user?.organization.signUpFee,
        disabledAt: user?.organization.disabledAt,
        contractEndedAt: user?.organization.contractEndedAt,
        contractBeganAt: user?.organization.contractBeganAt,
        contractMonthDuration: user?.organization.contractMonthDuration,
        trialAt: user?.organization.trialAt,
      },
    },
    created_at: user.createdAt,
  };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/uk31kig3';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
        callback();
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

export function plugPendo(callback) {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat([].slice.call(arguments, 0)),
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.addEventListener('load', callback);
      y.async = !0;
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })('bc6b3751-ba12-4dc5-68a0-e95da930c1b7');
}

export function plugDelighted(callback) {
  !(function (e, t, r, n) {
    if (!e[n]) {
      for (
        var a = (e[n] = []),
          i = [
            'survey',
            'reset',
            'config',
            'init',
            'set',
            'get',
            'event',
            'identify',
            'track',
            'page',
            'screen',
            'group',
            'alias',
          ],
          s = 0;
        s < i.length;
        s++
      ) {
        var c = i[s];
        a[c] =
          a[c] ||
          (function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              a.push([e, t]);
            };
          })(c);
      }
      a.SNIPPET_VERSION = '1.0.1';
      var o = t.createElement('script');
      o.addEventListener('load', callback);
      (o.type = 'text/javascript'),
        (o.async = !0),
        (o.src =
          'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
          r +
          '/' +
          n +
          '.js');
      var l = t.getElementsByTagName('script')[0];
      l.parentNode.insertBefore(o, l);
    }
  })(window, document, process.env.REACT_APP_DELIGHTED_API_KEY, 'delighted');
}

export function plugJivo(callback) {
  requireThirdPartyScript('//code.jivosite.com/widget/UBBmTycRC9', callback);
}

export function plugZendesk(callback) {
  requireThirdPartyScript(
    'https://static.zdassets.com/ekr/snippet.js?key=3f603e25-1635-4a12-b218-fab436aed212',
    callback,
    'ze-snippet',
  );
}

export function plugAmplitude(user, callback) {
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE);
  amplitudeInstance.setUserId(user?.organization.id);
  amplitudeInstance.setUserProperties({
    email: user?.email,
    name: user?.name,
    organizationName: user?.organization.name,
    cnpj: user?.organization.cnpj,
    disabledAt: user?.organization.disabledAt,
    contractEndedAt: user?.organization.contractEndedAt,
    contractBeganAt: user?.organization.contractBeganAt,
    contractMonthDuration: user?.organization.contractMonthDuration,
    trialAt: user?.organization.trialAt,
    sector: user?.organization.sector,
    category: user?.organization.category,
    capacity: user?.organization.capacity,
    monthlyPayment: user?.organization.monthlyPayment,
    signUpFee: user?.organization.signUpFee,
  });
  amplitudeInstance.logEvent('initialized', {});
  callback();
}
