import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Divider from '../../../components/Divider/Divider';
import { Header } from './styles';

export const Container = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding: 12px 16px;
  border: 1px solid #e7e7e8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 484px;
  padding: 24px;
  overflow-y: auto;
`;

export type PermissionsTable = {
  profile: string;
  activeUsers: number;
  actions: number;
};

function SettingsContent() {
  const location = useLocation();

  const path = location.pathname.split('/');

  const title = path[path.length - 1];

  const contentTitle = useMemo(() => {
    switch (title) {
      default:
        return 'Informações gerais';
    }
  }, [title]);

  return (
    <Container>
      <Header>
        <span>{contentTitle}</span>
      </Header>
      <Divider />
      <Outlet />
    </Container>
  );
}

export default SettingsContent;
