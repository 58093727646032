import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  background: #ffffff;
  border-radius: 5px;
`;

export const GlobalFilterWrapper = styled.div`
  padding: 13px 25px 5px 9px;
  border-bottom: 1px solid #f8f8f8;
`;

export const TableWrapper = styled.div`
  flex: 1;
`;

export const OrderByLabel = styled.span`
  margin: 0 20px 0 10px;
`;

export const AddressLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
`;

export const DropdownContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  font-size: 1.3rem;
`;
