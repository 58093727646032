import * as yup from 'yup';

export const vehicleSchema = yup.object().shape({
  plate: yup.string().required('Campo obrigatório'),
  displayName: yup.string().nullable(),
  brand: yup.string().nullable(),
  model: yup.string().nullable(),
  year: yup.string().nullable(),
  fuelType: yup.string(),
  document: yup.string(),
  price: yup.number().nullable(),
  observations: yup.string().nullable(),
});

export const trafficInfractionSchema = yup.object().shape({
  cost: yup.number().required('Campo obrigatório'),
  location: yup
    .object()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  infraction: yup
    .object()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  dueDate: yup.date().required('Campo obrigatório'),
  paidCost: yup.number().nullable(),
  paidAt: yup.date().nullable(),
  occurredAt: yup.date().required('Campo obrigatório'),
  // files: yup.array(),
});

export const payTrafficInfractionSchema = yup.object().shape({
  cost: yup.number().required('Campo obrigatório'),
  driver: yup
    .object()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  paidCost: yup.number(),
  paidAt: yup.date().required('Campo obrigatório'),
  observation: yup.string(),
});
