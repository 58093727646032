import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  position: relative;
  z-index: 3;
  grid-area: header;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 0;
  height: ${(props) => props.theme.header.height};
  background-color: ${(props) => props.theme.header.backgroundColor};
  box-shadow: 0 2px 10px 0 ${(props) => props.theme.boxShadowColor};
  user-select: none;
  overflow: hidden;
`;

export const ToggleMenuButton = styled.button`
  margin: 0;
  padding: 20px 21px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LogoWrapper = styled.div`
  margin-left: 11px;
`;

export const ProfilerWrapper = styled.div`
  padding-left: 12px;
`;

export const Flag = styled.span`
  position: absolute;
  right: -24px;
  top: 10px;
  background: #cc0000;
  color: white;
  font-weight: 600;

  width: 88px;
  transform: rotate(45deg);
  clip-path: polygon(20% 0%, 76% 0%, 100% 100%, 0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;
