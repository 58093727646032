import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  grid-area: avatar;
  --avatar-size: 43px;
  height: var(--avatar-size);
  width: var(--avatar-size);
  border: 1px solid ${(props) => props.theme.colors.greyLight};
  overflow: hidden;
  border-radius: 50%;

  & img {
    max-width: 100%;
    height: auto;
  }
`;

interface Props {
  name?: string;
  imgPath: string;
}

export default function index({ name, imgPath }: Props): ReactElement {
  return (
    <Container data-testid="avatar-id">
      <img alt={`${name} avatar`} src={imgPath} />
    </Container>
  );
}
