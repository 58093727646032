import React from 'react';
import styled from 'styled-components';

type MenuProps = {
  variant: 'open' | 'closed';
  gap?: number;
};

const Container = styled.div`
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  width: 2.125rem;
  height: 1.1875rem;
  color: var(--sidebar-menu-color-open);
`;

const Icon = styled.div<MenuProps>`
  &::after,
  &::before {
    content: '';
  }

  &,
  &::after,
  &::before {
    display: block;
    position: absolute;
    width: 100%;
    height: 0.1875rem;
    border-radius: 0.25rem;
    background-color: currentColor;
    transition: width var(--sidebar-collapse-duration) ease-in-out;
  }

  bottom: 0;

  &::before {
    bottom: ${({ gap }) => gap ?? 0}px;
    width: ${(props) => (props.variant === 'closed' ? 100 : 78)}%;
  }

  &::after {
    bottom: ${({ gap }) => (gap ?? 0) * 2}px;
    width: ${(props) => (props.variant === 'closed' ? 100 : 50)}%;
  }
`;

export default function Menu(props: MenuProps) {
  const { variant, gap } = props;
  return (
    <Container>
      <Icon gap={gap} variant={variant} />
    </Container>
  );
}

Menu.defaultProps = {
  variant: 'open',
  gap: 8,
};
