import styled from 'styled-components';

type ContainerProps = {
  className?: 'collapsed';
};

export const Container = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas: 'icon label expand';
  grid-template-columns: 49px auto;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: currentColor;
  cursor: pointer;
  padding: 16px;
  background: #f1f3f7;
  border-radius: 5px;

  &:hover {
    color: var(--color-purple);
  }
`;

export const SidebarContainer = styled.nav<ContainerProps>`
  display: block;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  grid-area: sidebar;
  padding-top: 0.65625rem;
  transition: width var(--sidebar-collapse-duration) ease-in-out,
    background-color var(--sidebar-collapse-duration) ease-in-out;
  color: var(--sidebar-menu-color-open);
  width: var(--sidebar-width-open);
  background-color: var(--sidebar-background-open);
  box-shadow: 0 2px 10px 0 var(--box-shadow-color);
  user-select: none; /* disable user selection */
  &.collapsed {
    color: var(--sidebar-menu-color-close);
    width: var(--sidebar-width-close);
    background-color: var(--sidebar-background-close);
  }
`;

export const IconContainer = styled.span`
  --icon-color: var(--sidebar-icon-color-open);
  display: block;
  grid-area: icon;
  text-align: center;
  font-size: 18px;
  transition: visibility var(--sidebar-collapse-duration) ease-in-out,
    opacity var(--sidebar-collapse-duration) ease-in-out;
  ${SidebarContainer}.collapsed & {
    --icon-color: var(--sidebar-icon-color-close);
  }
  &,
  &:hover {
    color: var(--icon-color);
  }

  ${SidebarContainer}:not(.collapsed) & {
    color: currentColor;
  }
`;

export const ExpandIconContainer = styled.span`
  display: flex;
  justify-content: end;
  grid-area: expand;
  font-size: 20px;

  svg {
    color: #545454;
    font-size: 0.5em;
    transition: all 0.3s;
  }

  svg.expanded {
    transform: rotate(90deg);
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;

  &.content-expanded {
    padding: 0.7125rem 0;
    opacity: 1;
    visibility: visible;
    height: auto;
  }
`;

export const LabelContainer = styled.span`
  display: block;
  position: relative;
  grid-area: label;
  text-align: left;
  transition: visibility var(--sidebar-collapse-duration) ease-in-out,
    opacity var(--sidebar-collapse-duration) ease-in-out;
  ${SidebarContainer}.collapsed & {
    visibility: hidden;
    opacity: 0;
  }
`;

export const Flag = styled.span`
  border: 1px solid var(--color-purple);
  border-radius: 4px;
  font-size: 0.625rem;
  color: var(--color-purple);
  padding: 1px 2px;
  margin-left: 5px;
`;
