import React, { ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';
import ChevronRight from '../../../components/icons/ChevronRight';
import { useLayoutContext } from '../../../contexts/LayoutContextProvider';
import SidebarContainer from './Container';

const Container = styled.li`
  margin: 0;
  padding: 0;
`;

const ExpandIconContainer = styled.span`
  display: flex;
  justify-content: end;
  grid-area: expand;
  font-size: 20px;

  svg {
    color: #545454;
    font-size: 0.5em;
    transition: all 0.3s;
  }

  svg.expanded {
    transform: rotate(90deg);
  }
`;

const MenuItem = styled.div<{ $isActive?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-areas: 'icon label expand';
  grid-template-columns: 49px auto;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: currentColor;
  cursor: pointer;
  padding: 8px 8px 8px 0;
  border-radius: 4px;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};

      ${ExpandIconContainer} {
        svg {
          color: ${(props) => props.theme.colors.white};
        }
      }
    `}
`;

const MenuItemContainer = styled.div`
  margin: 0 8px;
  border-radius: 4px;
`;

const IconContainer = styled.span`
  --icon-color: var(--sidebar-icon-color-open);
  display: block;
  grid-area: icon;
  text-align: center;
  font-size: 24px;
  transition: visibility var(--sidebar-collapse-duration) ease-in-out,
    opacity var(--sidebar-collapse-duration) ease-in-out;
  ${SidebarContainer}.collapsed & {
    --icon-color: var(--sidebar-icon-color-close);
  }
  &,
  &:hover {
    color: var(--icon-color);
  }

  ${SidebarContainer}:not(.collapsed) & {
    color: currentColor;
  }
`;

const ContentContainer = styled.div<{ $isActive?: boolean }>`
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;

  &.content-expanded {
    padding: 0.7125rem 0;
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  a {
    display: grid;
    grid-template-columns: 100%;
    font-weight: normal;
    font-size: 0.85rem;
    line-height: 1rem;
    padding: 0.6125rem 1.29rem;
    margin: 0 8px;
    color: #212121;
    border-radius: 0 8px 8px 0;
    position: relative;
    transition: background ease 0.2s;

    &::before {
      content: '';
      display: flex;
      width: 4px;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.primary};
      transition: all ease 0.3s;
    }

    &:hover {
      background-color: #f1f3f7;
    }
  }
`;

const LabelContainer = styled.span`
  display: block;
  position: relative;
  grid-area: label;
  text-align: left;
  transition: visibility var(--sidebar-collapse-duration) ease-in-out,
    opacity var(--sidebar-collapse-duration) ease-in-out;
  ${SidebarContainer}.collapsed & {
    visibility: hidden;
    opacity: 0;
  }
`;

const Flag = styled.span`
  border: 1px solid var(--color-purple);
  border-radius: 4px;
  font-size: 0.625rem;
  color: var(--color-purple);
  padding: 1px 2px;
  margin-left: 5px;
`;

type MenuItemExpandProps = {
  label: string | React.ReactNode;
  flag?: string;
  icon: ReactNode;
  children: ReactNode;
  isActive?: boolean;
};

export default function MenuItemExpand(props: MenuItemExpandProps) {
  const { label, icon, flag, children, isActive } = props;

  const { sidebarCollapsed } = useLayoutContext();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const onExpand = () => setIsExpanded((expanded) => !expanded);

  const shouldExpand = !sidebarCollapsed && isExpanded;

  useEffect(() => {
    if (sidebarCollapsed && isExpanded) {
      setIsExpanded(false);
    }
  }, [isExpanded, sidebarCollapsed]);

  return (
    <Container>
      <MenuItemContainer>
        <MenuItem
          className={shouldExpand ? 'expanded' : ''}
          onClick={onExpand}
          $isActive={isActive}
        >
          <IconContainer>{icon}</IconContainer>
          <LabelContainer>
            {label}
            {flag ? <Flag>{flag}</Flag> : null}
          </LabelContainer>
          <ExpandIconContainer>
            <ChevronRight className={shouldExpand ? 'expanded' : ''} />
          </ExpandIconContainer>
        </MenuItem>
      </MenuItemContainer>
      <ContentContainer className={shouldExpand ? 'content-expanded' : ''}>
        {children}
      </ContentContainer>
    </Container>
  );
}
