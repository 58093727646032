import amplitude from 'amplitude-js';
import React from 'react';
import { useEffectOnce } from 'react-use';
import { AppModuleEnum } from '../constants/app-modules-enum';

export type Event = {
  action: string;
  module: AppModuleEnum;
  submodule?: string;
  extra?: Record<string, any>;
};

export type PageView = {
  path: string;
  title: string;
  extra?: Record<string, any>;
};

export function useUserTracker() {
  const emitEvent = React.useCallback((event: Event) => {
    const amplitudeInstance = amplitude.getInstance();
    amplitudeInstance.logEvent(event.action, {
      ...event.extra,
      module: event.module,
      submodule: event.submodule,
    });
  }, []);

  const sendPageView = React.useCallback(
    ({ path: page, ...rest }: PageView) => {
      // TODO: track pages on amplitude
      // ReactGA.send({
      //   hitType: 'pageview',
      //   page: page,
      //   ...rest,
      //   extra: getContext(user),
      // } as any);
    },
    [],
  );

  return { emitEvent, sendPageView };
}

export function usePageViewTracker(event: PageView) {
  const { sendPageView } = useUserTracker();
  useEffectOnce(() => {
    sendPageView(event);
  });
}

export function useEventTracker(event: Event) {
  const { emitEvent } = useUserTracker();
  useEffectOnce(() => {
    emitEvent(event);
  });
}
