import styled from 'styled-components';
import Icon from '../Icon/Icon';

export const DrawerHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  & ${Icon} {
    cursor: pointer;
    color: var(--color-grey-dark);
    font-size: 1.2em;
  }
`;

export const DrawerTitle = styled.h6`
  color: ${({ theme }) => theme.colors.secondaryDark};
  margin: 0 0 0 16px;
  font-weight: 600;
  font-size: 18px;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 16px;
  height: 100%;
  line-height: 18px;
  position: relative;
`;

export const ActionsContainer = styled.section`
  display: flex;
  column-gap: 16px;
  background: #fafbfc;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px rgba(140, 152, 164, 0.176);
  padding: 16px 24px;
  bottom: 0;
  position: absolute;
  width: 100%;
  right: 0;
`;
