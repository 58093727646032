import React, { lazy, Suspense, useMemo } from 'react';
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom';
import FullLoading from '../components/FullLoading/FullLoading';
import NotFoundPage from '../components/NotFoundPage';
import { AppModuleEnum } from '../constants/app-modules-enum';
import { useCurrentUserContext } from '../contexts/CurrentUserContextProvider';
import MainLayout from '../layouts/MainLayout';
import OrganizationInformations from './settings/components/OrganizationInformations/OrganizationInformations';
import SettingsPage from './settings/SettingsPage';
import TrafficInfractionTab from './vehicleProfile/components/Tabs/TrafficInfractionTab';
import VehicleInfo from './vehicleProfile/components/Tabs/VehicleSettingsTab/VehicleInfo/VehicleInfo';
import VehicleSettings from './vehicleProfile/components/Tabs/VehicleSettingsTab/VehicleSettings/VehicleSettings';
import VehicleSettingsTab from './vehicleProfile/components/Tabs/VehicleSettingsTab/VehicleSettingsTab';

const AccountSuspended = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      '../components/AccountSuspended'
    ),
);
const DashboardPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './dashboard/DashboardPage'
    ),
);
const NewDashboardPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './new-dashboard/NewDashboardPage'
    ),
);
const DriversPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './drivers/DriversPage'
    ),
);
const UsersPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './users/UsersPage'
    ),
);
const UsersListPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './users/UsersListPage'
    ),
);
const UserSettingsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './users/UserSettingsPage'
    ),
);
const UserPermissions = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './users/components/SettingsContent/Permissions'
    ),
);
const UserProfile = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './users/components/SettingsContent/UserProfile'
    ),
);
const UserVehicles = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './users/components/SettingsContent/UserVehicles'
    ),
);

const IntegrationsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './integrations/IntegrationsPage'
    ),
);
const ImportsMappingPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './integrations/mappings/ImportsMappingPage'
    ),
);
const MonitoringPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './monitoring/MonitoringPage'
    ),
);
const FuellingsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './fuellings/FuellingsPage'
    ),
);
const ProvidersPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './providers/ProvidersPage'
    ),
);
const MaintenancesPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './maintenances/MaintenancesPage'
    ),
);
const TanksPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './tanks/TanksPage'
    ),
);
const ServicesPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './services/ServicesPage'
    ),
);
const RemindersPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './reminders/RemindersPage'
    ),
);
const VehiclesPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './vehicles/VehiclesPage'
    ),
);
const VehiclesListPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './vehicles/VehiclesListPage'
    ),
);
const VehicleProfilePage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './vehicleProfile/VehicleProfilePage'
    ),
);
const VehicleProfileOverview = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './vehicleProfile/components/Tabs/OverviewTab'
    ),
);
const ReportsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './reports/ReportsPage'
    ),
);

const ResetPassword = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      '../auth/pages/ResetPassword'
    ),
);

function Modules() {
  const {
    user: { modules },
    isSuspended,
  } = useCurrentUserContext();

  const routesDefinition: RouteObject[] = useMemo(() => {
    const routes: (RouteObject & { module?: AppModuleEnum })[] = [
      {
        module: AppModuleEnum.monitoring,
        path: '/',
        element: <MonitoringPage />,
      },
      {
        module: AppModuleEnum.indicators,
        path: 'dashboard',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <DashboardPage />,
          },
          {
            path: 'new',
            element: <NewDashboardPage />,
          },
        ],
      },
      {
        module: AppModuleEnum.integrations,
        path: 'integrations',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <IntegrationsPage />,
          },
          {
            path: ':integrationId/mappings/*',
            element: <ImportsMappingPage />,
          },
        ],
      },
      {
        module: AppModuleEnum.vehicles,
        path: 'vehicles',
        element: <VehiclesPage />,
        children: [
          {
            index: true,
            element: <VehiclesListPage />,
          },
          {
            path: ':vehicleId',
            element: <VehicleProfilePage />,
            children: [
              {
                index: true,
                element: <VehicleProfileOverview />,
              },
              {
                path: 'traffic-infractions',
                element: <TrafficInfractionTab />,
              },
              {
                path: 'settings',
                element: <VehicleSettingsTab />,
                children: [
                  {
                    index: true,
                    element: <VehicleInfo />,
                  },
                  {
                    path: 'vehicleSettings',
                    element: <VehicleSettings />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        module: AppModuleEnum.drivers,
        path: 'drivers',
        element: <DriversPage />,
      },
      {
        module: AppModuleEnum.fuellings,
        element: <Outlet />,
        path: 'fuellings',
        children: [
          {
            index: true,
            path: '',
            element: <FuellingsPage />,
          },
          {
            path: 'tanks',
            element: <TanksPage />,
          },
        ],
      },
      {
        module: AppModuleEnum.maintenances,
        path: 'maintenances',
        element: <MaintenancesPage />,
        children: [
          {
            path: 'services',
            element: <ServicesPage />,
          },
          {
            path: 'reminders',
            element: <RemindersPage />,
          },
        ],
      },
      {
        module: AppModuleEnum.providers,
        path: 'providers',
        element: <ProvidersPage />,
      },
      {
        module: AppModuleEnum.reports,
        path: 'reports',
        element: <ReportsPage />,
      },
      {
        module: AppModuleEnum.settings,
        path: 'settings',
        element: <SettingsPage />,
        children: [
          {
            index: true,
            element: <OrganizationInformations />,
          },
        ],
      },
      { path: 'users', element: <UsersPage /> },
      {
        path: 'users',
        element: <UsersPage />,
        children: [
          {
            index: true,
            element: <UsersListPage />,
          },
          {
            path: ':userId',
            element: <UserSettingsPage />,
            children: [
              {
                index: true,
                element: <UserProfile />,
              },
              {
                path: 'vehicles',
                element: <UserVehicles />,
              },
              {
                path: 'permissions',
                element: <UserPermissions />,
              },
            ],
          },
        ],
      },
      { path: '*', element: <NotFoundPage /> },
    ];

    const allowedRoutes = routes
      .filter(({ module }) => !module || modules.includes(module))
      .map(({ module, ...rest }) => rest as RouteObject);

    if (modules.includes(AppModuleEnum.monitoring)) {
      return allowedRoutes;
    }

    const firstModulePath = allowedRoutes[0].path ?? '*';
    const mainPath = firstModulePath !== '*' ? firstModulePath : '/not-found';

    return [
      { path: '/', element: <Navigate to={mainPath} /> },
      ...allowedRoutes,
    ];
  }, [modules]);

  const element = useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <Suspense fallback={<FullLoading />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: routesDefinition,
    },
  ]);

  if (isSuspended) {
    return (
      <MainLayout>
        <Suspense fallback={<FullLoading />}>
          <AccountSuspended />
        </Suspense>
      </MainLayout>
    );
  }

  if (window.location.pathname === '/password-reset') {
    return (
      <Suspense fallback={<FullLoading />}>
        <ResetPassword />
      </Suspense>
    );
  }

  return <>{element}</>;
}

export default Modules;
