import { TableProps } from 'antd';
import moment from 'moment';
import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';
import DropdownMenu, {
  DropdownMenuProps,
} from '../../../../../../components/DropdownMenu/DropdownMenu';
import Icon from '../../../../../../components/Icon/Icon';
import MoreVertical from '../../../../../../components/icons/MoreVertical';
import { TrafficInfractionFragment } from '../../../../../../generated/graphqlV2';
import formatCurrency from '../../../../../../helpers/format-currency';
import { CustomAntTable } from '../../../../../reminders/components/Table/Table.style';
import { DrawerType } from '../../../../types/TrafficInfractions.types';
import { useDrawerContext } from '../contexts/DrawerContext';
import { useTrafficInfractionFormContext } from '../contexts/TrafficInfractionFormContext';
import { useTrafficInfractionTableContext } from '../contexts/TrafficInfractionTableContext';
import GlobalFilter from '../GlobalFilter';
import { TableContainer } from '../styles';
import { AddressLabel, DropdownContainer, GlobalFilterWrapper } from './styles';

const actions = [
  { key: 'update', label: 'Editar' },
  { key: 'delete', label: 'Excluir' },
];

function TrafficInfractionTable() {
  const [selectedRow, setSelectedRow] = useState<Key[]>([]);
  const { setSelectedData, filteredData, fetching } =
    useTrafficInfractionTableContext();
  const { deleteTrafficInfractionHandler } = useTrafficInfractionFormContext();
  const { open } = useDrawerContext();

  const handleDropdownMenu: DropdownMenuProps['onClick'] = useCallback(
    (action, trafficInfraction) => {
      switch (action.key) {
        case 'update':
          open(DrawerType.edit, trafficInfraction);
          return;
        case 'delete':
          return deleteTrafficInfractionHandler([trafficInfraction.id]);
      }
    },
    [deleteTrafficInfractionHandler, open],
  );

  const columns: TableProps<TrafficInfractionFragment>['columns'] = [
    {
      title: 'Data',
      dataIndex: 'paidAt',
      render: (_value, record) => (
        <span>{moment(record.occurredAt).format('DD/MM/YY').toString()}</span>
      ),
    },
    {
      title: 'Condutor',
      dataIndex: 'driver.name',
      render: (_value, record) => <span>{record.driver?.name}</span>,
    },
    {
      title: 'Local',
      dataIndex: 'location',
      render: (_value, record) => (
        <AddressLabel>{record?.address}</AddressLabel>
      ),
    },
    {
      title: 'Infração',
      dataIndex: 'infraction.description',
      render: (_value, record) => <span>{record.infraction.description}</span>,
    },
    {
      title: 'Valor',
      dataIndex: 'paidCost',
      render: (_value, record) => (
        <span>{formatCurrency(record.paidCost)}</span>
      ),
    },
    {
      render: (_: string, record: any) => (
        <DropdownMenu
          actions={actions}
          onClick={(action) => handleDropdownMenu(action, record)}
          item={record}
        >
          <DropdownContainer>
            <Icon $size="21px" color="#434343">
              <MoreVertical />
            </Icon>
          </DropdownContainer>
        </DropdownMenu>
      ),
    },
  ];

  const parsedSelectedFines = useMemo(() => {
    return filteredData?.filter((fine) => {
      return selectedRow.indexOf(fine.id) > -1;
    });
  }, [filteredData, selectedRow]);

  const paidTrafficInfractions = useMemo(() => {
    return (
      filteredData?.filter((trafficInfraction) => !!trafficInfraction.paidAt) ||
      []
    );
  }, [filteredData]);

  useEffect(() => {
    setSelectedData(parsedSelectedFines || []);
  }, [parsedSelectedFines, setSelectedData]);

  return (
    <div>
      <p>Finalizadas ({paidTrafficInfractions?.length})</p>
      <TableContainer>
        <GlobalFilterWrapper>
          <GlobalFilter />
        </GlobalFilterWrapper>
        <CustomAntTable
          rowKey="id"
          columns={columns as any}
          dataSource={paidTrafficInfractions}
          rowSelection={{
            onChange: (keys) => setSelectedRow(keys),
            selectedRowKeys: selectedRow,
          }}
          loading={fetching}
        />
      </TableContainer>
    </div>
  );
}

export default TrafficInfractionTable;
