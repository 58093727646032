import React, {
  MouseEvent,
  ReactNode,
  TransitionEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDom from 'react-dom';
import CustomModalView from './CustomModalView';

const modalRoot: Element | null = document.getElementById('modal-root');

type Fade = 'in' | 'out';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
  children?: ReactNode;
  disableClose?: boolean;
}

function CustomModal(props: ModalProps) {
  const { isOpen, onClose, id, children, disableClose } = props;
  const [fadeType, setFadeType] = useState<Fade>('out');
  const [open, setOpen] = useState(false);

  const background = useRef<HTMLDivElement>(null);

  const onEscKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (disableClose) return;
      if (e.key === 'Escape') {
        setFadeType('out');
      }
      return;
    },
    [disableClose],
  );

  useEffect(() => {
    window.addEventListener('keydown', onEscKeyDown, false);
    return () => window.removeEventListener('keydown', onEscKeyDown, false);
  }, [onEscKeyDown]);

  useEffect(() => {
    if (!isOpen) {
      setFadeType('out');
    }
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (open) {
      setTimeout(() => setFadeType('in'), 50);
    }
  }, [open]);

  const transitionEnd = (e: TransitionEvent) => {
    if (e.propertyName !== 'opacity' || fadeType === 'in') return;

    if (fadeType === 'out') {
      onClose();
      setOpen(false);
    }
  };

  const handleClick = (e: MouseEvent) => {
    if (disableClose) return;
    e.preventDefault();
    setFadeType('out');
  };

  if (open)
    return ReactDom.createPortal(
      <CustomModalView
        id={id}
        fadeType={fadeType}
        transitionEnd={transitionEnd}
        handleClick={handleClick}
        ref={background}
      >
        {children}
      </CustomModalView>,
      modalRoot as HTMLElement,
    );
  return null;
}

export { CustomModal };
