import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import { useLayoutContext } from '../../contexts/LayoutContextProvider';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import Icon from '../Icon/Icon';
import PageContainer from './PageContainer';
import PageContent from './PageContent';
import PageHeader from './PageHeader';
import PageHeaderActions from './PageHeaderActions';

type PageProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  headerActions?: React.ReactNode;
  onlyContent?: boolean;
  goBack?: {
    to: () => void;
    label?: string;
    icon?: React.ReactNode;
  };
};

const GoBack = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`;

function Page(props: PageProps) {
  const { title, headerActions, onlyContent, children, goBack } = props;
  const { setTitle } = useLayoutContext();
  useEffect(() => {
    if (typeof title !== 'string') {
      setTitle(void 0);
      return;
    }

    setTitle(title);
  }, [setTitle, title]);

  if (onlyContent) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    );
  }

  return (
    <PageContainer>
      {goBack && (
        <GoBack onClick={goBack.to}>
          <Icon>{goBack.icon}</Icon>
          {goBack.label}
        </GoBack>
      )}
      <PageHeader title={title} />
      {!!headerActions && (
        <PageHeaderActions>{headerActions}</PageHeaderActions>
      )}
      <PageContent>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>
      </PageContent>
    </PageContainer>
  );
}

export default Page;
