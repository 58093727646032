import React, { useState } from 'react';
import Button from '../../../../components/Button/Button';
import { CustomModal } from '../../../../components/CustomModal/CustomModal';
import Icon from '../../../../components/Icon/Icon';
import Close from '../../../../components/icons/Close';
import { CancelButton } from '../../../../components/VehicleFastActions/EditSpeedmeterModal/styles';
import { VehicleTypeEnum } from '../../../../generated/graphqlV2';
import getVehicleImage from '../../../../helpers/get-vehicle-image';
import { useAvatarModalContext } from '../../contexts/AvatarModalContext';
import { useVehicleProfileContext } from '../../contexts/VehicleProfileContext';
import { AvatarList, Footer, Header, ImageContainer } from './styles';

export type AvatarModalProps = {
  isOpen: boolean;
  setClose: () => void;
};

const AVATAR_LIST = VehicleTypeEnum;

function AvatarModal(props: AvatarModalProps) {
  const { isOpen, setClose } = props;

  const { updateVehicle, loading } = useAvatarModalContext();
  const { vehicleInfo } = useVehicleProfileContext();
  const [selectedAvatar, setSelectedAvatar] = useState(
    vehicleInfo?.avatarType ?? vehicleInfo?.type,
  );

  const handleSelectAvatar = (avatar: string) => {
    const foundAvatar = Object.values(AVATAR_LIST).find(
      (item) => item === avatar,
    );

    setSelectedAvatar(foundAvatar);
  };

  const handleUpdateAvatar = async () => {
    if (!vehicleInfo) return;

    const payload = {
      id: vehicleInfo.id,
      avatarType: selectedAvatar ?? vehicleInfo.avatarType,
    };

    await updateVehicle(payload);
  };

  return (
    <CustomModal isOpen={isOpen} onClose={setClose}>
      <Header>
        <h2>Editar avatar</h2>
        <Icon onClick={setClose}>
          <Close />
        </Icon>
      </Header>
      <AvatarList>
        {Object.values(AVATAR_LIST).map((avatarItem) => {
          return (
            <ImageContainer
              key={avatarItem}
              $isSelected={selectedAvatar === avatarItem}
              onClick={() => handleSelectAvatar(avatarItem)}
            >
              <img src={getVehicleImage(avatarItem)} alt={avatarItem} />
            </ImageContainer>
          );
        })}
      </AvatarList>
      <Footer>
        <CancelButton onClick={setClose}>Cancelar</CancelButton>
        <Button onClick={handleUpdateAvatar} disabled={loading}>
          {loading ? 'Salvando...' : 'Alterar avatar'}
        </Button>
      </Footer>
    </CustomModal>
  );
}

export default AvatarModal;
