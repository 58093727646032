import { rgba } from 'polished';
import { createGlobalStyle } from 'styled-components';

const rgbNumbers = (colors: string) =>
  rgba(colors, 0.9)
    .replace(/\s/, '')
    .replace(/(rgba\(|,0?\.9\))/g, '');

const GlobalStyle = createGlobalStyle`
  :root {
  /* Common */
  --color-primary: ${(props) => props.theme.colors.primary} ;
  --color-primary-light: ${(props) => props.theme.colors.primaryLight};
  --color-purple: ${(props) => props.theme.colors.purple};
  --color-grey-dark: ${(props) => props.theme.colors.greyDark};
  --color-grey: ${(props) => props.theme.colors.grey};
  --color-grey-light: ${(props) => props.theme.colors.greyLight};
  --color-grey-lighter: ${(props) => props.theme.colors.secondaryLight};

  --box-shadow-color: ${(props) => props.theme.boxShadowColor};
  --side-modal-background-color: ${(props) =>
    props.theme.sideModal.backgroundColor};

  --color-alert: ${(props) => props.theme.colors.alert} ;
  --color-alert-light: ${(props) => props.theme.colors.alertLight};
  --color-primary-rgb: ${(props) => rgbNumbers(props.theme.colors.primary)};

  --color-machine-power-on: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.powerOn)}; 
  --color-machine-power-off: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.powerOff)}; 
  --color-machine-power-unknown: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.powerUnknown)}; 
  --color-machine-power-outdated: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.outdated)}; 
  --color-machine-stopped: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.stopped)};
  --color-machine-motion: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.motion)};
  --color-machine-driver-change: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.driverChange)};
  --color-auxiliary-warning: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.unplugged)};
  --color-auxiliary-successful: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.plugged)};  
  --color-machine-device-speeding: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.deviceSpeeding)};
  --color-machine-device-speeding-normalized: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.deviceSpeedingNormalized)};
  --color-machine-route-speeding: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.routeSpeeding)}; 
  --color-machine-hard-braking: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.hardBraking)}; 
  --color-machine-hard-cornering: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.hardCornering)}; 
  --color-machine-hard-acceleration: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.hardAcceleration)}; 
  --color-machine-overweight: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.overweight)};
  --color-machine-cockpit-open: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.cockpitOpen)};
  --color-machine-cockpit-close: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.cockpitClose)};
  --color-machine-trailer-open: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.trailerOpen)};
  --color-machine-fault: ${(props) =>
    rgbNumbers(props.theme.machineEventColors.fault)};

  --background-color: ${(props) => props.theme.backgroundColor};

  /* Header */
  --header-background:${(props) => props.theme.header.backgroundColor} ;
  --header-height: ${(props) => props.theme.header.height};

  /* Sidebar */
  --sidebar-width-close: ${(props) => props.theme.sidebar.widthClose};
  --sidebar-width-open: ${(props) => props.theme.sidebar.widthOpen};
  --sidebar-collapse-duration: ${(props) =>
    props.theme.sidebar.collapseDuration};
  --sidebar-background-open: ${(props) => props.theme.sidebar.backgroundOpen};
  --sidebar-background-close: ${(props) => props.theme.sidebar.backgroundClose};
  --sidebar-menu-color-open: ${(props) => props.theme.sidebar.menuColorOpen};
  --sidebar-menu-color-close: ${(props) => props.theme.sidebar.menuColorClose};
  --sidebar-icon-color-open: ${(props) => props.theme.sidebar.iconColorOpen};
  --sidebar-icon-color-close: ${(props) => props.theme.sidebar.iconColorClose};

  /* SideModal */
  --side-modal-width: ${(props) => props.theme.sideModal.width};
}

body {
  font-family: Inter, sans-serif;
  background-color: ${(props) => props.theme.bodyBackgroundColor};
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: ${(props) => props.theme.colors.purple};
}

p {
  color: #747678;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.9375rem;
}

h1 {
  color: ${(props) => props.theme.colors.secondaryDark};
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
}

h3 {
  color: ${(props) => props.theme.colors.grey};
  font-size: 0.95875rem;
  letter-spacing: 0;
  line-height: 1.1875rem;
  margin-bottom: 1.125rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
.ant-select-suffix > svg {
  fill: ${(props) => props.theme.colors.purple};
}
.ant-select-lg {
  font-size: 14px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${(props) => props.theme.colors.purple};
  border-color: ${(props) => props.theme.colors.purple};

  &::selection {
    background: ${(props) => props.theme.colors.purple}
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: ${(props) => props.theme.colors.purple}
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color:${(props) => props.theme.colors.purple}
}

.ant-checkbox-disabled > .ant-checkbox-inner{
  background-color: transparent;
};

.ant-modal-content, 
.ant-modal-content .ant-modal-header {
  border-radius: 10px;
}

.ant-modal-footer {
  padding: 16px;
}

.ant-select-selection-placeholder {
  margin-top: 5px;
}

.ant-tooltip {
  max-width: 192px;
  font-size: 12px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: ${(props) => props.theme.colors.purple};
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
  background-color: ${(props) => props.theme.colors.purple};
}

.ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-in-range .ant-picker-cell-range-hover-end .ant-picker-cell-inner{
  color: ${(props) => props.theme.colors.white};
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before{
  border-color: ${(props) => props.theme.colors.purple};
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: ${(props) => props.theme.colors.purple};
  color: ${(props) => props.theme.colors.white};
}

.ant-btn-primary:focus, 
.ant-btn-primary,
.ant-btn-primary:hover {
  background-color: ${(props) => props.theme.colors.purple};
  border-color: ${(props) => props.theme.colors.purple};
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before,
.ant-picker-cell-in-view.ant-picker-cell-in-range:before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after{
  background: ${(props) => props.theme.colors.purpleLight};
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after {
  border-top: 1px dashed ${(props) => props.theme.colors.purple};
  border-bottom: 1px dashed ${(props) => props.theme.colors.purple};
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after {
  border-right: 1px dashed ${(props) => props.theme.colors.purple};
}

tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
  border-left: 1px dashed ${(props) => props.theme.colors.purple};
}

.ReactCollapse--collapse {
  transition: height 0.35s ease-in-out;
}

.ReactCollapse--content {}

`;

export default GlobalStyle;
