import { useEffect, useState } from 'react';
interface Props {
  delayMS: number;
  isMounted: boolean;
}

export default function useUnmountingDelay({ isMounted, delayMS }: Props) {
  const [shouldRender, setShouldRender] = useState(isMounted);

  useEffect(() => {
    if (isMounted && shouldRender === false) {
      setShouldRender(true);
    }

    if (shouldRender === true && isMounted === false) {
      const timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, delayMS);

      return () => clearTimeout(timeoutId);
    }
  }, [delayMS, shouldRender, isMounted]);

  return shouldRender;
}
