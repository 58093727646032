import currency from 'currency.js';

export default function formatCurrency(value?: number | null, symbol = 'R$') {
  const symbolWithRightSpace = `${symbol} `;
  if (typeof value === 'undefined' || value === null) {
    return `${symbolWithRightSpace}-,--`;
  }

  return currency(value, {
    separator: '.',
    symbol: symbolWithRightSpace,
    decimal: ',',
  }).format();
}
