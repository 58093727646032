import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-self: stretch;
  justify-self: stretch;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

function ErrorFallback(props: FallbackProps) {
  const { error, resetErrorBoundary } = props;

  return (
    <Container role="alert">
      <Title>Lamentamos, algo deu errado.</Title>
      <pre>{error.message}</pre>
      <PrimaryButton onClick={resetErrorBoundary}>
        Tentar novamente
      </PrimaryButton>
    </Container>
  );
}

export default ErrorFallback;
