import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  citext: any;
  float8: number;
  json: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

export type AddressOutput = {
  __typename?: 'AddressOutput';
  address: Scalars['String'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type DriverSummary = {
  __typename?: 'DriverSummary';
  avg_speed?: Maybe<Scalars['Float']>;
  driver_id: Scalars['uuid'];
  max_speed?: Maybe<Scalars['Float']>;
  telemetry_events_by_day: Scalars['jsonb'];
  telemetry_summary?: Maybe<Scalars['jsonb']>;
  total_telemetry_events?: Maybe<Scalars['Int']>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type MaintenanceSummary = {
  __typename?: 'MaintenanceSummary';
  average_cost?: Maybe<Scalars['Float']>;
  expense?: Maybe<Scalars['Float']>;
  income?: Maybe<Scalars['Float']>;
  maintenances?: Maybe<Scalars['Float']>;
  maximum_cost?: Maybe<Scalars['Float']>;
  minimum_cost?: Maybe<Scalars['Float']>;
  total_cost?: Maybe<Scalars['Float']>;
  vehicle?: Maybe<Vehicle>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

export type PercentageTimeStoppedOutput = {
  __typename?: 'PercentageTimeStoppedOutput';
  total: Scalars['Int'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

export type TotalDistanceOutput = {
  __typename?: 'TotalDistanceOutput';
  total: Scalars['Int'];
};

export type TotalTimeEngineIdleOutput = {
  __typename?: 'TotalTimeEngineIdleOutput';
  total: Scalars['Int'];
};

export type TotalTimeStoppedOutput = {
  __typename?: 'TotalTimeStoppedOutput';
  total: Scalars['Int'];
};

export type TotalTimeStoppedWithIgnitionOnOutput = {
  __typename?: 'TotalTimeStoppedWithIgnitionOnOutput';
  total: Scalars['Int'];
};

export type TotalTimeWithIgnitionOnOutput = {
  __typename?: 'TotalTimeWithIgnitionOnOutput';
  total: Scalars['Int'];
};

export type UpdateVehicleMappingOutput = {
  __typename?: 'UpdateVehicleMappingOutput';
  attributes?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabled_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid?: Maybe<Scalars['Boolean']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

export type VehicleFuellingSummary = {
  __typename?: 'VehicleFuellingSummary';
  amount?: Maybe<Scalars['Float']>;
  average_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  average_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  average_cost?: Maybe<Scalars['Float']>;
  average_unit_cost?: Maybe<Scalars['Float']>;
  fuel_used_from_can?: Maybe<Scalars['Float']>;
  fuellings: Scalars['Float'];
  horimeter_autonomy_from_can?: Maybe<Scalars['Float']>;
  maximum_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  maximum_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  maximum_cost?: Maybe<Scalars['Float']>;
  maximum_unit_cost?: Maybe<Scalars['Float']>;
  minimum_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  minimum_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  minimum_cost?: Maybe<Scalars['Float']>;
  minimum_unit_cost?: Maybe<Scalars['Float']>;
  odometer_autonomy_from_can?: Maybe<Scalars['Float']>;
  total_cost?: Maybe<Scalars['Float']>;
  vehicle_id: Scalars['uuid'];
};

export type VehicleMappingInput = {
  disabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

export type VehicleProductivity = {
  __typename?: 'VehicleProductivity';
  journeys?: Maybe<Scalars['jsonb']>;
  vehicle?: Maybe<Vehicle>;
  vehicle_id: Scalars['uuid'];
};

export type VehicleRouteDetails = {
  __typename?: 'VehicleRouteDetails';
  average_speed?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  geojson?: Maybe<Scalars['jsonb']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  total_distance_traveled?: Maybe<Scalars['Float']>;
  total_time_stopped?: Maybe<Scalars['Float']>;
  total_time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  total_time_with_ignition_on?: Maybe<Scalars['Float']>;
};

export type VehicleSummary = {
  __typename?: 'VehicleSummary';
  avg_speed?: Maybe<Scalars['Float']>;
  engine_idle_by_day: Scalars['jsonb'];
  max_speed?: Maybe<Scalars['Float']>;
  percentage_time_engine_idle?: Maybe<Scalars['Float']>;
  telemetry_events_by_day: Scalars['jsonb'];
  telemetry_summary?: Maybe<Scalars['jsonb']>;
  total_telemetry_events?: Maybe<Scalars['Int']>;
  total_traveled?: Maybe<Scalars['Float']>;
  vehicle_id: Scalars['uuid'];
};

export type VehiclesAvgSpeedOutput = {
  __typename?: 'VehiclesAvgSpeedOutput';
  total: Scalars['Float'];
};

export type VehiclesAvgWeightOutput = {
  __typename?: 'VehiclesAvgWeightOutput';
  total: Scalars['Float'];
};

export type VehiclesMaxSpeedOutput = {
  __typename?: 'VehiclesMaxSpeedOutput';
  total: Scalars['Float'];
};

export type VehiclesMaxWeightOutput = {
  __typename?: 'VehiclesMaxWeightOutput';
  total: Scalars['Float'];
};

export type VehiclesTotalDistanceOutput = {
  __typename?: 'VehiclesTotalDistanceOutput';
  total: Scalars['Float'];
};

export type VehiclesTotalTimeStoppedOutput = {
  __typename?: 'VehiclesTotalTimeStoppedOutput';
  total: Scalars['Float'];
};

export type VehiclesTotalTimeStoppedWithIgnitionOnOutput = {
  __typename?: 'VehiclesTotalTimeStoppedWithIgnitionOnOutput';
  total: Scalars['Float'];
};

export type VehiclesTotalTimeWithIgnitionOnOutput = {
  __typename?: 'VehiclesTotalTimeWithIgnitionOnOutput';
  total: Scalars['Float'];
};

/** expression to compare columns of type citext. All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "driver" */
export type Driver = {
  __typename?: 'driver';
  birthday?: Maybe<Scalars['timestamptz']>;
  cnh?: Maybe<Scalars['String']>;
  cnh_category?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  external_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  source: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vehicle?: Maybe<Vehicle>;
};

/** aggregated selection of "driver" */
export type Driver_Aggregate = {
  __typename?: 'driver_aggregate';
  aggregate?: Maybe<Driver_Aggregate_Fields>;
  nodes: Array<Driver>;
};

/** aggregate fields of "driver" */
export type Driver_Aggregate_Fields = {
  __typename?: 'driver_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Driver_Max_Fields>;
  min?: Maybe<Driver_Min_Fields>;
};

/** aggregate fields of "driver" */
export type Driver_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Driver_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "driver" */
export type Driver_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Driver_Max_Order_By>;
  min?: InputMaybe<Driver_Min_Order_By>;
};

/** input type for inserting array relation for remote table "driver" */
export type Driver_Arr_Rel_Insert_Input = {
  data: Array<Driver_Insert_Input>;
  on_conflict?: InputMaybe<Driver_On_Conflict>;
};

/** Boolean expression to filter rows from the table "driver". All fields are combined with a logical 'AND'. */
export type Driver_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Driver_Bool_Exp>>>;
  _not?: InputMaybe<Driver_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Driver_Bool_Exp>>>;
  birthday?: InputMaybe<Timestamptz_Comparison_Exp>;
  cnh?: InputMaybe<String_Comparison_Exp>;
  cnh_category?: InputMaybe<String_Comparison_Exp>;
  cpf?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
};

/** unique or primary key constraints on table "driver" */
export enum Driver_Constraint {
  /** unique or primary key constraint */
  DriverCnhOrganizationIdKey = 'driver_cnh_organization_id_key',
  /** unique or primary key constraint */
  DriverCpfOrganizationIdKey = 'driver_cpf_organization_id_key',
  /** unique or primary key constraint */
  DriverPkey = 'driver_pkey',
  /** unique or primary key constraint */
  DriverUserIdKey = 'driver_user_id_key',
}

/** input type for inserting data into table "driver" */
export type Driver_Insert_Input = {
  birthday?: InputMaybe<Scalars['timestamptz']>;
  cnh?: InputMaybe<Scalars['String']>;
  cnh_category?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Driver_Max_Fields = {
  __typename?: 'driver_max_fields';
  birthday?: Maybe<Scalars['timestamptz']>;
  cnh?: Maybe<Scalars['String']>;
  cnh_category?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "driver" */
export type Driver_Max_Order_By = {
  birthday?: InputMaybe<Order_By>;
  cnh?: InputMaybe<Order_By>;
  cnh_category?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Driver_Min_Fields = {
  __typename?: 'driver_min_fields';
  birthday?: Maybe<Scalars['timestamptz']>;
  cnh?: Maybe<Scalars['String']>;
  cnh_category?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "driver" */
export type Driver_Min_Order_By = {
  birthday?: InputMaybe<Order_By>;
  cnh?: InputMaybe<Order_By>;
  cnh_category?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "driver" */
export type Driver_Mutation_Response = {
  __typename?: 'driver_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Driver>;
};

/** input type for inserting object relation for remote table "driver" */
export type Driver_Obj_Rel_Insert_Input = {
  data: Driver_Insert_Input;
  on_conflict?: InputMaybe<Driver_On_Conflict>;
};

/** on conflict condition type for table "driver" */
export type Driver_On_Conflict = {
  constraint: Driver_Constraint;
  update_columns: Array<Driver_Update_Column>;
  where?: InputMaybe<Driver_Bool_Exp>;
};

/** ordering options when selecting data from "driver" */
export type Driver_Order_By = {
  birthday?: InputMaybe<Order_By>;
  cnh?: InputMaybe<Order_By>;
  cnh_category?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
};

/** primary key columns input for table: "driver" */
export type Driver_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "driver" */
export enum Driver_Select_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Cnh = 'cnh',
  /** column name */
  CnhCategory = 'cnh_category',
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Source = 'source',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "driver" */
export type Driver_Set_Input = {
  birthday?: InputMaybe<Scalars['timestamptz']>;
  cnh?: InputMaybe<Scalars['String']>;
  cnh_category?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "driver" */
export enum Driver_Update_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Cnh = 'cnh',
  /** column name */
  CnhCategory = 'cnh_category',
  /** column name */
  Cpf = 'cpf',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Name = 'name',
  /** column name */
  Source = 'source',
  /** column name */
  Token = 'token',
  /** column name */
  UserId = 'user_id',
}

/** columns and relationships of "equipment" */
export type Equipment = {
  __typename?: 'equipment';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  identification: Scalars['citext'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "equipment" */
export type Equipment_Aggregate = {
  __typename?: 'equipment_aggregate';
  aggregate?: Maybe<Equipment_Aggregate_Fields>;
  nodes: Array<Equipment>;
};

/** aggregate fields of "equipment" */
export type Equipment_Aggregate_Fields = {
  __typename?: 'equipment_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Equipment_Max_Fields>;
  min?: Maybe<Equipment_Min_Fields>;
};

/** aggregate fields of "equipment" */
export type Equipment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Equipment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "equipment" */
export type Equipment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Equipment_Max_Order_By>;
  min?: InputMaybe<Equipment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "equipment" */
export type Equipment_Arr_Rel_Insert_Input = {
  data: Array<Equipment_Insert_Input>;
  on_conflict?: InputMaybe<Equipment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "equipment". All fields are combined with a logical 'AND'. */
export type Equipment_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Equipment_Bool_Exp>>>;
  _not?: InputMaybe<Equipment_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Equipment_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identification?: InputMaybe<Citext_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "equipment" */
export enum Equipment_Constraint {
  /** unique or primary key constraint */
  EquipmentOrganizationIdIdentificationKey = 'equipment_organization_id_identification_key',
  /** unique or primary key constraint */
  EquipmentPkey = 'equipment_pkey',
}

/** input type for inserting data into table "equipment" */
export type Equipment_Insert_Input = {
  identification?: InputMaybe<Scalars['citext']>;
};

/** aggregate max on columns */
export type Equipment_Max_Fields = {
  __typename?: 'equipment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identification?: Maybe<Scalars['citext']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "equipment" */
export type Equipment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Equipment_Min_Fields = {
  __typename?: 'equipment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identification?: Maybe<Scalars['citext']>;
  organization_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "equipment" */
export type Equipment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "equipment" */
export type Equipment_Mutation_Response = {
  __typename?: 'equipment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Equipment>;
};

/** input type for inserting object relation for remote table "equipment" */
export type Equipment_Obj_Rel_Insert_Input = {
  data: Equipment_Insert_Input;
  on_conflict?: InputMaybe<Equipment_On_Conflict>;
};

/** on conflict condition type for table "equipment" */
export type Equipment_On_Conflict = {
  constraint: Equipment_Constraint;
  update_columns: Array<Equipment_Update_Column>;
  where?: InputMaybe<Equipment_Bool_Exp>;
};

/** ordering options when selecting data from "equipment" */
export type Equipment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "equipment" */
export type Equipment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "equipment" */
export enum Equipment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identification = 'identification',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "equipment" */
export type Equipment_Set_Input = {
  identification?: InputMaybe<Scalars['citext']>;
};

/** update columns of table "equipment" */
export enum Equipment_Update_Column {
  /** column name */
  Identification = 'identification',
}

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "fuelling" */
export type Fuelling = {
  __typename?: 'fuelling';
  amount: Scalars['float8'];
  attributes?: Maybe<Scalars['jsonb']>;
  cost?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  external_id?: Maybe<Scalars['String']>;
  fuel_type: Scalars['String'];
  horimeter?: Maybe<Scalars['float8']>;
  /** An array relationship */
  horimeter_histories: Array<Horimeter_History>;
  /** An aggregated array relationship */
  horimeter_histories_aggregate: Horimeter_History_Aggregate;
  id: Scalars['uuid'];
  is_internal: Scalars['Boolean'];
  occurred_at: Scalars['timestamptz'];
  odometer?: Maybe<Scalars['float8']>;
  /** An array relationship */
  odometer_histories: Array<Odometer_History>;
  /** An aggregated array relationship */
  odometer_histories_aggregate: Odometer_History_Aggregate;
  organization_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  provider?: Maybe<Provider>;
  provider_id?: Maybe<Scalars['uuid']>;
  source: Scalars['String'];
  /** An object relationship */
  tank?: Maybe<Tank>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** columns and relationships of "fuelling" */
export type FuellingAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "fuelling" */
export type FuellingHorimeter_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** columns and relationships of "fuelling" */
export type FuellingHorimeter_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** columns and relationships of "fuelling" */
export type FuellingOdometer_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** columns and relationships of "fuelling" */
export type FuellingOdometer_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** aggregated selection of "fuelling" */
export type Fuelling_Aggregate = {
  __typename?: 'fuelling_aggregate';
  aggregate?: Maybe<Fuelling_Aggregate_Fields>;
  nodes: Array<Fuelling>;
};

/** aggregate fields of "fuelling" */
export type Fuelling_Aggregate_Fields = {
  __typename?: 'fuelling_aggregate_fields';
  avg?: Maybe<Fuelling_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Fuelling_Max_Fields>;
  min?: Maybe<Fuelling_Min_Fields>;
  stddev?: Maybe<Fuelling_Stddev_Fields>;
  stddev_pop?: Maybe<Fuelling_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fuelling_Stddev_Samp_Fields>;
  sum?: Maybe<Fuelling_Sum_Fields>;
  var_pop?: Maybe<Fuelling_Var_Pop_Fields>;
  var_samp?: Maybe<Fuelling_Var_Samp_Fields>;
  variance?: Maybe<Fuelling_Variance_Fields>;
};

/** aggregate fields of "fuelling" */
export type Fuelling_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fuelling_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fuelling" */
export type Fuelling_Aggregate_Order_By = {
  avg?: InputMaybe<Fuelling_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fuelling_Max_Order_By>;
  min?: InputMaybe<Fuelling_Min_Order_By>;
  stddev?: InputMaybe<Fuelling_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Fuelling_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Fuelling_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Fuelling_Sum_Order_By>;
  var_pop?: InputMaybe<Fuelling_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Fuelling_Var_Samp_Order_By>;
  variance?: InputMaybe<Fuelling_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Fuelling_Avg_Fields = {
  __typename?: 'fuelling_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  horimeter?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fuelling" */
export type Fuelling_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fuelling". All fields are combined with a logical 'AND'. */
export type Fuelling_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Fuelling_Bool_Exp>>>;
  _not?: InputMaybe<Fuelling_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Fuelling_Bool_Exp>>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  cost?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  fuel_type?: InputMaybe<String_Comparison_Exp>;
  horimeter?: InputMaybe<Float8_Comparison_Exp>;
  horimeter_histories?: InputMaybe<Horimeter_History_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_internal?: InputMaybe<Boolean_Comparison_Exp>;
  occurred_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  odometer?: InputMaybe<Float8_Comparison_Exp>;
  odometer_histories?: InputMaybe<Odometer_History_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  tank?: InputMaybe<Tank_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Fuelling_Max_Fields = {
  __typename?: 'fuelling_max_fields';
  amount?: Maybe<Scalars['float8']>;
  cost?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  horimeter?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  occurred_at?: Maybe<Scalars['timestamptz']>;
  odometer?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  provider_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "fuelling" */
export type Fuelling_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fuel_type?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fuelling_Min_Fields = {
  __typename?: 'fuelling_min_fields';
  amount?: Maybe<Scalars['float8']>;
  cost?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Scalars['String']>;
  horimeter?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  occurred_at?: Maybe<Scalars['timestamptz']>;
  odometer?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  provider_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "fuelling" */
export type Fuelling_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fuel_type?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "fuelling" */
export type Fuelling_Order_By = {
  amount?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fuel_type?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  horimeter_histories_aggregate?: InputMaybe<Horimeter_History_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_internal?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
  odometer_histories_aggregate?: InputMaybe<Odometer_History_Aggregate_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  tank?: InputMaybe<Tank_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "fuelling" */
export type Fuelling_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fuelling" */
export enum Fuelling_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  Horimeter = 'horimeter',
  /** column name */
  Id = 'id',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  OccurredAt = 'occurred_at',
  /** column name */
  Odometer = 'odometer',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate stddev on columns */
export type Fuelling_Stddev_Fields = {
  __typename?: 'fuelling_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  horimeter?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fuelling" */
export type Fuelling_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fuelling_Stddev_Pop_Fields = {
  __typename?: 'fuelling_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  horimeter?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fuelling" */
export type Fuelling_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fuelling_Stddev_Samp_Fields = {
  __typename?: 'fuelling_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  horimeter?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fuelling" */
export type Fuelling_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Fuelling_Sum_Fields = {
  __typename?: 'fuelling_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  cost?: Maybe<Scalars['float8']>;
  horimeter?: Maybe<Scalars['float8']>;
  odometer?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "fuelling" */
export type Fuelling_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Fuelling_Var_Pop_Fields = {
  __typename?: 'fuelling_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  horimeter?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fuelling" */
export type Fuelling_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fuelling_Var_Samp_Fields = {
  __typename?: 'fuelling_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  horimeter?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fuelling" */
export type Fuelling_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Fuelling_Variance_Fields = {
  __typename?: 'fuelling_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  horimeter?: Maybe<Scalars['Float']>;
  odometer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fuelling" */
export type Fuelling_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  horimeter?: InputMaybe<Order_By>;
  odometer?: InputMaybe<Order_By>;
};

export type Get_User_By_Credentials_Args = {
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary = {
  __typename?: 'hermes_daily_vehicle_summary';
  average_speed: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  distance_traveled: Scalars['float8'];
  engine_idle: Scalars['float8'];
  first_fuel_used?: Maybe<Scalars['float8']>;
  first_obd_horimeter?: Maybe<Scalars['float8']>;
  first_obd_odometer?: Maybe<Scalars['float8']>;
  geojson: Scalars['jsonb'];
  hard_accelerations: Scalars['float8'];
  hard_brakings: Scalars['float8'];
  hard_cornerings: Scalars['float8'];
  id: Scalars['uuid'];
  last_fuel_used?: Maybe<Scalars['float8']>;
  last_obd_horimeter?: Maybe<Scalars['float8']>;
  last_obd_odometer?: Maybe<Scalars['float8']>;
  maximum_speed: Scalars['float8'];
  time_stopped: Scalars['float8'];
  time_stopped_with_ignition_on: Scalars['float8'];
  time_with_ignition_on: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** columns and relationships of "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_SummaryGeojsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Aggregate = {
  __typename?: 'hermes_daily_vehicle_summary_aggregate';
  aggregate?: Maybe<Hermes_Daily_Vehicle_Summary_Aggregate_Fields>;
  nodes: Array<Hermes_Daily_Vehicle_Summary>;
};

/** aggregate fields of "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Aggregate_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_aggregate_fields';
  avg?: Maybe<Hermes_Daily_Vehicle_Summary_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Daily_Vehicle_Summary_Max_Fields>;
  min?: Maybe<Hermes_Daily_Vehicle_Summary_Min_Fields>;
  stddev?: Maybe<Hermes_Daily_Vehicle_Summary_Stddev_Fields>;
  stddev_pop?: Maybe<Hermes_Daily_Vehicle_Summary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hermes_Daily_Vehicle_Summary_Stddev_Samp_Fields>;
  sum?: Maybe<Hermes_Daily_Vehicle_Summary_Sum_Fields>;
  var_pop?: Maybe<Hermes_Daily_Vehicle_Summary_Var_Pop_Fields>;
  var_samp?: Maybe<Hermes_Daily_Vehicle_Summary_Var_Samp_Fields>;
  variance?: Maybe<Hermes_Daily_Vehicle_Summary_Variance_Fields>;
};

/** aggregate fields of "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Aggregate_Order_By = {
  avg?: InputMaybe<Hermes_Daily_Vehicle_Summary_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hermes_Daily_Vehicle_Summary_Max_Order_By>;
  min?: InputMaybe<Hermes_Daily_Vehicle_Summary_Min_Order_By>;
  stddev?: InputMaybe<Hermes_Daily_Vehicle_Summary_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hermes_Daily_Vehicle_Summary_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hermes_Daily_Vehicle_Summary_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hermes_Daily_Vehicle_Summary_Sum_Order_By>;
  var_pop?: InputMaybe<Hermes_Daily_Vehicle_Summary_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hermes_Daily_Vehicle_Summary_Var_Samp_Order_By>;
  variance?: InputMaybe<Hermes_Daily_Vehicle_Summary_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Hermes_Daily_Vehicle_Summary_Avg_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_avg_fields';
  average_speed?: Maybe<Scalars['Float']>;
  distance_traveled?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  first_fuel_used?: Maybe<Scalars['Float']>;
  first_obd_horimeter?: Maybe<Scalars['Float']>;
  first_obd_odometer?: Maybe<Scalars['Float']>;
  hard_accelerations?: Maybe<Scalars['Float']>;
  hard_brakings?: Maybe<Scalars['Float']>;
  hard_cornerings?: Maybe<Scalars['Float']>;
  last_fuel_used?: Maybe<Scalars['Float']>;
  last_obd_horimeter?: Maybe<Scalars['Float']>;
  last_obd_odometer?: Maybe<Scalars['Float']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  time_stopped?: Maybe<Scalars['Float']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  time_with_ignition_on?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Avg_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.daily_vehicle_summary". All fields are combined with a logical 'AND'. */
export type Hermes_Daily_Vehicle_Summary_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>>>;
  average_speed?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  distance_traveled?: InputMaybe<Float8_Comparison_Exp>;
  engine_idle?: InputMaybe<Float8_Comparison_Exp>;
  first_fuel_used?: InputMaybe<Float8_Comparison_Exp>;
  first_obd_horimeter?: InputMaybe<Float8_Comparison_Exp>;
  first_obd_odometer?: InputMaybe<Float8_Comparison_Exp>;
  geojson?: InputMaybe<Jsonb_Comparison_Exp>;
  hard_accelerations?: InputMaybe<Float8_Comparison_Exp>;
  hard_brakings?: InputMaybe<Float8_Comparison_Exp>;
  hard_cornerings?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_fuel_used?: InputMaybe<Float8_Comparison_Exp>;
  last_obd_horimeter?: InputMaybe<Float8_Comparison_Exp>;
  last_obd_odometer?: InputMaybe<Float8_Comparison_Exp>;
  maximum_speed?: InputMaybe<Float8_Comparison_Exp>;
  time_stopped?: InputMaybe<Float8_Comparison_Exp>;
  time_stopped_with_ignition_on?: InputMaybe<Float8_Comparison_Exp>;
  time_with_ignition_on?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hermes_Daily_Vehicle_Summary_Max_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_max_fields';
  average_speed?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  distance_traveled?: Maybe<Scalars['float8']>;
  engine_idle?: Maybe<Scalars['float8']>;
  first_fuel_used?: Maybe<Scalars['float8']>;
  first_obd_horimeter?: Maybe<Scalars['float8']>;
  first_obd_odometer?: Maybe<Scalars['float8']>;
  hard_accelerations?: Maybe<Scalars['float8']>;
  hard_brakings?: Maybe<Scalars['float8']>;
  hard_cornerings?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  last_fuel_used?: Maybe<Scalars['float8']>;
  last_obd_horimeter?: Maybe<Scalars['float8']>;
  last_obd_odometer?: Maybe<Scalars['float8']>;
  maximum_speed?: Maybe<Scalars['float8']>;
  time_stopped?: Maybe<Scalars['float8']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['float8']>;
  time_with_ignition_on?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Max_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Daily_Vehicle_Summary_Min_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_min_fields';
  average_speed?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  distance_traveled?: Maybe<Scalars['float8']>;
  engine_idle?: Maybe<Scalars['float8']>;
  first_fuel_used?: Maybe<Scalars['float8']>;
  first_obd_horimeter?: Maybe<Scalars['float8']>;
  first_obd_odometer?: Maybe<Scalars['float8']>;
  hard_accelerations?: Maybe<Scalars['float8']>;
  hard_brakings?: Maybe<Scalars['float8']>;
  hard_cornerings?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  last_fuel_used?: Maybe<Scalars['float8']>;
  last_obd_horimeter?: Maybe<Scalars['float8']>;
  last_obd_odometer?: Maybe<Scalars['float8']>;
  maximum_speed?: Maybe<Scalars['float8']>;
  time_stopped?: Maybe<Scalars['float8']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['float8']>;
  time_with_ignition_on?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Min_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  geojson?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.daily_vehicle_summary" */
export enum Hermes_Daily_Vehicle_Summary_Select_Column {
  /** column name */
  AverageSpeed = 'average_speed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DistanceTraveled = 'distance_traveled',
  /** column name */
  EngineIdle = 'engine_idle',
  /** column name */
  FirstFuelUsed = 'first_fuel_used',
  /** column name */
  FirstObdHorimeter = 'first_obd_horimeter',
  /** column name */
  FirstObdOdometer = 'first_obd_odometer',
  /** column name */
  Geojson = 'geojson',
  /** column name */
  HardAccelerations = 'hard_accelerations',
  /** column name */
  HardBrakings = 'hard_brakings',
  /** column name */
  HardCornerings = 'hard_cornerings',
  /** column name */
  Id = 'id',
  /** column name */
  LastFuelUsed = 'last_fuel_used',
  /** column name */
  LastObdHorimeter = 'last_obd_horimeter',
  /** column name */
  LastObdOdometer = 'last_obd_odometer',
  /** column name */
  MaximumSpeed = 'maximum_speed',
  /** column name */
  TimeStopped = 'time_stopped',
  /** column name */
  TimeStoppedWithIgnitionOn = 'time_stopped_with_ignition_on',
  /** column name */
  TimeWithIgnitionOn = 'time_with_ignition_on',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate stddev on columns */
export type Hermes_Daily_Vehicle_Summary_Stddev_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_stddev_fields';
  average_speed?: Maybe<Scalars['Float']>;
  distance_traveled?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  first_fuel_used?: Maybe<Scalars['Float']>;
  first_obd_horimeter?: Maybe<Scalars['Float']>;
  first_obd_odometer?: Maybe<Scalars['Float']>;
  hard_accelerations?: Maybe<Scalars['Float']>;
  hard_brakings?: Maybe<Scalars['Float']>;
  hard_cornerings?: Maybe<Scalars['Float']>;
  last_fuel_used?: Maybe<Scalars['Float']>;
  last_obd_horimeter?: Maybe<Scalars['Float']>;
  last_obd_odometer?: Maybe<Scalars['Float']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  time_stopped?: Maybe<Scalars['Float']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  time_with_ignition_on?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Stddev_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hermes_Daily_Vehicle_Summary_Stddev_Pop_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_stddev_pop_fields';
  average_speed?: Maybe<Scalars['Float']>;
  distance_traveled?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  first_fuel_used?: Maybe<Scalars['Float']>;
  first_obd_horimeter?: Maybe<Scalars['Float']>;
  first_obd_odometer?: Maybe<Scalars['Float']>;
  hard_accelerations?: Maybe<Scalars['Float']>;
  hard_brakings?: Maybe<Scalars['Float']>;
  hard_cornerings?: Maybe<Scalars['Float']>;
  last_fuel_used?: Maybe<Scalars['Float']>;
  last_obd_horimeter?: Maybe<Scalars['Float']>;
  last_obd_odometer?: Maybe<Scalars['Float']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  time_stopped?: Maybe<Scalars['Float']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  time_with_ignition_on?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Stddev_Pop_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hermes_Daily_Vehicle_Summary_Stddev_Samp_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_stddev_samp_fields';
  average_speed?: Maybe<Scalars['Float']>;
  distance_traveled?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  first_fuel_used?: Maybe<Scalars['Float']>;
  first_obd_horimeter?: Maybe<Scalars['Float']>;
  first_obd_odometer?: Maybe<Scalars['Float']>;
  hard_accelerations?: Maybe<Scalars['Float']>;
  hard_brakings?: Maybe<Scalars['Float']>;
  hard_cornerings?: Maybe<Scalars['Float']>;
  last_fuel_used?: Maybe<Scalars['Float']>;
  last_obd_horimeter?: Maybe<Scalars['Float']>;
  last_obd_odometer?: Maybe<Scalars['Float']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  time_stopped?: Maybe<Scalars['Float']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  time_with_ignition_on?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Stddev_Samp_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Hermes_Daily_Vehicle_Summary_Sum_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_sum_fields';
  average_speed?: Maybe<Scalars['float8']>;
  distance_traveled?: Maybe<Scalars['float8']>;
  engine_idle?: Maybe<Scalars['float8']>;
  first_fuel_used?: Maybe<Scalars['float8']>;
  first_obd_horimeter?: Maybe<Scalars['float8']>;
  first_obd_odometer?: Maybe<Scalars['float8']>;
  hard_accelerations?: Maybe<Scalars['float8']>;
  hard_brakings?: Maybe<Scalars['float8']>;
  hard_cornerings?: Maybe<Scalars['float8']>;
  last_fuel_used?: Maybe<Scalars['float8']>;
  last_obd_horimeter?: Maybe<Scalars['float8']>;
  last_obd_odometer?: Maybe<Scalars['float8']>;
  maximum_speed?: Maybe<Scalars['float8']>;
  time_stopped?: Maybe<Scalars['float8']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['float8']>;
  time_with_ignition_on?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Sum_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Hermes_Daily_Vehicle_Summary_Var_Pop_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_var_pop_fields';
  average_speed?: Maybe<Scalars['Float']>;
  distance_traveled?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  first_fuel_used?: Maybe<Scalars['Float']>;
  first_obd_horimeter?: Maybe<Scalars['Float']>;
  first_obd_odometer?: Maybe<Scalars['Float']>;
  hard_accelerations?: Maybe<Scalars['Float']>;
  hard_brakings?: Maybe<Scalars['Float']>;
  hard_cornerings?: Maybe<Scalars['Float']>;
  last_fuel_used?: Maybe<Scalars['Float']>;
  last_obd_horimeter?: Maybe<Scalars['Float']>;
  last_obd_odometer?: Maybe<Scalars['Float']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  time_stopped?: Maybe<Scalars['Float']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  time_with_ignition_on?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Var_Pop_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hermes_Daily_Vehicle_Summary_Var_Samp_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_var_samp_fields';
  average_speed?: Maybe<Scalars['Float']>;
  distance_traveled?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  first_fuel_used?: Maybe<Scalars['Float']>;
  first_obd_horimeter?: Maybe<Scalars['Float']>;
  first_obd_odometer?: Maybe<Scalars['Float']>;
  hard_accelerations?: Maybe<Scalars['Float']>;
  hard_brakings?: Maybe<Scalars['Float']>;
  hard_cornerings?: Maybe<Scalars['Float']>;
  last_fuel_used?: Maybe<Scalars['Float']>;
  last_obd_horimeter?: Maybe<Scalars['Float']>;
  last_obd_odometer?: Maybe<Scalars['Float']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  time_stopped?: Maybe<Scalars['Float']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  time_with_ignition_on?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Var_Samp_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hermes_Daily_Vehicle_Summary_Variance_Fields = {
  __typename?: 'hermes_daily_vehicle_summary_variance_fields';
  average_speed?: Maybe<Scalars['Float']>;
  distance_traveled?: Maybe<Scalars['Float']>;
  engine_idle?: Maybe<Scalars['Float']>;
  first_fuel_used?: Maybe<Scalars['Float']>;
  first_obd_horimeter?: Maybe<Scalars['Float']>;
  first_obd_odometer?: Maybe<Scalars['Float']>;
  hard_accelerations?: Maybe<Scalars['Float']>;
  hard_brakings?: Maybe<Scalars['Float']>;
  hard_cornerings?: Maybe<Scalars['Float']>;
  last_fuel_used?: Maybe<Scalars['Float']>;
  last_obd_horimeter?: Maybe<Scalars['Float']>;
  last_obd_odometer?: Maybe<Scalars['Float']>;
  maximum_speed?: Maybe<Scalars['Float']>;
  time_stopped?: Maybe<Scalars['Float']>;
  time_stopped_with_ignition_on?: Maybe<Scalars['Float']>;
  time_with_ignition_on?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hermes.daily_vehicle_summary" */
export type Hermes_Daily_Vehicle_Summary_Variance_Order_By = {
  average_speed?: InputMaybe<Order_By>;
  distance_traveled?: InputMaybe<Order_By>;
  engine_idle?: InputMaybe<Order_By>;
  first_fuel_used?: InputMaybe<Order_By>;
  first_obd_horimeter?: InputMaybe<Order_By>;
  first_obd_odometer?: InputMaybe<Order_By>;
  hard_accelerations?: InputMaybe<Order_By>;
  hard_brakings?: InputMaybe<Order_By>;
  hard_cornerings?: InputMaybe<Order_By>;
  last_fuel_used?: InputMaybe<Order_By>;
  last_obd_horimeter?: InputMaybe<Order_By>;
  last_obd_odometer?: InputMaybe<Order_By>;
  maximum_speed?: InputMaybe<Order_By>;
  time_stopped?: InputMaybe<Order_By>;
  time_stopped_with_ignition_on?: InputMaybe<Order_By>;
  time_with_ignition_on?: InputMaybe<Order_By>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_Device = {
  __typename?: 'hermes_device';
  /** An array relationship */
  event_traccars: Array<Hermes_Event_Traccar>;
  /** An aggregated array relationship */
  event_traccars_aggregate: Hermes_Event_Traccar_Aggregate;
  last_update?: Maybe<Scalars['timestamptz']>;
  organization_id?: Maybe<Scalars['uuid']>;
  unique_id: Scalars['String'];
  /** An object relationship */
  vehicle?: Maybe<Vehicle>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_DeviceEvent_TraccarsArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** columns and relationships of "hermes.device" */
export type Hermes_DeviceEvent_Traccars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "hermes.device". All fields are combined with a logical 'AND'. */
export type Hermes_Device_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Device_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Device_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Device_Bool_Exp>>>;
  event_traccars?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
  last_update?: InputMaybe<Timestamptz_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  unique_id?: InputMaybe<String_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** ordering options when selecting data from "hermes.device" */
export type Hermes_Device_Order_By = {
  event_traccars_aggregate?: InputMaybe<Hermes_Event_Traccar_Aggregate_Order_By>;
  last_update?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  unique_id?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.device" */
export type Hermes_Device_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.device" */
export enum Hermes_Device_Select_Column {
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UniqueId = 'unique_id',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** columns and relationships of "hermes.event_traccar" */
export type Hermes_Event_Traccar = {
  __typename?: 'hermes_event_traccar';
  address?: Maybe<Scalars['String']>;
  attributes: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  device: Hermes_Device;
  device_id: Scalars['uuid'];
  id: Scalars['uuid'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  server_time: Scalars['timestamptz'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "hermes.event_traccar" */
export type Hermes_Event_TraccarAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate = {
  __typename?: 'hermes_event_traccar_aggregate';
  aggregate?: Maybe<Hermes_Event_Traccar_Aggregate_Fields>;
  nodes: Array<Hermes_Event_Traccar>;
};

/** aggregate fields of "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate_Fields = {
  __typename?: 'hermes_event_traccar_aggregate_fields';
  avg?: Maybe<Hermes_Event_Traccar_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Event_Traccar_Max_Fields>;
  min?: Maybe<Hermes_Event_Traccar_Min_Fields>;
  stddev?: Maybe<Hermes_Event_Traccar_Stddev_Fields>;
  stddev_pop?: Maybe<Hermes_Event_Traccar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hermes_Event_Traccar_Stddev_Samp_Fields>;
  sum?: Maybe<Hermes_Event_Traccar_Sum_Fields>;
  var_pop?: Maybe<Hermes_Event_Traccar_Var_Pop_Fields>;
  var_samp?: Maybe<Hermes_Event_Traccar_Var_Samp_Fields>;
  variance?: Maybe<Hermes_Event_Traccar_Variance_Fields>;
};

/** aggregate fields of "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hermes_Event_Traccar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Aggregate_Order_By = {
  avg?: InputMaybe<Hermes_Event_Traccar_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hermes_Event_Traccar_Max_Order_By>;
  min?: InputMaybe<Hermes_Event_Traccar_Min_Order_By>;
  stddev?: InputMaybe<Hermes_Event_Traccar_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hermes_Event_Traccar_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hermes_Event_Traccar_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hermes_Event_Traccar_Sum_Order_By>;
  var_pop?: InputMaybe<Hermes_Event_Traccar_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hermes_Event_Traccar_Var_Samp_Order_By>;
  variance?: InputMaybe<Hermes_Event_Traccar_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Hermes_Event_Traccar_Avg_Fields = {
  __typename?: 'hermes_event_traccar_avg_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Avg_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.event_traccar". All fields are combined with a logical 'AND'. */
export type Hermes_Event_Traccar_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Event_Traccar_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Event_Traccar_Bool_Exp>>>;
  address?: InputMaybe<String_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  device?: InputMaybe<Hermes_Device_Bool_Exp>;
  device_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  server_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hermes_Event_Traccar_Max_Fields = {
  __typename?: 'hermes_event_traccar_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  server_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Event_Traccar_Min_Fields = {
  __typename?: 'hermes_event_traccar_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  device_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  server_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "hermes.event_traccar" */
export type Hermes_Event_Traccar_Order_By = {
  address?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device?: InputMaybe<Hermes_Device_Order_By>;
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  server_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.event_traccar" */
export type Hermes_Event_Traccar_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.event_traccar" */
export enum Hermes_Event_Traccar_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  ServerTime = 'server_time',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate stddev on columns */
export type Hermes_Event_Traccar_Stddev_Fields = {
  __typename?: 'hermes_event_traccar_stddev_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Stddev_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hermes_Event_Traccar_Stddev_Pop_Fields = {
  __typename?: 'hermes_event_traccar_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Stddev_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hermes_Event_Traccar_Stddev_Samp_Fields = {
  __typename?: 'hermes_event_traccar_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Stddev_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Hermes_Event_Traccar_Sum_Fields = {
  __typename?: 'hermes_event_traccar_sum_fields';
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Sum_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Hermes_Event_Traccar_Var_Pop_Fields = {
  __typename?: 'hermes_event_traccar_var_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Var_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hermes_Event_Traccar_Var_Samp_Fields = {
  __typename?: 'hermes_event_traccar_var_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Var_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hermes_Event_Traccar_Variance_Fields = {
  __typename?: 'hermes_event_traccar_variance_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hermes.event_traccar" */
export type Hermes_Event_Traccar_Variance_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** columns and relationships of "hermes.event_type" */
export type Hermes_Event_Type = {
  __typename?: 'hermes_event_type';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  icon_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workshift_events: Array<Hermes_Workshift_Event>;
};

/** columns and relationships of "hermes.event_type" */
export type Hermes_Event_TypeWorkshift_EventsArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** aggregated selection of "hermes.event_type" */
export type Hermes_Event_Type_Aggregate = {
  __typename?: 'hermes_event_type_aggregate';
  aggregate?: Maybe<Hermes_Event_Type_Aggregate_Fields>;
  nodes: Array<Hermes_Event_Type>;
};

/** aggregate fields of "hermes.event_type" */
export type Hermes_Event_Type_Aggregate_Fields = {
  __typename?: 'hermes_event_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Event_Type_Max_Fields>;
  min?: Maybe<Hermes_Event_Type_Min_Fields>;
};

/** aggregate fields of "hermes.event_type" */
export type Hermes_Event_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hermes_Event_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.event_type" */
export type Hermes_Event_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hermes_Event_Type_Max_Order_By>;
  min?: InputMaybe<Hermes_Event_Type_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.event_type". All fields are combined with a logical 'AND'. */
export type Hermes_Event_Type_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Event_Type_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Event_Type_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Event_Type_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  icon_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workshift_events?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** aggregate max on columns */
export type Hermes_Event_Type_Max_Fields = {
  __typename?: 'hermes_event_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hermes.event_type" */
export type Hermes_Event_Type_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  icon_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Event_Type_Min_Fields = {
  __typename?: 'hermes_event_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hermes.event_type" */
export type Hermes_Event_Type_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  icon_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "hermes.event_type" */
export type Hermes_Event_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  icon_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.event_type" */
export type Hermes_Event_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.event_type" */
export enum Hermes_Event_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  IconName = 'icon_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SlugName = 'slug_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "hermes.geofence" */
export type Hermes_Geofence = {
  __typename?: 'hermes_geofence';
  area: Scalars['jsonb'];
  attributes?: Maybe<Scalars['jsonb']>;
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_enabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  parking?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "hermes.geofence" */
export type Hermes_GeofenceAreaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "hermes.geofence" */
export type Hermes_GeofenceAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hermes.geofence" */
export type Hermes_Geofence_Aggregate = {
  __typename?: 'hermes_geofence_aggregate';
  aggregate?: Maybe<Hermes_Geofence_Aggregate_Fields>;
  nodes: Array<Hermes_Geofence>;
};

/** aggregate fields of "hermes.geofence" */
export type Hermes_Geofence_Aggregate_Fields = {
  __typename?: 'hermes_geofence_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Geofence_Max_Fields>;
  min?: Maybe<Hermes_Geofence_Min_Fields>;
};

/** aggregate fields of "hermes.geofence" */
export type Hermes_Geofence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hermes_Geofence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.geofence" */
export type Hermes_Geofence_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hermes_Geofence_Max_Order_By>;
  min?: InputMaybe<Hermes_Geofence_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Geofence_Append_Input = {
  area?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hermes.geofence" */
export type Hermes_Geofence_Arr_Rel_Insert_Input = {
  data: Array<Hermes_Geofence_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Geofence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hermes.geofence". All fields are combined with a logical 'AND'. */
export type Hermes_Geofence_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Geofence_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Geofence_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Geofence_Bool_Exp>>>;
  area?: InputMaybe<Jsonb_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  parking?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hermes.geofence" */
export enum Hermes_Geofence_Constraint {
  /** unique or primary key constraint */
  GeofencesPkey = 'geofences_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hermes_Geofence_Delete_At_Path_Input = {
  area?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attributes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hermes_Geofence_Delete_Elem_Input = {
  area?: InputMaybe<Scalars['Int']>;
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hermes_Geofence_Delete_Key_Input = {
  area?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "hermes.geofence" */
export type Hermes_Geofence_Insert_Input = {
  area?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  color?: InputMaybe<Scalars['String']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parking?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hermes_Geofence_Max_Fields = {
  __typename?: 'hermes_geofence_max_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hermes.geofence" */
export type Hermes_Geofence_Max_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Geofence_Min_Fields = {
  __typename?: 'hermes_geofence_min_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hermes.geofence" */
export type Hermes_Geofence_Min_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hermes.geofence" */
export type Hermes_Geofence_Mutation_Response = {
  __typename?: 'hermes_geofence_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hermes_Geofence>;
};

/** input type for inserting object relation for remote table "hermes.geofence" */
export type Hermes_Geofence_Obj_Rel_Insert_Input = {
  data: Hermes_Geofence_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Geofence_On_Conflict>;
};

/** on conflict condition type for table "hermes.geofence" */
export type Hermes_Geofence_On_Conflict = {
  constraint: Hermes_Geofence_Constraint;
  update_columns: Array<Hermes_Geofence_Update_Column>;
  where?: InputMaybe<Hermes_Geofence_Bool_Exp>;
};

/** ordering options when selecting data from "hermes.geofence" */
export type Hermes_Geofence_Order_By = {
  area?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  parking?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.geofence" */
export type Hermes_Geofence_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Geofence_Prepend_Input = {
  area?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hermes.geofence" */
export enum Hermes_Geofence_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Parking = 'parking',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "hermes.geofence" */
export type Hermes_Geofence_Set_Input = {
  area?: InputMaybe<Scalars['jsonb']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  color?: InputMaybe<Scalars['String']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parking?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "hermes.geofence" */
export enum Hermes_Geofence_Update_Column {
  /** column name */
  Area = 'area',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Color = 'color',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  Parking = 'parking',
  /** column name */
  Type = 'type',
}

/** columns and relationships of "hermes.position_gadget" */
export type Hermes_Position_Gadget = {
  __typename?: 'hermes_position_gadget';
  acceleration: Scalars['float8'];
  accuracy: Scalars['float8'];
  altitude: Scalars['float8'];
  attributes: Scalars['jsonb'];
  course: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  fix_time: Scalars['timestamptz'];
  gadget_id: Scalars['String'];
  id: Scalars['uuid'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  organization_id: Scalars['uuid'];
  server_time: Scalars['timestamptz'];
  speed: Scalars['float8'];
  speed_accuracy: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
  weight?: Maybe<Scalars['float8']>;
};

/** columns and relationships of "hermes.position_gadget" */
export type Hermes_Position_GadgetAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate = {
  __typename?: 'hermes_position_gadget_aggregate';
  aggregate?: Maybe<Hermes_Position_Gadget_Aggregate_Fields>;
  nodes: Array<Hermes_Position_Gadget>;
};

/** aggregate fields of "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate_Fields = {
  __typename?: 'hermes_position_gadget_aggregate_fields';
  avg?: Maybe<Hermes_Position_Gadget_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Position_Gadget_Max_Fields>;
  min?: Maybe<Hermes_Position_Gadget_Min_Fields>;
  stddev?: Maybe<Hermes_Position_Gadget_Stddev_Fields>;
  stddev_pop?: Maybe<Hermes_Position_Gadget_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hermes_Position_Gadget_Stddev_Samp_Fields>;
  sum?: Maybe<Hermes_Position_Gadget_Sum_Fields>;
  var_pop?: Maybe<Hermes_Position_Gadget_Var_Pop_Fields>;
  var_samp?: Maybe<Hermes_Position_Gadget_Var_Samp_Fields>;
  variance?: Maybe<Hermes_Position_Gadget_Variance_Fields>;
};

/** aggregate fields of "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hermes_Position_Gadget_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Aggregate_Order_By = {
  avg?: InputMaybe<Hermes_Position_Gadget_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hermes_Position_Gadget_Max_Order_By>;
  min?: InputMaybe<Hermes_Position_Gadget_Min_Order_By>;
  stddev?: InputMaybe<Hermes_Position_Gadget_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hermes_Position_Gadget_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hermes_Position_Gadget_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hermes_Position_Gadget_Sum_Order_By>;
  var_pop?: InputMaybe<Hermes_Position_Gadget_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hermes_Position_Gadget_Var_Samp_Order_By>;
  variance?: InputMaybe<Hermes_Position_Gadget_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Position_Gadget_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Arr_Rel_Insert_Input = {
  data: Array<Hermes_Position_Gadget_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Position_Gadget_On_Conflict>;
};

/** aggregate avg on columns */
export type Hermes_Position_Gadget_Avg_Fields = {
  __typename?: 'hermes_position_gadget_avg_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Avg_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hermes.position_gadget". All fields are combined with a logical 'AND'. */
export type Hermes_Position_Gadget_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Position_Gadget_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Position_Gadget_Bool_Exp>>>;
  acceleration?: InputMaybe<Float8_Comparison_Exp>;
  accuracy?: InputMaybe<Float8_Comparison_Exp>;
  altitude?: InputMaybe<Float8_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  course?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fix_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  gadget_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  server_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  speed?: InputMaybe<Float8_Comparison_Exp>;
  speed_accuracy?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
  weight?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "hermes.position_gadget" */
export enum Hermes_Position_Gadget_Constraint {
  /** unique or primary key constraint */
  PositionGadgetPkey = 'position_gadget_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hermes_Position_Gadget_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hermes_Position_Gadget_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hermes_Position_Gadget_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Inc_Input = {
  acceleration?: InputMaybe<Scalars['float8']>;
  accuracy?: InputMaybe<Scalars['float8']>;
  altitude?: InputMaybe<Scalars['float8']>;
  course?: InputMaybe<Scalars['float8']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  speed?: InputMaybe<Scalars['float8']>;
  speed_accuracy?: InputMaybe<Scalars['float8']>;
  weight?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Insert_Input = {
  acceleration?: InputMaybe<Scalars['float8']>;
  accuracy?: InputMaybe<Scalars['float8']>;
  altitude?: InputMaybe<Scalars['float8']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  course?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fix_time?: InputMaybe<Scalars['timestamptz']>;
  gadget_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  server_time?: InputMaybe<Scalars['timestamptz']>;
  speed?: InputMaybe<Scalars['float8']>;
  speed_accuracy?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
  weight?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Hermes_Position_Gadget_Max_Fields = {
  __typename?: 'hermes_position_gadget_max_fields';
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Max_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fix_time?: InputMaybe<Order_By>;
  gadget_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  server_time?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Position_Gadget_Min_Fields = {
  __typename?: 'hermes_position_gadget_min_fields';
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fix_time?: Maybe<Scalars['timestamptz']>;
  gadget_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  organization_id?: Maybe<Scalars['uuid']>;
  server_time?: Maybe<Scalars['timestamptz']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Min_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fix_time?: InputMaybe<Order_By>;
  gadget_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  server_time?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Mutation_Response = {
  __typename?: 'hermes_position_gadget_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hermes_Position_Gadget>;
};

/** input type for inserting object relation for remote table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Obj_Rel_Insert_Input = {
  data: Hermes_Position_Gadget_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Position_Gadget_On_Conflict>;
};

/** on conflict condition type for table "hermes.position_gadget" */
export type Hermes_Position_Gadget_On_Conflict = {
  constraint: Hermes_Position_Gadget_Constraint;
  update_columns: Array<Hermes_Position_Gadget_Update_Column>;
  where?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** ordering options when selecting data from "hermes.position_gadget" */
export type Hermes_Position_Gadget_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fix_time?: InputMaybe<Order_By>;
  gadget_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  server_time?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.position_gadget" */
export type Hermes_Position_Gadget_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Position_Gadget_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hermes.position_gadget" */
export enum Hermes_Position_Gadget_Select_Column {
  /** column name */
  Acceleration = 'acceleration',
  /** column name */
  Accuracy = 'accuracy',
  /** column name */
  Altitude = 'altitude',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Course = 'course',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FixTime = 'fix_time',
  /** column name */
  GadgetId = 'gadget_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ServerTime = 'server_time',
  /** column name */
  Speed = 'speed',
  /** column name */
  SpeedAccuracy = 'speed_accuracy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Weight = 'weight',
}

/** input type for updating data in table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Set_Input = {
  acceleration?: InputMaybe<Scalars['float8']>;
  accuracy?: InputMaybe<Scalars['float8']>;
  altitude?: InputMaybe<Scalars['float8']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  course?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fix_time?: InputMaybe<Scalars['timestamptz']>;
  gadget_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  organization_id?: InputMaybe<Scalars['uuid']>;
  server_time?: InputMaybe<Scalars['timestamptz']>;
  speed?: InputMaybe<Scalars['float8']>;
  speed_accuracy?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
  weight?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Hermes_Position_Gadget_Stddev_Fields = {
  __typename?: 'hermes_position_gadget_stddev_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Stddev_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hermes_Position_Gadget_Stddev_Pop_Fields = {
  __typename?: 'hermes_position_gadget_stddev_pop_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Stddev_Pop_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hermes_Position_Gadget_Stddev_Samp_Fields = {
  __typename?: 'hermes_position_gadget_stddev_samp_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Stddev_Samp_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Hermes_Position_Gadget_Sum_Fields = {
  __typename?: 'hermes_position_gadget_sum_fields';
  acceleration?: Maybe<Scalars['float8']>;
  accuracy?: Maybe<Scalars['float8']>;
  altitude?: Maybe<Scalars['float8']>;
  course?: Maybe<Scalars['float8']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  speed?: Maybe<Scalars['float8']>;
  speed_accuracy?: Maybe<Scalars['float8']>;
  weight?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Sum_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** update columns of table "hermes.position_gadget" */
export enum Hermes_Position_Gadget_Update_Column {
  /** column name */
  Acceleration = 'acceleration',
  /** column name */
  Accuracy = 'accuracy',
  /** column name */
  Altitude = 'altitude',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Course = 'course',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FixTime = 'fix_time',
  /** column name */
  GadgetId = 'gadget_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ServerTime = 'server_time',
  /** column name */
  Speed = 'speed',
  /** column name */
  SpeedAccuracy = 'speed_accuracy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
  /** column name */
  Weight = 'weight',
}

/** aggregate var_pop on columns */
export type Hermes_Position_Gadget_Var_Pop_Fields = {
  __typename?: 'hermes_position_gadget_var_pop_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Var_Pop_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hermes_Position_Gadget_Var_Samp_Fields = {
  __typename?: 'hermes_position_gadget_var_samp_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Var_Samp_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hermes_Position_Gadget_Variance_Fields = {
  __typename?: 'hermes_position_gadget_variance_fields';
  acceleration?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  course?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  speed_accuracy?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hermes.position_gadget" */
export type Hermes_Position_Gadget_Variance_Order_By = {
  acceleration?: InputMaybe<Order_By>;
  accuracy?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  course?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  speed?: InputMaybe<Order_By>;
  speed_accuracy?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** columns and relationships of "hermes.workshift" */
export type Hermes_Workshift = {
  __typename?: 'hermes_workshift';
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  organization_id?: Maybe<Scalars['uuid']>;
  started_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
  /** An array relationship */
  workshift_events: Array<Hermes_Workshift_Event>;
};

/** columns and relationships of "hermes.workshift" */
export type Hermes_WorkshiftWorkshift_EventsArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** aggregated selection of "hermes.workshift" */
export type Hermes_Workshift_Aggregate = {
  __typename?: 'hermes_workshift_aggregate';
  aggregate?: Maybe<Hermes_Workshift_Aggregate_Fields>;
  nodes: Array<Hermes_Workshift>;
};

/** aggregate fields of "hermes.workshift" */
export type Hermes_Workshift_Aggregate_Fields = {
  __typename?: 'hermes_workshift_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hermes_Workshift_Max_Fields>;
  min?: Maybe<Hermes_Workshift_Min_Fields>;
};

/** aggregate fields of "hermes.workshift" */
export type Hermes_Workshift_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hermes.workshift" */
export type Hermes_Workshift_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hermes_Workshift_Max_Order_By>;
  min?: InputMaybe<Hermes_Workshift_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hermes.workshift" */
export type Hermes_Workshift_Arr_Rel_Insert_Input = {
  data: Array<Hermes_Workshift_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Workshift_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hermes.workshift". All fields are combined with a logical 'AND'. */
export type Hermes_Workshift_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Workshift_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Workshift_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Workshift_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
  workshift_events?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** unique or primary key constraints on table "hermes.workshift" */
export enum Hermes_Workshift_Constraint {
  /** unique or primary key constraint */
  WorkshiftPkey = 'workshift_pkey',
}

/** columns and relationships of "hermes.workshift_event" */
export type Hermes_Workshift_Event = {
  __typename?: 'hermes_workshift_event';
  attributes?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event_type: Hermes_Event_Type;
  event_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  started_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  workshift: Hermes_Workshift;
  workshift_id: Scalars['uuid'];
};

/** columns and relationships of "hermes.workshift_event" */
export type Hermes_Workshift_EventAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Workshift_Event_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Arr_Rel_Insert_Input = {
  data: Array<Hermes_Workshift_Event_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Workshift_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hermes.workshift_event". All fields are combined with a logical 'AND'. */
export type Hermes_Workshift_Event_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Hermes_Workshift_Event_Bool_Exp>>>;
  _not?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Hermes_Workshift_Event_Bool_Exp>>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_type?: InputMaybe<Hermes_Event_Type_Bool_Exp>;
  event_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workshift?: InputMaybe<Hermes_Workshift_Bool_Exp>;
  workshift_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "hermes.workshift_event" */
export enum Hermes_Workshift_Event_Constraint {
  /** unique or primary key constraint */
  WorkshiftEventPkey = 'workshift_event_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hermes_Workshift_Event_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hermes_Workshift_Event_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hermes_Workshift_Event_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Inc_Input = {
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Insert_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  event_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workshift?: InputMaybe<Hermes_Workshift_Obj_Rel_Insert_Input>;
  workshift_id?: InputMaybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Mutation_Response = {
  __typename?: 'hermes_workshift_event_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hermes_Workshift_Event>;
};

/** input type for inserting object relation for remote table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Obj_Rel_Insert_Input = {
  data: Hermes_Workshift_Event_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Workshift_Event_On_Conflict>;
};

/** on conflict condition type for table "hermes.workshift_event" */
export type Hermes_Workshift_Event_On_Conflict = {
  constraint: Hermes_Workshift_Event_Constraint;
  update_columns: Array<Hermes_Workshift_Event_Update_Column>;
  where?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** ordering options when selecting data from "hermes.workshift_event" */
export type Hermes_Workshift_Event_Order_By = {
  attributes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Hermes_Event_Type_Order_By>;
  event_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workshift?: InputMaybe<Hermes_Workshift_Order_By>;
  workshift_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.workshift_event" */
export type Hermes_Workshift_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hermes_Workshift_Event_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hermes.workshift_event" */
export enum Hermes_Workshift_Event_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkshiftId = 'workshift_id',
}

/** input type for updating data in table "hermes.workshift_event" */
export type Hermes_Workshift_Event_Set_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  event_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workshift_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "hermes.workshift_event" */
export enum Hermes_Workshift_Event_Update_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkshiftId = 'workshift_id',
}

/** input type for inserting data into table "hermes.workshift" */
export type Hermes_Workshift_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
  workshift_events?: InputMaybe<Hermes_Workshift_Event_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Hermes_Workshift_Max_Fields = {
  __typename?: 'hermes_workshift_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hermes.workshift" */
export type Hermes_Workshift_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hermes_Workshift_Min_Fields = {
  __typename?: 'hermes_workshift_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organization_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hermes.workshift" */
export type Hermes_Workshift_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hermes.workshift" */
export type Hermes_Workshift_Mutation_Response = {
  __typename?: 'hermes_workshift_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hermes_Workshift>;
};

/** input type for inserting object relation for remote table "hermes.workshift" */
export type Hermes_Workshift_Obj_Rel_Insert_Input = {
  data: Hermes_Workshift_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Workshift_On_Conflict>;
};

/** on conflict condition type for table "hermes.workshift" */
export type Hermes_Workshift_On_Conflict = {
  constraint: Hermes_Workshift_Constraint;
  update_columns: Array<Hermes_Workshift_Update_Column>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** ordering options when selecting data from "hermes.workshift" */
export type Hermes_Workshift_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "hermes.workshift" */
export type Hermes_Workshift_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hermes.workshift" */
export enum Hermes_Workshift_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** input type for updating data in table "hermes.workshift" */
export type Hermes_Workshift_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "hermes.workshift" */
export enum Hermes_Workshift_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** columns and relationships of "horimeter_history" */
export type Horimeter_History = {
  __typename?: 'horimeter_history';
  created_at: Scalars['timestamptz'];
  current: Scalars['float8'];
  /** An object relationship */
  fuelling?: Maybe<Fuelling>;
  id: Scalars['uuid'];
  measured_at: Scalars['timestamptz'];
  previous?: Maybe<Scalars['float8']>;
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** aggregated selection of "horimeter_history" */
export type Horimeter_History_Aggregate = {
  __typename?: 'horimeter_history_aggregate';
  aggregate?: Maybe<Horimeter_History_Aggregate_Fields>;
  nodes: Array<Horimeter_History>;
};

/** aggregate fields of "horimeter_history" */
export type Horimeter_History_Aggregate_Fields = {
  __typename?: 'horimeter_history_aggregate_fields';
  avg?: Maybe<Horimeter_History_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Horimeter_History_Max_Fields>;
  min?: Maybe<Horimeter_History_Min_Fields>;
  stddev?: Maybe<Horimeter_History_Stddev_Fields>;
  stddev_pop?: Maybe<Horimeter_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Horimeter_History_Stddev_Samp_Fields>;
  sum?: Maybe<Horimeter_History_Sum_Fields>;
  var_pop?: Maybe<Horimeter_History_Var_Pop_Fields>;
  var_samp?: Maybe<Horimeter_History_Var_Samp_Fields>;
  variance?: Maybe<Horimeter_History_Variance_Fields>;
};

/** aggregate fields of "horimeter_history" */
export type Horimeter_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "horimeter_history" */
export type Horimeter_History_Aggregate_Order_By = {
  avg?: InputMaybe<Horimeter_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Horimeter_History_Max_Order_By>;
  min?: InputMaybe<Horimeter_History_Min_Order_By>;
  stddev?: InputMaybe<Horimeter_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Horimeter_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Horimeter_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Horimeter_History_Sum_Order_By>;
  var_pop?: InputMaybe<Horimeter_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Horimeter_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Horimeter_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "horimeter_history" */
export type Horimeter_History_Arr_Rel_Insert_Input = {
  data: Array<Horimeter_History_Insert_Input>;
  on_conflict?: InputMaybe<Horimeter_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Horimeter_History_Avg_Fields = {
  __typename?: 'horimeter_history_avg_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "horimeter_history" */
export type Horimeter_History_Avg_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "horimeter_history". All fields are combined with a logical 'AND'. */
export type Horimeter_History_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Horimeter_History_Bool_Exp>>>;
  _not?: InputMaybe<Horimeter_History_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Horimeter_History_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current?: InputMaybe<Float8_Comparison_Exp>;
  fuelling?: InputMaybe<Fuelling_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  measured_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  previous?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "horimeter_history" */
export enum Horimeter_History_Constraint {
  /** unique or primary key constraint */
  HorimeterHistoryFuellingIdVehicleIdKey = 'horimeter_history_fuelling_id_vehicle_id_key',
  /** unique or primary key constraint */
  HorimeterHistoryPkey = 'horimeter_history_pkey',
}

/** input type for incrementing integer column in table "horimeter_history" */
export type Horimeter_History_Inc_Input = {
  current?: InputMaybe<Scalars['float8']>;
  previous?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "horimeter_history" */
export type Horimeter_History_Insert_Input = {
  current?: InputMaybe<Scalars['float8']>;
  measured_at?: InputMaybe<Scalars['timestamptz']>;
  previous?: InputMaybe<Scalars['float8']>;
  source?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Horimeter_History_Max_Fields = {
  __typename?: 'horimeter_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "horimeter_history" */
export type Horimeter_History_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measured_at?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Horimeter_History_Min_Fields = {
  __typename?: 'horimeter_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "horimeter_history" */
export type Horimeter_History_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measured_at?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "horimeter_history" */
export type Horimeter_History_Mutation_Response = {
  __typename?: 'horimeter_history_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Horimeter_History>;
};

/** input type for inserting object relation for remote table "horimeter_history" */
export type Horimeter_History_Obj_Rel_Insert_Input = {
  data: Horimeter_History_Insert_Input;
  on_conflict?: InputMaybe<Horimeter_History_On_Conflict>;
};

/** on conflict condition type for table "horimeter_history" */
export type Horimeter_History_On_Conflict = {
  constraint: Horimeter_History_Constraint;
  update_columns: Array<Horimeter_History_Update_Column>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** ordering options when selecting data from "horimeter_history" */
export type Horimeter_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  fuelling?: InputMaybe<Fuelling_Order_By>;
  id?: InputMaybe<Order_By>;
  measured_at?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "horimeter_history" */
export type Horimeter_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "horimeter_history" */
export enum Horimeter_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  Id = 'id',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** input type for updating data in table "horimeter_history" */
export type Horimeter_History_Set_Input = {
  current?: InputMaybe<Scalars['float8']>;
  measured_at?: InputMaybe<Scalars['timestamptz']>;
  previous?: InputMaybe<Scalars['float8']>;
  source?: InputMaybe<Scalars['String']>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Horimeter_History_Stddev_Fields = {
  __typename?: 'horimeter_history_stddev_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "horimeter_history" */
export type Horimeter_History_Stddev_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Horimeter_History_Stddev_Pop_Fields = {
  __typename?: 'horimeter_history_stddev_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "horimeter_history" */
export type Horimeter_History_Stddev_Pop_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Horimeter_History_Stddev_Samp_Fields = {
  __typename?: 'horimeter_history_stddev_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "horimeter_history" */
export type Horimeter_History_Stddev_Samp_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Horimeter_History_Sum_Fields = {
  __typename?: 'horimeter_history_sum_fields';
  current?: Maybe<Scalars['float8']>;
  previous?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "horimeter_history" */
export type Horimeter_History_Sum_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** update columns of table "horimeter_history" */
export enum Horimeter_History_Update_Column {
  /** column name */
  Current = 'current',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate var_pop on columns */
export type Horimeter_History_Var_Pop_Fields = {
  __typename?: 'horimeter_history_var_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "horimeter_history" */
export type Horimeter_History_Var_Pop_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Horimeter_History_Var_Samp_Fields = {
  __typename?: 'horimeter_history_var_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "horimeter_history" */
export type Horimeter_History_Var_Samp_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Horimeter_History_Variance_Fields = {
  __typename?: 'horimeter_history_variance_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "horimeter_history" */
export type Horimeter_History_Variance_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** columns and relationships of "integration" */
export type Integration = {
  __typename?: 'integration';
  created_at: Scalars['timestamptz'];
  enabled_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  logs: Array<Integration_Log>;
  organization_id: Scalars['uuid'];
  setup_completed_at?: Maybe<Scalars['timestamptz']>;
  setup_error_message?: Maybe<Scalars['String']>;
  setup_progress: Scalars['Int'];
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "integration" */
export type IntegrationLogsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Log_Order_By>>;
  where?: InputMaybe<Integration_Log_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Integration_Append_Input = {
  credentials?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "integration" */
export type Integration_Arr_Rel_Insert_Input = {
  data: Array<Integration_Insert_Input>;
  on_conflict?: InputMaybe<Integration_On_Conflict>;
};

/** Boolean expression to filter rows from the table "integration". All fields are combined with a logical 'AND'. */
export type Integration_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Integration_Bool_Exp>>>;
  _not?: InputMaybe<Integration_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Integration_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logs?: InputMaybe<Integration_Log_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  setup_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  setup_error_message?: InputMaybe<String_Comparison_Exp>;
  setup_progress?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration" */
export enum Integration_Constraint {
  /** unique or primary key constraint */
  IntegrationPkey = 'integration_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Integration_Delete_At_Path_Input = {
  credentials?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Integration_Delete_Elem_Input = {
  credentials?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Integration_Delete_Key_Input = {
  credentials?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "integration" */
export type Integration_Insert_Input = {
  credentials?: InputMaybe<Scalars['jsonb']>;
  enabled_at?: InputMaybe<Scalars['timestamptz']>;
  source?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "integration_intent" */
export type Integration_Intent = {
  __typename?: 'integration_intent';
  attributes?: Maybe<Scalars['jsonb']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  integration: Integration;
  integration_id: Scalars['uuid'];
  progress?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "integration_intent" */
export type Integration_IntentAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "integration_intent" */
export type Integration_Intent_Aggregate = {
  __typename?: 'integration_intent_aggregate';
  aggregate?: Maybe<Integration_Intent_Aggregate_Fields>;
  nodes: Array<Integration_Intent>;
};

/** aggregate fields of "integration_intent" */
export type Integration_Intent_Aggregate_Fields = {
  __typename?: 'integration_intent_aggregate_fields';
  avg?: Maybe<Integration_Intent_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Integration_Intent_Max_Fields>;
  min?: Maybe<Integration_Intent_Min_Fields>;
  stddev?: Maybe<Integration_Intent_Stddev_Fields>;
  stddev_pop?: Maybe<Integration_Intent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Integration_Intent_Stddev_Samp_Fields>;
  sum?: Maybe<Integration_Intent_Sum_Fields>;
  var_pop?: Maybe<Integration_Intent_Var_Pop_Fields>;
  var_samp?: Maybe<Integration_Intent_Var_Samp_Fields>;
  variance?: Maybe<Integration_Intent_Variance_Fields>;
};

/** aggregate fields of "integration_intent" */
export type Integration_Intent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integration_Intent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "integration_intent" */
export type Integration_Intent_Aggregate_Order_By = {
  avg?: InputMaybe<Integration_Intent_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Integration_Intent_Max_Order_By>;
  min?: InputMaybe<Integration_Intent_Min_Order_By>;
  stddev?: InputMaybe<Integration_Intent_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Integration_Intent_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Integration_Intent_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Integration_Intent_Sum_Order_By>;
  var_pop?: InputMaybe<Integration_Intent_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Integration_Intent_Var_Samp_Order_By>;
  variance?: InputMaybe<Integration_Intent_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Integration_Intent_Avg_Fields = {
  __typename?: 'integration_intent_avg_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "integration_intent" */
export type Integration_Intent_Avg_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "integration_intent". All fields are combined with a logical 'AND'. */
export type Integration_Intent_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Integration_Intent_Bool_Exp>>>;
  _not?: InputMaybe<Integration_Intent_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Integration_Intent_Bool_Exp>>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Integration_Bool_Exp>;
  integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  progress?: InputMaybe<Float8_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Integration_Intent_Max_Fields = {
  __typename?: 'integration_intent_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  integration_id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "integration_intent" */
export type Integration_Intent_Max_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Integration_Intent_Min_Fields = {
  __typename?: 'integration_intent_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  integration_id?: Maybe<Scalars['uuid']>;
  progress?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "integration_intent" */
export type Integration_Intent_Min_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "integration_intent" */
export type Integration_Intent_Order_By = {
  attributes?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Integration_Order_By>;
  integration_id?: InputMaybe<Order_By>;
  progress?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "integration_intent" */
export type Integration_Intent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "integration_intent" */
export enum Integration_Intent_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  Progress = 'progress',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate stddev on columns */
export type Integration_Intent_Stddev_Fields = {
  __typename?: 'integration_intent_stddev_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "integration_intent" */
export type Integration_Intent_Stddev_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Integration_Intent_Stddev_Pop_Fields = {
  __typename?: 'integration_intent_stddev_pop_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "integration_intent" */
export type Integration_Intent_Stddev_Pop_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Integration_Intent_Stddev_Samp_Fields = {
  __typename?: 'integration_intent_stddev_samp_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "integration_intent" */
export type Integration_Intent_Stddev_Samp_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Integration_Intent_Sum_Fields = {
  __typename?: 'integration_intent_sum_fields';
  progress?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "integration_intent" */
export type Integration_Intent_Sum_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Integration_Intent_Var_Pop_Fields = {
  __typename?: 'integration_intent_var_pop_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "integration_intent" */
export type Integration_Intent_Var_Pop_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Integration_Intent_Var_Samp_Fields = {
  __typename?: 'integration_intent_var_samp_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "integration_intent" */
export type Integration_Intent_Var_Samp_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Integration_Intent_Variance_Fields = {
  __typename?: 'integration_intent_variance_fields';
  progress?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "integration_intent" */
export type Integration_Intent_Variance_Order_By = {
  progress?: InputMaybe<Order_By>;
};

/** columns and relationships of "integration_log" */
export type Integration_Log = {
  __typename?: 'integration_log';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  integration: Integration;
  integration_id: Scalars['uuid'];
  title: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "integration_log". All fields are combined with a logical 'AND'. */
export type Integration_Log_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Integration_Log_Bool_Exp>>>;
  _not?: InputMaybe<Integration_Log_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Integration_Log_Bool_Exp>>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Integration_Bool_Exp>;
  integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** ordering options when selecting data from "integration_log" */
export type Integration_Log_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Integration_Order_By>;
  integration_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "integration_log" */
export type Integration_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "integration_log" */
export enum Integration_Log_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** response of any mutation on the table "integration" */
export type Integration_Mutation_Response = {
  __typename?: 'integration_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Integration>;
};

/** input type for inserting object relation for remote table "integration" */
export type Integration_Obj_Rel_Insert_Input = {
  data: Integration_Insert_Input;
  on_conflict?: InputMaybe<Integration_On_Conflict>;
};

/** on conflict condition type for table "integration" */
export type Integration_On_Conflict = {
  constraint: Integration_Constraint;
  update_columns: Array<Integration_Update_Column>;
  where?: InputMaybe<Integration_Bool_Exp>;
};

/** ordering options when selecting data from "integration" */
export type Integration_Order_By = {
  created_at?: InputMaybe<Order_By>;
  enabled_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  setup_completed_at?: InputMaybe<Order_By>;
  setup_error_message?: InputMaybe<Order_By>;
  setup_progress?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "integration" */
export type Integration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Integration_Prepend_Input = {
  credentials?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "integration" */
export enum Integration_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnabledAt = 'enabled_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SetupCompletedAt = 'setup_completed_at',
  /** column name */
  SetupErrorMessage = 'setup_error_message',
  /** column name */
  SetupProgress = 'setup_progress',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "integration" */
export type Integration_Set_Input = {
  credentials?: InputMaybe<Scalars['jsonb']>;
  enabled_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "integration" */
export enum Integration_Update_Column {
  /** column name */
  Credentials = 'credentials',
  /** column name */
  EnabledAt = 'enabled_at',
}

/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "maintenance_service_order" */
export type Maintenance_Service_Order = {
  __typename?: 'maintenance_service_order';
  attributes: Scalars['jsonb'];
  cost: Scalars['float8'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  external_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  occurred_at: Scalars['timestamptz'];
  organization_id: Scalars['uuid'];
  serial_number: Scalars['String'];
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** columns and relationships of "maintenance_service_order" */
export type Maintenance_Service_OrderAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "maintenance_service_order" */
export type Maintenance_Service_Order_Aggregate = {
  __typename?: 'maintenance_service_order_aggregate';
  aggregate?: Maybe<Maintenance_Service_Order_Aggregate_Fields>;
  nodes: Array<Maintenance_Service_Order>;
};

/** aggregate fields of "maintenance_service_order" */
export type Maintenance_Service_Order_Aggregate_Fields = {
  __typename?: 'maintenance_service_order_aggregate_fields';
  avg?: Maybe<Maintenance_Service_Order_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Maintenance_Service_Order_Max_Fields>;
  min?: Maybe<Maintenance_Service_Order_Min_Fields>;
  stddev?: Maybe<Maintenance_Service_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Maintenance_Service_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Maintenance_Service_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Maintenance_Service_Order_Sum_Fields>;
  var_pop?: Maybe<Maintenance_Service_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Maintenance_Service_Order_Var_Samp_Fields>;
  variance?: Maybe<Maintenance_Service_Order_Variance_Fields>;
};

/** aggregate fields of "maintenance_service_order" */
export type Maintenance_Service_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Maintenance_Service_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "maintenance_service_order" */
export type Maintenance_Service_Order_Aggregate_Order_By = {
  avg?: InputMaybe<Maintenance_Service_Order_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Maintenance_Service_Order_Max_Order_By>;
  min?: InputMaybe<Maintenance_Service_Order_Min_Order_By>;
  stddev?: InputMaybe<Maintenance_Service_Order_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Maintenance_Service_Order_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Maintenance_Service_Order_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Maintenance_Service_Order_Sum_Order_By>;
  var_pop?: InputMaybe<Maintenance_Service_Order_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Maintenance_Service_Order_Var_Samp_Order_By>;
  variance?: InputMaybe<Maintenance_Service_Order_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Maintenance_Service_Order_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "maintenance_service_order" */
export type Maintenance_Service_Order_Arr_Rel_Insert_Input = {
  data: Array<Maintenance_Service_Order_Insert_Input>;
  on_conflict?: InputMaybe<Maintenance_Service_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Maintenance_Service_Order_Avg_Fields = {
  __typename?: 'maintenance_service_order_avg_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "maintenance_service_order". All fields are combined with a logical 'AND'. */
export type Maintenance_Service_Order_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Maintenance_Service_Order_Bool_Exp>>>;
  _not?: InputMaybe<Maintenance_Service_Order_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Maintenance_Service_Order_Bool_Exp>>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  cost?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  occurred_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "maintenance_service_order" */
export enum Maintenance_Service_Order_Constraint {
  /** unique or primary key constraint */
  MaintenanceServiceOrderExternalIdVehicleIdOccurredAtKe = 'maintenance_service_order_external_id_vehicle_id_occurred_at_ke',
  /** unique or primary key constraint */
  MaintenanceServiceOrderPkey = 'maintenance_service_order_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Maintenance_Service_Order_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Maintenance_Service_Order_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Maintenance_Service_Order_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "maintenance_service_order" */
export type Maintenance_Service_Order_Inc_Input = {
  cost?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "maintenance_service_order" */
export type Maintenance_Service_Order_Insert_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  cost?: InputMaybe<Scalars['float8']>;
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']>;
  serial_number?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Maintenance_Service_Order_Max_Fields = {
  __typename?: 'maintenance_service_order_max_fields';
  cost?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  occurred_at?: Maybe<Scalars['timestamptz']>;
  organization_id?: Maybe<Scalars['uuid']>;
  serial_number?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Max_Order_By = {
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Maintenance_Service_Order_Min_Fields = {
  __typename?: 'maintenance_service_order_min_fields';
  cost?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  occurred_at?: Maybe<Scalars['timestamptz']>;
  organization_id?: Maybe<Scalars['uuid']>;
  serial_number?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Min_Order_By = {
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "maintenance_service_order" */
export type Maintenance_Service_Order_Mutation_Response = {
  __typename?: 'maintenance_service_order_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Maintenance_Service_Order>;
};

/** input type for inserting object relation for remote table "maintenance_service_order" */
export type Maintenance_Service_Order_Obj_Rel_Insert_Input = {
  data: Maintenance_Service_Order_Insert_Input;
  on_conflict?: InputMaybe<Maintenance_Service_Order_On_Conflict>;
};

/** on conflict condition type for table "maintenance_service_order" */
export type Maintenance_Service_Order_On_Conflict = {
  constraint: Maintenance_Service_Order_Constraint;
  update_columns: Array<Maintenance_Service_Order_Update_Column>;
  where?: InputMaybe<Maintenance_Service_Order_Bool_Exp>;
};

/** ordering options when selecting data from "maintenance_service_order" */
export type Maintenance_Service_Order_Order_By = {
  attributes?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "maintenance_service_order" */
export type Maintenance_Service_Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Maintenance_Service_Order_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "maintenance_service_order" */
export enum Maintenance_Service_Order_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  OccurredAt = 'occurred_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** input type for updating data in table "maintenance_service_order" */
export type Maintenance_Service_Order_Set_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  cost?: InputMaybe<Scalars['float8']>;
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']>;
  serial_number?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Maintenance_Service_Order_Stddev_Fields = {
  __typename?: 'maintenance_service_order_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Maintenance_Service_Order_Stddev_Pop_Fields = {
  __typename?: 'maintenance_service_order_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Maintenance_Service_Order_Stddev_Samp_Fields = {
  __typename?: 'maintenance_service_order_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Maintenance_Service_Order_Sum_Fields = {
  __typename?: 'maintenance_service_order_sum_fields';
  cost?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** update columns of table "maintenance_service_order" */
export enum Maintenance_Service_Order_Update_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Cost = 'cost',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  OccurredAt = 'occurred_at',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Source = 'source',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate var_pop on columns */
export type Maintenance_Service_Order_Var_Pop_Fields = {
  __typename?: 'maintenance_service_order_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Maintenance_Service_Order_Var_Samp_Fields = {
  __typename?: 'maintenance_service_order_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Maintenance_Service_Order_Variance_Fields = {
  __typename?: 'maintenance_service_order_variance_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "maintenance_service_order" */
export type Maintenance_Service_Order_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "driver" */
  delete_driver?: Maybe<Driver_Mutation_Response>;
  /** delete single row from the table: "driver" */
  delete_driver_by_pk?: Maybe<Driver>;
  /** delete data from the table: "equipment" */
  delete_equipment?: Maybe<Equipment_Mutation_Response>;
  /** delete single row from the table: "equipment" */
  delete_equipment_by_pk?: Maybe<Equipment>;
  /** delete data from the table: "hermes.geofence" */
  delete_hermes_geofence?: Maybe<Hermes_Geofence_Mutation_Response>;
  /** delete single row from the table: "hermes.geofence" */
  delete_hermes_geofence_by_pk?: Maybe<Hermes_Geofence>;
  /** delete data from the table: "hermes.workshift" */
  delete_hermes_workshift?: Maybe<Hermes_Workshift_Mutation_Response>;
  /** delete single row from the table: "hermes.workshift" */
  delete_hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** delete data from the table: "hermes.workshift_event" */
  delete_hermes_workshift_event?: Maybe<Hermes_Workshift_Event_Mutation_Response>;
  /** delete single row from the table: "hermes.workshift_event" */
  delete_hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** delete data from the table: "horimeter_history" */
  delete_horimeter_history?: Maybe<Horimeter_History_Mutation_Response>;
  /** delete single row from the table: "horimeter_history" */
  delete_horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** delete data from the table: "integration" */
  delete_integration?: Maybe<Integration_Mutation_Response>;
  /** delete single row from the table: "integration" */
  delete_integration_by_pk?: Maybe<Integration>;
  /** delete data from the table: "maintenance_service_order" */
  delete_maintenance_service_order?: Maybe<Maintenance_Service_Order_Mutation_Response>;
  /** delete single row from the table: "maintenance_service_order" */
  delete_maintenance_service_order_by_pk?: Maybe<Maintenance_Service_Order>;
  /** delete data from the table: "odometer_history" */
  delete_odometer_history?: Maybe<Odometer_History_Mutation_Response>;
  /** delete single row from the table: "odometer_history" */
  delete_odometer_history_by_pk?: Maybe<Odometer_History>;
  /** delete data from the table: "vehicle" */
  delete_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** delete single row from the table: "vehicle" */
  delete_vehicle_by_pk?: Maybe<Vehicle>;
  /** insert data into the table: "driver" */
  insert_driver?: Maybe<Driver_Mutation_Response>;
  /** insert a single row into the table: "driver" */
  insert_driver_one?: Maybe<Driver>;
  /** insert data into the table: "equipment" */
  insert_equipment?: Maybe<Equipment_Mutation_Response>;
  /** insert a single row into the table: "equipment" */
  insert_equipment_one?: Maybe<Equipment>;
  /** insert data into the table: "hermes.geofence" */
  insert_hermes_geofence?: Maybe<Hermes_Geofence_Mutation_Response>;
  /** insert a single row into the table: "hermes.geofence" */
  insert_hermes_geofence_one?: Maybe<Hermes_Geofence>;
  /** insert data into the table: "hermes.position_gadget" */
  insert_hermes_position_gadget?: Maybe<Hermes_Position_Gadget_Mutation_Response>;
  /** insert a single row into the table: "hermes.position_gadget" */
  insert_hermes_position_gadget_one?: Maybe<Hermes_Position_Gadget>;
  /** insert data into the table: "hermes.workshift" */
  insert_hermes_workshift?: Maybe<Hermes_Workshift_Mutation_Response>;
  /** insert data into the table: "hermes.workshift_event" */
  insert_hermes_workshift_event?: Maybe<Hermes_Workshift_Event_Mutation_Response>;
  /** insert a single row into the table: "hermes.workshift_event" */
  insert_hermes_workshift_event_one?: Maybe<Hermes_Workshift_Event>;
  /** insert a single row into the table: "hermes.workshift" */
  insert_hermes_workshift_one?: Maybe<Hermes_Workshift>;
  /** insert data into the table: "horimeter_history" */
  insert_horimeter_history?: Maybe<Horimeter_History_Mutation_Response>;
  /** insert a single row into the table: "horimeter_history" */
  insert_horimeter_history_one?: Maybe<Horimeter_History>;
  /** insert data into the table: "integration" */
  insert_integration?: Maybe<Integration_Mutation_Response>;
  /** insert a single row into the table: "integration" */
  insert_integration_one?: Maybe<Integration>;
  /** insert data into the table: "maintenance_service_order" */
  insert_maintenance_service_order?: Maybe<Maintenance_Service_Order_Mutation_Response>;
  /** insert a single row into the table: "maintenance_service_order" */
  insert_maintenance_service_order_one?: Maybe<Maintenance_Service_Order>;
  /** insert data into the table: "odometer_history" */
  insert_odometer_history?: Maybe<Odometer_History_Mutation_Response>;
  /** insert a single row into the table: "odometer_history" */
  insert_odometer_history_one?: Maybe<Odometer_History>;
  /** insert data into the table: "vehicle" */
  insert_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** insert a single row into the table: "vehicle" */
  insert_vehicle_one?: Maybe<Vehicle>;
  /** update data of the table: "driver" */
  update_driver?: Maybe<Driver_Mutation_Response>;
  /** update single row of the table: "driver" */
  update_driver_by_pk?: Maybe<Driver>;
  /** update data of the table: "equipment" */
  update_equipment?: Maybe<Equipment_Mutation_Response>;
  /** update single row of the table: "equipment" */
  update_equipment_by_pk?: Maybe<Equipment>;
  /** update data of the table: "hermes.geofence" */
  update_hermes_geofence?: Maybe<Hermes_Geofence_Mutation_Response>;
  /** update single row of the table: "hermes.geofence" */
  update_hermes_geofence_by_pk?: Maybe<Hermes_Geofence>;
  /** update data of the table: "hermes.position_gadget" */
  update_hermes_position_gadget?: Maybe<Hermes_Position_Gadget_Mutation_Response>;
  /** update single row of the table: "hermes.position_gadget" */
  update_hermes_position_gadget_by_pk?: Maybe<Hermes_Position_Gadget>;
  /** update data of the table: "hermes.workshift" */
  update_hermes_workshift?: Maybe<Hermes_Workshift_Mutation_Response>;
  /** update single row of the table: "hermes.workshift" */
  update_hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** update data of the table: "hermes.workshift_event" */
  update_hermes_workshift_event?: Maybe<Hermes_Workshift_Event_Mutation_Response>;
  /** update single row of the table: "hermes.workshift_event" */
  update_hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** update data of the table: "horimeter_history" */
  update_horimeter_history?: Maybe<Horimeter_History_Mutation_Response>;
  /** update single row of the table: "horimeter_history" */
  update_horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** update data of the table: "integration" */
  update_integration?: Maybe<Integration_Mutation_Response>;
  /** update single row of the table: "integration" */
  update_integration_by_pk?: Maybe<Integration>;
  /** update data of the table: "maintenance_service_order" */
  update_maintenance_service_order?: Maybe<Maintenance_Service_Order_Mutation_Response>;
  /** update single row of the table: "maintenance_service_order" */
  update_maintenance_service_order_by_pk?: Maybe<Maintenance_Service_Order>;
  /** update data of the table: "odometer_history" */
  update_odometer_history?: Maybe<Odometer_History_Mutation_Response>;
  /** update single row of the table: "odometer_history" */
  update_odometer_history_by_pk?: Maybe<Odometer_History>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "vehicle" */
  update_vehicle?: Maybe<Vehicle_Mutation_Response>;
  /** update single row of the table: "vehicle" */
  update_vehicle_by_pk?: Maybe<Vehicle>;
  /** perform the action: "update_vehicle_mappings" */
  update_vehicle_mappings: Array<UpdateVehicleMappingOutput>;
};

/** mutation root */
export type Mutation_RootDelete_DriverArgs = {
  where: Driver_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_EquipmentArgs = {
  where: Equipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Equipment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hermes_GeofenceArgs = {
  where: Hermes_Geofence_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hermes_Geofence_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hermes_WorkshiftArgs = {
  where: Hermes_Workshift_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hermes_Workshift_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hermes_Workshift_EventArgs = {
  where: Hermes_Workshift_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hermes_Workshift_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Horimeter_HistoryArgs = {
  where: Horimeter_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Horimeter_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_IntegrationArgs = {
  where: Integration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Maintenance_Service_OrderArgs = {
  where: Maintenance_Service_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Maintenance_Service_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Odometer_HistoryArgs = {
  where: Odometer_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Odometer_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_VehicleArgs = {
  where: Vehicle_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vehicle_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInsert_DriverArgs = {
  objects: Array<Driver_Insert_Input>;
  on_conflict?: InputMaybe<Driver_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Driver_OneArgs = {
  object: Driver_Insert_Input;
  on_conflict?: InputMaybe<Driver_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EquipmentArgs = {
  objects: Array<Equipment_Insert_Input>;
  on_conflict?: InputMaybe<Equipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Equipment_OneArgs = {
  object: Equipment_Insert_Input;
  on_conflict?: InputMaybe<Equipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_GeofenceArgs = {
  objects: Array<Hermes_Geofence_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Geofence_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Geofence_OneArgs = {
  object: Hermes_Geofence_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Geofence_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Position_GadgetArgs = {
  objects: Array<Hermes_Position_Gadget_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Position_Gadget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Position_Gadget_OneArgs = {
  object: Hermes_Position_Gadget_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Position_Gadget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_WorkshiftArgs = {
  objects: Array<Hermes_Workshift_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Workshift_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Workshift_EventArgs = {
  objects: Array<Hermes_Workshift_Event_Insert_Input>;
  on_conflict?: InputMaybe<Hermes_Workshift_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Workshift_Event_OneArgs = {
  object: Hermes_Workshift_Event_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Workshift_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hermes_Workshift_OneArgs = {
  object: Hermes_Workshift_Insert_Input;
  on_conflict?: InputMaybe<Hermes_Workshift_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Horimeter_HistoryArgs = {
  objects: Array<Horimeter_History_Insert_Input>;
  on_conflict?: InputMaybe<Horimeter_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Horimeter_History_OneArgs = {
  object: Horimeter_History_Insert_Input;
  on_conflict?: InputMaybe<Horimeter_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_IntegrationArgs = {
  objects: Array<Integration_Insert_Input>;
  on_conflict?: InputMaybe<Integration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_OneArgs = {
  object: Integration_Insert_Input;
  on_conflict?: InputMaybe<Integration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Maintenance_Service_OrderArgs = {
  objects: Array<Maintenance_Service_Order_Insert_Input>;
  on_conflict?: InputMaybe<Maintenance_Service_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Maintenance_Service_Order_OneArgs = {
  object: Maintenance_Service_Order_Insert_Input;
  on_conflict?: InputMaybe<Maintenance_Service_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Odometer_HistoryArgs = {
  objects: Array<Odometer_History_Insert_Input>;
  on_conflict?: InputMaybe<Odometer_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Odometer_History_OneArgs = {
  object: Odometer_History_Insert_Input;
  on_conflict?: InputMaybe<Odometer_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VehicleArgs = {
  objects: Array<Vehicle_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vehicle_OneArgs = {
  object: Vehicle_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_DriverArgs = {
  _set?: InputMaybe<Driver_Set_Input>;
  where: Driver_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Driver_By_PkArgs = {
  _set?: InputMaybe<Driver_Set_Input>;
  pk_columns: Driver_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_EquipmentArgs = {
  _set?: InputMaybe<Equipment_Set_Input>;
  where: Equipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Equipment_By_PkArgs = {
  _set?: InputMaybe<Equipment_Set_Input>;
  pk_columns: Equipment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_GeofenceArgs = {
  _append?: InputMaybe<Hermes_Geofence_Append_Input>;
  _delete_at_path?: InputMaybe<Hermes_Geofence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hermes_Geofence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hermes_Geofence_Delete_Key_Input>;
  _prepend?: InputMaybe<Hermes_Geofence_Prepend_Input>;
  _set?: InputMaybe<Hermes_Geofence_Set_Input>;
  where: Hermes_Geofence_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Geofence_By_PkArgs = {
  _append?: InputMaybe<Hermes_Geofence_Append_Input>;
  _delete_at_path?: InputMaybe<Hermes_Geofence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hermes_Geofence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hermes_Geofence_Delete_Key_Input>;
  _prepend?: InputMaybe<Hermes_Geofence_Prepend_Input>;
  _set?: InputMaybe<Hermes_Geofence_Set_Input>;
  pk_columns: Hermes_Geofence_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Position_GadgetArgs = {
  _append?: InputMaybe<Hermes_Position_Gadget_Append_Input>;
  _delete_at_path?: InputMaybe<Hermes_Position_Gadget_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hermes_Position_Gadget_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hermes_Position_Gadget_Delete_Key_Input>;
  _inc?: InputMaybe<Hermes_Position_Gadget_Inc_Input>;
  _prepend?: InputMaybe<Hermes_Position_Gadget_Prepend_Input>;
  _set?: InputMaybe<Hermes_Position_Gadget_Set_Input>;
  where: Hermes_Position_Gadget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Position_Gadget_By_PkArgs = {
  _append?: InputMaybe<Hermes_Position_Gadget_Append_Input>;
  _delete_at_path?: InputMaybe<Hermes_Position_Gadget_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hermes_Position_Gadget_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hermes_Position_Gadget_Delete_Key_Input>;
  _inc?: InputMaybe<Hermes_Position_Gadget_Inc_Input>;
  _prepend?: InputMaybe<Hermes_Position_Gadget_Prepend_Input>;
  _set?: InputMaybe<Hermes_Position_Gadget_Set_Input>;
  pk_columns: Hermes_Position_Gadget_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_WorkshiftArgs = {
  _set?: InputMaybe<Hermes_Workshift_Set_Input>;
  where: Hermes_Workshift_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Workshift_By_PkArgs = {
  _set?: InputMaybe<Hermes_Workshift_Set_Input>;
  pk_columns: Hermes_Workshift_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Workshift_EventArgs = {
  _append?: InputMaybe<Hermes_Workshift_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Hermes_Workshift_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hermes_Workshift_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hermes_Workshift_Event_Delete_Key_Input>;
  _inc?: InputMaybe<Hermes_Workshift_Event_Inc_Input>;
  _prepend?: InputMaybe<Hermes_Workshift_Event_Prepend_Input>;
  _set?: InputMaybe<Hermes_Workshift_Event_Set_Input>;
  where: Hermes_Workshift_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hermes_Workshift_Event_By_PkArgs = {
  _append?: InputMaybe<Hermes_Workshift_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Hermes_Workshift_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hermes_Workshift_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hermes_Workshift_Event_Delete_Key_Input>;
  _inc?: InputMaybe<Hermes_Workshift_Event_Inc_Input>;
  _prepend?: InputMaybe<Hermes_Workshift_Event_Prepend_Input>;
  _set?: InputMaybe<Hermes_Workshift_Event_Set_Input>;
  pk_columns: Hermes_Workshift_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Horimeter_HistoryArgs = {
  _inc?: InputMaybe<Horimeter_History_Inc_Input>;
  _set?: InputMaybe<Horimeter_History_Set_Input>;
  where: Horimeter_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Horimeter_History_By_PkArgs = {
  _inc?: InputMaybe<Horimeter_History_Inc_Input>;
  _set?: InputMaybe<Horimeter_History_Set_Input>;
  pk_columns: Horimeter_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_IntegrationArgs = {
  _append?: InputMaybe<Integration_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Delete_Key_Input>;
  _prepend?: InputMaybe<Integration_Prepend_Input>;
  _set?: InputMaybe<Integration_Set_Input>;
  where: Integration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_By_PkArgs = {
  _append?: InputMaybe<Integration_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Delete_Key_Input>;
  _prepend?: InputMaybe<Integration_Prepend_Input>;
  _set?: InputMaybe<Integration_Set_Input>;
  pk_columns: Integration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Maintenance_Service_OrderArgs = {
  _append?: InputMaybe<Maintenance_Service_Order_Append_Input>;
  _delete_at_path?: InputMaybe<Maintenance_Service_Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Maintenance_Service_Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Maintenance_Service_Order_Delete_Key_Input>;
  _inc?: InputMaybe<Maintenance_Service_Order_Inc_Input>;
  _prepend?: InputMaybe<Maintenance_Service_Order_Prepend_Input>;
  _set?: InputMaybe<Maintenance_Service_Order_Set_Input>;
  where: Maintenance_Service_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Maintenance_Service_Order_By_PkArgs = {
  _append?: InputMaybe<Maintenance_Service_Order_Append_Input>;
  _delete_at_path?: InputMaybe<Maintenance_Service_Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Maintenance_Service_Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Maintenance_Service_Order_Delete_Key_Input>;
  _inc?: InputMaybe<Maintenance_Service_Order_Inc_Input>;
  _prepend?: InputMaybe<Maintenance_Service_Order_Prepend_Input>;
  _set?: InputMaybe<Maintenance_Service_Order_Set_Input>;
  pk_columns: Maintenance_Service_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Odometer_HistoryArgs = {
  _inc?: InputMaybe<Odometer_History_Inc_Input>;
  _set?: InputMaybe<Odometer_History_Set_Input>;
  where: Odometer_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Odometer_History_By_PkArgs = {
  _inc?: InputMaybe<Odometer_History_Inc_Input>;
  _set?: InputMaybe<Odometer_History_Set_Input>;
  pk_columns: Odometer_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_VehicleArgs = {
  _inc?: InputMaybe<Vehicle_Inc_Input>;
  _set?: InputMaybe<Vehicle_Set_Input>;
  where: Vehicle_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vehicle_By_PkArgs = {
  _inc?: InputMaybe<Vehicle_Inc_Input>;
  _set?: InputMaybe<Vehicle_Set_Input>;
  pk_columns: Vehicle_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vehicle_MappingsArgs = {
  mappings: Array<VehicleMappingInput>;
};

/** columns and relationships of "odometer_history" */
export type Odometer_History = {
  __typename?: 'odometer_history';
  created_at: Scalars['timestamptz'];
  current: Scalars['float8'];
  /** An object relationship */
  fuelling?: Maybe<Fuelling>;
  fuelling_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  measured_at: Scalars['timestamptz'];
  previous?: Maybe<Scalars['float8']>;
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** aggregated selection of "odometer_history" */
export type Odometer_History_Aggregate = {
  __typename?: 'odometer_history_aggregate';
  aggregate?: Maybe<Odometer_History_Aggregate_Fields>;
  nodes: Array<Odometer_History>;
};

/** aggregate fields of "odometer_history" */
export type Odometer_History_Aggregate_Fields = {
  __typename?: 'odometer_history_aggregate_fields';
  avg?: Maybe<Odometer_History_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Odometer_History_Max_Fields>;
  min?: Maybe<Odometer_History_Min_Fields>;
  stddev?: Maybe<Odometer_History_Stddev_Fields>;
  stddev_pop?: Maybe<Odometer_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Odometer_History_Stddev_Samp_Fields>;
  sum?: Maybe<Odometer_History_Sum_Fields>;
  var_pop?: Maybe<Odometer_History_Var_Pop_Fields>;
  var_samp?: Maybe<Odometer_History_Var_Samp_Fields>;
  variance?: Maybe<Odometer_History_Variance_Fields>;
};

/** aggregate fields of "odometer_history" */
export type Odometer_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Odometer_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "odometer_history" */
export type Odometer_History_Aggregate_Order_By = {
  avg?: InputMaybe<Odometer_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Odometer_History_Max_Order_By>;
  min?: InputMaybe<Odometer_History_Min_Order_By>;
  stddev?: InputMaybe<Odometer_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Odometer_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Odometer_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Odometer_History_Sum_Order_By>;
  var_pop?: InputMaybe<Odometer_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Odometer_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Odometer_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "odometer_history" */
export type Odometer_History_Arr_Rel_Insert_Input = {
  data: Array<Odometer_History_Insert_Input>;
  on_conflict?: InputMaybe<Odometer_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Odometer_History_Avg_Fields = {
  __typename?: 'odometer_history_avg_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "odometer_history" */
export type Odometer_History_Avg_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "odometer_history". All fields are combined with a logical 'AND'. */
export type Odometer_History_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Odometer_History_Bool_Exp>>>;
  _not?: InputMaybe<Odometer_History_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Odometer_History_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current?: InputMaybe<Float8_Comparison_Exp>;
  fuelling?: InputMaybe<Fuelling_Bool_Exp>;
  fuelling_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  measured_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  previous?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "odometer_history" */
export enum Odometer_History_Constraint {
  /** unique or primary key constraint */
  OdometerHistoryFuellingIdVehicleIdKey = 'odometer_history_fuelling_id_vehicle_id_key',
  /** unique or primary key constraint */
  OdometerHistoryPkey = 'odometer_history_pkey',
}

/** input type for incrementing integer column in table "odometer_history" */
export type Odometer_History_Inc_Input = {
  current?: InputMaybe<Scalars['float8']>;
  previous?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "odometer_history" */
export type Odometer_History_Insert_Input = {
  current?: InputMaybe<Scalars['float8']>;
  measured_at?: InputMaybe<Scalars['timestamptz']>;
  previous?: InputMaybe<Scalars['float8']>;
  source?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<Vehicle_Obj_Rel_Insert_Input>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Odometer_History_Max_Fields = {
  __typename?: 'odometer_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  fuelling_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "odometer_history" */
export type Odometer_History_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  fuelling_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measured_at?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Odometer_History_Min_Fields = {
  __typename?: 'odometer_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current?: Maybe<Scalars['float8']>;
  fuelling_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  measured_at?: Maybe<Scalars['timestamptz']>;
  previous?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "odometer_history" */
export type Odometer_History_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  fuelling_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measured_at?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "odometer_history" */
export type Odometer_History_Mutation_Response = {
  __typename?: 'odometer_history_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Odometer_History>;
};

/** input type for inserting object relation for remote table "odometer_history" */
export type Odometer_History_Obj_Rel_Insert_Input = {
  data: Odometer_History_Insert_Input;
  on_conflict?: InputMaybe<Odometer_History_On_Conflict>;
};

/** on conflict condition type for table "odometer_history" */
export type Odometer_History_On_Conflict = {
  constraint: Odometer_History_Constraint;
  update_columns: Array<Odometer_History_Update_Column>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** ordering options when selecting data from "odometer_history" */
export type Odometer_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  fuelling?: InputMaybe<Fuelling_Order_By>;
  fuelling_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measured_at?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "odometer_history" */
export type Odometer_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "odometer_history" */
export enum Odometer_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Current = 'current',
  /** column name */
  FuellingId = 'fuelling_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** input type for updating data in table "odometer_history" */
export type Odometer_History_Set_Input = {
  current?: InputMaybe<Scalars['float8']>;
  measured_at?: InputMaybe<Scalars['timestamptz']>;
  previous?: InputMaybe<Scalars['float8']>;
  source?: InputMaybe<Scalars['String']>;
  vehicle_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Odometer_History_Stddev_Fields = {
  __typename?: 'odometer_history_stddev_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "odometer_history" */
export type Odometer_History_Stddev_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Odometer_History_Stddev_Pop_Fields = {
  __typename?: 'odometer_history_stddev_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "odometer_history" */
export type Odometer_History_Stddev_Pop_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Odometer_History_Stddev_Samp_Fields = {
  __typename?: 'odometer_history_stddev_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "odometer_history" */
export type Odometer_History_Stddev_Samp_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Odometer_History_Sum_Fields = {
  __typename?: 'odometer_history_sum_fields';
  current?: Maybe<Scalars['float8']>;
  previous?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "odometer_history" */
export type Odometer_History_Sum_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** update columns of table "odometer_history" */
export enum Odometer_History_Update_Column {
  /** column name */
  Current = 'current',
  /** column name */
  MeasuredAt = 'measured_at',
  /** column name */
  Previous = 'previous',
  /** column name */
  Source = 'source',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate var_pop on columns */
export type Odometer_History_Var_Pop_Fields = {
  __typename?: 'odometer_history_var_pop_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "odometer_history" */
export type Odometer_History_Var_Pop_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Odometer_History_Var_Samp_Fields = {
  __typename?: 'odometer_history_var_samp_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "odometer_history" */
export type Odometer_History_Var_Samp_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Odometer_History_Variance_Fields = {
  __typename?: 'odometer_history_variance_fields';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "odometer_history" */
export type Odometer_History_Variance_Order_By = {
  current?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "provider" */
export type Provider = {
  __typename?: 'provider';
  attributes?: Maybe<Scalars['jsonb']>;
  cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  fuellings: Array<Fuelling>;
  /** An aggregated array relationship */
  fuellings_aggregate: Fuelling_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  source: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "provider" */
export type ProviderAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "provider" */
export type ProviderFuellingsArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** columns and relationships of "provider" */
export type ProviderFuellings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** aggregated selection of "provider" */
export type Provider_Aggregate = {
  __typename?: 'provider_aggregate';
  aggregate?: Maybe<Provider_Aggregate_Fields>;
  nodes: Array<Provider>;
};

/** aggregate fields of "provider" */
export type Provider_Aggregate_Fields = {
  __typename?: 'provider_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Provider_Max_Fields>;
  min?: Maybe<Provider_Min_Fields>;
};

/** aggregate fields of "provider" */
export type Provider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Provider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider" */
export type Provider_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Provider_Max_Order_By>;
  min?: InputMaybe<Provider_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "provider". All fields are combined with a logical 'AND'. */
export type Provider_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Provider_Bool_Exp>>>;
  _not?: InputMaybe<Provider_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Provider_Bool_Exp>>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  cnpj?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  fuellings?: InputMaybe<Fuelling_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Provider_Max_Fields = {
  __typename?: 'provider_max_fields';
  cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "provider" */
export type Provider_Max_Order_By = {
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Min_Fields = {
  __typename?: 'provider_min_fields';
  cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "provider" */
export type Provider_Min_Order_By = {
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "provider" */
export type Provider_Order_By = {
  attributes?: InputMaybe<Order_By>;
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fuellings_aggregate?: InputMaybe<Fuelling_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "provider" */
export type Provider_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "provider" */
export enum Provider_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Cnpj = 'cnpj',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "driver" */
  driver: Array<Driver>;
  /** fetch aggregated fields from the table: "driver" */
  driver_aggregate: Driver_Aggregate;
  /** fetch data from the table: "driver" using primary key columns */
  driver_by_pk?: Maybe<Driver>;
  /** fetch data from the table: "equipment" */
  equipment: Array<Equipment>;
  /** fetch aggregated fields from the table: "equipment" */
  equipment_aggregate: Equipment_Aggregate;
  /** fetch data from the table: "equipment" using primary key columns */
  equipment_by_pk?: Maybe<Equipment>;
  /** fetch data from the table: "fuelling" */
  fuelling: Array<Fuelling>;
  /** fetch aggregated fields from the table: "fuelling" */
  fuelling_aggregate: Fuelling_Aggregate;
  /** fetch data from the table: "fuelling" using primary key columns */
  fuelling_by_pk?: Maybe<Fuelling>;
  /** execute function "get_user_by_credentials" which returns "user" */
  get_user_by_credentials: Array<User>;
  /** fetch data from the table: "hermes.daily_vehicle_summary" */
  hermes_daily_vehicle_summary: Array<Hermes_Daily_Vehicle_Summary>;
  /** fetch aggregated fields from the table: "hermes.daily_vehicle_summary" */
  hermes_daily_vehicle_summary_aggregate: Hermes_Daily_Vehicle_Summary_Aggregate;
  /** fetch data from the table: "hermes.daily_vehicle_summary" using primary key columns */
  hermes_daily_vehicle_summary_by_pk?: Maybe<Hermes_Daily_Vehicle_Summary>;
  /** fetch data from the table: "hermes.device" */
  hermes_device: Array<Hermes_Device>;
  /** fetch data from the table: "hermes.device" using primary key columns */
  hermes_device_by_pk?: Maybe<Hermes_Device>;
  /** fetch data from the table: "hermes.event_traccar" */
  hermes_event_traccar: Array<Hermes_Event_Traccar>;
  /** fetch aggregated fields from the table: "hermes.event_traccar" */
  hermes_event_traccar_aggregate: Hermes_Event_Traccar_Aggregate;
  /** fetch data from the table: "hermes.event_traccar" using primary key columns */
  hermes_event_traccar_by_pk?: Maybe<Hermes_Event_Traccar>;
  /** fetch data from the table: "hermes.event_type" */
  hermes_event_type: Array<Hermes_Event_Type>;
  /** fetch aggregated fields from the table: "hermes.event_type" */
  hermes_event_type_aggregate: Hermes_Event_Type_Aggregate;
  /** fetch data from the table: "hermes.event_type" using primary key columns */
  hermes_event_type_by_pk?: Maybe<Hermes_Event_Type>;
  /** fetch data from the table: "hermes.geofence" */
  hermes_geofence: Array<Hermes_Geofence>;
  /** fetch aggregated fields from the table: "hermes.geofence" */
  hermes_geofence_aggregate: Hermes_Geofence_Aggregate;
  /** fetch data from the table: "hermes.geofence" using primary key columns */
  hermes_geofence_by_pk?: Maybe<Hermes_Geofence>;
  /** fetch data from the table: "hermes.position_gadget" */
  hermes_position_gadget: Array<Hermes_Position_Gadget>;
  /** fetch aggregated fields from the table: "hermes.position_gadget" */
  hermes_position_gadget_aggregate: Hermes_Position_Gadget_Aggregate;
  /** fetch data from the table: "hermes.position_gadget" using primary key columns */
  hermes_position_gadget_by_pk?: Maybe<Hermes_Position_Gadget>;
  /** fetch data from the table: "hermes.workshift" */
  hermes_workshift: Array<Hermes_Workshift>;
  /** fetch aggregated fields from the table: "hermes.workshift" */
  hermes_workshift_aggregate: Hermes_Workshift_Aggregate;
  /** fetch data from the table: "hermes.workshift" using primary key columns */
  hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** fetch data from the table: "hermes.workshift_event" */
  hermes_workshift_event: Array<Hermes_Workshift_Event>;
  /** fetch data from the table: "hermes.workshift_event" using primary key columns */
  hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** fetch data from the table: "horimeter_history" */
  horimeter_history: Array<Horimeter_History>;
  /** fetch aggregated fields from the table: "horimeter_history" */
  horimeter_history_aggregate: Horimeter_History_Aggregate;
  /** fetch data from the table: "horimeter_history" using primary key columns */
  horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** fetch data from the table: "integration" */
  integration: Array<Integration>;
  /** fetch data from the table: "integration" using primary key columns */
  integration_by_pk?: Maybe<Integration>;
  /** fetch data from the table: "integration_intent" */
  integration_intent: Array<Integration_Intent>;
  /** fetch aggregated fields from the table: "integration_intent" */
  integration_intent_aggregate: Integration_Intent_Aggregate;
  /** fetch data from the table: "integration_intent" using primary key columns */
  integration_intent_by_pk?: Maybe<Integration_Intent>;
  /** fetch data from the table: "integration_log" */
  integration_log: Array<Integration_Log>;
  /** fetch data from the table: "integration_log" using primary key columns */
  integration_log_by_pk?: Maybe<Integration_Log>;
  /** fetch data from the table: "maintenance_service_order" */
  maintenance_service_order: Array<Maintenance_Service_Order>;
  /** fetch aggregated fields from the table: "maintenance_service_order" */
  maintenance_service_order_aggregate: Maintenance_Service_Order_Aggregate;
  /** fetch data from the table: "maintenance_service_order" using primary key columns */
  maintenance_service_order_by_pk?: Maybe<Maintenance_Service_Order>;
  /** perform the action: "maintenance_summary" */
  maintenance_summary: Array<MaintenanceSummary>;
  /** fetch data from the table: "odometer_history" */
  odometer_history: Array<Odometer_History>;
  /** fetch aggregated fields from the table: "odometer_history" */
  odometer_history_aggregate: Odometer_History_Aggregate;
  /** fetch data from the table: "odometer_history" using primary key columns */
  odometer_history_by_pk?: Maybe<Odometer_History>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table: "tank" */
  tank: Array<Tank>;
  /** fetch data from the table: "tank" using primary key columns */
  tank_by_pk?: Maybe<Tank>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "vehicle" */
  vehicle_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "vehicle" using primary key columns */
  vehicle_by_pk?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_driver_history" */
  vehicle_driver_history: Array<Vehicle_Driver_History>;
  /** fetch aggregated fields from the table: "vehicle_driver_history" */
  vehicle_driver_history_aggregate: Vehicle_Driver_History_Aggregate;
  /** fetch data from the table: "vehicle_driver_history" using primary key columns */
  vehicle_driver_history_by_pk?: Maybe<Vehicle_Driver_History>;
  /** fetch data from the table: "vehicle_mapping" */
  vehicle_mapping: Array<Vehicle_Mapping>;
  /** fetch data from the table: "vehicle_mapping" using primary key columns */
  vehicle_mapping_by_pk?: Maybe<Vehicle_Mapping>;
  /** perform the action: "vehicle_route_details" */
  vehicle_route_details: VehicleRouteDetails;
  /** fetch data from the table: "vehicle_type" */
  vehicle_type: Array<Vehicle_Type>;
  /** fetch aggregated fields from the table: "vehicle_type" */
  vehicle_type_aggregate: Vehicle_Type_Aggregate;
  /** fetch data from the table: "vehicle_type" using primary key columns */
  vehicle_type_by_pk?: Maybe<Vehicle_Type>;
  /** perform the action: "vehicles_fuelling_summary" */
  vehicles_fuelling_summary: Array<VehicleFuellingSummary>;
  /** perform the action: "vehicles_max_speed" */
  vehicles_max_speed: VehiclesMaxSpeedOutput;
  /** perform the action: "vehicles_productivity" */
  vehicles_productivity: Array<VehicleProductivity>;
  /** perform the action: "vehicles_total_distance" */
  vehicles_total_distance: VehiclesTotalDistanceOutput;
  /** perform the action: "vehicles_total_time_stopped" */
  vehicles_total_time_stopped: VehiclesTotalTimeStoppedOutput;
  /** perform the action: "vehicles_total_time_stopped_with_ignition_on" */
  vehicles_total_time_stopped_with_ignition_on: VehiclesTotalTimeStoppedWithIgnitionOnOutput;
  /** perform the action: "vehicles_total_time_with_ignition_on" */
  vehicles_total_time_with_ignition_on: VehiclesTotalTimeWithIgnitionOnOutput;
};

/** query root */
export type Query_RootDriverArgs = {
  distinct_on?: InputMaybe<Array<Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Driver_Order_By>>;
  where?: InputMaybe<Driver_Bool_Exp>;
};

/** query root */
export type Query_RootDriver_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Driver_Order_By>>;
  where?: InputMaybe<Driver_Bool_Exp>;
};

/** query root */
export type Query_RootDriver_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootEquipmentArgs = {
  distinct_on?: InputMaybe<Array<Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equipment_Order_By>>;
  where?: InputMaybe<Equipment_Bool_Exp>;
};

/** query root */
export type Query_RootEquipment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equipment_Order_By>>;
  where?: InputMaybe<Equipment_Bool_Exp>;
};

/** query root */
export type Query_RootEquipment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootFuellingArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** query root */
export type Query_RootFuelling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** query root */
export type Query_RootFuelling_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootGet_User_By_CredentialsArgs = {
  args: Get_User_By_Credentials_Args;
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Daily_Vehicle_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Order_By>>;
  where?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Daily_Vehicle_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Order_By>>;
  where?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Daily_Vehicle_Summary_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_DeviceArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Device_Order_By>>;
  where?: InputMaybe<Hermes_Device_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Device_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Event_TraccarArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Traccar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Traccar_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Event_TypeArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Type_Order_By>>;
  where?: InputMaybe<Hermes_Event_Type_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Type_Order_By>>;
  where?: InputMaybe<Hermes_Event_Type_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Event_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_GeofenceArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Geofence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Geofence_Order_By>>;
  where?: InputMaybe<Hermes_Geofence_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Geofence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Geofence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Geofence_Order_By>>;
  where?: InputMaybe<Hermes_Geofence_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Geofence_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Position_GadgetArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Position_Gadget_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Position_Gadget_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_WorkshiftArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Workshift_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Workshift_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHermes_Workshift_EventArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** query root */
export type Query_RootHermes_Workshift_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHorimeter_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** query root */
export type Query_RootHorimeter_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** query root */
export type Query_RootHorimeter_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootIntegrationArgs = {
  distinct_on?: InputMaybe<Array<Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Order_By>>;
  where?: InputMaybe<Integration_Bool_Exp>;
};

/** query root */
export type Query_RootIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootIntegration_IntentArgs = {
  distinct_on?: InputMaybe<Array<Integration_Intent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Intent_Order_By>>;
  where?: InputMaybe<Integration_Intent_Bool_Exp>;
};

/** query root */
export type Query_RootIntegration_Intent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Intent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Intent_Order_By>>;
  where?: InputMaybe<Integration_Intent_Bool_Exp>;
};

/** query root */
export type Query_RootIntegration_Intent_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootIntegration_LogArgs = {
  distinct_on?: InputMaybe<Array<Integration_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Log_Order_By>>;
  where?: InputMaybe<Integration_Log_Bool_Exp>;
};

/** query root */
export type Query_RootIntegration_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootMaintenance_Service_OrderArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Service_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Service_Order_Order_By>>;
  where?: InputMaybe<Maintenance_Service_Order_Bool_Exp>;
};

/** query root */
export type Query_RootMaintenance_Service_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Service_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Service_Order_Order_By>>;
  where?: InputMaybe<Maintenance_Service_Order_Bool_Exp>;
};

/** query root */
export type Query_RootMaintenance_Service_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootMaintenance_SummaryArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** query root */
export type Query_RootOdometer_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** query root */
export type Query_RootOdometer_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** query root */
export type Query_RootOdometer_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** query root */
export type Query_RootProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** query root */
export type Query_RootProvider_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootTankArgs = {
  distinct_on?: InputMaybe<Array<Tank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tank_Order_By>>;
  where?: InputMaybe<Tank_Bool_Exp>;
};

/** query root */
export type Query_RootTank_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootVehicleArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootVehicle_Driver_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Driver_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Driver_History_Order_By>>;
  where?: InputMaybe<Vehicle_Driver_History_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_Driver_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Driver_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Driver_History_Order_By>>;
  where?: InputMaybe<Vehicle_Driver_History_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_Driver_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootVehicle_MappingArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Mapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Mapping_Order_By>>;
  where?: InputMaybe<Vehicle_Mapping_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_Mapping_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootVehicle_Route_DetailsArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleId: Scalars['uuid'];
};

/** query root */
export type Query_RootVehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};

/** query root */
export type Query_RootVehicle_Type_By_PkArgs = {
  slug_name: Scalars['String'];
};

/** query root */
export type Query_RootVehicles_Fuelling_SummaryArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** query root */
export type Query_RootVehicles_Max_SpeedArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** query root */
export type Query_RootVehicles_ProductivityArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** query root */
export type Query_RootVehicles_Total_DistanceArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** query root */
export type Query_RootVehicles_Total_Time_StoppedArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** query root */
export type Query_RootVehicles_Total_Time_Stopped_With_Ignition_OnArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** query root */
export type Query_RootVehicles_Total_Time_With_Ignition_OnArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "driver" */
  driver: Array<Driver>;
  /** fetch aggregated fields from the table: "driver" */
  driver_aggregate: Driver_Aggregate;
  /** fetch data from the table: "driver" using primary key columns */
  driver_by_pk?: Maybe<Driver>;
  /** fetch data from the table: "equipment" */
  equipment: Array<Equipment>;
  /** fetch aggregated fields from the table: "equipment" */
  equipment_aggregate: Equipment_Aggregate;
  /** fetch data from the table: "equipment" using primary key columns */
  equipment_by_pk?: Maybe<Equipment>;
  /** fetch data from the table: "fuelling" */
  fuelling: Array<Fuelling>;
  /** fetch aggregated fields from the table: "fuelling" */
  fuelling_aggregate: Fuelling_Aggregate;
  /** fetch data from the table: "fuelling" using primary key columns */
  fuelling_by_pk?: Maybe<Fuelling>;
  /** execute function "get_user_by_credentials" which returns "user" */
  get_user_by_credentials: Array<User>;
  /** fetch data from the table: "hermes.daily_vehicle_summary" */
  hermes_daily_vehicle_summary: Array<Hermes_Daily_Vehicle_Summary>;
  /** fetch aggregated fields from the table: "hermes.daily_vehicle_summary" */
  hermes_daily_vehicle_summary_aggregate: Hermes_Daily_Vehicle_Summary_Aggregate;
  /** fetch data from the table: "hermes.daily_vehicle_summary" using primary key columns */
  hermes_daily_vehicle_summary_by_pk?: Maybe<Hermes_Daily_Vehicle_Summary>;
  /** fetch data from the table: "hermes.device" */
  hermes_device: Array<Hermes_Device>;
  /** fetch data from the table: "hermes.device" using primary key columns */
  hermes_device_by_pk?: Maybe<Hermes_Device>;
  /** fetch data from the table: "hermes.event_traccar" */
  hermes_event_traccar: Array<Hermes_Event_Traccar>;
  /** fetch aggregated fields from the table: "hermes.event_traccar" */
  hermes_event_traccar_aggregate: Hermes_Event_Traccar_Aggregate;
  /** fetch data from the table: "hermes.event_traccar" using primary key columns */
  hermes_event_traccar_by_pk?: Maybe<Hermes_Event_Traccar>;
  /** fetch data from the table: "hermes.event_type" */
  hermes_event_type: Array<Hermes_Event_Type>;
  /** fetch aggregated fields from the table: "hermes.event_type" */
  hermes_event_type_aggregate: Hermes_Event_Type_Aggregate;
  /** fetch data from the table: "hermes.event_type" using primary key columns */
  hermes_event_type_by_pk?: Maybe<Hermes_Event_Type>;
  /** fetch data from the table: "hermes.geofence" */
  hermes_geofence: Array<Hermes_Geofence>;
  /** fetch aggregated fields from the table: "hermes.geofence" */
  hermes_geofence_aggregate: Hermes_Geofence_Aggregate;
  /** fetch data from the table: "hermes.geofence" using primary key columns */
  hermes_geofence_by_pk?: Maybe<Hermes_Geofence>;
  /** fetch data from the table: "hermes.position_gadget" */
  hermes_position_gadget: Array<Hermes_Position_Gadget>;
  /** fetch aggregated fields from the table: "hermes.position_gadget" */
  hermes_position_gadget_aggregate: Hermes_Position_Gadget_Aggregate;
  /** fetch data from the table: "hermes.position_gadget" using primary key columns */
  hermes_position_gadget_by_pk?: Maybe<Hermes_Position_Gadget>;
  /** fetch data from the table: "hermes.workshift" */
  hermes_workshift: Array<Hermes_Workshift>;
  /** fetch aggregated fields from the table: "hermes.workshift" */
  hermes_workshift_aggregate: Hermes_Workshift_Aggregate;
  /** fetch data from the table: "hermes.workshift" using primary key columns */
  hermes_workshift_by_pk?: Maybe<Hermes_Workshift>;
  /** fetch data from the table: "hermes.workshift_event" */
  hermes_workshift_event: Array<Hermes_Workshift_Event>;
  /** fetch data from the table: "hermes.workshift_event" using primary key columns */
  hermes_workshift_event_by_pk?: Maybe<Hermes_Workshift_Event>;
  /** fetch data from the table: "horimeter_history" */
  horimeter_history: Array<Horimeter_History>;
  /** fetch aggregated fields from the table: "horimeter_history" */
  horimeter_history_aggregate: Horimeter_History_Aggregate;
  /** fetch data from the table: "horimeter_history" using primary key columns */
  horimeter_history_by_pk?: Maybe<Horimeter_History>;
  /** fetch data from the table: "integration" */
  integration: Array<Integration>;
  /** fetch data from the table: "integration" using primary key columns */
  integration_by_pk?: Maybe<Integration>;
  /** fetch data from the table: "integration_intent" */
  integration_intent: Array<Integration_Intent>;
  /** fetch aggregated fields from the table: "integration_intent" */
  integration_intent_aggregate: Integration_Intent_Aggregate;
  /** fetch data from the table: "integration_intent" using primary key columns */
  integration_intent_by_pk?: Maybe<Integration_Intent>;
  /** fetch data from the table: "integration_log" */
  integration_log: Array<Integration_Log>;
  /** fetch data from the table: "integration_log" using primary key columns */
  integration_log_by_pk?: Maybe<Integration_Log>;
  /** fetch data from the table: "maintenance_service_order" */
  maintenance_service_order: Array<Maintenance_Service_Order>;
  /** fetch aggregated fields from the table: "maintenance_service_order" */
  maintenance_service_order_aggregate: Maintenance_Service_Order_Aggregate;
  /** fetch data from the table: "maintenance_service_order" using primary key columns */
  maintenance_service_order_by_pk?: Maybe<Maintenance_Service_Order>;
  /** perform the action: "maintenance_summary" */
  maintenance_summary: Array<MaintenanceSummary>;
  /** fetch data from the table: "odometer_history" */
  odometer_history: Array<Odometer_History>;
  /** fetch aggregated fields from the table: "odometer_history" */
  odometer_history_aggregate: Odometer_History_Aggregate;
  /** fetch data from the table: "odometer_history" using primary key columns */
  odometer_history_by_pk?: Maybe<Odometer_History>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table: "tank" */
  tank: Array<Tank>;
  /** fetch data from the table: "tank" using primary key columns */
  tank_by_pk?: Maybe<Tank>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "vehicle" */
  vehicle_aggregate: Vehicle_Aggregate;
  /** fetch data from the table: "vehicle" using primary key columns */
  vehicle_by_pk?: Maybe<Vehicle>;
  /** fetch data from the table: "vehicle_driver_history" */
  vehicle_driver_history: Array<Vehicle_Driver_History>;
  /** fetch aggregated fields from the table: "vehicle_driver_history" */
  vehicle_driver_history_aggregate: Vehicle_Driver_History_Aggregate;
  /** fetch data from the table: "vehicle_driver_history" using primary key columns */
  vehicle_driver_history_by_pk?: Maybe<Vehicle_Driver_History>;
  /** fetch data from the table: "vehicle_mapping" */
  vehicle_mapping: Array<Vehicle_Mapping>;
  /** fetch data from the table: "vehicle_mapping" using primary key columns */
  vehicle_mapping_by_pk?: Maybe<Vehicle_Mapping>;
  /** perform the action: "vehicle_route_details" */
  vehicle_route_details: VehicleRouteDetails;
  /** fetch data from the table: "vehicle_type" */
  vehicle_type: Array<Vehicle_Type>;
  /** fetch aggregated fields from the table: "vehicle_type" */
  vehicle_type_aggregate: Vehicle_Type_Aggregate;
  /** fetch data from the table: "vehicle_type" using primary key columns */
  vehicle_type_by_pk?: Maybe<Vehicle_Type>;
  /** perform the action: "vehicles_fuelling_summary" */
  vehicles_fuelling_summary: Array<VehicleFuellingSummary>;
  /** perform the action: "vehicles_max_speed" */
  vehicles_max_speed: VehiclesMaxSpeedOutput;
  /** perform the action: "vehicles_productivity" */
  vehicles_productivity: Array<VehicleProductivity>;
  /** perform the action: "vehicles_total_distance" */
  vehicles_total_distance: VehiclesTotalDistanceOutput;
  /** perform the action: "vehicles_total_time_stopped" */
  vehicles_total_time_stopped: VehiclesTotalTimeStoppedOutput;
  /** perform the action: "vehicles_total_time_stopped_with_ignition_on" */
  vehicles_total_time_stopped_with_ignition_on: VehiclesTotalTimeStoppedWithIgnitionOnOutput;
  /** perform the action: "vehicles_total_time_with_ignition_on" */
  vehicles_total_time_with_ignition_on: VehiclesTotalTimeWithIgnitionOnOutput;
};

/** subscription root */
export type Subscription_RootDriverArgs = {
  distinct_on?: InputMaybe<Array<Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Driver_Order_By>>;
  where?: InputMaybe<Driver_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootDriver_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Driver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Driver_Order_By>>;
  where?: InputMaybe<Driver_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootDriver_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootEquipmentArgs = {
  distinct_on?: InputMaybe<Array<Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equipment_Order_By>>;
  where?: InputMaybe<Equipment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootEquipment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Equipment_Order_By>>;
  where?: InputMaybe<Equipment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootEquipment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootFuellingArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootFuelling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootFuelling_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootGet_User_By_CredentialsArgs = {
  args: Get_User_By_Credentials_Args;
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Daily_Vehicle_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Order_By>>;
  where?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Daily_Vehicle_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Order_By>>;
  where?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Daily_Vehicle_Summary_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_DeviceArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Device_Order_By>>;
  where?: InputMaybe<Hermes_Device_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Device_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Event_TraccarArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Traccar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Traccar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Traccar_Order_By>>;
  where?: InputMaybe<Hermes_Event_Traccar_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Traccar_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Event_TypeArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Type_Order_By>>;
  where?: InputMaybe<Hermes_Event_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Event_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Event_Type_Order_By>>;
  where?: InputMaybe<Hermes_Event_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Event_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_GeofenceArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Geofence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Geofence_Order_By>>;
  where?: InputMaybe<Hermes_Geofence_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Geofence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Geofence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Geofence_Order_By>>;
  where?: InputMaybe<Hermes_Geofence_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Geofence_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Position_GadgetArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Position_Gadget_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Position_Gadget_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_WorkshiftArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Workshift_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Workshift_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHermes_Workshift_EventArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Event_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Event_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHermes_Workshift_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHorimeter_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHorimeter_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHorimeter_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootIntegrationArgs = {
  distinct_on?: InputMaybe<Array<Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Order_By>>;
  where?: InputMaybe<Integration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootIntegration_IntentArgs = {
  distinct_on?: InputMaybe<Array<Integration_Intent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Intent_Order_By>>;
  where?: InputMaybe<Integration_Intent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootIntegration_Intent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Intent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Intent_Order_By>>;
  where?: InputMaybe<Integration_Intent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootIntegration_Intent_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootIntegration_LogArgs = {
  distinct_on?: InputMaybe<Array<Integration_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Integration_Log_Order_By>>;
  where?: InputMaybe<Integration_Log_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootIntegration_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootMaintenance_Service_OrderArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Service_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Service_Order_Order_By>>;
  where?: InputMaybe<Maintenance_Service_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMaintenance_Service_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Service_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Service_Order_Order_By>>;
  where?: InputMaybe<Maintenance_Service_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMaintenance_Service_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootMaintenance_SummaryArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** subscription root */
export type Subscription_RootOdometer_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootOdometer_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootOdometer_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProvider_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootTankArgs = {
  distinct_on?: InputMaybe<Array<Tank_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tank_Order_By>>;
  where?: InputMaybe<Tank_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTank_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootVehicleArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootVehicle_Driver_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Driver_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Driver_History_Order_By>>;
  where?: InputMaybe<Vehicle_Driver_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_Driver_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Driver_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Driver_History_Order_By>>;
  where?: InputMaybe<Vehicle_Driver_History_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_Driver_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootVehicle_MappingArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Mapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Mapping_Order_By>>;
  where?: InputMaybe<Vehicle_Mapping_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_Mapping_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootVehicle_Route_DetailsArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleId: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootVehicle_TypeArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Type_Order_By>>;
  where?: InputMaybe<Vehicle_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootVehicle_Type_By_PkArgs = {
  slug_name: Scalars['String'];
};

/** subscription root */
export type Subscription_RootVehicles_Fuelling_SummaryArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** subscription root */
export type Subscription_RootVehicles_Max_SpeedArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** subscription root */
export type Subscription_RootVehicles_ProductivityArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** subscription root */
export type Subscription_RootVehicles_Total_DistanceArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** subscription root */
export type Subscription_RootVehicles_Total_Time_StoppedArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** subscription root */
export type Subscription_RootVehicles_Total_Time_Stopped_With_Ignition_OnArgs =
  {
    endAt: Scalars['timestamptz'];
    startAt: Scalars['timestamptz'];
    vehicleIds: Array<Scalars['uuid']>;
  };

/** subscription root */
export type Subscription_RootVehicles_Total_Time_With_Ignition_OnArgs = {
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
  vehicleIds: Array<Scalars['uuid']>;
};

/** columns and relationships of "tank" */
export type Tank = {
  __typename?: 'tank';
  active: Scalars['Boolean'];
  attributes?: Maybe<Scalars['jsonb']>;
  capacity?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  initial_volume?: Maybe<Scalars['float8']>;
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  source: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "tank" */
export type TankAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "tank". All fields are combined with a logical 'AND'. */
export type Tank_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Tank_Bool_Exp>>>;
  _not?: InputMaybe<Tank_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Tank_Bool_Exp>>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  capacity?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  initial_volume?: InputMaybe<Float8_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** ordering options when selecting data from "tank" */
export type Tank_Order_By = {
  active?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  capacity?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_volume?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "tank" */
export type Tank_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tank" */
export enum Tank_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  InitialVolume = 'initial_volume',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  cpf?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  organization_id: Scalars['uuid'];
  settings: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workshifts: Array<Hermes_Workshift>;
  /** An aggregated array relationship */
  workshifts_aggregate: Hermes_Workshift_Aggregate;
};

/** columns and relationships of "user" */
export type UserSettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "user" */
export type UserWorkshiftsArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserWorkshifts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  settings?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Bool_Exp>>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Bool_Exp>>>;
  cpf?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  settings?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workshifts?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserCpfKey = 'user_cpf_key',
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserEmailTokenKey = 'user_email_token_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint */
  UserRecoveryPasswordHashKey = 'user_recovery_password_hash_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  settings?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  settings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  settings?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  settings?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workshifts_aggregate?: InputMaybe<Hermes_Workshift_Aggregate_Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  settings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  settings?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Settings = 'settings',
}

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vehicle" */
export type Vehicle = {
  __typename?: 'vehicle';
  autonomy?: Maybe<Scalars['float8']>;
  avatar_type?: Maybe<Vehicle_Avatar_Type_Enum>;
  brand?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  daily_vehicle_summaries: Array<Hermes_Daily_Vehicle_Summary>;
  /** An aggregated array relationship */
  daily_vehicle_summaries_aggregate: Hermes_Daily_Vehicle_Summary_Aggregate;
  /** An array relationship */
  devices: Array<Hermes_Device>;
  display_name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  /** An object relationship */
  driver?: Maybe<Driver>;
  driver_id?: Maybe<Scalars['uuid']>;
  external_id?: Maybe<Scalars['String']>;
  fuel_type?: Maybe<Vehicle_Fuel_Type_Enum>;
  /** An array relationship */
  fuellings: Array<Fuelling>;
  /** An aggregated array relationship */
  fuellings_aggregate: Fuelling_Aggregate;
  /** An array relationship */
  horimeter_histories: Array<Horimeter_History>;
  /** An aggregated array relationship */
  horimeter_histories_aggregate: Horimeter_History_Aggregate;
  /** An object relationship */
  horimeter_history?: Maybe<Horimeter_History>;
  id: Scalars['uuid'];
  last_driver_token?: Maybe<Scalars['String']>;
  last_horimeter_history_id?: Maybe<Scalars['uuid']>;
  last_odometer_history_id?: Maybe<Scalars['uuid']>;
  max_speed: Scalars['Int'];
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed: Scalars['float8'];
  minimal_parking_duration?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  /** An array relationship */
  odometer_histories: Array<Odometer_History>;
  /** An aggregated array relationship */
  odometer_histories_aggregate: Odometer_History_Aggregate;
  /** An object relationship */
  odometer_history?: Maybe<Odometer_History>;
  organization_id: Scalars['uuid'];
  plate: Scalars['String'];
  /** An array relationship */
  position_gadgets: Array<Hermes_Position_Gadget>;
  /** An aggregated array relationship */
  position_gadgets_aggregate: Hermes_Position_Gadget_Aggregate;
  price?: Maybe<Scalars['float8']>;
  source: Scalars['String'];
  type: Vehicle_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle_type: Vehicle_Type;
  /** An array relationship */
  workshifts: Array<Hermes_Workshift>;
  /** An aggregated array relationship */
  workshifts_aggregate: Hermes_Workshift_Aggregate;
  year?: Maybe<Scalars['String']>;
};

/** columns and relationships of "vehicle" */
export type VehicleDaily_Vehicle_SummariesArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Order_By>>;
  where?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleDaily_Vehicle_Summaries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Daily_Vehicle_Summary_Order_By>>;
  where?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleDevicesArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Device_Order_By>>;
  where?: InputMaybe<Hermes_Device_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleFuellingsArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleFuellings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fuelling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fuelling_Order_By>>;
  where?: InputMaybe<Fuelling_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleHorimeter_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleHorimeter_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Horimeter_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Horimeter_History_Order_By>>;
  where?: InputMaybe<Horimeter_History_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleOdometer_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleOdometer_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Odometer_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Odometer_History_Order_By>>;
  where?: InputMaybe<Odometer_History_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehiclePosition_GadgetsArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehiclePosition_Gadgets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Position_Gadget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Position_Gadget_Order_By>>;
  where?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleWorkshiftsArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** columns and relationships of "vehicle" */
export type VehicleWorkshifts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hermes_Workshift_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hermes_Workshift_Order_By>>;
  where?: InputMaybe<Hermes_Workshift_Bool_Exp>;
};

/** aggregated selection of "vehicle" */
export type Vehicle_Aggregate = {
  __typename?: 'vehicle_aggregate';
  aggregate?: Maybe<Vehicle_Aggregate_Fields>;
  nodes: Array<Vehicle>;
};

/** aggregate fields of "vehicle" */
export type Vehicle_Aggregate_Fields = {
  __typename?: 'vehicle_aggregate_fields';
  avg?: Maybe<Vehicle_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Vehicle_Max_Fields>;
  min?: Maybe<Vehicle_Min_Fields>;
  stddev?: Maybe<Vehicle_Stddev_Fields>;
  stddev_pop?: Maybe<Vehicle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vehicle_Stddev_Samp_Fields>;
  sum?: Maybe<Vehicle_Sum_Fields>;
  var_pop?: Maybe<Vehicle_Var_Pop_Fields>;
  var_samp?: Maybe<Vehicle_Var_Samp_Fields>;
  variance?: Maybe<Vehicle_Variance_Fields>;
};

/** aggregate fields of "vehicle" */
export type Vehicle_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle" */
export type Vehicle_Aggregate_Order_By = {
  avg?: InputMaybe<Vehicle_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Max_Order_By>;
  min?: InputMaybe<Vehicle_Min_Order_By>;
  stddev?: InputMaybe<Vehicle_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vehicle_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vehicle_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vehicle_Sum_Order_By>;
  var_pop?: InputMaybe<Vehicle_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vehicle_Var_Samp_Order_By>;
  variance?: InputMaybe<Vehicle_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vehicle" */
export type Vehicle_Arr_Rel_Insert_Input = {
  data: Array<Vehicle_Insert_Input>;
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};

export enum Vehicle_Avatar_Type_Enum {
  /** Ônibus */
  Bus = 'bus',
  /** Carro */
  Car = 'car',
  /** Equipamento */
  Equipment = 'equipment',
  /** Caminhão de Lixo */
  GarbageCollector = 'garbage_collector',
  /** Moto */
  Motorcycle = 'motorcycle',
  /** Implemento */
  Trailer = 'trailer',
  /** Caminhão */
  Truck = 'truck',
}

/** expression to compare columns of type vehicle_avatar_type_enum. All fields are combined with logical 'AND'. */
export type Vehicle_Avatar_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vehicle_Avatar_Type_Enum>;
  _in?: InputMaybe<Array<Vehicle_Avatar_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Vehicle_Avatar_Type_Enum>;
  _nin?: InputMaybe<Array<Vehicle_Avatar_Type_Enum>>;
};

/** aggregate avg on columns */
export type Vehicle_Avg_Fields = {
  __typename?: 'vehicle_avg_fields';
  autonomy?: Maybe<Scalars['Float']>;
  max_speed?: Maybe<Scalars['Float']>;
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
  minimal_parking_duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vehicle" */
export type Vehicle_Avg_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle". All fields are combined with a logical 'AND'. */
export type Vehicle_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Vehicle_Bool_Exp>>>;
  _not?: InputMaybe<Vehicle_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Vehicle_Bool_Exp>>>;
  autonomy?: InputMaybe<Float8_Comparison_Exp>;
  avatar_type?: InputMaybe<Vehicle_Avatar_Type_Enum_Comparison_Exp>;
  brand?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  daily_vehicle_summaries?: InputMaybe<Hermes_Daily_Vehicle_Summary_Bool_Exp>;
  devices?: InputMaybe<Hermes_Device_Bool_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  document?: InputMaybe<String_Comparison_Exp>;
  driver?: InputMaybe<Driver_Bool_Exp>;
  driver_id?: InputMaybe<Uuid_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  fuel_type?: InputMaybe<Vehicle_Fuel_Type_Enum_Comparison_Exp>;
  fuellings?: InputMaybe<Fuelling_Bool_Exp>;
  horimeter_histories?: InputMaybe<Horimeter_History_Bool_Exp>;
  horimeter_history?: InputMaybe<Horimeter_History_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_driver_token?: InputMaybe<String_Comparison_Exp>;
  last_horimeter_history_id?: InputMaybe<Uuid_Comparison_Exp>;
  last_odometer_history_id?: InputMaybe<Uuid_Comparison_Exp>;
  max_speed?: InputMaybe<Int_Comparison_Exp>;
  maximum_volume?: InputMaybe<Int_Comparison_Exp>;
  maximum_weight?: InputMaybe<Int_Comparison_Exp>;
  min_speed?: InputMaybe<Float8_Comparison_Exp>;
  minimal_parking_duration?: InputMaybe<Int_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
  observation?: InputMaybe<String_Comparison_Exp>;
  odometer_histories?: InputMaybe<Odometer_History_Bool_Exp>;
  odometer_history?: InputMaybe<Odometer_History_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  plate?: InputMaybe<String_Comparison_Exp>;
  position_gadgets?: InputMaybe<Hermes_Position_Gadget_Bool_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Vehicle_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle_type?: InputMaybe<Vehicle_Type_Bool_Exp>;
  workshifts?: InputMaybe<Hermes_Workshift_Bool_Exp>;
  year?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vehicle" */
export enum Vehicle_Constraint {
  /** unique or primary key constraint */
  VehiclePkey = 'vehicle_pkey',
  /** unique or primary key constraint */
  VehiclePlateOrganizationIdKey = 'vehicle_plate_organization_id_key',
}

/** columns and relationships of "vehicle_driver_history" */
export type Vehicle_Driver_History = {
  __typename?: 'vehicle_driver_history';
  assigned_at: Scalars['timestamptz'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  driver: Driver;
  driver_id: Scalars['uuid'];
  id: Scalars['uuid'];
  source: Scalars['String'];
  unassigned_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vehicle: Vehicle;
  vehicle_id: Scalars['uuid'];
};

/** aggregated selection of "vehicle_driver_history" */
export type Vehicle_Driver_History_Aggregate = {
  __typename?: 'vehicle_driver_history_aggregate';
  aggregate?: Maybe<Vehicle_Driver_History_Aggregate_Fields>;
  nodes: Array<Vehicle_Driver_History>;
};

/** aggregate fields of "vehicle_driver_history" */
export type Vehicle_Driver_History_Aggregate_Fields = {
  __typename?: 'vehicle_driver_history_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Vehicle_Driver_History_Max_Fields>;
  min?: Maybe<Vehicle_Driver_History_Min_Fields>;
};

/** aggregate fields of "vehicle_driver_history" */
export type Vehicle_Driver_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Driver_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_driver_history" */
export type Vehicle_Driver_History_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Driver_History_Max_Order_By>;
  min?: InputMaybe<Vehicle_Driver_History_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_driver_history". All fields are combined with a logical 'AND'. */
export type Vehicle_Driver_History_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Vehicle_Driver_History_Bool_Exp>>>;
  _not?: InputMaybe<Vehicle_Driver_History_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Vehicle_Driver_History_Bool_Exp>>>;
  assigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  driver?: InputMaybe<Driver_Bool_Exp>;
  driver_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  unassigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vehicle_Driver_History_Max_Fields = {
  __typename?: 'vehicle_driver_history_max_fields';
  assigned_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  unassigned_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "vehicle_driver_history" */
export type Vehicle_Driver_History_Max_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  driver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  unassigned_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Driver_History_Min_Fields = {
  __typename?: 'vehicle_driver_history_min_fields';
  assigned_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  unassigned_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "vehicle_driver_history" */
export type Vehicle_Driver_History_Min_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  driver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  unassigned_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "vehicle_driver_history" */
export type Vehicle_Driver_History_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  driver?: InputMaybe<Driver_Order_By>;
  driver_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  unassigned_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "vehicle_driver_history" */
export type Vehicle_Driver_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vehicle_driver_history" */
export enum Vehicle_Driver_History_Select_Column {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  UnassignedAt = 'unassigned_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleId = 'vehicle_id',
}

export enum Vehicle_Fuel_Type_Enum {
  /** ARLA 32 */
  Arla_32 = 'arla_32',
  /** Diesel */
  Diesel = 'diesel',
  /** Álcool */
  Ethanol = 'ethanol',
  /** Gasolina */
  Gasoline = 'gasoline',
  /** GNV */
  Gnv = 'gnv',
  /** Querosene */
  Kerosene = 'kerosene',
  /** Outro */
  Other = 'other',
}

/** expression to compare columns of type vehicle_fuel_type_enum. All fields are combined with logical 'AND'. */
export type Vehicle_Fuel_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vehicle_Fuel_Type_Enum>;
  _in?: InputMaybe<Array<Vehicle_Fuel_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Vehicle_Fuel_Type_Enum>;
  _nin?: InputMaybe<Array<Vehicle_Fuel_Type_Enum>>;
};

/** input type for incrementing integer column in table "vehicle" */
export type Vehicle_Inc_Input = {
  maximum_volume?: InputMaybe<Scalars['Int']>;
  maximum_weight?: InputMaybe<Scalars['Int']>;
  min_speed?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "vehicle" */
export type Vehicle_Insert_Input = {
  brand?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Driver_Obj_Rel_Insert_Input>;
  driver_id?: InputMaybe<Scalars['uuid']>;
  horimeter_histories?: InputMaybe<Horimeter_History_Arr_Rel_Insert_Input>;
  horimeter_history?: InputMaybe<Horimeter_History_Obj_Rel_Insert_Input>;
  maximum_volume?: InputMaybe<Scalars['Int']>;
  maximum_weight?: InputMaybe<Scalars['Int']>;
  min_speed?: InputMaybe<Scalars['float8']>;
  model?: InputMaybe<Scalars['String']>;
  odometer_histories?: InputMaybe<Odometer_History_Arr_Rel_Insert_Input>;
  odometer_history?: InputMaybe<Odometer_History_Obj_Rel_Insert_Input>;
  plate?: InputMaybe<Scalars['String']>;
  position_gadgets?: InputMaybe<Hermes_Position_Gadget_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Vehicle_Type_Enum>;
  workshifts?: InputMaybe<Hermes_Workshift_Arr_Rel_Insert_Input>;
  year?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "vehicle_mapping" */
export type Vehicle_Mapping = {
  __typename?: 'vehicle_mapping';
  attributes?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  disabled_at?: Maybe<Scalars['timestamptz']>;
  external_id: Scalars['String'];
  id: Scalars['uuid'];
  integration_id: Scalars['uuid'];
  name: Scalars['String'];
  organization_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  valid: Scalars['Boolean'];
  /** An object relationship */
  vehicle?: Maybe<Vehicle>;
  vehicle_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "vehicle_mapping" */
export type Vehicle_MappingAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "vehicle_mapping". All fields are combined with a logical 'AND'. */
export type Vehicle_Mapping_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Vehicle_Mapping_Bool_Exp>>>;
  _not?: InputMaybe<Vehicle_Mapping_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Vehicle_Mapping_Bool_Exp>>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disabled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid?: InputMaybe<Boolean_Comparison_Exp>;
  vehicle?: InputMaybe<Vehicle_Bool_Exp>;
  vehicle_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** ordering options when selecting data from "vehicle_mapping" */
export type Vehicle_Mapping_Order_By = {
  attributes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disabled_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid?: InputMaybe<Order_By>;
  vehicle?: InputMaybe<Vehicle_Order_By>;
  vehicle_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "vehicle_mapping" */
export type Vehicle_Mapping_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vehicle_mapping" */
export enum Vehicle_Mapping_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Valid = 'valid',
  /** column name */
  VehicleId = 'vehicle_id',
}

/** aggregate max on columns */
export type Vehicle_Max_Fields = {
  __typename?: 'vehicle_max_fields';
  autonomy?: Maybe<Scalars['float8']>;
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['uuid']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_driver_token?: Maybe<Scalars['String']>;
  last_horimeter_history_id?: Maybe<Scalars['uuid']>;
  last_odometer_history_id?: Maybe<Scalars['uuid']>;
  max_speed?: Maybe<Scalars['Int']>;
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
  minimal_parking_duration?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  plate?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vehicle" */
export type Vehicle_Max_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  driver_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_driver_token?: InputMaybe<Order_By>;
  last_horimeter_history_id?: InputMaybe<Order_By>;
  last_odometer_history_id?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  plate?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Min_Fields = {
  __typename?: 'vehicle_min_fields';
  autonomy?: Maybe<Scalars['float8']>;
  brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['uuid']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_driver_token?: Maybe<Scalars['String']>;
  last_horimeter_history_id?: Maybe<Scalars['uuid']>;
  last_odometer_history_id?: Maybe<Scalars['uuid']>;
  max_speed?: Maybe<Scalars['Int']>;
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
  minimal_parking_duration?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['uuid']>;
  plate?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vehicle" */
export type Vehicle_Min_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  driver_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_driver_token?: InputMaybe<Order_By>;
  last_horimeter_history_id?: InputMaybe<Order_By>;
  last_odometer_history_id?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  plate?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vehicle" */
export type Vehicle_Mutation_Response = {
  __typename?: 'vehicle_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Vehicle>;
};

/** input type for inserting object relation for remote table "vehicle" */
export type Vehicle_Obj_Rel_Insert_Input = {
  data: Vehicle_Insert_Input;
  on_conflict?: InputMaybe<Vehicle_On_Conflict>;
};

/** on conflict condition type for table "vehicle" */
export type Vehicle_On_Conflict = {
  constraint: Vehicle_Constraint;
  update_columns: Array<Vehicle_Update_Column>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** ordering options when selecting data from "vehicle" */
export type Vehicle_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  avatar_type?: InputMaybe<Order_By>;
  brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  daily_vehicle_summaries_aggregate?: InputMaybe<Hermes_Daily_Vehicle_Summary_Aggregate_Order_By>;
  display_name?: InputMaybe<Order_By>;
  document?: InputMaybe<Order_By>;
  driver?: InputMaybe<Driver_Order_By>;
  driver_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fuel_type?: InputMaybe<Order_By>;
  fuellings_aggregate?: InputMaybe<Fuelling_Aggregate_Order_By>;
  horimeter_histories_aggregate?: InputMaybe<Horimeter_History_Aggregate_Order_By>;
  horimeter_history?: InputMaybe<Horimeter_History_Order_By>;
  id?: InputMaybe<Order_By>;
  last_driver_token?: InputMaybe<Order_By>;
  last_horimeter_history_id?: InputMaybe<Order_By>;
  last_odometer_history_id?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  odometer_histories_aggregate?: InputMaybe<Odometer_History_Aggregate_Order_By>;
  odometer_history?: InputMaybe<Odometer_History_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  plate?: InputMaybe<Order_By>;
  position_gadgets_aggregate?: InputMaybe<Hermes_Position_Gadget_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicle_type?: InputMaybe<Vehicle_Type_Order_By>;
  workshifts_aggregate?: InputMaybe<Hermes_Workshift_Aggregate_Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "vehicle" */
export type Vehicle_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vehicle" */
export enum Vehicle_Select_Column {
  /** column name */
  Autonomy = 'autonomy',
  /** column name */
  AvatarType = 'avatar_type',
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Document = 'document',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  Id = 'id',
  /** column name */
  LastDriverToken = 'last_driver_token',
  /** column name */
  LastHorimeterHistoryId = 'last_horimeter_history_id',
  /** column name */
  LastOdometerHistoryId = 'last_odometer_history_id',
  /** column name */
  MaxSpeed = 'max_speed',
  /** column name */
  MaximumVolume = 'maximum_volume',
  /** column name */
  MaximumWeight = 'maximum_weight',
  /** column name */
  MinSpeed = 'min_speed',
  /** column name */
  MinimalParkingDuration = 'minimal_parking_duration',
  /** column name */
  Model = 'model',
  /** column name */
  Observation = 'observation',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Plate = 'plate',
  /** column name */
  Price = 'price',
  /** column name */
  Source = 'source',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "vehicle" */
export type Vehicle_Set_Input = {
  brand?: InputMaybe<Scalars['String']>;
  driver_id?: InputMaybe<Scalars['uuid']>;
  last_horimeter_history_id?: InputMaybe<Scalars['uuid']>;
  last_odometer_history_id?: InputMaybe<Scalars['uuid']>;
  maximum_volume?: InputMaybe<Scalars['Int']>;
  maximum_weight?: InputMaybe<Scalars['Int']>;
  min_speed?: InputMaybe<Scalars['float8']>;
  model?: InputMaybe<Scalars['String']>;
  plate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Vehicle_Type_Enum>;
  year?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Vehicle_Stddev_Fields = {
  __typename?: 'vehicle_stddev_fields';
  autonomy?: Maybe<Scalars['Float']>;
  max_speed?: Maybe<Scalars['Float']>;
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
  minimal_parking_duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vehicle" */
export type Vehicle_Stddev_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vehicle_Stddev_Pop_Fields = {
  __typename?: 'vehicle_stddev_pop_fields';
  autonomy?: Maybe<Scalars['Float']>;
  max_speed?: Maybe<Scalars['Float']>;
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
  minimal_parking_duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vehicle" */
export type Vehicle_Stddev_Pop_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vehicle_Stddev_Samp_Fields = {
  __typename?: 'vehicle_stddev_samp_fields';
  autonomy?: Maybe<Scalars['Float']>;
  max_speed?: Maybe<Scalars['Float']>;
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
  minimal_parking_duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vehicle" */
export type Vehicle_Stddev_Samp_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Vehicle_Sum_Fields = {
  __typename?: 'vehicle_sum_fields';
  autonomy?: Maybe<Scalars['float8']>;
  max_speed?: Maybe<Scalars['Int']>;
  maximum_volume?: Maybe<Scalars['Int']>;
  maximum_weight?: Maybe<Scalars['Int']>;
  min_speed?: Maybe<Scalars['float8']>;
  minimal_parking_duration?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "vehicle" */
export type Vehicle_Sum_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** columns and relationships of "vehicle_type" */
export type Vehicle_Type = {
  __typename?: 'vehicle_type';
  name: Scalars['String'];
  slug_name: Scalars['String'];
  /** An array relationship */
  vehicles: Array<Vehicle>;
  /** An aggregated array relationship */
  vehicles_aggregate: Vehicle_Aggregate;
};

/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehiclesArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** columns and relationships of "vehicle_type" */
export type Vehicle_TypeVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vehicle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vehicle_Order_By>>;
  where?: InputMaybe<Vehicle_Bool_Exp>;
};

/** aggregated selection of "vehicle_type" */
export type Vehicle_Type_Aggregate = {
  __typename?: 'vehicle_type_aggregate';
  aggregate?: Maybe<Vehicle_Type_Aggregate_Fields>;
  nodes: Array<Vehicle_Type>;
};

/** aggregate fields of "vehicle_type" */
export type Vehicle_Type_Aggregate_Fields = {
  __typename?: 'vehicle_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Vehicle_Type_Max_Fields>;
  min?: Maybe<Vehicle_Type_Min_Fields>;
};

/** aggregate fields of "vehicle_type" */
export type Vehicle_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vehicle_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vehicle_type" */
export type Vehicle_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vehicle_Type_Max_Order_By>;
  min?: InputMaybe<Vehicle_Type_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vehicle_type". All fields are combined with a logical 'AND'. */
export type Vehicle_Type_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Vehicle_Type_Bool_Exp>>>;
  _not?: InputMaybe<Vehicle_Type_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Vehicle_Type_Bool_Exp>>>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug_name?: InputMaybe<String_Comparison_Exp>;
  vehicles?: InputMaybe<Vehicle_Bool_Exp>;
};

export enum Vehicle_Type_Enum {
  /** Ônibus */
  Bus = 'bus',
  /** Carro */
  Car = 'car',
  /** Equipamento */
  Equipment = 'equipment',
  /** Caminhão de Lixo */
  GarbageCollector = 'garbage_collector',
  /** Moto */
  Motorcycle = 'motorcycle',
  /** Implemento */
  Trailer = 'trailer',
  /** Caminhão */
  Truck = 'truck',
}

/** expression to compare columns of type vehicle_type_enum. All fields are combined with logical 'AND'. */
export type Vehicle_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vehicle_Type_Enum>;
  _in?: InputMaybe<Array<Vehicle_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Vehicle_Type_Enum>;
  _nin?: InputMaybe<Array<Vehicle_Type_Enum>>;
};

/** aggregate max on columns */
export type Vehicle_Type_Max_Fields = {
  __typename?: 'vehicle_type_max_fields';
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vehicle_type" */
export type Vehicle_Type_Max_Order_By = {
  name?: InputMaybe<Order_By>;
  slug_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vehicle_Type_Min_Fields = {
  __typename?: 'vehicle_type_min_fields';
  name?: Maybe<Scalars['String']>;
  slug_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vehicle_type" */
export type Vehicle_Type_Min_Order_By = {
  name?: InputMaybe<Order_By>;
  slug_name?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "vehicle_type" */
export type Vehicle_Type_Order_By = {
  name?: InputMaybe<Order_By>;
  slug_name?: InputMaybe<Order_By>;
  vehicles_aggregate?: InputMaybe<Vehicle_Aggregate_Order_By>;
};

/** primary key columns input for table: "vehicle_type" */
export type Vehicle_Type_Pk_Columns_Input = {
  slug_name: Scalars['String'];
};

/** select columns of table "vehicle_type" */
export enum Vehicle_Type_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  SlugName = 'slug_name',
}

/** update columns of table "vehicle" */
export enum Vehicle_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  LastHorimeterHistoryId = 'last_horimeter_history_id',
  /** column name */
  LastOdometerHistoryId = 'last_odometer_history_id',
  /** column name */
  MaximumVolume = 'maximum_volume',
  /** column name */
  MaximumWeight = 'maximum_weight',
  /** column name */
  MinSpeed = 'min_speed',
  /** column name */
  Model = 'model',
  /** column name */
  Plate = 'plate',
  /** column name */
  Type = 'type',
  /** column name */
  Year = 'year',
}

/** aggregate var_pop on columns */
export type Vehicle_Var_Pop_Fields = {
  __typename?: 'vehicle_var_pop_fields';
  autonomy?: Maybe<Scalars['Float']>;
  max_speed?: Maybe<Scalars['Float']>;
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
  minimal_parking_duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vehicle" */
export type Vehicle_Var_Pop_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vehicle_Var_Samp_Fields = {
  __typename?: 'vehicle_var_samp_fields';
  autonomy?: Maybe<Scalars['Float']>;
  max_speed?: Maybe<Scalars['Float']>;
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
  minimal_parking_duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vehicle" */
export type Vehicle_Var_Samp_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vehicle_Variance_Fields = {
  __typename?: 'vehicle_variance_fields';
  autonomy?: Maybe<Scalars['Float']>;
  max_speed?: Maybe<Scalars['Float']>;
  maximum_volume?: Maybe<Scalars['Float']>;
  maximum_weight?: Maybe<Scalars['Float']>;
  min_speed?: Maybe<Scalars['Float']>;
  minimal_parking_duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vehicle" */
export type Vehicle_Variance_Order_By = {
  autonomy?: InputMaybe<Order_By>;
  max_speed?: InputMaybe<Order_By>;
  maximum_volume?: InputMaybe<Order_By>;
  maximum_weight?: InputMaybe<Order_By>;
  min_speed?: InputMaybe<Order_By>;
  minimal_parking_duration?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

export type GetMaintenanceSummaryQueryVariables = Exact<{
  vehicleIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  endAt: Scalars['timestamptz'];
}>;

export type GetMaintenanceSummaryQuery = {
  __typename?: 'query_root';
  maintenance_summary: Array<{
    __typename?: 'MaintenanceSummary';
    vehicle_id?: any | null | undefined;
    maintenances?: number | null | undefined;
    income?: number | null | undefined;
    expense?: number | null | undefined;
    total_cost?: number | null | undefined;
    average_cost?: number | null | undefined;
    maximum_cost?: number | null | undefined;
    minimum_cost?: number | null | undefined;
  }>;
};

export type GetVehiclesFuellingSummaryQueryVariables = Exact<{
  vehicleIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  endAt: Scalars['timestamptz'];
}>;

export type GetVehiclesFuellingSummaryQuery = {
  __typename?: 'query_root';
  vehicles_fuelling_summary: Array<{
    __typename?: 'VehicleFuellingSummary';
    vehicle_id: any;
    fuellings: number;
    amount?: number | null | undefined;
    total_cost?: number | null | undefined;
    maximum_cost?: number | null | undefined;
    minimum_cost?: number | null | undefined;
    average_cost?: number | null | undefined;
    maximum_unit_cost?: number | null | undefined;
    minimum_unit_cost?: number | null | undefined;
    average_unit_cost?: number | null | undefined;
    fuel_used_from_can?: number | null | undefined;
    odometer_autonomy_from_can?: number | null | undefined;
    horimeter_autonomy_from_can?: number | null | undefined;
    maximum_autonomy_from_odometer?: number | null | undefined;
    minimum_autonomy_from_odometer?: number | null | undefined;
    average_autonomy_from_odometer?: number | null | undefined;
    maximum_autonomy_from_horimeter?: number | null | undefined;
    minimum_autonomy_from_horimeter?: number | null | undefined;
    average_autonomy_from_horimeter?: number | null | undefined;
  }>;
};

export type GetVehiclesMaxSpeedQueryVariables = Exact<{
  vehicleIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  endAt: Scalars['timestamptz'];
}>;

export type GetVehiclesMaxSpeedQuery = {
  __typename?: 'query_root';
  vehicles_max_speed: { __typename?: 'VehiclesMaxSpeedOutput'; total: number };
};

export type GetVehiclesTotalDistanceQueryVariables = Exact<{
  vehicleIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  endAt: Scalars['timestamptz'];
}>;

export type GetVehiclesTotalDistanceQuery = {
  __typename?: 'query_root';
  vehicles_total_distance: {
    __typename?: 'VehiclesTotalDistanceOutput';
    total: number;
  };
};

export type GetVehiclesTotalTimeStoppedWithIgnitionOnQueryVariables = Exact<{
  vehicleIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  endAt: Scalars['timestamptz'];
}>;

export type GetVehiclesTotalTimeStoppedWithIgnitionOnQuery = {
  __typename?: 'query_root';
  vehicles_total_time_stopped_with_ignition_on: {
    __typename?: 'VehiclesTotalTimeStoppedWithIgnitionOnOutput';
    total: number;
  };
};

export type GetVehiclesTotalTimeStoppedQueryVariables = Exact<{
  vehicleIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  endAt: Scalars['timestamptz'];
}>;

export type GetVehiclesTotalTimeStoppedQuery = {
  __typename?: 'query_root';
  vehicles_total_time_stopped: {
    __typename?: 'VehiclesTotalTimeStoppedOutput';
    total: number;
  };
};

export type GetVehiclesTotalTimeWithIgnitionOnQueryVariables = Exact<{
  vehicleIds: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  endAt: Scalars['timestamptz'];
}>;

export type GetVehiclesTotalTimeWithIgnitionOnQuery = {
  __typename?: 'query_root';
  vehicles_total_time_with_ignition_on: {
    __typename?: 'VehiclesTotalTimeWithIgnitionOnOutput';
    total: number;
  };
};

export type IntegrationLogFragment = {
  __typename?: 'integration_log';
  id: any;
  type: string;
  code: string;
  title: string;
  description?: string | null | undefined;
  created_at: any;
  updated_at: any;
};

export type ListIntegrationLogQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ListIntegrationLogQuery = {
  __typename?: 'query_root';
  integration_log: Array<{
    __typename?: 'integration_log';
    id: any;
    type: string;
    code: string;
    title: string;
    description?: string | null | undefined;
    created_at: any;
    updated_at: any;
  }>;
};

export type AppendUserSettingsMutationVariables = Exact<{
  id: Scalars['uuid'];
  settings?: InputMaybe<Scalars['jsonb']>;
}>;

export type AppendUserSettingsMutation = {
  __typename?: 'mutation_root';
  update_user_by_pk?: { __typename?: 'user'; settings: any } | null | undefined;
};

export type VehicleFuellingSummaryFragment = {
  __typename?: 'VehicleFuellingSummary';
  vehicle_id: any;
  fuellings: number;
  amount?: number | null | undefined;
  total_cost?: number | null | undefined;
  maximum_cost?: number | null | undefined;
  minimum_cost?: number | null | undefined;
  average_cost?: number | null | undefined;
  maximum_unit_cost?: number | null | undefined;
  minimum_unit_cost?: number | null | undefined;
  average_unit_cost?: number | null | undefined;
  fuel_used_from_can?: number | null | undefined;
  odometer_autonomy_from_can?: number | null | undefined;
  horimeter_autonomy_from_can?: number | null | undefined;
  maximum_autonomy_from_odometer?: number | null | undefined;
  minimum_autonomy_from_odometer?: number | null | undefined;
  average_autonomy_from_odometer?: number | null | undefined;
  maximum_autonomy_from_horimeter?: number | null | undefined;
  minimum_autonomy_from_horimeter?: number | null | undefined;
  average_autonomy_from_horimeter?: number | null | undefined;
};

export type VehicleProductivityQueryVariables = Exact<{
  vehicle_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  endAt: Scalars['timestamptz'];
  startAt: Scalars['timestamptz'];
}>;

export type VehicleProductivityQuery = {
  __typename?: 'query_root';
  vehicles_productivity: Array<{
    __typename?: 'VehicleProductivity';
    journeys?: any | null | undefined;
    vehicle?:
      | {
          __typename?: 'vehicle';
          id: any;
          plate: string;
          model?: string | null | undefined;
          type: Vehicle_Type_Enum;
        }
      | null
      | undefined;
  }>;
};

export type VehicleFragment = {
  __typename?: 'vehicle';
  id: any;
  plate: string;
  model?: string | null | undefined;
  brand?: string | null | undefined;
  type: Vehicle_Type_Enum;
  year?: string | null | undefined;
  document?: string | null | undefined;
  observation?: string | null | undefined;
  price?: number | null | undefined;
  min_speed: number;
  created_at: any;
  updated_at: any;
  displayName?: string | null | undefined;
  fuelType?: Vehicle_Fuel_Type_Enum | null | undefined;
  avatarType?: Vehicle_Avatar_Type_Enum | null | undefined;
};

export const IntegrationLogFragmentDoc = gql`
  fragment IntegrationLog on integration_log {
    id
    type
    code
    title
    description
    created_at
    updated_at
  }
`;
export const VehicleFuellingSummaryFragmentDoc = gql`
  fragment VehicleFuellingSummary on VehicleFuellingSummary {
    vehicle_id
    fuellings
    amount
    total_cost
    maximum_cost
    minimum_cost
    average_cost
    maximum_unit_cost
    minimum_unit_cost
    average_unit_cost
    fuel_used_from_can
    odometer_autonomy_from_can
    horimeter_autonomy_from_can
    maximum_autonomy_from_odometer
    minimum_autonomy_from_odometer
    average_autonomy_from_odometer
    maximum_autonomy_from_horimeter
    minimum_autonomy_from_horimeter
    average_autonomy_from_horimeter
  }
`;
export const VehicleFragmentDoc = gql`
  fragment Vehicle on vehicle {
    id
    plate
    displayName: display_name
    model
    brand
    type
    year
    fuelType: fuel_type
    document
    observation
    avatarType: avatar_type
    price
    min_speed
    created_at
    updated_at
  }
`;
export const GetMaintenanceSummaryDocument = gql`
  query getMaintenanceSummary(
    $vehicleIds: [uuid!]!
    $startAt: timestamptz!
    $endAt: timestamptz!
  ) {
    maintenance_summary(
      vehicleIds: $vehicleIds
      startAt: $startAt
      endAt: $endAt
    ) {
      vehicle_id
      maintenances
      income
      expense
      total_cost
      average_cost
      maximum_cost
      minimum_cost
    }
  }
`;

export function useGetMaintenanceSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GetMaintenanceSummaryQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetMaintenanceSummaryQuery>({
    query: GetMaintenanceSummaryDocument,
    ...options,
  });
}
export const GetVehiclesFuellingSummaryDocument = gql`
  query getVehiclesFuellingSummary(
    $vehicleIds: [uuid!]!
    $startAt: timestamptz!
    $endAt: timestamptz!
  ) {
    vehicles_fuelling_summary(
      startAt: $startAt
      endAt: $endAt
      vehicleIds: $vehicleIds
    ) {
      ...VehicleFuellingSummary
    }
  }
  ${VehicleFuellingSummaryFragmentDoc}
`;

export function useGetVehiclesFuellingSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesFuellingSummaryQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesFuellingSummaryQuery>({
    query: GetVehiclesFuellingSummaryDocument,
    ...options,
  });
}
export const GetVehiclesMaxSpeedDocument = gql`
  query getVehiclesMaxSpeed(
    $vehicleIds: [uuid!]!
    $startAt: timestamptz!
    $endAt: timestamptz!
  ) {
    vehicles_max_speed(
      startAt: $startAt
      endAt: $endAt
      vehicleIds: $vehicleIds
    ) {
      total
    }
  }
`;

export function useGetVehiclesMaxSpeedQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesMaxSpeedQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesMaxSpeedQuery>({
    query: GetVehiclesMaxSpeedDocument,
    ...options,
  });
}
export const GetVehiclesTotalDistanceDocument = gql`
  query getVehiclesTotalDistance(
    $vehicleIds: [uuid!]!
    $startAt: timestamptz!
    $endAt: timestamptz!
  ) {
    vehicles_total_distance(
      startAt: $startAt
      endAt: $endAt
      vehicleIds: $vehicleIds
    ) {
      total
    }
  }
`;

export function useGetVehiclesTotalDistanceQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesTotalDistanceQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesTotalDistanceQuery>({
    query: GetVehiclesTotalDistanceDocument,
    ...options,
  });
}
export const GetVehiclesTotalTimeStoppedWithIgnitionOnDocument = gql`
  query getVehiclesTotalTimeStoppedWithIgnitionOn(
    $vehicleIds: [uuid!]!
    $startAt: timestamptz!
    $endAt: timestamptz!
  ) {
    vehicles_total_time_stopped_with_ignition_on(
      startAt: $startAt
      endAt: $endAt
      vehicleIds: $vehicleIds
    ) {
      total
    }
  }
`;

export function useGetVehiclesTotalTimeStoppedWithIgnitionOnQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesTotalTimeStoppedWithIgnitionOnQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesTotalTimeStoppedWithIgnitionOnQuery>({
    query: GetVehiclesTotalTimeStoppedWithIgnitionOnDocument,
    ...options,
  });
}
export const GetVehiclesTotalTimeStoppedDocument = gql`
  query getVehiclesTotalTimeStopped(
    $vehicleIds: [uuid!]!
    $startAt: timestamptz!
    $endAt: timestamptz!
  ) {
    vehicles_total_time_stopped(
      startAt: $startAt
      endAt: $endAt
      vehicleIds: $vehicleIds
    ) {
      total
    }
  }
`;

export function useGetVehiclesTotalTimeStoppedQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesTotalTimeStoppedQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesTotalTimeStoppedQuery>({
    query: GetVehiclesTotalTimeStoppedDocument,
    ...options,
  });
}
export const GetVehiclesTotalTimeWithIgnitionOnDocument = gql`
  query getVehiclesTotalTimeWithIgnitionOn(
    $vehicleIds: [uuid!]!
    $startAt: timestamptz!
    $endAt: timestamptz!
  ) {
    vehicles_total_time_with_ignition_on(
      startAt: $startAt
      endAt: $endAt
      vehicleIds: $vehicleIds
    ) {
      total
    }
  }
`;

export function useGetVehiclesTotalTimeWithIgnitionOnQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesTotalTimeWithIgnitionOnQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesTotalTimeWithIgnitionOnQuery>({
    query: GetVehiclesTotalTimeWithIgnitionOnDocument,
    ...options,
  });
}
export const ListIntegrationLogDocument = gql`
  query listIntegrationLog($id: uuid!, $limit: Int = 100) {
    integration_log(
      where: { integration_id: { _eq: $id } }
      limit: $limit
      order_by: { created_at: desc }
    ) {
      ...IntegrationLog
    }
  }
  ${IntegrationLogFragmentDoc}
`;

export function useListIntegrationLogQuery(
  options: Omit<
    Urql.UseQueryArgs<ListIntegrationLogQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<ListIntegrationLogQuery>({
    query: ListIntegrationLogDocument,
    ...options,
  });
}
export const AppendUserSettingsDocument = gql`
  mutation AppendUserSettings($id: uuid!, $settings: jsonb) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _append: { settings: $settings }
    ) {
      settings
    }
  }
`;

export function useAppendUserSettingsMutation() {
  return Urql.useMutation<
    AppendUserSettingsMutation,
    AppendUserSettingsMutationVariables
  >(AppendUserSettingsDocument);
}
export const VehicleProductivityDocument = gql`
  query vehicleProductivity(
    $vehicle_ids: [uuid!]!
    $endAt: timestamptz!
    $startAt: timestamptz!
  ) {
    vehicles_productivity(
      vehicleIds: $vehicle_ids
      endAt: $endAt
      startAt: $startAt
    ) {
      vehicle {
        id
        plate
        model
        type
      }
      journeys
    }
  }
`;

export function useVehicleProductivityQuery(
  options: Omit<
    Urql.UseQueryArgs<VehicleProductivityQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<VehicleProductivityQuery>({
    query: VehicleProductivityDocument,
    ...options,
  });
}
