import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import Button from '../../../../../../components/Button/Button';
import { DrawerType } from '../../../../types/TrafficInfractions.types';
import { useDrawerContext } from '../contexts/DrawerContext';
import { useTrafficInfractionTableContext } from '../contexts/TrafficInfractionTableContext';
import { Actions, PageActionsContainer, PageTitle } from './styles';

type RangeValue<Moment> = [Moment, Moment] | null;

function PageActions() {
  const { open } = useDrawerContext();
  const { setDateRange, dateRange } = useTrafficInfractionTableContext();

  function handleRangeChange(range: RangeValue<Moment | null>) {
    const [startAt, endAt] = range?.toString().split(',') ?? [];

    setDateRange({
      startAt: moment(startAt).startOf('month').toDate(),
      endAt: moment(endAt).endOf('month').toDate(),
    });
  }

  return (
    <PageActionsContainer>
      <PageTitle>Multas</PageTitle>
      <Actions>
        <DatePicker.RangePicker
          onChange={(e) => handleRangeChange(e)}
          format="MM/YYYY"
          picker="month"
          value={[moment(dateRange.startAt), moment(dateRange.endAt)]}
        />
        <Button onClick={() => open(DrawerType.add)}>Adicionar multa</Button>
      </Actions>
    </PageActionsContainer>
  );
}

export default PageActions;
