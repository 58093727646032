import { Menu as Dropdown, MenuItems } from '@reach/menu-button';
// import "@reach/menu-button/styles.css";
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Divider from '../../../components/Divider/Divider';
import Icon from '../../../components/Icon/Icon';
import AddUser from '../../../components/icons/AddUser';
import Chart from '../../../components/icons/Chart';
import Dashboard from '../../../components/icons/Dashboard';
import Drivers from '../../../components/icons/Drivers';
import ExternalLink from '../../../components/icons/ExternalLink';
import Itinerarios from '../../../components/icons/Itinerarios';
import LocationPin from '../../../components/icons/LocationPin';
import Maintenance from '../../../components/icons/Maintenance';
import Providers from '../../../components/icons/Providers';
import Settings from '../../../components/icons/Settings';
import Tank from '../../../components/icons/Tank';
import VehicleCar from '../../../components/icons/VehicleCar';
import { AppModuleEnum } from '../../../constants/app-modules-enum';
import { useCurrentUserContext } from '../../../contexts/CurrentUserContextProvider';
import { useLayoutContext } from '../../../contexts/LayoutContextProvider';
import { useUserTracker } from '../../../hooks/use-user-tracker';
import Container from './Container';
import MenuItem from './MenuItem';
import MenuItemExpand from './MenuItemExpand';
import {
  DropdownButton,
  DropdownItem,
  Link,
  MenuList,
  Popup,
  RecommendationLink,
} from './styles';

type Submenu = {
  label: string;
  path: string;
};

type BaseMenu = {
  label: string | React.ReactNode;
  icon: React.ReactNode;
  module?: AppModuleEnum;
  flag?: string;
  path?: string;
  menus?: Submenu[];
  isExternal?: boolean;
};

type MenuWithSubmenu = BaseMenu & {
  path: undefined;
  menus: Submenu[];
};

type Menu = BaseMenu | MenuWithSubmenu;

const menu: Menu[] = [
  {
    label: 'Indicadores',
    icon: <Dashboard />,
    module: AppModuleEnum.indicators,
    path: '/dashboard' || '/new-dashboard',
  },
  {
    label: 'Monitoramento',
    icon: <LocationPin />,
    module: AppModuleEnum.monitoring,
    path: '/',
  },
  {
    label: 'Integrações',
    icon: <Itinerarios />,
    path: '/integrations',
    module: AppModuleEnum.integrations,
  },
  {
    label: 'Veículos',
    icon: <VehicleCar />,
    module: AppModuleEnum.vehicles,
    path: '/vehicles',
  },
  {
    label: 'Motoristas',
    icon: <Drivers />,
    module: AppModuleEnum.drivers,
    path: '/drivers',
  },
  {
    label: 'Combustível',
    icon: <Tank />,
    module: AppModuleEnum.fuellings,
    menus: [
      { label: 'Abastecimentos', path: '/fuellings' },
      { label: 'Meus tanques', path: '/fuellings/tanks' },
    ],
  },
  {
    label: 'Manutenções',
    icon: <Maintenance />,
    module: AppModuleEnum.maintenances,
    menus: [
      { label: 'Histórico de serviços', path: '/maintenances/services' },
      { label: 'Lembretes', path: '/maintenances/reminders' },
    ],
  },
  {
    label: 'Fornecedores',
    icon: <Providers />,
    module: AppModuleEnum.providers,
    path: '/providers',
  },
  {
    label: 'Relatórios',
    icon: <Chart />,
    module: AppModuleEnum.reports,
    path: '/reports',
  },
  {
    label: 'Configurações',
    icon: <Settings />,
    module: AppModuleEnum.settings,
    path: '/settings',
  },
];

const secondMenu: Menu[] = [
  {
    label: (
      <RecommendationLink>
        Indicações
        <Icon $size="12px">
          <ExternalLink />
        </Icon>
      </RecommendationLink>
    ),
    icon: <AddUser />,
    path: 'https://lp.infleet.com.br/infleet-programa-de-indicacao-gestor-de-frotas/',
    isExternal: true,
  },
];

function Sidebar() {
  const {
    user: { modules },
  } = useCurrentUserContext();
  const { sidebarCollapsed } = useLayoutContext();

  const location = useLocation();

  const { emitEvent } = useUserTracker();

  const handleRecommendationClick = useCallback(() => {
    emitEvent({
      action: 'accessIndication',
      module: AppModuleEnum.others,
    });
  }, [emitEvent]);

  return (
    <Container className={sidebarCollapsed ? 'collapsed' : void 0 && 'active'}>
      <MenuList>
        {menu
          .filter(({ module }) => !module || modules.includes(module))
          .map(({ label, icon, path, flag, menus }, index) => {
            if (!menus) {
              return (
                <MenuItem
                  key={index}
                  label={label}
                  icon={icon}
                  href={path!}
                  flag={flag}
                />
              );
            }
            return (
              <div key={index}>
                {sidebarCollapsed ? (
                  <Dropdown>
                    <DropdownButton
                      $isActive={menus.some(
                        (menu) => menu.path === location.pathname,
                      )}
                    >
                      <Icon>{icon}</Icon>
                    </DropdownButton>
                    <Popup
                      portal={true}
                      position={(targetRect) => {
                        return {
                          top: (targetRect?.y ?? 0) - 8,
                          left: 72,
                          zIndex: 99,
                        };
                      }}
                    >
                      <MenuItems>
                        {menus.map(({ label, path }, index) => (
                          <DropdownItem
                            key={index}
                            to={path}
                            $isActive={location.pathname === path}
                          >
                            <li>{label}</li>
                          </DropdownItem>
                        ))}
                      </MenuItems>
                    </Popup>
                  </Dropdown>
                ) : (
                  <MenuItemExpand
                    key={index}
                    label={label}
                    icon={icon}
                    flag={flag}
                    isActive={menus.some(
                      (menu) => menu.path === location.pathname,
                    )}
                  >
                    {menus.map(({ label, path }, index) => (
                      <Link
                        key={index}
                        to={path}
                        $isActive={location.pathname === path}
                      >
                        {label}
                      </Link>
                    ))}
                  </MenuItemExpand>
                )}
              </div>
            );
          })}
      </MenuList>
      <Divider />
      <MenuList>
        {secondMenu.map(({ label, icon, path, flag, isExternal }, index) => (
          <MenuItem
            key={index}
            label={label}
            icon={icon}
            href={path!}
            flag={flag}
            isExternal={isExternal}
            event={handleRecommendationClick}
          />
        ))}
      </MenuList>
    </Container>
  );
}

export default Sidebar;
