import styled from 'styled-components';

type InputProps = {
  $isFocused?: boolean;
};

export const Input = styled.input<InputProps>`
  height: 100%;
  width: 100%;
  top: 18px;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 5px;
  padding: 0.5rem 1rem;
  outline: none;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.purple};
  }
`;
