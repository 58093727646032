import React, { lazy, Suspense, useMemo } from 'react';
import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import FullLoading from '../components/FullLoading/FullLoading';

const LoginPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './pages/Login'
    ),
);
const ResetPasswordPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './pages/ResetPassword'
    ),
);
const RecoveryPasswordPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      './pages/RecoveryPassword'
    ),
);

function AuthRoutes() {
  const routesDefinition: RouteObject[] = useMemo(() => {
    const routes: RouteObject[] = [
      {
        path: '/',
        element: <LoginPage />,
      },
      {
        path: '/password-reset',
        element: <ResetPasswordPage />,
      },
      {
        path: '/recovery-password',
        element: <RecoveryPasswordPage />,
      },
    ];

    return [...routes];
  }, []);

  const element = useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<FullLoading />}>
          <Outlet />
        </Suspense>
      ),
      children: routesDefinition,
    },
  ]);

  return <>{element}</>;
}

export default AuthRoutes;
