import React, { createContext, ReactNode, useContext } from 'react';
import { useAppendUserSettingsMutation } from '../generated/graphql';
import { useCurrentUserContext } from './CurrentUserContextProvider';

type Settings = Record<string, unknown> | Record<string, unknown>[];

interface SettingsContextValue {
  settings: Record<string, Settings>;
  saveSettings: (key: string, settings: Settings) => Promise<void>;
}

const SettingsContext = createContext<SettingsContextValue>({} as any);

type LayoutProviderProps = {
  children: ReactNode;
};

export default function SettingsContextProvider(props: LayoutProviderProps) {
  const { children } = props;
  const {
    user: { id: userId, settings: defaultSettings = {} },
  } = useCurrentUserContext();
  const [settings, setSettings] =
    React.useState<Record<string, Settings>>(defaultSettings);

  const [, mutation] = useAppendUserSettingsMutation();

  const saveSettings: SettingsContextValue['saveSettings'] = React.useCallback(
    async (key, settings) => {
      try {
        await mutation({
          id: userId,
          settings: {
            [key]: settings,
          },
        });

        setSettings((previousSettings) => ({
          ...(previousSettings ?? {}),
          [key]: settings,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    [userId, mutation],
  );

  const value = React.useMemo(
    () => ({
      settings,
      saveSettings,
    }),
    [settings, saveSettings],
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettingsContext() {
  const context = useContext(SettingsContext);

  if (typeof context === 'undefined') {
    throw new Error(`useSettingsContext must be used within a SettingsContext`);
  }

  return context;
}
