import React from 'react';

const unmaskValue = (maskedValue = ''): number | null => {
  if (maskedValue === ('' || undefined)) {
    return null;
  }
  return parseInt(maskedValue.replace(/\D/g, '') || '0', 10) / 100;
};

export type MaskedValueParams = {
  value?: number | string | null;
  formatter: (value: number | null) => string;
};

export default function useMaskedDecimalValue({
  value,
  formatter,
}: MaskedValueParams) {
  const sanitizedNumber = value === '' ? null : Number(value);
  const formattedValue = formatter(sanitizedNumber);

  const cutEndIfNeed = React.useCallback(
    (nextValue: string, prevValue: string): string => {
      if (nextValue.length > prevValue.length) {
        return nextValue;
      }

      const endPrev = prevValue.match(/\D{2}$/)?.[0];
      const endNext = nextValue.match(/\D{2}$/)?.[0];

      if (!isNaN(Number(endPrev)) || endPrev === endNext) {
        return nextValue;
      }

      const plainValue = unmaskValue(nextValue)?.toFixed(2) ?? '';

      const newValue = Number(plainValue.substring(0, plainValue.length - 1));

      return formatter(newValue / 10);
    },
    [formatter],
  );

  const unmask = React.useCallback(
    (value: string) => {
      return unmaskValue(cutEndIfNeed(value, formattedValue));
    },
    [cutEndIfNeed, formattedValue],
  );

  return {
    formattedValue,
    unmask,
  };
}
