import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {
  Container,
  LoadingContainer,
  TimePickerContainer,
  TimePickerWrapper,
} from './styles';

export type DatePickerProps = {
  placeholder?: string;
  value?: Date | null;
  onChange: (value: Date | null) => void;
  allowClear?: boolean;
  error?: string;
  label?: string;
  showNow?: boolean;
  format?: 'HH:mm' | 'HH:mm:ss';
  loading?: boolean;
  name?: string;
};

function TimePicker(props: DatePickerProps) {
  const {
    value,
    label,
    onChange,
    placeholder,
    error,
    format = 'HH:mm:ss',
    loading,
    showNow = false,
    allowClear = true,
    name,
  } = props;

  const handleChange = (date: moment.Moment | null) => {
    onChange(date ? date?.toDate() : date);
  };

  return (
    <Container>
      <TimePickerContainer>
        {loading ? (
          <LoadingContainer>
            <Skeleton height="100%" />
          </LoadingContainer>
        ) : (
          <>
            {label && <label htmlFor="time-picker">{label}</label>}
            <TimePickerWrapper
              value={value ? moment(value) : value}
              format={format}
              onChange={(value) => handleChange(value)}
              placeholder={placeholder}
              showSecond={false}
              allowClear={allowClear}
              showNow={showNow}
              name={name}
            />
          </>
        )}
      </TimePickerContainer>
      {error && <ErrorMessage message={error} />}
    </Container>
  );
}

export default TimePicker;
