import React, { useMemo } from 'react';
import { NavLink as Link, useLocation, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import VehicleCar from '../../../../../components/icons/VehicleCar';
import MenuItem from '../../../../../components/Menu/MenuItem';
import MenuItemExpand from '../../../../../components/Menu/MenuItemExpand';
import { Title } from './styles';

interface LinkProps {
  $isActive: boolean;
}

const Container = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding: 12px 16px;
  border: 1px solid #e7e7e8;
  border-radius: 5px;
  height: fit-content;
`;

const NavLink = styled(Link)<LinkProps>`
  font-size: 16px;
  line-height: 19px;

  display: grid;
  grid-template-columns: 100%;
  padding: 16px 52px;
  color: #434343;

  &:hover {
    color: ${(props) => props.theme.colors.purple};
  }

  ${({ $isActive }) =>
    $isActive
      ? css`
          font-weight: 500;
          color: ${(props) => props.theme.colors.primary};
        `
      : css`
          font-weight: 400;
          color: #434343;
        `}
`;

type Submenu = {
  label: string;
  path: string;
};

type BaseMenu = {
  label: string | React.ReactNode;
  icon: React.ReactNode;
  flag?: string;
  path?: string;
  menus?: Submenu[];
  isExternal?: boolean;
};

type MenuWithSubmenu = BaseMenu & {
  path: undefined;
  menus: Submenu[];
};

type MenuType = BaseMenu | MenuWithSubmenu;

function SettingsActions() {
  const params = useParams();
  const location = useLocation();

  const menu: MenuType[] = useMemo(() => {
    return [
      {
        label: 'Veículo',
        icon: <VehicleCar />,
        menus: [
          {
            label: 'Informação do veículo',
            path: `/vehicles/${params.vehicleId}/settings`,
          },
          {
            label: 'Configurações do veículo',
            path: `/vehicles/${params.vehicleId}/settings/vehicleSettings`,
          },
        ],
      },
      // {
      //   label: 'Notificações',
      //   icon: <Bell />,
      //   path: '/settings',
      //   flag: 'novo',
      // },
    ];
  }, [params.vehicleId]);

  return (
    <Container>
      <Title>Configurações</Title>
      {menu.map(({ label, icon, path, flag, menus }, index) => {
        if (!menus) {
          return (
            <MenuItem
              key={index}
              label={label}
              icon={icon}
              href={path!}
              flag={flag}
            />
          );
        }
        return (
          <MenuItemExpand key={index} label={label} icon={icon} flag={flag}>
            {menus.map(({ label, path }, index) => (
              <NavLink
                key={index}
                to={path}
                $isActive={location.pathname === path}
              >
                {label}
              </NavLink>
            ))}
          </MenuItemExpand>
        );
      })}
    </Container>
  );
}

export default SettingsActions;
