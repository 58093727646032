import { Table } from 'antd';
import { rgba } from 'polished';
import styled from 'styled-components';

export const CustomAntTable = styled(Table)`
  height: 100%;

  .ant-table-thead > tr > th {
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }

  thead > tr > th {
    background-color: white;
    color: #747678;
    padding-bottom: 8px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px;
    border-bottom: none;
  }

  .ant-table-placeholder:hover {
    background-color: #eef2f9;
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: #eef2f9;
  }

  .ant-table-cell > a {
    color: black;
  }

  tbody > tr:nth-child(2n + 1) {
    > td:first-child,
    > td:last-child {
      border-radius: 0 !important;
    }

    background: ${rgba('#f1f3f7', 0.39)};
  }

  tbody > tr {
    color: #434343;
    &:nth-child(2n + 1) {
      td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      td:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  // remove hover effect
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: none;
    border-color: rgba(0, 0, 0, 0.03);
  }

  .ant-pagination {
    padding: 0 19px;
  }
  .ant-pagination-prev > .ant-pagination-item-link,
  .ant-pagination-next > .ant-pagination-item-link {
    border: none;
    color: #677788;
  }

  .ant-pagination-item {
    color: #35384d;
    border-color: transparent;
    &.ant-pagination-item-active {
      background-color: ${(props) => props.theme.colors.purple};
      border-color: ${(props) => props.theme.colors.purple};
      a {
        color: #fff;
      }
    }
  }

  .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .ant-pagination.ant-table-pagination {
    position: sticky;
    bottom: 0;
    background: #fff;
    margin: 0;
    padding: 16px;
  }
`;

export const ButtonOptions = styled.button`
  position: absolute;
  top: 14px;
  right: 16px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  outline: 0;
  background: transparent;
  border: 0;
  opacity: 0.4;
  font-size: 1.625rem;
  color: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  height: 560px;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  flex-direction: column;
`;

export const EmptyText = styled.h3``;

export const EmptyMessage = styled.p``;
