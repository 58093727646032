import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../../../components/Button/Button';
import DateTimePicker from '../../../../../components/DateTimePicker/DateTimePicker';
import { ActionsContainer } from '../../../../../components/Drawer/styles';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import InputWithSuffix from '../../../../../components/InputWithSuffix/InputWithSuffix';
import SelectDriver from '../../../../../components/SelectDriver';
import TextArea from '../../../../../components/TextArea';
import { TrafficInfractionFragment } from '../../../../../generated/graphqlV2';
import { PayTrafficInfractionFormValues } from '../../../types/Forms.types';
import { useTrafficInfractionFormContext } from '../../Tabs/TrafficInfractionTab/contexts/TrafficInfractionFormContext';
import { payTrafficInfractionSchema } from '../formValidations';
import { Field, Form, FormContainer, Label, Row } from '../styles';

export type VehicleProfileFormProps = {
  onClose: () => void;
  data: TrafficInfractionFragment;
};

function PayTrafficInfraction(props: VehicleProfileFormProps) {
  const { onClose, data } = props;
  const { payTrafficInfractionHandler, loading } =
    useTrafficInfractionFormContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PayTrafficInfractionFormValues>({
    defaultValues: {
      paidAt: data?.paidAt ? moment(data?.paidAt) : moment(),
      driver: data?.driver || undefined,
      paidCost: data?.cost || undefined,
      observation: data?.observation || undefined,
      cost: data?.cost || undefined,
    },
    resolver: yupResolver(payTrafficInfractionSchema),
  });

  const onSubmit: SubmitHandler<PayTrafficInfractionFormValues> = (
    formData,
  ) => {
    const payload = {
      occurredAt: data.occurredAt,
      driver: formData.driver!.id,
      id: data!.id,
      paidAt: formData.paidAt,
      paidCost: formData.paidCost,
      observation: formData.observation,
    };

    payTrafficInfractionHandler(payload);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Field>
          <Controller
            name="paidAt"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateTimePicker
                label="Data do pagamento"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Field>
        <Row>
          <Field>
            <Controller
              name="driver"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <SelectDriver
                  label="Condutor"
                  name={name}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.driver && (
              <ErrorMessage message={(errors.driver as any).message} />
            )}
          </Field>
        </Row>
        <Row>
          <Field>
            <Label weight={500} style={{ marginBottom: '0.5rem' }}>
              Valor da multa
            </Label>
            <Controller
              name="cost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWithSuffix
                  suffix="R$"
                  onChange={onChange}
                  value={value}
                  disabled
                />
              )}
            />
          </Field>
          <Field>
            <Label weight={500} style={{ marginBottom: '0.5rem' }}>
              Valor pago
            </Label>
            <Controller
              name="paidCost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWithSuffix
                  suffix="R$"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.paidCost && (
              <ErrorMessage message={errors.paidCost.message} />
            )}
          </Field>
        </Row>
        <Row>
          <Field>
            <Label weight={500}>Observações</Label>
            <Controller
              name="observation"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <TextArea
                  label=""
                  name={name}
                  onChange={onChange}
                  css={{ minHeight: '250px' }}
                  value={value}
                />
              )}
            />
          </Field>
        </Row>

        {/* <UploadFiles {...register('referenceDocument')} name="files" setValue={setValue} /> */}
      </FormContainer>
      <ActionsContainer>
        <Button $fullWidth type="submit" disabled={loading}>
          {loading ? 'Salvando...' : 'Pagar'}
        </Button>
      </ActionsContainer>
    </Form>
  );
}

export default PayTrafficInfraction;
