import React from 'react';
import useThirdPartyServices from './hooks/use-third-party-services';
import Modules from './modules';

function App() {
  useThirdPartyServices();
  return <Modules />;
}

export default App;
