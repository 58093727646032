import * as React from 'react';
import { AuthType, useAuth } from '../../../auth/contexts/AuthContextProvider';
import MenuIcon from '../../../components/icons/Menu';
import { useLayoutContext } from '../../../contexts/LayoutContextProvider';
import Profile, { ProfileProps } from '../Profile';
import {
  Container,
  Flag,
  Left,
  LogoWrapper,
  ProfilerWrapper,
  Right,
  ToggleMenuButton,
} from './Header.styles';
import NotificationsButton from './NotificationsButton';

export interface HeaderProps extends ProfileProps {}

function Header(props: HeaderProps) {
  const { name, onLogout } = props;
  const { sidebarCollapsed, toggleSidebar } = useLayoutContext();
  const { loginType } = useAuth();

  return (
    <Container>
      <Left>
        <ToggleMenuButton type="button" onClick={toggleSidebar}>
          <MenuIcon variant={sidebarCollapsed ? 'open' : 'closed'} />
        </ToggleMenuButton>
        <LogoWrapper>
          <img
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt="Infleet - Monitoramento inteligente"
            width="88"
            height="27"
          />
        </LogoWrapper>
      </Left>
      <Right>
        <NotificationsButton />
        <ProfilerWrapper>
          <Profile name={name} onLogout={onLogout} />
        </ProfilerWrapper>
        {loginType === AuthType.Token && <Flag>ops</Flag>}
      </Right>
    </Container>
  );
}

export default Header;
