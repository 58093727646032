import React from 'react';
import styled from 'styled-components';
import Input from '../Input';
import useMaskedDecimalValue from './use-masked-decimal-value';

export type MaskedNumberInputProps = {
  label: string;
  className?: string;
  error?: string;
  value?: number | string | null;
  onChange: (value: number | null) => void;
  formatter: (value: number | null) => string;
  required?: boolean;
  name?: string;
};

const defaultFormatter = (value: number | null) => {
  if (value === null || typeof value === 'undefined' || isNaN(value)) {
    return '';
  }

  return value.toString();
};

function MaskedNumberInput(props: MaskedNumberInputProps) {
  const {
    label,
    error,
    value = null,
    formatter = defaultFormatter,
    onChange,
    name,
  } = props;

  const { formattedValue, unmask } = useMaskedDecimalValue({
    value,
    formatter,
  });

  const handleChange = (event: string | null) => {
    if (event === null) {
      onChange(null);
      return;
    }
    onChange(unmask(event));
  };

  return (
    <Input
      label={label}
      onChange={handleChange}
      value={formattedValue}
      error={error}
      type="phone"
      name={name}
    />
  );
}

export default styled(MaskedNumberInput)``;
