import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import CustomScrollbar from '../../../../components/CustomScrollbar/CustomScrollbar';
import Input from '../../../../components/Input';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import TimePicker from '../../../../components/TimePicker/TimePicker';
import { useCurrentUserContext } from '../../../../contexts/CurrentUserContextProvider';
import { cnpj_mask } from '../../../../helpers/cnpj-mask';
import { getHoursAndMinutesFromSeconds } from '../../../vehicleProfile/helpers/getHoursAndMinutesFromSeconds';
import { useOrganizationContex } from '../../contexts/OrganizationContextProvider';

import { ButtonContainer, Col, Form, FormContainer } from './styles';

const Info = styled.div`
  h3 {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #667085;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #98a2b3;
  }
`;

const Row = styled.section`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 16px;
`;

const vehicleSchema = yup.object().shape({
  startWork: yup.date().nullable(),
  endWork: yup.date().nullable(),
  workOnWeekends: yup.boolean().nullable(),
  maxSpeed: yup.number().nullable(),
  timeDisconnectionAlert: yup.date().nullable(),
  timeIddle: yup.date().nullable(),
});

const choices = [
  { id: 1, name: 'Sim' },
  { id: 2, name: 'Não' },
];

type FormValues = {
  organizationName: string;
  address?: string;
  cnpj?: string;
  phone?: string;
  startWorkAt: Date;
  endWorkAt: Date;
  workOnWeekends: boolean;
  maxSpeed: number;
  timeDisconnectionAlert: Date;
  timeStoppedWithIgnitionOn: Date;
};

function getSeconds(date: Date) {
  const hoursToSeconds = moment(date).get('hours') * 60 * 60;
  const minutesToSeconds = moment(date).get('minutes') * 60;

  return hoursToSeconds + minutesToSeconds;
}

function OrganizationInformations() {
  const { organization, fetchingUser: fetchingOrganization } =
    useCurrentUserContext();
  const { updateOrganizationLoading, updateOrganization } =
    useOrganizationContex();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(vehicleSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const {
      endWorkAt,
      startWorkAt,
      maxSpeed,
      timeDisconnectionAlert,
      timeStoppedWithIgnitionOn,
      workOnWeekends,
      address,
      organizationName,
      cnpj,
      phone,
    } = data;

    try {
      const formattedEndWorkAt = new Date(endWorkAt);
      const formattedStartWorkAt = new Date(startWorkAt);

      const payload = {
        id: organization.id,
        address,
        cnpj,
        name: organizationName,
        phone,
        settings: {
          endWorkAt: formattedEndWorkAt,
          startWorkAt: formattedStartWorkAt,
          maxSpeed,
          timeDisconnectionAlert: getSeconds(timeDisconnectionAlert),
          timeStoppedWithIgnitionOn: getSeconds(timeStoppedWithIgnitionOn),
          workOnWeekends,
        },
      };
      updateOrganization(payload);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!organization) return;

    const { name, address, phone, settings, cnpj } = organization;

    const [disconnectionHours, disconnectioMinutes] =
      settings?.timeDisconnectionAlert
        ? getHoursAndMinutesFromSeconds(settings?.timeDisconnectionAlert)
        : [1, 0];
    const [ignitionHours, ignitionMinutes] = settings?.timeDisconnectionAlert
      ? getHoursAndMinutesFromSeconds(settings?.timeStoppedWithIgnitionOn)
      : [0, 30];

    const startWorkAt =
      settings?.startWorkAt ?? new Date().setHours(8, 30, 0, 0);
    const endWorkAt = settings?.endWorkAt ?? new Date().setHours(17, 30, 0, 0);
    const workOnWeekends = settings?.workOnWeekends ?? true;
    const maxSpeed = settings?.maxSpeed;
    const timeDisconnectionAlert = moment()
      .set({
        hour: disconnectionHours,
        minutes: disconnectioMinutes,
        seconds: 0,
      })
      .toDate();
    const timeStoppedWithIgnitionOn = moment()
      .set({ hours: ignitionHours, minutes: ignitionMinutes, seconds: 0 })
      .toDate();

    setValue('maxSpeed', maxSpeed ?? 80);
    setValue('startWorkAt', startWorkAt);
    setValue('endWorkAt', endWorkAt);
    setValue('workOnWeekends', workOnWeekends);
    setValue('timeDisconnectionAlert', timeDisconnectionAlert);
    setValue('timeStoppedWithIgnitionOn', timeStoppedWithIgnitionOn);
    setValue('address', address ?? undefined);
    setValue('phone', phone ?? undefined);
    setValue('organizationName', name);
    setValue('cnpj', cnpj ?? undefined);
  }, [organization, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <CustomScrollbar>
          <Section>
            <Info>
              <h3>Informações gerais</h3>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="organizationName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Organização"
                      value={value}
                      onChange={onChange}
                      error={errors.organizationName?.message}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Endereço"
                      value={value}
                      onChange={onChange}
                      error={errors.address?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  name="cnpj"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="CNPJ"
                      value={cnpj_mask(value)}
                      onChange={onChange}
                      error={errors.cnpj?.message}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Telefone"
                      value={value}
                      onChange={onChange}
                      error={errors.phone?.message}
                    />
                  )}
                />
              </Col>
            </Row>
          </Section>

          <Section>
            <Info>
              <h3>Jornada de trabalho</h3>
              <p>
                As configurações da jornada de trabalho são utilizadas para
                gerar relatórios e consolidar dados para indicadores.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="startWorkAt"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Início do expediente dos motoristas"
                      format="HH:mm"
                      allowClear={false}
                      loading={fetchingOrganization}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="endWorkAt"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Final do expediente dos motoristas"
                      format="HH:mm"
                      allowClear={false}
                      loading={fetchingOrganization}
                    />
                  )}
                />
              </Col>
            </Row>
          </Section>

          <Section>
            <RadioButtonContainer>
              <Info>
                <h3>A empresa opera nos finais de semana?</h3>
                <p>
                  Use essa configuração caso sua empresa opere aos finais de
                  semana.
                </p>
              </Info>
              <div>
                <Controller
                  name="workOnWeekends"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioButton
                      choices={choices}
                      value={value ? 0 : 1}
                      onChange={(event) => onChange(event.index === 0)}
                      maxWidth="192px"
                      minWidth="192px"
                    />
                  )}
                />
              </div>
            </RadioButtonContainer>
            {/* <Row>
              <Col>
                <Controller
                  name="startWorkAt"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Início do expediente dos motoristas"
                      format="HH:mm"
                      allowClear={false}
                      // loading={true}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="endWorkAt"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Final do expediente dos motoristas"
                      format="HH:mm"
                      allowClear={false}
                      // loading={true}
                    />
                  )}
                />
              </Col>
            </Row> */}
          </Section>

          {/* <Section>
            <Info>
              <h3>Velocidade máxima configurada</h3>
              <p>
                Velocidade máxima configurada é utilizado para gerar eventos
                caso o motorista exceda essa velocidade pré estabelecida.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="maxSpeed"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <MaskedNumberInput
                      formatter={(value) =>
                        formatKilometerPerHour(value ?? undefined)
                      }
                      label=""
                      value={value}
                      onChange={onChange}
                      error={errors.maxSpeed?.message}
                      name={name}
                    />
                  )}
                />
              </Col>
              <Col />
            </Row>
          </Section> */}

          <Section>
            <Info>
              <h3>Alerta de desconexão</h3>
              <p>
                Defina qual o tempo minimo que o rastreador pode ficar
                desconectado antes de gerar um alerta.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="timeDisconnectionAlert"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Tempo minímo (hh:mm)"
                      format="HH:mm"
                      allowClear={false}
                      loading={fetchingOrganization}
                    />
                  )}
                />
              </Col>
              <Col />
            </Row>
          </Section>

          {/* <Section>
            <Info>
              <h3>Tempo ocioso</h3>
              <p>
                Propriedade que será utilizada para mandar alerta em caso de
                início de ociosidade.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="timeStoppedWithIgnitionOn"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Tempo minímo (hh:mm)"
                      format="HH:mm"
                      allowClear={false}
                      loading={fetchingOrganization}
                    />
                  )}
                />
              </Col>
              <Col />
            </Row>
          </Section> */}
        </CustomScrollbar>
      </FormContainer>

      <ButtonContainer>
        <Button disabled={updateOrganizationLoading}>
          {updateOrganizationLoading ? 'Salvando...' : 'Salvar'}
        </Button>
      </ButtonContainer>
    </Form>
  );
}

export default OrganizationInformations;
