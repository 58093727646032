import { DatePicker, DatePickerProps } from 'antd';
import styled from 'styled-components';
import Icon from '../../../../components/Icon/Icon';

interface LabelProps {
  weight?: number | string;
}

export const CustomDatePicker = styled(DatePicker)<DatePickerProps>`
  input {
    padding: 4px 10px;
    border-radius: 5px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FormContainer = styled.div`
  height: 100%;
  padding: 0 24px;
  margin-top: 1rem;
`;

export const DrawerHead = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 24px;

  & ${Icon} {
    cursor: pointer;
    color: var(--color-grey-dark);
    font-size: 1.2em;
  }
`;

export const DrawerTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondaryDark};
  margin: 0 0 0 16px;
`;

export const Row = styled.section`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    border-radius: 5px;
  }
`;

export const SubmitContainer = styled.section`
  background: #fafbfc;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px rgba(140, 152, 164, 0.176);
  padding: 12px 24px 16px;
`;

export const TrafficInfractionForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const FieldsContainer = styled.div`
  padding: 0 24px 24px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label<LabelProps>`
  display: flex;
  width: 100%;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin: 1rem 0;
`;

export const ButtonContainer = styled.div`
  padding: 24px;
  border-top: 1px solid #e4e4e4;
`;

export const FinishStep = styled.div`
  padding: 24px;
  border-top: 1px solid #e4e4e4;
  display: flex;
  gap: 8px;
`;
