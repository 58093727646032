import styled from 'styled-components';

export const Item = styled.li`
  padding: 18px 26px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: grid;
  grid-template-columns: 10% 15% 25% 6% 20% 10% 10% 4%;
  align-items: center;

  & + & {
    margin-top: 10px;
  }

  p {
    margin: 0;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.neutral};
  text-transform: uppercase;
  font-size: 12px;
`;

export const Address = styled.p`
  max-width: 230px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;
