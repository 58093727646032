import { differenceInHours, parseISO } from 'date-fns';
import React, { createContext, ReactNode, useContext } from 'react';
import { useAuth } from '../auth/contexts/AuthContextProvider';
import FullLoading from '../components/FullLoading/FullLoading';
import {
  OrganizationFragment,
  useMeQuery,
  UserFragment,
} from '../generated/graphqlV2';

interface CurrentUserContextValue {
  user: UserFragment;
  organization: OrganizationFragment;
  isSuspended: boolean;
  willBeSuspended: boolean;
  daysUntilBeSuspended?: number;
  fetchingUser: boolean;
}

export const CurrentUserContext = createContext<CurrentUserContextValue>(
  {} as any,
);

type LayoutProviderProps = {
  children: ReactNode;
};

export default function CurrentUserContextProvider(props: LayoutProviderProps) {
  const { children } = props;

  const { accessToken } = useAuth();

  const { data, loading } = useMeQuery({
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    },
  });

  const currentUser = data?.me;

  const value: CurrentUserContextValue = React.useMemo(() => {
    const disabledAt = currentUser?.organization?.disabledAt;

    let isSuspended = false;
    let willBeSuspended = false;
    let daysUntilBeSuspended: number | undefined = void 0;

    if (disabledAt) {
      willBeSuspended = true;
      daysUntilBeSuspended = Math.round(
        differenceInHours(parseISO(disabledAt), new Date()) / 24,
      );
      isSuspended = daysUntilBeSuspended <= 0;
    }

    return {
      user: currentUser!,
      organization: currentUser?.organization!,
      isSuspended: isSuspended,
      willBeSuspended: willBeSuspended,
      daysUntilBeSuspended: daysUntilBeSuspended,
      fetchingUser: loading,
    };
  }, [currentUser, loading]);

  if (!currentUser) {
    return <FullLoading />;
  }

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUserContext() {
  const context = useContext(CurrentUserContext);

  if (typeof context === 'undefined') {
    throw new Error(
      `useCurrentUserContext must be used within a CurrentUserContext`,
    );
  }

  return context;
}
