export const DEVELOPMENT_ID_APP_BASE_URL = 'http://localhost:9001';
export const ID_APP_BASE_URL = 'https://id.infleet.com.br';
export const STORAGE_KEY = 'infleet';
export const PERFORMANCE_FILTERS_KEY = 'performance_filters';
export const AUTH_TYPE = 'auth_type';
export const ACCESS_TOKEN_KEY = __DEV__
  ? 'access_token'
  : (0xfffadb14).toString();
export const REFRESH_TOKEN_KEY = __DEV__
  ? 'refresh_token'
  : (0xff52c41a).toString();
export const CURRENT_USER_KEY = __DEV__
  ? 'current_user'
  : (0xff002766).toString();
