import { ApolloClient, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { session } from '../auth/contexts/AuthContextProvider';

const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT!;

const httpLink = createUploadLink({ uri: graphqlEndpoint });

const wsLink = new WebSocketLink({
  uri: graphqlEndpoint.replace(/^http/, 'ws'),
  options: {
    reconnect: true,
    connectionParams: async () => {
      // return await authClient.getHttpHeaders();
      return {
        authorization: `Bearer ${await session.getAccessToken()}`,
      };
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      if (
        error.message === 'Unauthorized' ||
        error.message.includes('User not found') ||
        error.extensions?.exception?.status === 401
      ) {
        session.cleanPersistedData();
      }

      console.log(
        `[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(
          error.locations,
        )}, Path: ${error.path}`,
      );
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext(async () => ({
  headers: {
    authorization: `Bearer ${await session.getAccessToken()}`,
  },
}));

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(from([errorLink, httpLink as any])),
);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});

export default apolloClient;
