import React, {
  forwardRef,
  MouseEvent,
  ReactNode,
  TransitionEvent,
} from 'react';
import {
  ModalContent,
  ModalDialog,
  ModalOverlay,
  ScrollDisabler,
  StyledModal,
} from './styles';

export type CustomModalViewProps = {
  id?: string;
  fadeType: 'in' | 'out';
  transitionEnd: (e: TransitionEvent) => void;
  children?: ReactNode;
  handleClick: (e: MouseEvent) => void;
};

const CustomModalView = forwardRef<HTMLDivElement, CustomModalViewProps>(
  function CustomModalView(props, ref) {
    const { id, fadeType, transitionEnd, children, handleClick } = props;
    return (
      <>
        <StyledModal
          id={id}
          className={`fade-${fadeType}`}
          onTransitionEnd={transitionEnd}
        >
          <ModalDialog>
            <ModalContent>{children}</ModalContent>
          </ModalDialog>
          <ModalOverlay onMouseDown={handleClick} ref={ref} />
        </StyledModal>
        <ScrollDisabler />
      </>
    );
  },
);

export default CustomModalView;
