import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { useNotificationsQuery } from '../../generated/graphqlV2';
import durationFormat from '../../helpers/duration-format';
import EmptyNotifications from './EmptyNotifications';
import NotificationItem from './NotificationItem';

const Container = styled.div`
  position: fixed;
  width: 400px;
  right: 180px;
  top: 70px;
  z-index: 1500;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);

  background: #ffffff;
  border: 1px solid #e7eaf3;
  border-radius: 3px 3px 4px 4px;
`;

const Header = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 3px 3px 0px 0px;
  padding: 24px;
  border-bottom: 2px solid #e7eaf3;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #434343;
`;

const Content = styled.div`
  background-color: #fff;
  max-height: 400px;
  min-height: 50px;
  overflow-y: auto;
`;

// const Footer = styled.div`
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 17px;
//   color: #434343;
//   padding: 20px;
//   text-align: center;
// `;

function Notifications() {
  const { data: { notifications = [] } = {} } = useNotificationsQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <Header>
        <Title>Notificações</Title>
      </Header>
      <Content>
        {!notifications.length ? (
          <EmptyNotifications />
        ) : (
          notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              unRead={
                Math.abs(
                  moment().diff(moment(notification.readAt), 'minutes'),
                ) < 1
              }
              title={notification.title}
              imageUrl={notification.imageUrl}
              description={notification.description}
              duration={durationFormat(
                Math.abs(
                  moment().diff(moment(notification.createdAt), 'seconds'),
                ),
              )}
              actions={notification.actions}
            />
          ))
        )}
      </Content>
      {/* <Footer>Ver todas as notificações</Footer> */}
    </Container>
  );
}

export default Notifications;
