import React from 'react';
import styled from 'styled-components';
import X from '../../components/icons/X';
import { useCurrentUserContext } from '../../contexts/CurrentUserContextProvider';
import { useToggle } from 'react-use';

const Container = styled.div`
  grid-area: announcement;
  background: #ffeced;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  min-height: 16px;
  line-height: 100%;
  color: #d83d0e;
  padding: 14px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.button`
  border: 0;
  background-color: transparent;
  font-size: 24px;
  height: 36px;
  width: 36px;
  position: absolute;
  right: 0;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
`;

function Announcement() {
  const [closed, toggle] = useToggle(false);
  const { daysUntilBeSuspended, isSuspended, willBeSuspended } =
    useCurrentUserContext();
  if (!willBeSuspended || closed) return null;

  if (isSuspended) return null;

  let whenWillBeSuspended = `em ${daysUntilBeSuspended} ${
    daysUntilBeSuspended === 1 ? ' dia' : ' dias'
  }`;

  if (daysUntilBeSuspended! <= 0) {
    whenWillBeSuspended = 'hoje';
  }

  return (
    <Container>
      <div>
        Sua conta será suspensa {whenWillBeSuspended} devido a atrasos no
        pagamento, para mais detalhes de como evitar que sua conta seja suspensa
        entre em contato com o nosso time.
      </div>
      <CloseButton onClick={toggle}>
        <X />
      </CloseButton>
    </Container>
  );
}

export default Announcement;
