import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-areas: 'welcome avatar chevron';
  grid-template-columns:
    minmax(min-content, max-content) minmax(min-content, max-content)
    minmax(min-content, max-content);
  align-items: center;
`;

export const Wrapper = styled.div`
  grid-area: welcome;
  margin-right: 1rem;
`;

export const WelcomeMessage = styled.div`
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-align: right;
  font-weight: normal;
  color: ${(props) => props.theme.colors.grey};
`;

export const FirstName = styled.div`
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: right;
  font-weight: normal;
  color: ${(props) => props.theme.colors.primary};
`;
