export enum AppModuleEnum {
  fuellings = 'fuellings',
  providers = 'providers',
  indicators = 'indicators',
  integrations = 'integrations',
  maintenances = 'maintenances',
  monitoring = 'monitoring',
  drivers = 'drivers',
  vehicles = 'vehicles',
  imports = 'imports',
  reports = 'reports',
  settings = 'settings',
  others = 'others',
}
