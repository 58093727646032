import * as React from 'react';
import Button, { ButtonProps } from '../Button/Button';

export type PrimaryButtonProps = Omit<ButtonProps, '$variant'>;

const PrimaryButton = React.forwardRef<PrimaryButtonProps, any>(
  (props, ref) => {
    return <Button $variant="primary" {...props} ref={ref} />;
  },
);

export default PrimaryButton;
