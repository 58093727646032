import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** Date custom scalar type */
  jsonb: any;
};

/** Aplicações */
export type App = {
  __typename?: 'App';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizations: Array<Organization>;
  slugName: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

/** Modulos existentes na aplicações */
export type AppModule = {
  __typename?: 'AppModule';
  name: Scalars['String'];
  slugName: Scalars['String'];
};

export type Authorization = {
  __typename?: 'Authorization';
  accessToken: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  user: User;
};

/** Service Category */
export type Category = {
  __typename?: 'Category';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Name */
  name: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type ChangeUserVehiclesInput = {
  attach?: InputMaybe<Array<Scalars['ID']>>;
  detach?: InputMaybe<Array<Scalars['ID']>>;
  user: Scalars['ID'];
};

export type ChangeVehicleMeasureInput = {
  measure: Scalars['Float'];
  measuredAt: Scalars['DateTime'];
  measurement: MeasurementTypeEnum;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

/** Chip */
export type Chip = {
  __typename?: 'Chip';
  broker: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  device?: Maybe<Device>;
  deviceId?: Maybe<Scalars['ID']>;
  enabledAt?: Maybe<Scalars['DateTime']>;
  iccid: Scalars['String'];
  id: Scalars['ID'];
  msisdn: Scalars['String'];
  telecomOperator: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export enum CostTypeEnum {
  Discount = 'discount',
  Extra = 'extra',
  Labor = 'labor',
  Tax = 'tax',
}

export type CreateChipInput = {
  broker: Scalars['String'];
  deviceId?: InputMaybe<Scalars['ID']>;
  enabledAt?: InputMaybe<Scalars['DateTime']>;
  iccid: Scalars['String'];
  msisdn: Scalars['String'];
  telecomOperator: Scalars['String'];
};

export type CreateDeviceInput = {
  chipId?: InputMaybe<Scalars['ID']>;
  installedAt?: InputMaybe<Scalars['DateTime']>;
  /** Modelo do dispositivo */
  model?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  technicianId?: InputMaybe<Scalars['ID']>;
  tenure?: InputMaybe<Scalars['String']>;
  uninstalledAt?: InputMaybe<Scalars['DateTime']>;
  /** IMEI do dispositivo */
  uniqueId: Scalars['String'];
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type CreateDriverInput = {
  /** Data de nascimento */
  birthday?: InputMaybe<Scalars['DateTime']>;
  canLogin?: InputMaybe<Scalars['Boolean']>;
  cnh?: InputMaybe<Scalars['String']>;
  cnhCategory?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateFeatureFlagInput = {
  enabled: Scalars['Boolean'];
  /** Código da feature */
  feature: Scalars['String'];
  organization?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type CreateFuellingInput = {
  /** Quantidade de combustível abastecido em L */
  amount: Scalars['Float'];
  /** Custo em R$ do abastecimento */
  cost?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Encheu o tanque ou não? */
  filledUp?: InputMaybe<Scalars['Boolean']>;
  /** Tipo do combustível: diesel, gas... */
  fuelType: Scalars['String'];
  /** Horimetro no momento do abastecimento */
  horimeter?: InputMaybe<Scalars['Float']>;
  invoiceEvidence?: InputMaybe<Scalars['ID']>;
  /** Abastecido no posto interno ou não */
  isInternal: Scalars['Boolean'];
  /** Data que o abastecimento foi realizado */
  occurredAt: Scalars['DateTime'];
  /** Odômetro no momento do abastecimento */
  odometer?: InputMaybe<Scalars['Float']>;
  odometerEvidence?: InputMaybe<Scalars['ID']>;
  provider?: InputMaybe<Scalars['ID']>;
  tank?: InputMaybe<Scalars['ID']>;
  vehicle: Scalars['ID'];
};

export type CreateGeofenceInput = {
  /** Geometria da cerca em GeoJSON */
  area: Scalars['jsonb'];
  /** Código hexiadecimal da cor #HEX */
  color: Scalars['String'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  parking?: InputMaybe<Scalars['Boolean']>;
  /** Tipo da cerca: poligon e circle */
  type: GeofenceTypeEnum;
};

export type CreateGroupInput = {
  /** Nome */
  name: Scalars['String'];
  /** Organização */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Veiculos */
  vehicles: Array<Scalars['ID']>;
};

export type CreateIntegrationInput = {
  credentials?: InputMaybe<Scalars['jsonb']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  source: Scalars['String'];
};

export type CreateMaintenanceParteInput = {
  /** Nome da peca */
  name: Scalars['String'];
  organization: Scalars['ID'];
  standard: Scalars['Boolean'];
};

export type CreateMaintenanceReminderInput = {
  /** Frequência do lembrete por medida */
  frequencyMeasure?: InputMaybe<Scalars['Float']>;
  /** Frequência do lembrete por data */
  frequencyTime?: InputMaybe<Scalars['Int']>;
  /** Medição na última manuteção */
  lastMeasure: Scalars['Float'];
  /** Data da última manuteção */
  lastOccurrence: Scalars['DateTime'];
  /** Serviço */
  service: Scalars['ID'];
  /** Aviso com antecedência por medida */
  thresholdMeasure?: InputMaybe<Scalars['Float']>;
  /** Aviso com antecedência por data */
  thresholdTime?: InputMaybe<Scalars['Int']>;
  /** Veículo */
  vehicle: Scalars['ID'];
};

export type CreateMaintenanceServiceInput = {
  category?: InputMaybe<Scalars['ID']>;
  /** Nome do serviço */
  name: Scalars['String'];
  organization?: InputMaybe<Scalars['ID']>;
  standard?: InputMaybe<Scalars['Boolean']>;
};

export type CreateMaintenanceTaskInput = {
  /** Data do servico */
  completedAt?: InputMaybe<Scalars['DateTime']>;
  /** Descricao do servico */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Valor do horimeter */
  horimeter?: InputMaybe<Scalars['Float']>;
  /** Valor do odometro */
  odometer?: InputMaybe<Scalars['Float']>;
  organization?: InputMaybe<Scalars['ID']>;
  /** pecas */
  parts: Array<ReferenceTaskPartInput>;
  /** precos */
  prices: Array<ReferenceTaskCostInput>;
  provider?: InputMaybe<Scalars['ID']>;
  service?: InputMaybe<Scalars['ID']>;
  type: TaskTypeEnum;
  vehicle: Scalars['ID'];
};

export type CreateOpsServiceInput = {
  category: OpsServiceCategory;
  deviceId?: InputMaybe<Scalars['ID']>;
  deviceTenure?: InputMaybe<Scalars['String']>;
  isTaxable: Scalars['Boolean'];
  isTrial: Scalars['Boolean'];
  occurredAt: Scalars['DateTime'];
  organizationId: Scalars['ID'];
  shouldDisableVehicle?: InputMaybe<Scalars['Boolean']>;
  technicianId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};

export type CreateOrderInput = {
  /** Custo em R$ da ordem */
  amount?: InputMaybe<Scalars['Float']>;
  /** Numero da Nota */
  invoiceNumber: Scalars['Float'];
  /** Data da Ordem */
  occurredAt: Scalars['DateTime'];
  organization: Scalars['ID'];
  /** Origem da Ordem */
  source: Scalars['String'];
  tank: Scalars['ID'];
  /** Valor em R$ da ordem */
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateOrganizationInput = {
  address?: InputMaybe<Scalars['String']>;
  apps: Array<Scalars['ID']>;
  capacity?: InputMaybe<OrganizationCapacity>;
  category?: InputMaybe<Scalars['String']>;
  cnpj: Scalars['String'];
  contractBeganAt?: InputMaybe<Scalars['DateTime']>;
  contractEndedAt?: InputMaybe<Scalars['DateTime']>;
  contractMonthDuration?: InputMaybe<Scalars['Float']>;
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Razão Social */
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  primaryOrganization?: InputMaybe<Scalars['ID']>;
  sector?: InputMaybe<Scalars['String']>;
  signUpFee?: InputMaybe<Scalars['Float']>;
  trialAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateProviderInput = {
  /** CNPJ do fornecedor / oficina */
  cnpj?: InputMaybe<Scalars['String']>;
  /** Nome do fornecedor ou oficina */
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type CreateShippingInput = {
  city: Scalars['String'];
  devices?: InputMaybe<Array<Scalars['ID']>>;
  devolution: Scalars['Boolean'];
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  state: Scalars['String'];
  technicianId?: InputMaybe<Scalars['ID']>;
};

export type CreateTankInput = {
  /** Capacidade máxima do tanque */
  capacity?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  /** Volume inicial do tanque */
  initialVolume?: InputMaybe<Scalars['Float']>;
  /** Nome do tanque de combustível */
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateTechnicianInput = {
  additionalFee?: InputMaybe<Scalars['Float']>;
  address?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bankAccountDigit?: InputMaybe<Scalars['String']>;
  bankAgency?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankOwnerDocumentNumber?: InputMaybe<Scalars['String']>;
  bankOwnerName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contractSignedAt?: InputMaybe<Scalars['DateTime']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  installationPrice?: InputMaybe<Scalars['Float']>;
  maintenancePrice?: InputMaybe<Scalars['Float']>;
  movementPrice?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pix?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  uninstallationPrice?: InputMaybe<Scalars['Float']>;
};

export type CreateTrafficInfractionInput = {
  /** Endereço */
  address?: InputMaybe<Scalars['String']>;
  /** Custo */
  cost?: InputMaybe<Scalars['Float']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Data de vencimento */
  dueDate?: InputMaybe<Scalars['DateTime']>;
  infraction: Scalars['ID'];
  /** Localização */
  location?: InputMaybe<TrafficInfractionLocationInput>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Pago em... */
  paidAt?: InputMaybe<Scalars['DateTime']>;
  /** Custo Pago */
  paidCost?: InputMaybe<Scalars['Float']>;
  /** Documento de referência */
  referenceDocument?: InputMaybe<Scalars['String']>;
  vehicle: Scalars['ID'];
};

export type CreateUserInput = {
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  modules: Array<Scalars['String']>;
  name: Scalars['String'];
  /** primary user organization */
  organization?: InputMaybe<Scalars['ID']>;
  organizations?: InputMaybe<Array<Scalars['ID']>>;
  password: Scalars['String'];
  role: UserRole;
};

export type CreateVehicleInput = {
  /** Autonomia do veículo */
  autonomy?: InputMaybe<Scalars['Float']>;
  avatarType?: InputMaybe<VehicleTypeEnum>;
  /** Marca, ex: GM, Nissan, Toyota... */
  brand?: InputMaybe<Scalars['String']>;
  /** Veículo desabilitado */
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  /** Documento */
  document?: InputMaybe<Scalars['String']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType?: InputMaybe<FuelTypeEnum>;
  lastDriverToken?: InputMaybe<Scalars['String']>;
  /** Velocidade máxima */
  maxSpeed?: InputMaybe<Scalars['Float']>;
  /** Volume máximo */
  maximumVolume?: InputMaybe<Scalars['Int']>;
  /** Carga máxima */
  maximumWeight?: InputMaybe<Scalars['Int']>;
  /** Velocidade mínima para ser considerado como parado */
  minSpeed?: InputMaybe<Scalars['Float']>;
  /** Duração mínima do estacionamento */
  minimalParkingDuration?: InputMaybe<Scalars['Float']>;
  /** Modelo, ex: Fiesta, March, C3... */
  model?: InputMaybe<Scalars['String']>;
  /** Pagamento Recorrente Mensal */
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  /** Identificação */
  plate: Scalars['String'];
  /** Preço */
  price?: InputMaybe<Scalars['Float']>;
  /** Taxa de Adesão */
  signUpFee?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  /** Data de início da cobrança */
  taxabledAt?: InputMaybe<Scalars['DateTime']>;
  type: Scalars['String'];
  useObdData?: InputMaybe<Scalars['Boolean']>;
  /** Ano do veículo */
  year?: InputMaybe<Scalars['String']>;
};

/** Medição diaria com base na telemetria */
export type DailyWorkMeasure = {
  __typename?: 'DailyWorkMeasure';
  /** Horário inicial para contabilizar o horimetro */
  beginMeasureHorimeterAt: Scalars['DateTime'];
  /** Horário inicial para contabilizar o odometro */
  beginMeasureOdometerAt: Scalars['DateTime'];
  changedByUser?: Maybe<User>;
  /** Usuário que mudou a medida */
  changedByUserId?: Maybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  distanceTraveled?: Maybe<Scalars['Float']>;
  finalHorimeter?: Maybe<Scalars['Int']>;
  finalOdometer?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  initialHorimeter: Scalars['Int'];
  initialOdometer: Scalars['Float'];
  timeWithIgnitionOn?: Maybe<Scalars['Int']>;
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

/** Dispositivo */
export type Device = {
  __typename?: 'Device';
  chip?: Maybe<Chip>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  installedAt?: Maybe<Scalars['DateTime']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  model?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  technician?: Maybe<Technician>;
  technicianId?: Maybe<Scalars['ID']>;
  tenure?: Maybe<Scalars['String']>;
  uninstalledAt?: Maybe<Scalars['DateTime']>;
  uniqueId: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['ID']>;
};

export type DevicePaginate = {
  __typename?: 'DevicePaginate';
  /** Dispositivos */
  devices: Array<Device>;
  /** Total de registros */
  total: Scalars['Int'];
};

/** Arquivo armazenado */
export type DocumentFile = {
  __typename?: 'DocumentFile';
  category: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  size: Scalars['Float'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Url para acesso ao arquivo */
  url: Scalars['String'];
};

/** Motorista */
export type Driver = {
  __typename?: 'Driver';
  /** Status do Motorista */
  active?: Maybe<Scalars['Boolean']>;
  /** Data de nascimento */
  birthday: Scalars['DateTime'];
  cnh?: Maybe<Scalars['String']>;
  cnhCategory?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que o motorista foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization: Organization;
  /** @deprecated Não será mais utilizado nas futuras versões */
  source?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
};

/** Mapeamento de motorista */
export type DriverMapping = {
  __typename?: 'DriverMapping';
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  /** @deprecated Should use driver.id instead */
  driverId?: Maybe<Scalars['ID']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type DriverRanking = {
  __typename?: 'DriverRanking';
  avgSpeed: Scalars['Float'];
  driverId: Scalars['ID'];
  maxSpeed: Scalars['Float'];
  telemetrySummary: Scalars['jsonb'];
  totalTelemetryEvents: Scalars['Int'];
};

/** Eventos do veículo */
export type Event = {
  __typename?: 'Event';
  address?: Maybe<Scalars['String']>;
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  geofence?: Maybe<Geofence>;
  id: Scalars['ID'];
  isAlarm: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** @deprecated Não teremos mais o evento relacionado a organização apenas ao veículo */
  organization?: Maybe<Organization>;
  origin: Scalars['String'];
  /** Data de ocorrência do evento */
  reportedAt: Scalars['DateTime'];
  slugName: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
  /** Histórico da troca do motorista */
  vehicleDriverChangeHistory?: Maybe<VehicleDriverChangeHistory>;
  vehicleId: Scalars['ID'];
};

export type EventOccurrences = {
  __typename?: 'EventOccurrences';
  boardActivated: Scalars['Int'];
  boardDeactivated: Scalars['Int'];
  cockpitDoorClose: Scalars['Int'];
  cockpitDoorOpen: Scalars['Int'];
  deviceMoving: Scalars['Int'];
  deviceOverspeed: Scalars['Int'];
  deviceOverweight: Scalars['Int'];
  deviceStopped: Scalars['Int'];
  driverChange: Scalars['Int'];
  fault: Scalars['Int'];
  geofenceEnter: Scalars['Int'];
  geofenceExit: Scalars['Int'];
  hardAcceleration: Scalars['Int'];
  hardBraking: Scalars['Int'];
  hardCornering: Scalars['Int'];
  ignitionOff: Scalars['Int'];
  ignitionOn: Scalars['Int'];
  plugged: Scalars['Int'];
  routeOverspeed: Scalars['Int'];
  trailerOpen: Scalars['Int'];
  unplugged: Scalars['Int'];
};

/** Resumo dos Eventos */
export type EventSummary = {
  __typename?: 'EventSummary';
  occurrences: Scalars['Int'];
  slugName: Scalars['String'];
};

/** Funcionalidades que estão liberadas para o cliente */
export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a feature foi bloqueada */
  disabledAt?: Maybe<Scalars['DateTime']>;
  /** Data que a feature foi liberada */
  enabledAt?: Maybe<Scalars['DateTime']>;
  /** Código da feature */
  feature: Scalars['String'];
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type FilterOrder = {
  amountMax?: InputMaybe<Scalars['Float']>;
  amountMin?: InputMaybe<Scalars['Float']>;
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  tanks: Array<Scalars['ID']>;
  valueMax?: InputMaybe<Scalars['Float']>;
  valueMin?: InputMaybe<Scalars['Float']>;
};

export type FilterPartInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type FilterServiceInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type FilterTaskInput = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  drivers?: InputMaybe<Array<Scalars['ID']>>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  provider?: InputMaybe<Array<Scalars['ID']>>;
  services?: InputMaybe<Array<Scalars['ID']>>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<TaskTypeEnum>;
  vehicles?: InputMaybe<Array<Scalars['ID']>>;
};

export type FindInfractionInput = {
  /** Código */
  code?: InputMaybe<Scalars['String']>;
  /** Valor em R$ da infração */
  cost?: InputMaybe<Scalars['Float']>;
  /** Descrição */
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** Pontos */
  points?: InputMaybe<Scalars['Float']>;
};

/** Tipos de combustíveis */
export type FuelType = {
  __typename?: 'FuelType';
  description: Scalars['String'];
  slugName: Scalars['String'];
};

export enum FuelTypeEnum {
  Arla_32 = 'arla_32',
  Diesel = 'diesel',
  Ethanol = 'ethanol',
  Gasoline = 'gasoline',
  Gnv = 'gnv',
  Kerosene = 'kerosene',
  Other = 'other',
}

/** Mapeamento dos tipos de combustível */
export type FuelTypeMapping = {
  __typename?: 'FuelTypeMapping';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  fuelType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

/** Abastecimento */
export type Fuelling = {
  __typename?: 'Fuelling';
  /** Quantidade de combustível abastecido em L */
  amount: Scalars['Float'];
  attributes: Scalars['jsonb'];
  /** Custo em R$ do abastecimento */
  cost?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  /** Campo livre para inserção de observações */
  description?: Maybe<Scalars['String']>;
  driver?: Maybe<Driver>;
  externalId?: Maybe<Scalars['String']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType: Scalars['String'];
  /** Horímetro no momento do abastecimento */
  horimeter?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceEvidence?: Maybe<DocumentFile>;
  /** Abastecido no posto interno ou não */
  isInternal: Scalars['Boolean'];
  /** Data que o abastecimento foi realizado */
  occurredAt: Scalars['DateTime'];
  /** Odômetro no momento do abastecimento */
  odometer?: Maybe<Scalars['Float']>;
  odometerEvidence?: Maybe<DocumentFile>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  organization?: Maybe<Organization>;
  provider?: Maybe<Provider>;
  source: Scalars['String'];
  tank?: Maybe<Tank>;
  tankTransaction?: Maybe<TankTransaction>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

/** Referência de abastecimento */
export type FuellingReference = {
  __typename?: 'FuellingReference';
  /** Valor máximo aceitável para horimetro */
  maxHorimeter?: Maybe<Scalars['Float']>;
  /** Valor máximo aceitável para odometro */
  maxOdometer?: Maybe<Scalars['Float']>;
  /** Valor mínimo aceitável para horimetro */
  minHorimeter: Scalars['Float'];
  /** Valor mínimo aceitável para odometro */
  minOdometer: Scalars['Float'];
};

/** Endereço */
export type Geocode = {
  __typename?: 'Geocode';
  boundingBox?: Maybe<Array<Array<Scalars['Float']>>>;
  category: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Endereço completo */
  description: Scalars['String'];
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  settlement?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Geocercas */
export type Geofence = {
  __typename?: 'Geofence';
  /** Geometria da cerca em GeoJSON */
  area: Scalars['jsonb'];
  /** Código hexiadecimal da cor #HEX */
  color: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parking?: Maybe<Scalars['Boolean']>;
  /** Tipo da cerca: poligon e circle */
  type: GeofenceTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export enum GeofenceTypeEnum {
  Circle = 'circle',
  Polygon = 'polygon',
}

/** Grupo */
export type Group = {
  __typename?: 'Group';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Veículos associados ao grupo */
  vehicles: Array<Vehicle>;
};

/** Histórico do horimetro */
export type HorimeterHistory = {
  __typename?: 'HorimeterHistory';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  current: Scalars['Float'];
  fuelling?: Maybe<Fuelling>;
  id: Scalars['ID'];
  /** Data de medição */
  measuredAt: Scalars['DateTime'];
  previous?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

/** Infrações de transito */
export type Infraction = {
  __typename?: 'Infraction';
  /** Código */
  code: Scalars['String'];
  /** Valor em R$ da infração */
  cost?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a multa foi deletada */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Descrição */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** Pontos */
  points?: Maybe<Scalars['Float']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Integrações */
export type Integration = {
  __typename?: 'Integration';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  enabledAt?: Maybe<Scalars['DateTime']>;
  hasPendency: Scalars['Boolean'];
  id: Scalars['ID'];
  intents: Array<IntegrationIntent>;
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  pendingDriverMappings: Scalars['Int'];
  pendingFuelTypeMappings: Scalars['Int'];
  pendingProviderMappings: Scalars['Int'];
  pendingVehicleMappings: Scalars['Int'];
  setupCompletedAt?: Maybe<Scalars['DateTime']>;
  setupErrorMessage?: Maybe<Scalars['String']>;
  setupProgress?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Integrações */
export type IntegrationIntent = {
  __typename?: 'IntegrationIntent';
  attributes: Scalars['jsonb'];
  completed: Scalars['Boolean'];
  completedAt: Scalars['DateTime'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  progress?: Maybe<Scalars['Float']>;
  storage?: Maybe<DocumentFile>;
  /** @deprecated Should use storage.id instead */
  storageId?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type ListTrafficInfractionInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  vehicle: Scalars['ID'];
};

/** DEPRECATED - Use Position - Localização atual do veículo */
export type Location = {
  __typename?: 'Location';
  accuracy: Scalars['Float'];
  address?: Maybe<Scalars['String']>;
  altitude: Scalars['Float'];
  attributes: Scalars['jsonb'];
  course: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data no rastreador */
  deviceTime: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  /** Data real da localização */
  fixTime: Scalars['DateTime'];
  id: Scalars['ID'];
  ignition?: Maybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Data de quando a posição chegou ao servidor */
  serverTime: Scalars['DateTime'];
  source: Scalars['String'];
  speed: Scalars['Float'];
  status: VehicleStatus;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
  weight?: Maybe<Scalars['Float']>;
};

export type MaintenanceCostPerTypeReport = {
  __typename?: 'MaintenanceCostPerTypeReport';
  /** Manutenção Corretiva */
  correctiveMaintenanceCost: Scalars['Float'];
  /** Data */
  date: Scalars['String'];
  /** Manutenção Preventiva */
  preventiveMaintenanceCost: Scalars['Float'];
  /** Quantidade */
  quantity: Scalars['Float'];
};

export type MaintenanceCostPerTypeReportInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleIds: Array<Scalars['ID']>;
};

export type MaintenanceCostPerTypeReportResponse = {
  __typename?: 'MaintenanceCostPerTypeReportResponse';
  /** pecas */
  report: Array<MaintenanceCostPerTypeReport>;
  /** Data */
  vehicleId: Scalars['String'];
};

/** Lembrete */
export type MaintenanceReminder = {
  __typename?: 'MaintenanceReminder';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Frequência do lembrete por medida */
  frequencyMeasure?: Maybe<Scalars['Float']>;
  /** Frequência do lembrete por data */
  frequencyTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Medição na última manuteção */
  lastMeasure: Scalars['Float'];
  /** Data da última manuteção */
  lastOccurrence: Scalars['DateTime'];
  /** Dias restantes, quando negativo representa quantos dias se passaram */
  remainingDays: Scalars['Int'];
  /** Medição restante, quando negativo representa quantas Horas/KM se passaram */
  remainingMeasure: Scalars['Int'];
  /** Serviço */
  service: Service;
  /** Aviso com antecedência por medida */
  thresholdMeasure?: Maybe<Scalars['Float']>;
  /** Aviso com antecedência por data */
  thresholdTime?: Maybe<Scalars['Int']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Veículo */
  vehicle: Vehicle;
};

export type MaintenanceSummaryInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleIds: Array<Scalars['ID']>;
};

export type MaintenanceSummaryResponse = {
  __typename?: 'MaintenanceSummaryResponse';
  /** Custo médio */
  averageCost?: Maybe<Scalars['Float']>;
  /** Quantidade de manutenções */
  maintenances?: Maybe<Scalars['Float']>;
  /** Custo máximo */
  maximumCost?: Maybe<Scalars['Float']>;
  /** Custo mínimo */
  minimumCost?: Maybe<Scalars['Float']>;
  /** Custo total */
  totalCost?: Maybe<Scalars['Float']>;
  /** Id do veiculo */
  vehicleId: Scalars['String'];
};

export enum MeasurementTypeEnum {
  Horimeter = 'horimeter',
  Odometer = 'odometer',
}

export type Mutation = {
  __typename?: 'Mutation';
  authorize: Authorization;
  changeUserPassword: Scalars['Boolean'];
  changeUserVehicles: Scalars['Boolean'];
  changeVehicleMeasure: DailyWorkMeasure;
  createChip: Chip;
  createDevice: Device;
  createDriver: Driver;
  createFeatureFlag: FeatureFlag;
  createFuelling: Fuelling;
  createGeofence: Geofence;
  createGroup: Group;
  createInstallationServices: Array<OpsService>;
  createIntegration: Integration;
  createMaintenanceReminders: Array<MaintenanceReminder>;
  createOrder: Order;
  createOrganization: Organization;
  createPart: Part;
  createProvider: Provider;
  createService: Service;
  createShipping: Shipping;
  createTank: Tank;
  createTask: Task;
  createTechnician: Technician;
  createTrafficInfraction: TrafficInfraction;
  createUninstallationServices: Array<OpsService>;
  createUnmappedDrivers: Array<DriverMapping>;
  createUnmappedProviders: Array<ProviderMapping>;
  createUnmappedVehicles: Array<VehicleMapping>;
  createUser: User;
  createVehicle: Vehicle;
  deleteChip: Scalars['Boolean'];
  deleteDevice: Scalars['Boolean'];
  deleteDriver: Scalars['Boolean'];
  deleteFeatureFlag: Scalars['Boolean'];
  deleteFuelling: Scalars['Boolean'];
  deleteGeofence: Scalars['Boolean'];
  deleteGroup: Scalars['Boolean'];
  deleteIntegration: Scalars['Boolean'];
  deleteMaintenanceReminder: Scalars['Boolean'];
  deleteOrder: Scalars['Boolean'];
  deletePart: Scalars['Boolean'];
  deleteProvider: Scalars['Boolean'];
  deleteService: Scalars['Boolean'];
  deleteTank: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  deleteTechnician: Scalars['Boolean'];
  deleteTrafficInfraction: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deviceImports: Array<Device>;
  disableVehicle: Vehicle;
  enableVehicle: Vehicle;
  login: Authorization;
  notificationsRegisterDeviceToken: Scalars['Boolean'];
  payTrafficInfraction: TrafficInfraction;
  requestPasswordRecovery: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  retryImportIntegrationIntent: IntegrationIntent;
  saveVehicles: Array<Vehicle>;
  setCurrentDriverToVehicleDriverChangeHistory: VehicleDriverChangeHistory;
  setPreviousDriverToVehicleDriverChangeHistory: VehicleDriverChangeHistory;
  setVehicleDriver: Vehicle;
  /** @deprecated Should use the changeVehicleMeasure */
  setVehicleMeasure: Scalars['Boolean'];
  technicianImports: Array<Technician>;
  updateChip: Chip;
  updateDevice: Device;
  updateDriver: Driver;
  updateDriverMappings: Array<DriverMapping>;
  updateFeatureFlag: FeatureFlag;
  updateFuelTypeMappings: Array<FuelTypeMapping>;
  updateFuelling: Fuelling;
  updateGeofence: Geofence;
  updateGroup: Group;
  updateMaintenanceReminder: MaintenanceReminder;
  updateOpsService: OpsService;
  updateOrder: Order;
  updateOrganization: Organization;
  updateProvider: Provider;
  updateProviderMappings: Array<ProviderMapping>;
  updateService: Service;
  updateShipping: Shipping;
  updateTank: Tank;
  updateTask: Task;
  updateTechnician: Technician;
  updateTrafficInfraction: TrafficInfraction;
  updateUser: User;
  updateVehicle: Vehicle;
  updateVehicleMappings: Array<VehicleMapping>;
  uploadFileIntegration: IntegrationIntent;
  vehicleImports: Array<Vehicle>;
};

export type MutationAuthorizeArgs = {
  appName: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationChangeUserPasswordArgs = {
  password: Scalars['String'];
  user: Scalars['ID'];
};

export type MutationChangeUserVehiclesArgs = {
  payload: ChangeUserVehiclesInput;
};

export type MutationChangeVehicleMeasureArgs = {
  payload: ChangeVehicleMeasureInput;
};

export type MutationCreateChipArgs = {
  createChipInput: CreateChipInput;
};

export type MutationCreateDeviceArgs = {
  payload: CreateDeviceInput;
};

export type MutationCreateDriverArgs = {
  payload: CreateDriverInput;
};

export type MutationCreateFeatureFlagArgs = {
  payload: CreateFeatureFlagInput;
};

export type MutationCreateFuellingArgs = {
  payload: CreateFuellingInput;
};

export type MutationCreateGeofenceArgs = {
  payload: CreateGeofenceInput;
};

export type MutationCreateGroupArgs = {
  payload: CreateGroupInput;
};

export type MutationCreateInstallationServicesArgs = {
  payload: Array<CreateOpsServiceInput>;
};

export type MutationCreateIntegrationArgs = {
  payload: CreateIntegrationInput;
};

export type MutationCreateMaintenanceRemindersArgs = {
  reminders: Array<CreateMaintenanceReminderInput>;
};

export type MutationCreateOrderArgs = {
  payload: CreateOrderInput;
};

export type MutationCreateOrganizationArgs = {
  payload: CreateOrganizationInput;
};

export type MutationCreatePartArgs = {
  payload: CreateMaintenanceParteInput;
};

export type MutationCreateProviderArgs = {
  payload: CreateProviderInput;
};

export type MutationCreateServiceArgs = {
  payload: CreateMaintenanceServiceInput;
};

export type MutationCreateShippingArgs = {
  createShippingInput: CreateShippingInput;
};

export type MutationCreateTankArgs = {
  payload: CreateTankInput;
};

export type MutationCreateTaskArgs = {
  payload: CreateMaintenanceTaskInput;
};

export type MutationCreateTechnicianArgs = {
  createTechnicianInput: CreateTechnicianInput;
};

export type MutationCreateTrafficInfractionArgs = {
  payload: CreateTrafficInfractionInput;
};

export type MutationCreateUninstallationServicesArgs = {
  payload: Array<CreateOpsServiceInput>;
};

export type MutationCreateUnmappedDriversArgs = {
  mappings: Array<Scalars['ID']>;
};

export type MutationCreateUnmappedProvidersArgs = {
  mappings: Array<Scalars['ID']>;
};

export type MutationCreateUnmappedVehiclesArgs = {
  mappings: Array<Scalars['ID']>;
};

export type MutationCreateUserArgs = {
  payload: CreateUserInput;
};

export type MutationCreateVehicleArgs = {
  payload: CreateVehicleInput;
};

export type MutationDeleteChipArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDeviceArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDriverArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFeatureFlagArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFuellingArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteGeofenceArgs = {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMaintenanceReminderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePartArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProviderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteServiceArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTankArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTechnicianArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTrafficInfractionArgs = {
  ids: Array<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeviceImportsArgs = {
  file: Scalars['Upload'];
};

export type MutationDisableVehicleArgs = {
  vehicle: Scalars['ID'];
};

export type MutationEnableVehicleArgs = {
  vehicle: Scalars['ID'];
};

export type MutationLoginArgs = {
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
  username: Scalars['String'];
};

export type MutationNotificationsRegisterDeviceTokenArgs = {
  platform?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationPayTrafficInfractionArgs = {
  payload: PayTrafficInfractionInput;
};

export type MutationRequestPasswordRecoveryArgs = {
  username: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationRetryImportIntegrationIntentArgs = {
  intentId: Scalars['ID'];
};

export type MutationSaveVehiclesArgs = {
  payload: Array<CreateVehicleInput>;
};

export type MutationSetCurrentDriverToVehicleDriverChangeHistoryArgs = {
  payload: SetDriverToVehicleDriverChangeHistoryInput;
};

export type MutationSetPreviousDriverToVehicleDriverChangeHistoryArgs = {
  payload: SetDriverToVehicleDriverChangeHistoryInput;
};

export type MutationSetVehicleDriverArgs = {
  payload: SetVehicleDriverInput;
};

export type MutationSetVehicleMeasureArgs = {
  payload: SetVehicleMeasureInput;
};

export type MutationTechnicianImportsArgs = {
  file: Scalars['Upload'];
};

export type MutationUpdateChipArgs = {
  updateChipInput: UpdateChipInput;
};

export type MutationUpdateDeviceArgs = {
  payload: UpdateDeviceInput;
};

export type MutationUpdateDriverArgs = {
  payload: UpdateDriverInput;
};

export type MutationUpdateDriverMappingsArgs = {
  payload: Array<UpdateDriverMappingInput>;
};

export type MutationUpdateFeatureFlagArgs = {
  payload: UpdateFeatureFlagInput;
};

export type MutationUpdateFuelTypeMappingsArgs = {
  payload: Array<UpdateFuelTypeMappingInput>;
};

export type MutationUpdateFuellingArgs = {
  payload: UpdateFuellingInput;
};

export type MutationUpdateGeofenceArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
  payload: UpdateGeofenceInput;
};

export type MutationUpdateGroupArgs = {
  payload: UpdateGroupInput;
};

export type MutationUpdateMaintenanceReminderArgs = {
  payload: UpdateMaintenanceReminderInput;
};

export type MutationUpdateOpsServiceArgs = {
  payload: UpdateOpsServiceInput;
};

export type MutationUpdateOrderArgs = {
  payload: UpdateOrderInput;
};

export type MutationUpdateOrganizationArgs = {
  payload: UpdateOrganizationInput;
};

export type MutationUpdateProviderArgs = {
  payload: UpdateProviderInput;
};

export type MutationUpdateProviderMappingsArgs = {
  payload: Array<UpdateProviderMappingInput>;
};

export type MutationUpdateServiceArgs = {
  payload: UpdateMaintenanceServiceInput;
};

export type MutationUpdateShippingArgs = {
  updateShippingInput: UpdateShippingInput;
};

export type MutationUpdateTankArgs = {
  payload: UpdateTankInput;
};

export type MutationUpdateTaskArgs = {
  payload: UpdateMaintenanceTaskInput;
};

export type MutationUpdateTechnicianArgs = {
  updateTechnicianInput: UpdateTechnicianInput;
};

export type MutationUpdateTrafficInfractionArgs = {
  payload: UpdateTrafficInfractionInput;
};

export type MutationUpdateUserArgs = {
  payload: UpdateUserInput;
};

export type MutationUpdateVehicleArgs = {
  payload: UpdateVehicleInput;
};

export type MutationUpdateVehicleMappingsArgs = {
  payload: Array<UpdateVehicleMappingInput>;
};

export type MutationUploadFileIntegrationArgs = {
  file: Scalars['Upload'];
  integrationId: Scalars['ID'];
};

export type MutationVehicleImportsArgs = {
  file: Scalars['Upload'];
  organizationId: Scalars['ID'];
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  actions: Array<NotificationAction>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  emailTemplate?: Maybe<NotificationEmailTemplate>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  interactedAt?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
  service: Scalars['ID'];
  status: Scalars['Int'];
  title: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Notification action */
export type NotificationAction = {
  __typename?: 'NotificationAction';
  deepLinkAndroid: Scalars['String'];
  deepLinkIOS: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type NotificationEmailTemplate = {
  __typename?: 'NotificationEmailTemplate';
  cc: Array<Scalars['String']>;
  co: Array<Scalars['String']>;
  subject: Scalars['String'];
  to: Scalars['String'];
};

/** Notification summary */
export type NotificationSummary = {
  __typename?: 'NotificationSummary';
  expired: Scalars['Int'];
  interacted: Scalars['Int'];
  read: Scalars['Int'];
  unExpired: Scalars['Int'];
  unInteracted: Scalars['Int'];
  unRead: Scalars['Int'];
};

/** Histórico do odometro */
export type OdometerHistory = {
  __typename?: 'OdometerHistory';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  current: Scalars['Float'];
  fuelling?: Maybe<Fuelling>;
  id: Scalars['ID'];
  /** Data de medição */
  measuredAt: Scalars['DateTime'];
  previous?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

export type OpsService = {
  __typename?: 'OpsService';
  category: OpsServiceCategory;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  device: Device;
  deviceId: Scalars['ID'];
  id: Scalars['ID'];
  isTaxable: Scalars['Boolean'];
  occurredAt: Scalars['DateTime'];
  organization: Organization;
  organizationId: Scalars['ID'];
  technician: Technician;
  technicianId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

export enum OpsServiceCategory {
  Installation = 'installation',
  Uninstallation = 'uninstallation',
}

/** Ordem */
export type Order = {
  __typename?: 'Order';
  /** Quantidade em litros */
  amount: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Numero da Nota */
  invoiceNumber: Scalars['Float'];
  /** Data que a ordem foi realizada */
  occurredAt: Scalars['DateTime'];
  organization: Organization;
  /** Origem da Ordem */
  source: Scalars['String'];
  tank?: Maybe<Tank>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Valor em R$ */
  value: Scalars['Float'];
};

/** Organização */
export type Organization = {
  __typename?: 'Organization';
  address?: Maybe<Scalars['String']>;
  apps: Array<App>;
  capacity?: Maybe<OrganizationCapacity>;
  category?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  /** Data de fechamento do cliente */
  contractBeganAt?: Maybe<Scalars['DateTime']>;
  /** Data de desativação do cliente */
  contractEndedAt?: Maybe<Scalars['DateTime']>;
  /** Duração do contrato */
  contractMonthDuration?: Maybe<Scalars['Int']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Organização com acesso cortado em */
  disabledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Pagamento recorrente mensal */
  monthlyPayment?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  /** Organização matriz da filial. */
  primaryOrganization?: Maybe<Organization>;
  sector?: Maybe<Scalars['String']>;
  /** Configurações globais da organização */
  settings: Scalars['jsonb'];
  /** Taxa de adesão */
  signUpFee?: Maybe<Scalars['Float']>;
  /** Início do trial */
  trialAt?: Maybe<Scalars['DateTime']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};

export enum OrganizationCapacity {
  Five = 'Five',
  Four = 'Four',
  One = 'One',
  Six = 'Six',
  Three = 'Three',
  Two = 'Two',
}

/** Parts */
export type Part = {
  __typename?: 'Part';
  /** status da peca */
  active: Scalars['Boolean'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Nome da Peca */
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  standard: Scalars['Boolean'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type PayTrafficInfractionInput = {
  driver?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Pago em... */
  paidAt: Scalars['DateTime'];
  /** Custo Pago */
  paidCost: Scalars['Float'];
};

/** Posição do veículo */
export type Position = {
  __typename?: 'Position';
  accuracy: Scalars['Float'];
  address?: Maybe<Scalars['String']>;
  altitude: Scalars['Float'];
  attributes: Scalars['jsonb'];
  course: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data no rastreador */
  deviceTime: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  /** Data real da localização */
  fixTime: Scalars['DateTime'];
  id: Scalars['ID'];
  ignition?: Maybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Data de quando a posição chegou ao servidor */
  serverTime: Scalars['DateTime'];
  source: Scalars['String'];
  speed: Scalars['Float'];
  status: VehicleStatus;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
  weight?: Maybe<Scalars['Float']>;
};

/** Fornecedor */
export type Provider = {
  __typename?: 'Provider';
  /** Status */
  active: Scalars['Boolean'];
  attributes: Scalars['jsonb'];
  cnpj?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Mapeamento de fornecedor */
export type ProviderMapping = {
  __typename?: 'ProviderMapping';
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  provider?: Maybe<Provider>;
  /** @deprecated Should use provider.id instead */
  providerId?: Maybe<Scalars['ID']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  app: App;
  apps: Array<App>;
  categories: Array<Category>;
  chips: Array<Chip>;
  countEvents: Array<EventSummary>;
  dailyWorkMeasures: Array<DailyWorkMeasure>;
  devices: DevicePaginate;
  driverMappings: Array<DriverMapping>;
  drivers: Array<Driver>;
  driversRanking: Array<DriverRanking>;
  featureFlags: Array<FeatureFlag>;
  findGeocode: Geocode;
  findInfractions: Array<Infraction>;
  findMaintenanceReminders: Array<MaintenanceReminder>;
  /** Busca as posições de um veículo em um período. */
  findPositions: Array<Position>;
  findTripsByVehicle: Array<Trip>;
  findVehicleEvents: Array<Event>;
  findVehicleFuellings: Array<Fuelling>;
  findVehicles: VehiclePaginate;
  fuelTypeMappings: Array<FuelTypeMapping>;
  fuelTypes: Array<FuelType>;
  geofence: Geofence;
  geofences: Array<Geofence>;
  getFuellingReference: FuellingReference;
  groups: Array<Group>;
  integration: Integration;
  integrations: Array<Integration>;
  maintenanceCostPerTypeReport: Array<MaintenanceCostPerTypeReportResponse>;
  maintenanceReminder: MaintenanceReminder;
  maintenanceSummary: Array<MaintenanceSummaryResponse>;
  me: User;
  modules: Array<AppModule>;
  myFeatureFlags: Array<UserFeatureFlag>;
  notifications: Array<Notification>;
  notificationsSummary: NotificationSummary;
  opsService: Array<OpsService>;
  orders: Array<Order>;
  organizations: Array<Organization>;
  parts: Array<Part>;
  providerMappings: Array<ProviderMapping>;
  providers: Array<Provider>;
  routeDetails: RouteDetails;
  services: Array<Service>;
  shippings: Array<Shipping>;
  tanks: Array<Tank>;
  tasks: Array<Task>;
  technicians: Array<Technician>;
  totalTimeStopped: TotalResponse;
  totalTimeStoppedWithIgnitionOn: TotalResponse;
  trafficInfractions: Array<TrafficInfraction>;
  user: User;
  users: Array<User>;
  vehicle: Vehicle;
  vehicleDriverChangeHistory: VehicleDriverChangeHistory;
  vehicleMappings: Array<VehicleMapping>;
  vehicleReport: Array<VehicleReport>;
  vehicleTypes: Array<VehicleType>;
  vehicles: Array<Vehicle>;
  vehiclesByPlate: Array<Vehicle>;
  vehiclesFuellingSummary: Array<VehiclesFuellingSummaryResponse>;
  vehiclesMaxSpeed: TotalResponse;
  vehiclesRanking: Array<VehicleRanking>;
  vehiclesSummaries: Array<VehicleSummary>;
  vehiclesTotalDistance: TotalResponse;
  vehiclesTotalTimeWithIgnitionOn: TotalResponse;
  workShiftEventTypes: Array<WorkShiftEventType>;
};

export type QueryAppArgs = {
  id: Scalars['ID'];
};

export type QueryCountEventsArgs = {
  endAt: Scalars['DateTime'];
  geofences?: InputMaybe<Array<Scalars['ID']>>;
  organizationId?: InputMaybe<Scalars['ID']>;
  slugNames?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type QueryDailyWorkMeasuresArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryDevicesArgs = {
  hasVehicle?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchVehicleOrganization?: InputMaybe<Scalars['ID']>;
  searchVehiclePlate?: InputMaybe<Scalars['String']>;
  vehicleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryDriverMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryDriversArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryDriversRankingArgs = {
  drivers: Array<Scalars['ID']>;
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
};

export type QueryFindGeocodeArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type QueryFindInfractionsArgs = {
  payload: FindInfractionInput;
};

export type QueryFindPositionsArgs = {
  endAt: Scalars['DateTime'];
  limit?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryFindTripsByVehicleArgs = {
  finishedAt: Scalars['DateTime'];
  startedAt: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryFindVehicleEventsArgs = {
  endAt: Scalars['DateTime'];
  geofences?: InputMaybe<Array<Scalars['ID']>>;
  organizationId?: InputMaybe<Scalars['ID']>;
  slugNames?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type QueryFindVehicleFuellingsArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryFindVehiclesArgs = {
  filter?: InputMaybe<VehicleFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryFuelTypeMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryGeofenceArgs = {
  id: Scalars['ID'];
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryGeofencesArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetFuellingReferenceArgs = {
  date: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};

export type QueryMaintenanceCostPerTypeReportArgs = {
  payload: MaintenanceCostPerTypeReportInput;
};

export type QueryMaintenanceReminderArgs = {
  id: Scalars['ID'];
};

export type QueryMaintenanceSummaryArgs = {
  payload: MaintenanceSummaryInput;
};

export type QueryNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryOrdersArgs = {
  filter?: InputMaybe<FilterOrder>;
};

export type QueryPartsArgs = {
  filter?: InputMaybe<FilterPartInput>;
};

export type QueryProviderMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryRouteDetailsArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryServicesArgs = {
  filter?: InputMaybe<FilterServiceInput>;
};

export type QueryTasksArgs = {
  filter?: InputMaybe<FilterTaskInput>;
};

export type QueryTotalTimeStoppedArgs = {
  payload: ReportInput;
};

export type QueryTotalTimeStoppedWithIgnitionOnArgs = {
  payload: ReportInput;
};

export type QueryTrafficInfractionsArgs = {
  payload: ListTrafficInfractionInput;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['ID']>;
};

export type QueryVehicleArgs = {
  id: Scalars['ID'];
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryVehicleDriverChangeHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryVehicleMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryVehicleReportArgs = {
  payload: VehicleReportInput;
};

export type QueryVehiclesArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryVehiclesByPlateArgs = {
  plate: Scalars['String'];
};

export type QueryVehiclesFuellingSummaryArgs = {
  payload: ReportInput;
};

export type QueryVehiclesMaxSpeedArgs = {
  payload: ReportInput;
};

export type QueryVehiclesRankingArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryVehiclesSummariesArgs = {
  endAt: Scalars['DateTime'];
  groupedBy?: InputMaybe<SummaryDateGroupedByEnum>;
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryVehiclesTotalDistanceArgs = {
  payload: ReportInput;
};

export type QueryVehiclesTotalTimeWithIgnitionOnArgs = {
  payload: ReportInput;
};

export type ReferenceTaskCostInput = {
  /** Valor */
  amount?: InputMaybe<Scalars['Float']>;
  type: CostTypeEnum;
};

export type ReferenceTaskPartInput = {
  /** Valor */
  amount?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  /** Quantidade */
  quantity?: InputMaybe<Scalars['Int']>;
};

export type ReportInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleIds: Array<Scalars['ID']>;
};

export type RouteDetails = {
  __typename?: 'RouteDetails';
  averageSpeed?: Maybe<Scalars['Float']>;
  engineIdle?: Maybe<Scalars['Float']>;
  geojson: Scalars['jsonb'];
  maximumSpeed?: Maybe<Scalars['Float']>;
  totalDistanceTraveled?: Maybe<Scalars['Float']>;
  totalTimeStopped?: Maybe<Scalars['Float']>;
  totalTimeStoppedWithIgnitionOn?: Maybe<Scalars['Float']>;
  totalTimeWithIgnitionOn?: Maybe<Scalars['Float']>;
};

/** Service */
export type Service = {
  __typename?: 'Service';
  /** status do servico */
  active?: Maybe<Scalars['Boolean']>;
  category: Category;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que o serviço foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Nome do Servico */
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  /** padrao do sistema */
  standard?: Maybe<Scalars['Boolean']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type SetDriverToVehicleDriverChangeHistoryInput = {
  driver: Scalars['ID'];
  id: Scalars['ID'];
};

export type SetVehicleDriverInput = {
  driver?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  vehicle: Scalars['ID'];
};

export type SetVehicleMeasureInput = {
  /** Valor atual do horimetro */
  horimeter?: InputMaybe<Scalars['Float']>;
  /** Valor atual do odometro */
  odometer?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  vehicle: Scalars['ID'];
};

/** Envios */
export type Shipping = {
  __typename?: 'Shipping';
  city: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  devices?: Maybe<Array<Device>>;
  devolution: Scalars['Boolean'];
  id: Scalars['ID'];
  occurredAt: Scalars['DateTime'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  state: Scalars['String'];
  technician?: Maybe<Technician>;
  technicianId?: Maybe<Scalars['ID']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

/** Parada */
export type Stop = {
  __typename?: 'Stop';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  driverId?: Maybe<Scalars['ID']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  firstPosition: Position;
  id: Scalars['ID'];
  lastPosition?: Maybe<Position>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  startedAt: Scalars['DateTime'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newVehicleLocation: Location;
  syncIntegration: Integration;
  updateVehicle: Vehicle;
};

export type SubscriptionNewVehicleLocationArgs = {
  vehicles: Array<Scalars['ID']>;
};

export type SubscriptionSyncIntegrationArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionUpdateVehicleArgs = {
  vehicles: Array<Scalars['ID']>;
};

export enum SummaryDateGroupedByEnum {
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Month = 'month',
  Quarter = 'quarter',
  Second = 'second',
  Week = 'week',
  Year = 'year',
}

/** Tanque */
export type Tank = {
  __typename?: 'Tank';
  /** Status do tank */
  active?: Maybe<Scalars['Boolean']>;
  attributes: Scalars['jsonb'];
  balance: Scalars['Float'];
  /** Capacidade máxima do tanque */
  capacity?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que o tank foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Volume inicial do tanque */
  initialVolume?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  organization: Organization;
  /** Preço por litro */
  pricePerLiter?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Transação do tanque */
export type TankTransaction = {
  __typename?: 'TankTransaction';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  fuelling?: Maybe<Fuelling>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  tank: Tank;
  /** Tipo de lançamento */
  type: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Quantidade em litros */
  value: Scalars['Float'];
};

/** Service */
export type Task = {
  __typename?: 'Task';
  /** Custo total */
  amount: Scalars['Float'];
  /** Data que o servico foi concluido */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a manutenção foi deletada */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Descricao do Servico */
  description: Scalars['String'];
  driver?: Maybe<Driver>;
  /** Horimetro */
  horimeter?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Odometro */
  odometer?: Maybe<Scalars['Float']>;
  parts: Array<TaskPart>;
  prices: Array<TaskCost>;
  provider?: Maybe<Provider>;
  service: Service;
  /** Tipo de Servico */
  type: TaskTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

/** Task Cost */
export type TaskCost = {
  __typename?: 'TaskCost';
  /** Valor total */
  amount: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  task: Task;
  /** Tipo de preco */
  type: CostTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Task_Parts */
export type TaskPart = {
  __typename?: 'TaskPart';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  part: Part;
  /** Valor em R$ */
  price: Scalars['Float'];
  /** Quantidade */
  quantity: Scalars['Int'];
  /** Subtotal */
  subTotal: Scalars['Float'];
  task: Task;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export enum TaskTypeEnum {
  Corrective = 'corrective',
  Preventive = 'preventive',
}

/** Técnico */
export type Technician = {
  __typename?: 'Technician';
  additionalFee?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAccountDigit?: Maybe<Scalars['String']>;
  bankAgency?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankOwnerDocumentNumber?: Maybe<Scalars['String']>;
  bankOwnerName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contractSignedAt?: Maybe<Scalars['DateTime']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hasReceipt?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  installationPrice?: Maybe<Scalars['Float']>;
  maintenancePrice?: Maybe<Scalars['Float']>;
  movementPrice?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  observation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pix?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  uninstallationPrice?: Maybe<Scalars['Float']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type TotalResponse = {
  __typename?: 'TotalResponse';
  total: Scalars['Float'];
};

/** Infrações de transito */
export type TrafficInfraction = {
  __typename?: 'TrafficInfraction';
  /** Endereço */
  address?: Maybe<Scalars['String']>;
  /** Valor em R$ da infração */
  cost?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a multa foi deletada */
  deletedAt?: Maybe<Scalars['DateTime']>;
  driver?: Maybe<Driver>;
  /** Data de vencimento */
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  infraction: Infraction;
  /** Localização */
  location?: Maybe<Scalars['jsonb']>;
  /** Observação */
  observation?: Maybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  paidAt?: Maybe<Scalars['DateTime']>;
  /** Custo pago */
  paidCost?: Maybe<Scalars['Float']>;
  /** Documento referência */
  referenceDocument?: Maybe<Scalars['String']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

export type TrafficInfractionLocationInput = {
  /** Custo */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Custo */
  longitude?: InputMaybe<Scalars['Float']>;
};

/** Viagem */
export type Trip = {
  __typename?: 'Trip';
  averageSpeed?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  distanceTraveled?: Maybe<Scalars['Float']>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  eventsJson?: Maybe<Scalars['jsonb']>;
  finalStop?: Maybe<Stop>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  fuelConsumedLiters?: Maybe<Scalars['Float']>;
  fuelConsumedPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  initialStop: Stop;
  maximumSpeed?: Maybe<Scalars['Float']>;
  startedAt: Scalars['DateTime'];
  timeAboveMaxConfSpeed?: Maybe<Scalars['Int']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

export type UpdateChipInput = {
  broker?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['ID']>;
  enabledAt?: InputMaybe<Scalars['DateTime']>;
  iccid?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  msisdn?: InputMaybe<Scalars['String']>;
  telecomOperator?: InputMaybe<Scalars['String']>;
};

export type UpdateDeviceInput = {
  chipId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  installedAt?: InputMaybe<Scalars['DateTime']>;
  /** Modelo do dispositivo */
  model?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  technicianId?: InputMaybe<Scalars['ID']>;
  tenure?: InputMaybe<Scalars['String']>;
  uninstalledAt?: InputMaybe<Scalars['DateTime']>;
  /** IMEI do dispositivo */
  uniqueId?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateDriverInput = {
  /** Data de nascimento */
  birthday?: InputMaybe<Scalars['DateTime']>;
  canLogin?: InputMaybe<Scalars['Boolean']>;
  cnh?: InputMaybe<Scalars['String']>;
  cnhCategory?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverMappingInput = {
  driverId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  valid: Scalars['Boolean'];
};

export type UpdateFeatureFlagInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type UpdateFuelTypeMappingInput = {
  fuelType?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  valid: Scalars['Boolean'];
};

export type UpdateFuellingInput = {
  /** Quantidade de combustível abastecido em L */
  amount?: InputMaybe<Scalars['Float']>;
  /** Custo em R$ do abastecimento */
  cost?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Encheu o tanque ou não? */
  filledUp?: InputMaybe<Scalars['Boolean']>;
  /** Tipo do combustível: diesel, gas... */
  fuelType?: InputMaybe<Scalars['String']>;
  /** Horimetro no momento do abastecimento */
  horimeter?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceEvidence?: InputMaybe<Scalars['ID']>;
  /** Abastecido no posto interno ou não */
  isInternal?: InputMaybe<Scalars['Boolean']>;
  /** Data que o abastecimento foi realizado */
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  /** Odômetro no momento do abastecimento */
  odometer?: InputMaybe<Scalars['Float']>;
  odometerEvidence?: InputMaybe<Scalars['ID']>;
  provider?: InputMaybe<Scalars['ID']>;
  tank?: InputMaybe<Scalars['ID']>;
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type UpdateGeofenceInput = {
  /** Geometria da cerca em GeoJSON */
  area?: InputMaybe<Scalars['jsonb']>;
  /** Código hexiadecimal da cor #HEX */
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  parking?: InputMaybe<Scalars['Boolean']>;
  /** Tipo da cerca: poligon e circle */
  type?: InputMaybe<GeofenceTypeEnum>;
};

export type UpdateGroupInput = {
  id: Scalars['ID'];
  /** Nome */
  name: Scalars['String'];
  /** Organização */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Veiculos */
  vehicles: Array<Scalars['ID']>;
};

export type UpdateMaintenanceReminderInput = {
  /** Frequência do lembrete por medida */
  frequencyMeasure?: InputMaybe<Scalars['Float']>;
  /** Frequência do lembrete por data */
  frequencyTime?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Medição na última manuteção */
  lastMeasure?: InputMaybe<Scalars['Float']>;
  /** Data da última manuteção */
  lastOccurrence?: InputMaybe<Scalars['DateTime']>;
  /** Serviço */
  service?: InputMaybe<Scalars['ID']>;
  /** Aviso com antecedência por medida */
  thresholdMeasure?: InputMaybe<Scalars['Float']>;
  /** Aviso com antecedência por data */
  thresholdTime?: InputMaybe<Scalars['Int']>;
  /** Veículo */
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type UpdateMaintenanceServiceInput = {
  category?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Nome do serviço */
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['ID']>;
  standard?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateMaintenanceTaskInput = {
  /** Data do servico */
  completedAt?: InputMaybe<Scalars['DateTime']>;
  /** Descricao do servico */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Valor do horimeter */
  horimeter?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Valor do odometro */
  odometer?: InputMaybe<Scalars['Float']>;
  organization?: InputMaybe<Scalars['ID']>;
  /** pecas */
  parts?: InputMaybe<Array<ReferenceTaskPartInput>>;
  /** precos */
  prices?: InputMaybe<Array<ReferenceTaskCostInput>>;
  provider?: InputMaybe<Scalars['ID']>;
  service?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<TaskTypeEnum>;
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type UpdateOpsServiceInput = {
  category?: InputMaybe<OpsServiceCategory>;
  deviceId?: InputMaybe<Scalars['ID']>;
  deviceTenure?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isTaxable?: InputMaybe<Scalars['Boolean']>;
  isTrial?: InputMaybe<Scalars['Boolean']>;
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  shouldDisableVehicle?: InputMaybe<Scalars['Boolean']>;
  technicianId?: InputMaybe<Scalars['ID']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateOrderInput = {
  /** Custo em R$ da ordem */
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Numero da Nota */
  invoiceNumber?: InputMaybe<Scalars['Float']>;
  /** Data da Ordem */
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  organization?: InputMaybe<Scalars['ID']>;
  /** Origem da Ordem */
  source?: InputMaybe<Scalars['String']>;
  tank?: InputMaybe<Scalars['ID']>;
  /** Valor em R$ da ordem */
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<Scalars['String']>;
  apps?: InputMaybe<Array<Scalars['ID']>>;
  capacity?: InputMaybe<OrganizationCapacity>;
  category?: InputMaybe<Scalars['String']>;
  cnpj?: InputMaybe<Scalars['String']>;
  contractBeganAt?: InputMaybe<Scalars['DateTime']>;
  /** Data que o contrato foi finalizado */
  contractEndedAt?: InputMaybe<Scalars['DateTime']>;
  contractMonthDuration?: InputMaybe<Scalars['Float']>;
  /** Data que o acesso do cliente será cortado */
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Razão Social */
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  primaryOrganization?: InputMaybe<Scalars['ID']>;
  sector?: InputMaybe<Scalars['String']>;
  /** Configurações globais da organização */
  settings?: InputMaybe<Scalars['jsonb']>;
  signUpFee?: InputMaybe<Scalars['Float']>;
  /** Data que o cliente começou o trial */
  trialAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateProviderInput = {
  /** CNPJ do fornecedor / oficina */
  cnpj?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Nome do fornecedor ou oficina */
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type UpdateProviderMappingInput = {
  id: Scalars['ID'];
  providerId?: InputMaybe<Scalars['ID']>;
  valid: Scalars['Boolean'];
};

export type UpdateShippingInput = {
  city?: InputMaybe<Scalars['String']>;
  devices?: InputMaybe<Array<Scalars['ID']>>;
  devolution?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['String']>;
  technicianId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTankInput = {
  /** Capacidade máxima do tanque */
  capacity?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Volume inicial do tanque */
  initialVolume?: InputMaybe<Scalars['Float']>;
  /** Nome do tanque de combustível */
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTechnicianInput = {
  additionalFee?: InputMaybe<Scalars['Float']>;
  address?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bankAccountDigit?: InputMaybe<Scalars['String']>;
  bankAgency?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankOwnerDocumentNumber?: InputMaybe<Scalars['String']>;
  bankOwnerName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contractSignedAt?: InputMaybe<Scalars['DateTime']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  installationPrice?: InputMaybe<Scalars['Float']>;
  maintenancePrice?: InputMaybe<Scalars['Float']>;
  movementPrice?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pix?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  uninstallationPrice?: InputMaybe<Scalars['Float']>;
};

export type UpdateTrafficInfractionInput = {
  /** Endereço */
  address?: InputMaybe<Scalars['String']>;
  /** Custo */
  cost?: InputMaybe<Scalars['Float']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Data de vencimento */
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  infraction: Scalars['ID'];
  /** Localização */
  location?: InputMaybe<TrafficInfractionLocationInput>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Pago em... */
  paidAt?: InputMaybe<Scalars['DateTime']>;
  /** Custo Pago */
  paidCost?: InputMaybe<Scalars['Float']>;
  /** Documento de referência */
  referenceDocument?: InputMaybe<Scalars['String']>;
  vehicle: Scalars['ID'];
};

export type UpdateUserInput = {
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  modules?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  /** primary user organization */
  organization?: InputMaybe<Scalars['ID']>;
  organizations?: InputMaybe<Array<Scalars['ID']>>;
  role?: InputMaybe<UserRole>;
};

export type UpdateVehicleInput = {
  /** Autonomia do veículo */
  autonomy?: InputMaybe<Scalars['Float']>;
  avatarType?: InputMaybe<VehicleTypeEnum>;
  /** Marca, ex: GM, Nissan, Toyota... */
  brand?: InputMaybe<Scalars['String']>;
  /** Veículo desabilitado */
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  /** Documento */
  document?: InputMaybe<Scalars['String']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType?: InputMaybe<FuelTypeEnum>;
  id: Scalars['ID'];
  lastDriverToken?: InputMaybe<Scalars['String']>;
  /** Velocidade máxima */
  maxSpeed?: InputMaybe<Scalars['Float']>;
  /** Volume máximo */
  maximumVolume?: InputMaybe<Scalars['Int']>;
  /** Carga máxima */
  maximumWeight?: InputMaybe<Scalars['Int']>;
  /** Velocidade mínima para ser considerado como parado */
  minSpeed?: InputMaybe<Scalars['Float']>;
  /** Duração mínima do estacionamento */
  minimalParkingDuration?: InputMaybe<Scalars['Float']>;
  /** Modelo, ex: Fiesta, March, C3... */
  model?: InputMaybe<Scalars['String']>;
  /** Pagamento Recorrente Mensal */
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Identificação */
  plate?: InputMaybe<Scalars['String']>;
  /** Preço */
  price?: InputMaybe<Scalars['Float']>;
  /** Configurações globais do veículo */
  settings?: InputMaybe<Scalars['jsonb']>;
  /** Taxa de Adesão */
  signUpFee?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  /** Data de início da cobrança */
  taxabledAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
  useObdData?: InputMaybe<Scalars['Boolean']>;
  /** Ano do veículo */
  year?: InputMaybe<Scalars['String']>;
};

export type UpdateVehicleMappingInput = {
  id: Scalars['ID'];
  valid: Scalars['Boolean'];
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  /** Lista de veículos que o usuário tem controle */
  availableVehicles: Array<Vehicle>;
  cpf?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a usuario foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  /** Data de verificação do e-mail */
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Modulos */
  modules: Scalars['jsonb'];
  name?: Maybe<Scalars['String']>;
  /** Organização principal do usuário */
  organization: Organization;
  /** Lista de organizações a qual o usuário tem acesso */
  organizations: Array<Organization>;
  /** RBAC: https://casl.js.org/ */
  permissions: Scalars['jsonb'];
  role: UserRole;
  /** Configurações globais da conta do usuário */
  settings: Scalars['jsonb'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Veículos diretamente associados ao usuário */
  vehicles: Array<Vehicle>;
};

/** Funcionalidades que estão liberadas para o usuário logado */
export type UserFeatureFlag = {
  __typename?: 'UserFeatureFlag';
  /** Status da funcionalidade */
  enabled: Scalars['Boolean'];
  /** Código da funcionalidade */
  feature: Scalars['String'];
};

export enum UserRole {
  Admin = 'admin',
  Director = 'director',
  Driver = 'driver',
  Manager = 'manager',
  User = 'user',
}

/** Veículo */
export type Vehicle = {
  __typename?: 'Vehicle';
  /** Autonomia do veículo */
  autonomy?: Maybe<Scalars['Float']>;
  avatarType: VehicleTypeEnum;
  /** Marca, ex: GM, Nissan, Toyota... */
  brand?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
  /** Veículo desabilitado */
  disabledAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  /** Documento */
  document?: Maybe<Scalars['String']>;
  driver?: Maybe<Driver>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  externalId?: Maybe<Scalars['String']>;
  fuelLevel?: Maybe<Scalars['Float']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType?: Maybe<FuelTypeEnum>;
  groups: Array<Group>;
  horimeter: Scalars['Float'];
  horimeterAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastDriverToken?: Maybe<Scalars['String']>;
  lastHorimeterHistory?: Maybe<HorimeterHistory>;
  lastOdometerHistory?: Maybe<OdometerHistory>;
  location?: Maybe<Location>;
  /** Velocidade máxima */
  maxSpeed?: Maybe<Scalars['Float']>;
  /** Volume máximo */
  maximumVolume?: Maybe<Scalars['Int']>;
  /** Carga máxima */
  maximumWeight?: Maybe<Scalars['Int']>;
  /** Velocidade mínima para ser considerado como parado */
  minSpeed?: Maybe<Scalars['Float']>;
  /** Duração mínima do estacionamento */
  minimalParkingDuration?: Maybe<Scalars['Float']>;
  /** Modelo, ex: Fiesta, March, C3... */
  model?: Maybe<Scalars['String']>;
  /** Pagamento Recorrente Mensal */
  monthlyPayment?: Maybe<Scalars['Float']>;
  /** Observação */
  observation?: Maybe<Scalars['String']>;
  odometer: Scalars['Float'];
  odometerAt?: Maybe<Scalars['DateTime']>;
  organization: Organization;
  plate: Scalars['String'];
  pluggedStatus?: Maybe<Scalars['Boolean']>;
  pluggedStatusAt?: Maybe<Scalars['DateTime']>;
  /** Preço */
  price?: Maybe<Scalars['Float']>;
  /** Configurações globais do veículo */
  settings: Scalars['jsonb'];
  /** Taxa de Adesão */
  signUpFee?: Maybe<Scalars['Float']>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  source?: Maybe<Scalars['String']>;
  /** @deprecated Should use the location.status instead, this should be removed in next releases */
  status: VehicleStatus;
  /** Volume máximo do tanque (L) */
  tankSize?: Maybe<Scalars['Int']>;
  /** Data de início da cobrança */
  taxabledAt?: Maybe<Scalars['DateTime']>;
  type: VehicleTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Determina se o processamento deve considerar ou não os dados da rede CAN */
  useObdData: Scalars['Boolean'];
  /** Ano do veículo */
  year?: Maybe<Scalars['String']>;
};

/** Histórico de troca de motorista */
export type VehicleDriverChangeHistory = {
  __typename?: 'VehicleDriverChangeHistory';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  currentDriver?: Maybe<Driver>;
  currentDriverToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Data da troca de motorista */
  occurredAt: Scalars['DateTime'];
  position?: Maybe<Position>;
  previousDriver?: Maybe<Driver>;
  previousDriverToken?: Maybe<Scalars['String']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

export type VehicleFilterInput = {
  /** Desabilitado */
  disabled?: InputMaybe<Scalars['Boolean']>;
  /** Apelido */
  displayName?: InputMaybe<Scalars['String']>;
  /** Veículo tem dispositivo */
  hasDevice?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Nome da organização */
  organizationName?: InputMaybe<Scalars['String']>;
  /** Placa */
  plate?: InputMaybe<Scalars['String']>;
};

/** Mapeamento de veículos */
export type VehicleMapping = {
  __typename?: 'VehicleMapping';
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data de desativação */
  disabledAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  vehicle?: Maybe<Vehicle>;
  /** @deprecated Should use vehicle.id instead */
  vehicleId?: Maybe<Scalars['ID']>;
};

export type VehiclePaginate = {
  __typename?: 'VehiclePaginate';
  /** Total de registros */
  total: Scalars['Int'];
  /** Veículos */
  vehicles: Array<Vehicle>;
};

export type VehicleRanking = {
  __typename?: 'VehicleRanking';
  avgSpeed: Scalars['Float'];
  engineIdleByDay: Scalars['jsonb'];
  maxSpeed: Scalars['Float'];
  percentageTimeEngineIdle: Scalars['Float'];
  telemetrySummary: Scalars['jsonb'];
  totalTelemetryEvents: Scalars['Int'];
  totalTraveled: Scalars['Float'];
  vehicleId: Scalars['ID'];
};

export type VehicleReport = {
  __typename?: 'VehicleReport';
  /** Autonomia do veiculo da tabela de abastecimento */
  autonomy?: Maybe<Scalars['Float']>;
  /** Custo por hora */
  costPerHour: Scalars['Float'];
  /** Custo por km */
  costPerKM: Scalars['Float'];
  /** Distancia percorrida em metros da tabela de viagem */
  distanceTraveled?: Maybe<Scalars['Float']>;
  /** valor das despesas */
  expenditure: Scalars['Float'];
  /** Quantidade de combustivel abastecimento */
  fuelConsumedLiters?: Maybe<Scalars['Float']>;
  /** Custo de combustivel da tabela de abastecimento */
  fuelConsumedPrice?: Maybe<Scalars['Float']>;
  /** Distancia percorrida em metros da tabela de abastecimento */
  fuelDistanceTraveled?: Maybe<Scalars['Float']>;
  /** Tempo gasto em ms da tabela de abastecimento */
  fuelTimeTraveled?: Maybe<Scalars['Float']>;
  /** Quantidade de manutenções */
  maintenance: Scalars['Float'];
  /** Custo das menutenções */
  maintenancePrice: Scalars['Float'];
  /** Competência do report */
  startAt: Scalars['String'];
  /** Tempo gasto em ms da tabela de viagem */
  timeTraveled?: Maybe<Scalars['Float']>;
  /** Total de gastos */
  totalCost: Scalars['Float'];
  /** Autonomia do veiculo da tabela de viegens */
  tripAutonomy?: Maybe<Scalars['Float']>;
  /** Quantidade de combustivel consumido da tabela de viagem */
  tripFuelConsumedLiters?: Maybe<Scalars['Float']>;
  /** Custo de combustivel da tabela de viagem */
  tripFuelConsumedPrice?: Maybe<Scalars['Float']>;
};

export type VehicleReportInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
};

export enum VehicleStatus {
  Off = 'OFF',
  On = 'ON',
  Unknown = 'UNKNOWN',
}

/** Relatório diário de telemetria */
export type VehicleSummary = {
  __typename?: 'VehicleSummary';
  averageSpeed: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  distanceTraveled: Scalars['Float'];
  endAt: Scalars['DateTime'];
  engineIdle: Scalars['Float'];
  eventOccurrences: EventOccurrences;
  /** @deprecated This field is not valid, should not be used */
  id: Scalars['ID'];
  maximumSpeed: Scalars['Float'];
  positions: Scalars['Int'];
  startAt: Scalars['DateTime'];
  timeOverSpeed: Scalars['Int'];
  timeStopped: Scalars['Int'];
  timeStoppedWithIgnitionOn: Scalars['Int'];
  timeWithIgnitionOn: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

/** Tipo do veículo */
export type VehicleType = {
  __typename?: 'VehicleType';
  name: Scalars['String'];
  slugName: VehicleTypeEnum;
};

export enum VehicleTypeEnum {
  Bus = 'bus',
  Car = 'car',
  Equipment = 'equipment',
  Forklift = 'forklift',
  GarbageCollector = 'garbage_collector',
  Motorcycle = 'motorcycle',
  Trailer = 'trailer',
  Truck = 'truck',
}

export type VehiclesFuellingSummaryResponse = {
  __typename?: 'VehiclesFuellingSummaryResponse';
  amount: Scalars['Float'];
  average_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  average_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  average_cost: Scalars['Float'];
  average_unit_cost: Scalars['Float'];
  fuel_used_from_can?: Maybe<Scalars['Float']>;
  fuellings: Scalars['Float'];
  horimeter_autonomy_from_can?: Maybe<Scalars['Float']>;
  maximum_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  maximum_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  maximum_cost: Scalars['Float'];
  maximum_unit_cost: Scalars['Float'];
  minimum_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  minimum_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  minimum_cost: Scalars['Float'];
  minimum_unit_cost: Scalars['Float'];
  odometer_autonomy_from_can?: Maybe<Scalars['Float']>;
  total_cost: Scalars['Float'];
  vehicle_id: Scalars['String'];
};

/** Jornada */
export type WorkShift = {
  __typename?: 'WorkShift';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Final da jornada */
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  organization: Organization;
  /** Inicio da jornada */
  startedAt: Scalars['DateTime'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  user: User;
  vehicle: Vehicle;
};

/** Tipo de eventos de uma Jornada */
export type WorkShiftEventType = {
  __typename?: 'WorkShiftEventType';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  /** Nome do icone do material-icons */
  iconName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  slugName: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type LoginWithCredentialsMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginWithCredentialsMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'Authorization';
    accessToken: string;
    refreshToken?: string | null | undefined;
    user: {
      __typename?: 'User';
      id: string;
      name?: string | null | undefined;
      cpf?: string | null | undefined;
      email?: string | null | undefined;
      role: UserRole;
      createdAt: any;
      updatedAt: any;
      settings: any;
      modules: any;
      permissions: any;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null | undefined;
        disabledAt?: any | null | undefined;
        createdAt: any;
        trialAt?: any | null | undefined;
        contractEndedAt?: any | null | undefined;
        address?: string | null | undefined;
        phone?: string | null | undefined;
        settings: any;
        signUpFee?: number | null | undefined;
        monthlyPayment?: number | null | undefined;
        capacity?: OrganizationCapacity | null | undefined;
        category?: string | null | undefined;
        sector?: string | null | undefined;
        contractBeganAt?: any | null | undefined;
        contractMonthDuration?: number | null | undefined;
      };
      vehicles: Array<{
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        displayName?: string | null | undefined;
        model?: string | null | undefined;
        brand?: string | null | undefined;
        type: VehicleTypeEnum;
        year?: string | null | undefined;
        fuelType?: FuelTypeEnum | null | undefined;
        document?: string | null | undefined;
        observation?: string | null | undefined;
        avatarType: VehicleTypeEnum;
        price?: number | null | undefined;
        lastDriverToken?: string | null | undefined;
        odometer: number;
        horimeter: number;
        fuelLevel?: number | null | undefined;
        settings: any;
        useObdData: boolean;
        max_speed?: number | null | undefined;
        min_speed?: number | null | undefined;
        tank_size?: number | null | undefined;
        created_at: any;
        updated_at: any;
        plugged_status?: boolean | null | undefined;
        plugged_status_at?: any | null | undefined;
      }>;
    };
  };
};

export type RequestPasswordRecoveryMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type RequestPasswordRecoveryMutation = {
  __typename?: 'Mutation';
  requestPasswordRecovery: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{ __typename?: 'Category'; id: string; name: string }>;
};

export type ChangeUserPasswordMutationVariables = Exact<{
  user: Scalars['ID'];
  password: Scalars['String'];
}>;

export type ChangeUserPasswordMutation = {
  __typename?: 'Mutation';
  changeUserPassword: boolean;
};

export type ChangeUserVehiclesMutationVariables = Exact<{
  change: ChangeUserVehiclesInput;
}>;

export type ChangeUserVehiclesMutation = {
  __typename?: 'Mutation';
  changeUserVehicles: boolean;
};

export type ChangeVehicleMeasureMutationVariables = Exact<{
  vehicleId: Scalars['ID'];
  measure: Scalars['Float'];
  measurement: MeasurementTypeEnum;
  measuredAt: Scalars['DateTime'];
}>;

export type ChangeVehicleMeasureMutation = {
  __typename?: 'Mutation';
  changeVehicleMeasure: {
    __typename?: 'DailyWorkMeasure';
    id: string;
    vehicleId: string;
  };
};

export type CreateDriverMutationVariables = Exact<{
  payload: CreateDriverInput;
}>;

export type CreateDriverMutation = {
  __typename?: 'Mutation';
  createDriver: {
    __typename?: 'Driver';
    id: string;
    name?: string | null | undefined;
    cpf?: string | null | undefined;
    token?: string | null | undefined;
    organization: { __typename?: 'Organization'; id: string; name: string };
    user?: { __typename?: 'User'; id: string } | null | undefined;
  };
};

export type CreateFuellingMutationVariables = Exact<{
  payload: CreateFuellingInput;
}>;

export type CreateFuellingMutation = {
  __typename?: 'Mutation';
  createFuelling: {
    __typename?: 'Fuelling';
    id: string;
    amount: number;
    occurredAt: any;
    cost?: number | null | undefined;
    fuelType: string;
    attributes: any;
    odometer?: number | null | undefined;
    horimeter?: number | null | undefined;
    description?: string | null | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
    tank?:
      | {
          __typename?: 'Tank';
          id: string;
          name: string;
          initialVolume?: number | null | undefined;
          capacity?: number | null | undefined;
          balance: number;
        }
      | null
      | undefined;
    invoiceEvidence?:
      | {
          __typename?: 'DocumentFile';
          category: string;
          createdAt: any;
          fileName: string;
          id: string;
          mimeType: string;
          organizationId: string;
          size: number;
          updatedAt: any;
          url: string;
        }
      | null
      | undefined;
    odometerEvidence?:
      | {
          __typename?: 'DocumentFile';
          category: string;
          createdAt: any;
          fileName: string;
          id: string;
          mimeType: string;
          organizationId: string;
          size: number;
          updatedAt: any;
          url: string;
        }
      | null
      | undefined;
  };
};

export type CreateMaintenanceReminderMutationVariables = Exact<{
  reminders:
    | Array<CreateMaintenanceReminderInput>
    | CreateMaintenanceReminderInput;
}>;

export type CreateMaintenanceReminderMutation = {
  __typename?: 'Mutation';
  createMaintenanceReminders: Array<{
    __typename?: 'MaintenanceReminder';
    id: string;
    createdAt: any;
    updatedAt: any;
    frequencyTime?: number | null | undefined;
    thresholdTime?: number | null | undefined;
    frequencyMeasure?: number | null | undefined;
    thresholdMeasure?: number | null | undefined;
    lastOccurrence: any;
    lastMeasure: number;
    remainingMeasure: number;
    remainingDays: number;
    service: {
      __typename?: 'Service';
      id: string;
      name: string;
      organization?:
        | { __typename?: 'Organization'; id: string }
        | null
        | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
    };
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
  }>;
};

export type CreateOrderMutationVariables = Exact<{
  order: CreateOrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  createOrder: {
    __typename?: 'Order';
    id: string;
    invoiceNumber: number;
    amount: number;
    value: number;
    occurredAt: any;
    tank?:
      | {
          __typename?: 'Tank';
          id: string;
          name: string;
          initialVolume?: number | null | undefined;
          capacity?: number | null | undefined;
          balance: number;
        }
      | null
      | undefined;
  };
};

export type CreatePartMutationVariables = Exact<{
  payload: CreateMaintenanceParteInput;
}>;

export type CreatePartMutation = {
  __typename?: 'Mutation';
  createPart: {
    __typename?: 'Part';
    id: string;
    name: string;
    standard: boolean;
    createdAt: any;
  };
};

export type CreateProviderMutationVariables = Exact<{
  provider: CreateProviderInput;
}>;

export type CreateProviderMutation = {
  __typename?: 'Mutation';
  createProvider: {
    __typename?: 'Provider';
    id: string;
    name: string;
    cnpj?: string | null | undefined;
    source: string;
  };
};

export type CreateServiceMutationVariables = Exact<{
  payload: CreateMaintenanceServiceInput;
}>;

export type CreateServiceMutation = {
  __typename?: 'Mutation';
  createService: {
    __typename?: 'Service';
    id: string;
    name: string;
    organization?:
      | { __typename?: 'Organization'; id: string }
      | null
      | undefined;
    category: { __typename?: 'Category'; id: string; name: string };
  };
};

export type CreateTankMutationVariables = Exact<{
  tank: CreateTankInput;
}>;

export type CreateTankMutation = {
  __typename?: 'Mutation';
  createTank: {
    __typename?: 'Tank';
    id: string;
    name: string;
    initialVolume?: number | null | undefined;
    capacity?: number | null | undefined;
    balance: number;
  };
};

export type CreateTaskMutationVariables = Exact<{
  payload: CreateMaintenanceTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: {
    __typename?: 'Task';
    id: string;
    createdAt: any;
    updatedAt: any;
    completedAt?: any | null | undefined;
    description: string;
    type: TaskTypeEnum;
    odometer?: number | null | undefined;
    horimeter?: number | null | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    service: {
      __typename?: 'Service';
      id: string;
      name: string;
      organization?:
        | { __typename?: 'Organization'; id: string }
        | null
        | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
    };
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
    parts: Array<{
      __typename?: 'TaskPart';
      id: string;
      quantity: number;
      price: number;
      part: {
        __typename?: 'Part';
        id: string;
        name: string;
        standard: boolean;
        createdAt: any;
      };
    }>;
    prices: Array<{
      __typename?: 'TaskCost';
      id: string;
      type: CostTypeEnum;
      amount: number;
    }>;
  };
};

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: string;
    name?: string | null | undefined;
    email?: string | null | undefined;
    cpf?: string | null | undefined;
    role: UserRole;
  };
};

export type DeleteDriverMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteDriverMutation = {
  __typename?: 'Mutation';
  deleteDriver: boolean;
};

export type DeleteFuellingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFuellingMutation = {
  __typename?: 'Mutation';
  deleteFuelling: boolean;
};

export type DeleteReminderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteReminderMutation = {
  __typename?: 'Mutation';
  deleteMaintenanceReminder: boolean;
};

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteOrderMutation = {
  __typename?: 'Mutation';
  deleteOrder: boolean;
};

export type DeletePartMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePartMutation = {
  __typename?: 'Mutation';
  deletePart: boolean;
};

export type DeleteProviderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProviderMutation = {
  __typename?: 'Mutation';
  deleteProvider: boolean;
};

export type DeleteServiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteServiceMutation = {
  __typename?: 'Mutation';
  deleteService: boolean;
};

export type DeleteTankMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTankMutation = {
  __typename?: 'Mutation';
  deleteTank: boolean;
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTaskMutation = {
  __typename?: 'Mutation';
  deleteTask: boolean;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: boolean;
};

export type DriversQueryVariables = Exact<{ [key: string]: never }>;

export type DriversQuery = {
  __typename?: 'Query';
  drivers: Array<{
    __typename?: 'Driver';
    id: string;
    name?: string | null | undefined;
    cpf?: string | null | undefined;
    cnh?: string | null | undefined;
    token?: string | null | undefined;
    user?: { __typename?: 'User'; id: string } | null | undefined;
  }>;
};

export type FindGeocodeQueryVariables = Exact<{
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}>;

export type FindGeocodeQuery = {
  __typename?: 'Query';
  findGeocode: { __typename?: 'Geocode'; description: string };
};

export type FindPositionsQueryVariables = Exact<{
  vehicle: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type FindPositionsQuery = {
  __typename?: 'Query';
  findPositions: Array<{
    __typename?: 'Position';
    id: string;
    latitude: number;
    longitude: number;
    altitude: number;
    course: number;
    ignition?: boolean | null | undefined;
    speed: number;
    address?: string | null | undefined;
    fixTime: any;
    attributes: any;
  }>;
};

export type TripsQueryVariables = Exact<{
  vehicle: Scalars['ID'];
  startedAt: Scalars['DateTime'];
  finishedAt: Scalars['DateTime'];
}>;

export type TripsQuery = {
  __typename?: 'Query';
  trips: Array<{
    __typename?: 'Trip';
    id: string;
    timeAboveMaxConfSpeed?: number | null | undefined;
    maximumSpeed?: number | null | undefined;
    distanceTraveled?: number | null | undefined;
    averageSpeed?: number | null | undefined;
    startedAt: any;
    finishedAt?: any | null | undefined;
    fuelConsumedLiters?: number | null | undefined;
    fuelConsumedPrice?: number | null | undefined;
    eventsJson?: any | null | undefined;
    initialStop: {
      __typename?: 'Stop';
      id: string;
      startedAt: any;
      finishedAt?: any | null | undefined;
      latitude: number;
      longitude: number;
    };
    finalStop?:
      | {
          __typename?: 'Stop';
          id: string;
          startedAt: any;
          finishedAt?: any | null | undefined;
          latitude: number;
          longitude: number;
        }
      | null
      | undefined;
  }>;
};

export type CategoryFragment = {
  __typename?: 'Category';
  id: string;
  name: string;
};

export type DocumentFileFragment = {
  __typename?: 'DocumentFile';
  category: string;
  createdAt: any;
  fileName: string;
  id: string;
  mimeType: string;
  organizationId: string;
  size: number;
  updatedAt: any;
  url: string;
};

export type DriverFragment = {
  __typename?: 'Driver';
  id: string;
  name?: string | null | undefined;
  cpf?: string | null | undefined;
  cnh?: string | null | undefined;
  token?: string | null | undefined;
  user?: { __typename?: 'User'; id: string } | null | undefined;
};

export type EventFragment = {
  __typename?: 'Event';
  id: string;
  reportedAt: any;
  slugName: string;
  latitude: number;
  longitude: number;
  address?: string | null | undefined;
  attributes: any;
  origin: string;
  vehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
  driver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
  geofence?:
    | {
        __typename?: 'Geofence';
        id: string;
        name: string;
        type: GeofenceTypeEnum;
        color: string;
        area: any;
        isEnabled?: boolean | null | undefined;
        parking?: boolean | null | undefined;
      }
    | null
    | undefined;
  vehicleDriverChangeHistory?:
    | {
        __typename?: 'VehicleDriverChangeHistory';
        id: string;
        currentDriverToken?: string | null | undefined;
        previousDriverToken?: string | null | undefined;
        currentDriver?:
          | {
              __typename?: 'Driver';
              id: string;
              name?: string | null | undefined;
              cpf?: string | null | undefined;
              cnh?: string | null | undefined;
              token?: string | null | undefined;
              user?: { __typename?: 'User'; id: string } | null | undefined;
            }
          | null
          | undefined;
        previousDriver?:
          | {
              __typename?: 'Driver';
              id: string;
              name?: string | null | undefined;
              cpf?: string | null | undefined;
              cnh?: string | null | undefined;
              token?: string | null | undefined;
              user?: { __typename?: 'User'; id: string } | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type EventOccurrencesFragment = {
  __typename?: 'EventOccurrences';
  boardActivated: number;
  boardDeactivated: number;
  cockpitDoorClose: number;
  cockpitDoorOpen: number;
  deviceMoving: number;
  deviceOverspeed: number;
  deviceOverweight: number;
  deviceStopped: number;
  driverChange: number;
  fault: number;
  geofenceEnter: number;
  geofenceExit: number;
  hardAcceleration: number;
  hardBraking: number;
  hardCornering: number;
  ignitionOff: number;
  ignitionOn: number;
  plugged: number;
  routeOverspeed: number;
  trailerOpen: number;
  unplugged: number;
};

export type FuelTypeFragment = {
  __typename?: 'FuelType';
  slugName: string;
  description: string;
};

export type FuellingFragment = {
  __typename?: 'Fuelling';
  id: string;
  amount: number;
  occurredAt: any;
  cost?: number | null | undefined;
  fuelType: string;
  attributes: any;
  odometer?: number | null | undefined;
  horimeter?: number | null | undefined;
  description?: string | null | undefined;
  vehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
  provider?:
    | {
        __typename?: 'Provider';
        id: string;
        name: string;
        cnpj?: string | null | undefined;
        source: string;
      }
    | null
    | undefined;
  tank?:
    | {
        __typename?: 'Tank';
        id: string;
        name: string;
        initialVolume?: number | null | undefined;
        capacity?: number | null | undefined;
        balance: number;
      }
    | null
    | undefined;
  invoiceEvidence?:
    | {
        __typename?: 'DocumentFile';
        category: string;
        createdAt: any;
        fileName: string;
        id: string;
        mimeType: string;
        organizationId: string;
        size: number;
        updatedAt: any;
        url: string;
      }
    | null
    | undefined;
  odometerEvidence?:
    | {
        __typename?: 'DocumentFile';
        category: string;
        createdAt: any;
        fileName: string;
        id: string;
        mimeType: string;
        organizationId: string;
        size: number;
        updatedAt: any;
        url: string;
      }
    | null
    | undefined;
};

export type GeofenceFragment = {
  __typename?: 'Geofence';
  id: string;
  name: string;
  type: GeofenceTypeEnum;
  color: string;
  area: any;
  isEnabled?: boolean | null | undefined;
  parking?: boolean | null | undefined;
};

export type HorimeterHistoryFragment = {
  __typename?: 'HorimeterHistory';
  id: string;
  current: number;
};

export type IntegrationIntentFragment = {
  __typename?: 'IntegrationIntent';
  id: string;
  integrationId: string;
  progress?: number | null | undefined;
  attributes: any;
  error?: string | null | undefined;
  createdAt: any;
  completed: boolean;
};

export type LocationFragment = {
  __typename?: 'Location';
  id: string;
  vehicleId: string;
  driverId?: string | null | undefined;
  latitude: number;
  longitude: number;
  course: number;
  ignition?: boolean | null | undefined;
  status: VehicleStatus;
  speed: number;
  address?: string | null | undefined;
  fixTime: any;
  attributes: any;
};

export type MaintenanceCostPerTypeReportFragment = {
  __typename?: 'MaintenanceCostPerTypeReport';
  date: string;
  quantity: number;
  correctiveMaintenanceCost: number;
  preventiveMaintenanceCost: number;
};

export type MaintenanceReminderFragment = {
  __typename?: 'MaintenanceReminder';
  id: string;
  createdAt: any;
  updatedAt: any;
  frequencyTime?: number | null | undefined;
  thresholdTime?: number | null | undefined;
  frequencyMeasure?: number | null | undefined;
  thresholdMeasure?: number | null | undefined;
  lastOccurrence: any;
  lastMeasure: number;
  remainingMeasure: number;
  remainingDays: number;
  service: {
    __typename?: 'Service';
    id: string;
    name: string;
    organization?:
      | { __typename?: 'Organization'; id: string }
      | null
      | undefined;
    category: { __typename?: 'Category'; id: string; name: string };
  };
  vehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
};

export type OdometerHistoryFragment = {
  __typename?: 'OdometerHistory';
  id: string;
  current: number;
};

export type OrderFragment = {
  __typename?: 'Order';
  id: string;
  invoiceNumber: number;
  amount: number;
  value: number;
  occurredAt: any;
  tank?:
    | {
        __typename?: 'Tank';
        id: string;
        name: string;
        initialVolume?: number | null | undefined;
        capacity?: number | null | undefined;
        balance: number;
      }
    | null
    | undefined;
};

export type OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  cnpj?: string | null | undefined;
  disabledAt?: any | null | undefined;
  createdAt: any;
  trialAt?: any | null | undefined;
  contractEndedAt?: any | null | undefined;
  address?: string | null | undefined;
  phone?: string | null | undefined;
  settings: any;
  signUpFee?: number | null | undefined;
  monthlyPayment?: number | null | undefined;
  capacity?: OrganizationCapacity | null | undefined;
  category?: string | null | undefined;
  sector?: string | null | undefined;
  contractBeganAt?: any | null | undefined;
  contractMonthDuration?: number | null | undefined;
};

export type PartFragment = {
  __typename?: 'Part';
  id: string;
  name: string;
  standard: boolean;
  createdAt: any;
};

export type PositionFragment = {
  __typename?: 'Position';
  id: string;
  latitude: number;
  longitude: number;
  altitude: number;
  course: number;
  ignition?: boolean | null | undefined;
  speed: number;
  address?: string | null | undefined;
  fixTime: any;
  attributes: any;
};

export type ProviderFragment = {
  __typename?: 'Provider';
  id: string;
  name: string;
  cnpj?: string | null | undefined;
  source: string;
};

export type DailyWorkerMeasureFragment = {
  __typename?: 'DailyWorkMeasure';
  id: string;
  initialOdometer: number;
  initialHorimeter: number;
  finalOdometer?: number | null | undefined;
  finalHorimeter?: number | null | undefined;
  beginMeasureOdometerAt: any;
  beginMeasureHorimeterAt: any;
  date: any;
  distanceTraveled?: number | null | undefined;
  timeWithIgnitionOn?: number | null | undefined;
  vehicleId: string;
  vehicle: {
    __typename?: 'Vehicle';
    plate: string;
    displayName?: string | null | undefined;
    odometer: number;
    horimeter: number;
  };
};

export type VehicleSummaryFragment = {
  __typename?: 'VehicleSummary';
  startAt: any;
  endAt: any;
  vehicleId: string;
  distanceTraveled: number;
  averageSpeed: number;
  maximumSpeed: number;
  engineIdle: number;
  timeOverSpeed: number;
  timeStopped: number;
  timeWithIgnitionOn: number;
  timeStoppedWithIgnitionOn: number;
  vehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
  eventOccurrences: {
    __typename?: 'EventOccurrences';
    boardActivated: number;
    boardDeactivated: number;
    cockpitDoorClose: number;
    cockpitDoorOpen: number;
    deviceMoving: number;
    deviceOverspeed: number;
    deviceOverweight: number;
    deviceStopped: number;
    driverChange: number;
    fault: number;
    geofenceEnter: number;
    geofenceExit: number;
    hardAcceleration: number;
    hardBraking: number;
    hardCornering: number;
    ignitionOff: number;
    ignitionOn: number;
    plugged: number;
    routeOverspeed: number;
    trailerOpen: number;
    unplugged: number;
  };
};

export type ServiceFragment = {
  __typename?: 'Service';
  id: string;
  name: string;
  organization?: { __typename?: 'Organization'; id: string } | null | undefined;
  category: { __typename?: 'Category'; id: string; name: string };
};

export type StopFragment = {
  __typename?: 'Stop';
  id: string;
  startedAt: any;
  finishedAt?: any | null | undefined;
  latitude: number;
  longitude: number;
};

export type TankFragment = {
  __typename?: 'Tank';
  id: string;
  name: string;
  initialVolume?: number | null | undefined;
  capacity?: number | null | undefined;
  balance: number;
};

export type TaskPartFragment = {
  __typename?: 'TaskPart';
  id: string;
  price: number;
  quantity: number;
  subTotal: number;
  part: {
    __typename?: 'Part';
    id: string;
    name: string;
    standard: boolean;
    createdAt: any;
  };
};

export type TaskFragment = {
  __typename?: 'Task';
  id: string;
  createdAt: any;
  updatedAt: any;
  completedAt?: any | null | undefined;
  description: string;
  type: TaskTypeEnum;
  odometer?: number | null | undefined;
  horimeter?: number | null | undefined;
  amount: number;
  vehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
  service: {
    __typename?: 'Service';
    id: string;
    name: string;
    organization?:
      | { __typename?: 'Organization'; id: string }
      | null
      | undefined;
    category: { __typename?: 'Category'; id: string; name: string };
  };
  driver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
  provider?:
    | {
        __typename?: 'Provider';
        id: string;
        name: string;
        cnpj?: string | null | undefined;
        source: string;
      }
    | null
    | undefined;
  parts: Array<{
    __typename?: 'TaskPart';
    id: string;
    price: number;
    quantity: number;
    subTotal: number;
    part: {
      __typename?: 'Part';
      id: string;
      name: string;
      standard: boolean;
      createdAt: any;
    };
  }>;
  prices: Array<{
    __typename?: 'TaskCost';
    id: string;
    type: CostTypeEnum;
    amount: number;
  }>;
};

export type InfractionFragment = {
  __typename?: 'Infraction';
  id: string;
  code: string;
  cost?: number | null | undefined;
  points?: number | null | undefined;
  description: string;
};

export type TrafficInfractionFragment = {
  __typename?: 'TrafficInfraction';
  id: string;
  cost?: number | null | undefined;
  paidCost?: number | null | undefined;
  referenceDocument?: string | null | undefined;
  location?: any | null | undefined;
  observation?: string | null | undefined;
  paidAt?: any | null | undefined;
  dueDate?: any | null | undefined;
  occurredAt: any;
  address?: string | null | undefined;
  vehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
  driver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
  infraction: {
    __typename?: 'Infraction';
    id: string;
    code: string;
    cost?: number | null | undefined;
    points?: number | null | undefined;
    description: string;
  };
};

export type TripFragment = {
  __typename?: 'Trip';
  id: string;
  timeAboveMaxConfSpeed?: number | null | undefined;
  maximumSpeed?: number | null | undefined;
  distanceTraveled?: number | null | undefined;
  averageSpeed?: number | null | undefined;
  startedAt: any;
  finishedAt?: any | null | undefined;
  fuelConsumedLiters?: number | null | undefined;
  fuelConsumedPrice?: number | null | undefined;
  eventsJson?: any | null | undefined;
  initialStop: {
    __typename?: 'Stop';
    id: string;
    startedAt: any;
    finishedAt?: any | null | undefined;
    latitude: number;
    longitude: number;
  };
  finalStop?:
    | {
        __typename?: 'Stop';
        id: string;
        startedAt: any;
        finishedAt?: any | null | undefined;
        latitude: number;
        longitude: number;
      }
    | null
    | undefined;
};

export type UserWithVehiclesFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null | undefined;
  cpf?: string | null | undefined;
  email?: string | null | undefined;
  role: UserRole;
  createdAt: any;
  updatedAt: any;
  settings: any;
  modules: any;
  permissions: any;
  availableVehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  }>;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null | undefined;
    disabledAt?: any | null | undefined;
    createdAt: any;
    trialAt?: any | null | undefined;
    contractEndedAt?: any | null | undefined;
    address?: string | null | undefined;
    phone?: string | null | undefined;
    settings: any;
    signUpFee?: number | null | undefined;
    monthlyPayment?: number | null | undefined;
    capacity?: OrganizationCapacity | null | undefined;
    category?: string | null | undefined;
    sector?: string | null | undefined;
    contractBeganAt?: any | null | undefined;
    contractMonthDuration?: number | null | undefined;
  };
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  }>;
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null | undefined;
  cpf?: string | null | undefined;
  email?: string | null | undefined;
  role: UserRole;
  createdAt: any;
  updatedAt: any;
  settings: any;
  modules: any;
  permissions: any;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null | undefined;
    disabledAt?: any | null | undefined;
    createdAt: any;
    trialAt?: any | null | undefined;
    contractEndedAt?: any | null | undefined;
    address?: string | null | undefined;
    phone?: string | null | undefined;
    settings: any;
    signUpFee?: number | null | undefined;
    monthlyPayment?: number | null | undefined;
    capacity?: OrganizationCapacity | null | undefined;
    category?: string | null | undefined;
    sector?: string | null | undefined;
    contractBeganAt?: any | null | undefined;
    contractMonthDuration?: number | null | undefined;
  };
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  }>;
};

export type VehicleDriverChangeHistoryFragment = {
  __typename?: 'VehicleDriverChangeHistory';
  id: string;
  currentDriverToken?: string | null | undefined;
  previousDriverToken?: string | null | undefined;
  currentDriver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
  previousDriver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type GroupFragment = {
  __typename?: 'Group';
  id: string;
  name: string;
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  }>;
};

export type VehicleReportFragment = {
  __typename?: 'VehicleReport';
  expenditure: number;
  totalCost: number;
  costPerHour: number;
  distanceTraveled?: number | null | undefined;
  timeTraveled?: number | null | undefined;
  costPerKM: number;
  maintenance: number;
  maintenancePrice: number;
  fuelConsumedLiters?: number | null | undefined;
  fuelConsumedPrice?: number | null | undefined;
  startAt: string;
  tripFuelConsumedLiters?: number | null | undefined;
  tripFuelConsumedPrice?: number | null | undefined;
  autonomy?: number | null | undefined;
  tripAutonomy?: number | null | undefined;
};

export type VehicleWithDriverFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  displayName?: string | null | undefined;
  model?: string | null | undefined;
  brand?: string | null | undefined;
  type: VehicleTypeEnum;
  year?: string | null | undefined;
  fuelType?: FuelTypeEnum | null | undefined;
  document?: string | null | undefined;
  observation?: string | null | undefined;
  avatarType: VehicleTypeEnum;
  price?: number | null | undefined;
  lastDriverToken?: string | null | undefined;
  odometer: number;
  horimeter: number;
  fuelLevel?: number | null | undefined;
  settings: any;
  useObdData: boolean;
  max_speed?: number | null | undefined;
  min_speed?: number | null | undefined;
  tank_size?: number | null | undefined;
  created_at: any;
  updated_at: any;
  plugged_status?: boolean | null | undefined;
  plugged_status_at?: any | null | undefined;
  driver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type VehicleWithLastInfoFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  displayName?: string | null | undefined;
  model?: string | null | undefined;
  brand?: string | null | undefined;
  type: VehicleTypeEnum;
  year?: string | null | undefined;
  fuelType?: FuelTypeEnum | null | undefined;
  document?: string | null | undefined;
  observation?: string | null | undefined;
  avatarType: VehicleTypeEnum;
  price?: number | null | undefined;
  lastDriverToken?: string | null | undefined;
  odometer: number;
  horimeter: number;
  fuelLevel?: number | null | undefined;
  settings: any;
  useObdData: boolean;
  max_speed?: number | null | undefined;
  min_speed?: number | null | undefined;
  tank_size?: number | null | undefined;
  created_at: any;
  updated_at: any;
  plugged_status?: boolean | null | undefined;
  plugged_status_at?: any | null | undefined;
  location?:
    | {
        __typename?: 'Location';
        id: string;
        vehicleId: string;
        driverId?: string | null | undefined;
        latitude: number;
        longitude: number;
        course: number;
        ignition?: boolean | null | undefined;
        status: VehicleStatus;
        speed: number;
        address?: string | null | undefined;
        fixTime: any;
        attributes: any;
      }
    | null
    | undefined;
  driver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type VehicleWithLocationAndDriverFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  displayName?: string | null | undefined;
  model?: string | null | undefined;
  brand?: string | null | undefined;
  type: VehicleTypeEnum;
  year?: string | null | undefined;
  fuelType?: FuelTypeEnum | null | undefined;
  document?: string | null | undefined;
  observation?: string | null | undefined;
  avatarType: VehicleTypeEnum;
  price?: number | null | undefined;
  lastDriverToken?: string | null | undefined;
  odometer: number;
  horimeter: number;
  fuelLevel?: number | null | undefined;
  settings: any;
  useObdData: boolean;
  max_speed?: number | null | undefined;
  min_speed?: number | null | undefined;
  tank_size?: number | null | undefined;
  created_at: any;
  updated_at: any;
  plugged_status?: boolean | null | undefined;
  plugged_status_at?: any | null | undefined;
  location?:
    | {
        __typename?: 'Location';
        id: string;
        vehicleId: string;
        driverId?: string | null | undefined;
        latitude: number;
        longitude: number;
        course: number;
        ignition?: boolean | null | undefined;
        status: VehicleStatus;
        speed: number;
        address?: string | null | undefined;
        fixTime: any;
        attributes: any;
      }
    | null
    | undefined;
  driver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type VehicleFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  displayName?: string | null | undefined;
  model?: string | null | undefined;
  brand?: string | null | undefined;
  type: VehicleTypeEnum;
  year?: string | null | undefined;
  fuelType?: FuelTypeEnum | null | undefined;
  document?: string | null | undefined;
  observation?: string | null | undefined;
  avatarType: VehicleTypeEnum;
  price?: number | null | undefined;
  lastDriverToken?: string | null | undefined;
  odometer: number;
  horimeter: number;
  fuelLevel?: number | null | undefined;
  settings: any;
  useObdData: boolean;
  max_speed?: number | null | undefined;
  min_speed?: number | null | undefined;
  tank_size?: number | null | undefined;
  created_at: any;
  updated_at: any;
  plugged_status?: boolean | null | undefined;
  plugged_status_at?: any | null | undefined;
};

export type FuelTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FuelTypesQuery = {
  __typename?: 'Query';
  fuelTypes: Array<{
    __typename?: 'FuelType';
    slugName: string;
    description: string;
  }>;
};

export type GetFuellingReferenceQueryVariables = Exact<{
  vehicle: Scalars['ID'];
  date: Scalars['DateTime'];
}>;

export type GetFuellingReferenceQuery = {
  __typename?: 'Query';
  getFuellingReference: {
    __typename?: 'FuellingReference';
    minOdometer: number;
    maxOdometer?: number | null | undefined;
    minHorimeter: number;
    maxHorimeter?: number | null | undefined;
  };
};

export type DriversRankingQueryVariables = Exact<{
  drivers: Array<Scalars['ID']> | Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;

export type DriversRankingQuery = {
  __typename?: 'Query';
  driversRanking: Array<{
    __typename?: 'DriverRanking';
    driverId: string;
    avgSpeed: number;
    maxSpeed: number;
    telemetrySummary: any;
    totalTelemetryEvents: number;
  }>;
};

export type VehiclesRankingQueryVariables = Exact<{
  vehicles: Array<Scalars['ID']> | Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;

export type VehiclesRankingQuery = {
  __typename?: 'Query';
  vehiclesRanking: Array<{
    __typename?: 'VehicleRanking';
    avgSpeed: number;
    engineIdleByDay: any;
    maxSpeed: number;
    percentageTimeEngineIdle: number;
    telemetrySummary: any;
    totalTelemetryEvents: number;
    totalTraveled: number;
    vehicleId: string;
  }>;
};

export type CreateIntegrationMutationVariables = Exact<{
  payload: CreateIntegrationInput;
}>;

export type CreateIntegrationMutation = {
  __typename?: 'Mutation';
  createIntegration: {
    __typename?: 'Integration';
    id: string;
    source: string;
    pendingDriverMappings: number;
    pendingFuelTypeMappings: number;
    pendingProviderMappings: number;
    pendingVehicleMappings: number;
    hasPendency: boolean;
    setupProgress?: number | null | undefined;
    setupErrorMessage?: string | null | undefined;
    setupCompletedAt?: any | null | undefined;
    enabledAt?: any | null | undefined;
  };
};

export type CreateUnmappedDriversMutationVariables = Exact<{
  mappings: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type CreateUnmappedDriversMutation = {
  __typename?: 'Mutation';
  createUnmappedDrivers: Array<{
    __typename?: 'DriverMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    driverId?: string | null | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type CreateUnmappedProvidersMutationVariables = Exact<{
  mappings: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type CreateUnmappedProvidersMutation = {
  __typename?: 'Mutation';
  createUnmappedProviders: Array<{
    __typename?: 'ProviderMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    providerId?: string | null | undefined;
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
  }>;
};

export type CreateUnmappedVehiclesMutationVariables = Exact<{
  mappings: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type CreateUnmappedVehiclesMutation = {
  __typename?: 'Mutation';
  createUnmappedVehicles: Array<{
    __typename?: 'VehicleMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    vehicleId?: string | null | undefined;
    disabledAt?: any | null | undefined;
    vehicle?:
      | {
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          displayName?: string | null | undefined;
          model?: string | null | undefined;
          brand?: string | null | undefined;
          type: VehicleTypeEnum;
          year?: string | null | undefined;
          fuelType?: FuelTypeEnum | null | undefined;
          document?: string | null | undefined;
          observation?: string | null | undefined;
          avatarType: VehicleTypeEnum;
          price?: number | null | undefined;
          lastDriverToken?: string | null | undefined;
          odometer: number;
          horimeter: number;
          fuelLevel?: number | null | undefined;
          settings: any;
          useObdData: boolean;
          max_speed?: number | null | undefined;
          min_speed?: number | null | undefined;
          tank_size?: number | null | undefined;
          created_at: any;
          updated_at: any;
          plugged_status?: boolean | null | undefined;
          plugged_status_at?: any | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type DeleteIntegrationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteIntegrationMutation = {
  __typename?: 'Mutation';
  deleteIntegration: boolean;
};

export type DriverMappingFragment = {
  __typename?: 'DriverMapping';
  id: string;
  name: string;
  integrationId: string;
  valid: boolean;
  externalId: string;
  driverId?: string | null | undefined;
  driver?:
    | {
        __typename?: 'Driver';
        id: string;
        name?: string | null | undefined;
        cpf?: string | null | undefined;
        cnh?: string | null | undefined;
        token?: string | null | undefined;
        user?: { __typename?: 'User'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type DriverMappingsQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type DriverMappingsQuery = {
  __typename?: 'Query';
  driverMappings: Array<{
    __typename?: 'DriverMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    driverId?: string | null | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type FuelTypeMappingFragment = {
  __typename?: 'FuelTypeMapping';
  id: string;
  name: string;
  fuelType?: string | null | undefined;
  integrationId: string;
  valid: boolean;
  externalId: string;
};

export type FuelTypeMappingsQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type FuelTypeMappingsQuery = {
  __typename?: 'Query';
  fuelTypeMappings: Array<{
    __typename?: 'FuelTypeMapping';
    id: string;
    name: string;
    fuelType?: string | null | undefined;
    integrationId: string;
    valid: boolean;
    externalId: string;
  }>;
};

export type IntegrationWithIntentsFragment = {
  __typename?: 'Integration';
  id: string;
  source: string;
  pendingDriverMappings: number;
  pendingFuelTypeMappings: number;
  pendingProviderMappings: number;
  pendingVehicleMappings: number;
  hasPendency: boolean;
  setupProgress?: number | null | undefined;
  setupErrorMessage?: string | null | undefined;
  setupCompletedAt?: any | null | undefined;
  enabledAt?: any | null | undefined;
  intents: Array<{
    __typename?: 'IntegrationIntent';
    id: string;
    integrationId: string;
    progress?: number | null | undefined;
    attributes: any;
    error?: string | null | undefined;
    createdAt: any;
    completed: boolean;
  }>;
};

export type IntegrationFragment = {
  __typename?: 'Integration';
  id: string;
  source: string;
  pendingDriverMappings: number;
  pendingFuelTypeMappings: number;
  pendingProviderMappings: number;
  pendingVehicleMappings: number;
  hasPendency: boolean;
  setupProgress?: number | null | undefined;
  setupErrorMessage?: string | null | undefined;
  setupCompletedAt?: any | null | undefined;
  enabledAt?: any | null | undefined;
};

export type IntegrationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IntegrationQuery = {
  __typename?: 'Query';
  integration: {
    __typename?: 'Integration';
    id: string;
    source: string;
    pendingDriverMappings: number;
    pendingFuelTypeMappings: number;
    pendingProviderMappings: number;
    pendingVehicleMappings: number;
    hasPendency: boolean;
    setupProgress?: number | null | undefined;
    setupErrorMessage?: string | null | undefined;
    setupCompletedAt?: any | null | undefined;
    enabledAt?: any | null | undefined;
    intents: Array<{
      __typename?: 'IntegrationIntent';
      id: string;
      integrationId: string;
      progress?: number | null | undefined;
      attributes: any;
      error?: string | null | undefined;
      createdAt: any;
      completed: boolean;
    }>;
  };
};

export type IntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type IntegrationsQuery = {
  __typename?: 'Query';
  integrations: Array<{
    __typename?: 'Integration';
    id: string;
    source: string;
    pendingDriverMappings: number;
    pendingFuelTypeMappings: number;
    pendingProviderMappings: number;
    pendingVehicleMappings: number;
    hasPendency: boolean;
    setupProgress?: number | null | undefined;
    setupErrorMessage?: string | null | undefined;
    setupCompletedAt?: any | null | undefined;
    enabledAt?: any | null | undefined;
  }>;
};

export type ProviderMappingFragment = {
  __typename?: 'ProviderMapping';
  id: string;
  name: string;
  integrationId: string;
  valid: boolean;
  externalId: string;
  providerId?: string | null | undefined;
  provider?:
    | {
        __typename?: 'Provider';
        id: string;
        name: string;
        cnpj?: string | null | undefined;
        source: string;
      }
    | null
    | undefined;
};

export type ProviderMappingsQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type ProviderMappingsQuery = {
  __typename?: 'Query';
  providerMappings: Array<{
    __typename?: 'ProviderMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    providerId?: string | null | undefined;
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
  }>;
};

export type SyncIntegrationSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SyncIntegrationSubscription = {
  __typename?: 'Subscription';
  syncIntegration: {
    __typename?: 'Integration';
    id: string;
    source: string;
    pendingDriverMappings: number;
    pendingFuelTypeMappings: number;
    pendingProviderMappings: number;
    pendingVehicleMappings: number;
    hasPendency: boolean;
    setupProgress?: number | null | undefined;
    setupErrorMessage?: string | null | undefined;
    setupCompletedAt?: any | null | undefined;
    enabledAt?: any | null | undefined;
  };
};

export type UpdateDriverMappingsMutationVariables = Exact<{
  mappings: Array<UpdateDriverMappingInput> | UpdateDriverMappingInput;
}>;

export type UpdateDriverMappingsMutation = {
  __typename?: 'Mutation';
  updateDriverMappings: Array<{
    __typename?: 'DriverMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    driverId?: string | null | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type UpdateFuelTypeMappingsMutationVariables = Exact<{
  mappings: Array<UpdateFuelTypeMappingInput> | UpdateFuelTypeMappingInput;
}>;

export type UpdateFuelTypeMappingsMutation = {
  __typename?: 'Mutation';
  updateFuelTypeMappings: Array<{
    __typename?: 'FuelTypeMapping';
    id: string;
    name: string;
    fuelType?: string | null | undefined;
    integrationId: string;
    valid: boolean;
    externalId: string;
  }>;
};

export type UpdateProviderMappingsMutationVariables = Exact<{
  mappings: Array<UpdateProviderMappingInput> | UpdateProviderMappingInput;
}>;

export type UpdateProviderMappingsMutation = {
  __typename?: 'Mutation';
  updateProviderMappings: Array<{
    __typename?: 'ProviderMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    providerId?: string | null | undefined;
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
  }>;
};

export type UpdateVehicleMappingsMutationVariables = Exact<{
  mappings: Array<UpdateVehicleMappingInput> | UpdateVehicleMappingInput;
}>;

export type UpdateVehicleMappingsMutation = {
  __typename?: 'Mutation';
  updateVehicleMappings: Array<{
    __typename?: 'VehicleMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    vehicleId?: string | null | undefined;
    disabledAt?: any | null | undefined;
    vehicle?:
      | {
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          displayName?: string | null | undefined;
          model?: string | null | undefined;
          brand?: string | null | undefined;
          type: VehicleTypeEnum;
          year?: string | null | undefined;
          fuelType?: FuelTypeEnum | null | undefined;
          document?: string | null | undefined;
          observation?: string | null | undefined;
          avatarType: VehicleTypeEnum;
          price?: number | null | undefined;
          lastDriverToken?: string | null | undefined;
          odometer: number;
          horimeter: number;
          fuelLevel?: number | null | undefined;
          settings: any;
          useObdData: boolean;
          max_speed?: number | null | undefined;
          min_speed?: number | null | undefined;
          tank_size?: number | null | undefined;
          created_at: any;
          updated_at: any;
          plugged_status?: boolean | null | undefined;
          plugged_status_at?: any | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type UploadFileIntegrationMutationVariables = Exact<{
  file: Scalars['Upload'];
  integrationId: Scalars['ID'];
}>;

export type UploadFileIntegrationMutation = {
  __typename?: 'Mutation';
  uploadFileIntegration: {
    __typename?: 'IntegrationIntent';
    id: string;
    integrationId: string;
    progress?: number | null | undefined;
    attributes: any;
    error?: string | null | undefined;
    createdAt: any;
    completed: boolean;
  };
};

export type VehicleMappingFragment = {
  __typename?: 'VehicleMapping';
  id: string;
  name: string;
  integrationId: string;
  valid: boolean;
  externalId: string;
  vehicleId?: string | null | undefined;
  disabledAt?: any | null | undefined;
  vehicle?:
    | {
        __typename?: 'Vehicle';
        id: string;
        plate: string;
        displayName?: string | null | undefined;
        model?: string | null | undefined;
        brand?: string | null | undefined;
        type: VehicleTypeEnum;
        year?: string | null | undefined;
        fuelType?: FuelTypeEnum | null | undefined;
        document?: string | null | undefined;
        observation?: string | null | undefined;
        avatarType: VehicleTypeEnum;
        price?: number | null | undefined;
        lastDriverToken?: string | null | undefined;
        odometer: number;
        horimeter: number;
        fuelLevel?: number | null | undefined;
        settings: any;
        useObdData: boolean;
        max_speed?: number | null | undefined;
        min_speed?: number | null | undefined;
        tank_size?: number | null | undefined;
        created_at: any;
        updated_at: any;
        plugged_status?: boolean | null | undefined;
        plugged_status_at?: any | null | undefined;
      }
    | null
    | undefined;
};

export type VehicleMappingsQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type VehicleMappingsQuery = {
  __typename?: 'Query';
  vehicleMappings: Array<{
    __typename?: 'VehicleMapping';
    id: string;
    name: string;
    integrationId: string;
    valid: boolean;
    externalId: string;
    vehicleId?: string | null | undefined;
    disabledAt?: any | null | undefined;
    vehicle?:
      | {
          __typename?: 'Vehicle';
          id: string;
          plate: string;
          displayName?: string | null | undefined;
          model?: string | null | undefined;
          brand?: string | null | undefined;
          type: VehicleTypeEnum;
          year?: string | null | undefined;
          fuelType?: FuelTypeEnum | null | undefined;
          document?: string | null | undefined;
          observation?: string | null | undefined;
          avatarType: VehicleTypeEnum;
          price?: number | null | undefined;
          lastDriverToken?: string | null | undefined;
          odometer: number;
          horimeter: number;
          fuelLevel?: number | null | undefined;
          settings: any;
          useObdData: boolean;
          max_speed?: number | null | undefined;
          min_speed?: number | null | undefined;
          tank_size?: number | null | undefined;
          created_at: any;
          updated_at: any;
          plugged_status?: boolean | null | undefined;
          plugged_status_at?: any | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type ListAllFuellingQueryVariables = Exact<{
  vehicleIds: Array<Scalars['ID']> | Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;

export type ListAllFuellingQuery = {
  __typename?: 'Query';
  fuelling: Array<{
    __typename?: 'Fuelling';
    id: string;
    amount: number;
    occurredAt: any;
    cost?: number | null | undefined;
    fuelType: string;
    attributes: any;
    odometer?: number | null | undefined;
    horimeter?: number | null | undefined;
    description?: string | null | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
    tank?:
      | {
          __typename?: 'Tank';
          id: string;
          name: string;
          initialVolume?: number | null | undefined;
          capacity?: number | null | undefined;
          balance: number;
        }
      | null
      | undefined;
    invoiceEvidence?:
      | {
          __typename?: 'DocumentFile';
          category: string;
          createdAt: any;
          fileName: string;
          id: string;
          mimeType: string;
          organizationId: string;
          size: number;
          updatedAt: any;
          url: string;
        }
      | null
      | undefined;
    odometerEvidence?:
      | {
          __typename?: 'DocumentFile';
          category: string;
          createdAt: any;
          fileName: string;
          id: string;
          mimeType: string;
          organizationId: string;
          size: number;
          updatedAt: any;
          url: string;
        }
      | null
      | undefined;
  }>;
};

export type RemindersQueryVariables = Exact<{ [key: string]: never }>;

export type RemindersQuery = {
  __typename?: 'Query';
  findMaintenanceReminders: Array<{
    __typename?: 'MaintenanceReminder';
    id: string;
    createdAt: any;
    updatedAt: any;
    frequencyTime?: number | null | undefined;
    thresholdTime?: number | null | undefined;
    frequencyMeasure?: number | null | undefined;
    thresholdMeasure?: number | null | undefined;
    lastOccurrence: any;
    lastMeasure: number;
    remainingMeasure: number;
    remainingDays: number;
    service: {
      __typename?: 'Service';
      id: string;
      name: string;
      organization?:
        | { __typename?: 'Organization'; id: string }
        | null
        | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
    };
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
  }>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    name?: string | null | undefined;
    cpf?: string | null | undefined;
    email?: string | null | undefined;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    settings: any;
    modules: any;
    permissions: any;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null | undefined;
      disabledAt?: any | null | undefined;
      createdAt: any;
      trialAt?: any | null | undefined;
      contractEndedAt?: any | null | undefined;
      address?: string | null | undefined;
      phone?: string | null | undefined;
      settings: any;
      signUpFee?: number | null | undefined;
      monthlyPayment?: number | null | undefined;
      capacity?: OrganizationCapacity | null | undefined;
      category?: string | null | undefined;
      sector?: string | null | undefined;
      contractBeganAt?: any | null | undefined;
      contractMonthDuration?: number | null | undefined;
    };
    vehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
  };
};

export type CountEventsQueryVariables = Exact<{
  vehicleId?: InputMaybe<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  eventTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  geofences?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type CountEventsQuery = {
  __typename?: 'Query';
  countEvents: Array<{
    __typename?: 'EventSummary';
    slugName: string;
    occurrences: number;
  }>;
};

export type CreateGeofenceMutationVariables = Exact<{
  payload: CreateGeofenceInput;
}>;

export type CreateGeofenceMutation = {
  __typename?: 'Mutation';
  createGeofence: {
    __typename?: 'Geofence';
    id: string;
    name: string;
    type: GeofenceTypeEnum;
    color: string;
    area: any;
    isEnabled?: boolean | null | undefined;
    parking?: boolean | null | undefined;
  };
};

export type DeleteGeofenceMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type DeleteGeofenceMutation = {
  __typename?: 'Mutation';
  deleteGeofence: boolean;
};

export type FindVehicleEventsQueryVariables = Exact<{
  vehicleId?: InputMaybe<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  eventTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  geofences?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type FindVehicleEventsQuery = {
  __typename?: 'Query';
  findVehicleEvents: Array<{
    __typename?: 'Event';
    id: string;
    reportedAt: any;
    slugName: string;
    latitude: number;
    longitude: number;
    address?: string | null | undefined;
    attributes: any;
    origin: string;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    geofence?:
      | {
          __typename?: 'Geofence';
          id: string;
          name: string;
          type: GeofenceTypeEnum;
          color: string;
          area: any;
          isEnabled?: boolean | null | undefined;
          parking?: boolean | null | undefined;
        }
      | null
      | undefined;
    vehicleDriverChangeHistory?:
      | {
          __typename?: 'VehicleDriverChangeHistory';
          id: string;
          currentDriverToken?: string | null | undefined;
          previousDriverToken?: string | null | undefined;
          currentDriver?:
            | {
                __typename?: 'Driver';
                id: string;
                name?: string | null | undefined;
                cpf?: string | null | undefined;
                cnh?: string | null | undefined;
                token?: string | null | undefined;
                user?: { __typename?: 'User'; id: string } | null | undefined;
              }
            | null
            | undefined;
          previousDriver?:
            | {
                __typename?: 'Driver';
                id: string;
                name?: string | null | undefined;
                cpf?: string | null | undefined;
                cnh?: string | null | undefined;
                token?: string | null | undefined;
                user?: { __typename?: 'User'; id: string } | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GeofencesQueryVariables = Exact<{ [key: string]: never }>;

export type GeofencesQuery = {
  __typename?: 'Query';
  geofences: Array<{
    __typename?: 'Geofence';
    id: string;
    name: string;
    type: GeofenceTypeEnum;
    color: string;
    area: any;
    isEnabled?: boolean | null | undefined;
    parking?: boolean | null | undefined;
  }>;
};

export type GetMaintenanceCostQueryVariables = Exact<{
  payload: MaintenanceCostPerTypeReportInput;
}>;

export type GetMaintenanceCostQuery = {
  __typename?: 'Query';
  maintenanceCostPerTypeReport: Array<{
    __typename?: 'MaintenanceCostPerTypeReportResponse';
    report: Array<{
      __typename?: 'MaintenanceCostPerTypeReport';
      date: string;
      quantity: number;
      correctiveMaintenanceCost: number;
      preventiveMaintenanceCost: number;
    }>;
  }>;
};

export type NewVehicleLocationSubscriptionVariables = Exact<{
  vehicles: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type NewVehicleLocationSubscription = {
  __typename?: 'Subscription';
  newVehicleLocation: {
    __typename?: 'Location';
    id: string;
    vehicleId: string;
    driverId?: string | null | undefined;
    latitude: number;
    longitude: number;
    course: number;
    ignition?: boolean | null | undefined;
    status: VehicleStatus;
    speed: number;
    address?: string | null | undefined;
    fixTime: any;
    attributes: any;
  };
};

export type RouteDetailsQueryVariables = Exact<{
  vehicle: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;

export type RouteDetailsQuery = {
  __typename?: 'Query';
  routeDetails: {
    __typename?: 'RouteDetails';
    averageSpeed?: number | null | undefined;
    maximumSpeed?: number | null | undefined;
    totalDistanceTraveled?: number | null | undefined;
    totalTimeStopped?: number | null | undefined;
    totalTimeStoppedWithIgnitionOn?: number | null | undefined;
    totalTimeWithIgnitionOn?: number | null | undefined;
    engineIdle?: number | null | undefined;
    geojson: any;
  };
};

export type UpdateGeofenceMutationVariables = Exact<{
  payload: UpdateGeofenceInput;
}>;

export type UpdateGeofenceMutation = {
  __typename?: 'Mutation';
  updateGeofence: {
    __typename?: 'Geofence';
    id: string;
    name: string;
    type: GeofenceTypeEnum;
    color: string;
    area: any;
    isEnabled?: boolean | null | undefined;
    parking?: boolean | null | undefined;
  };
};

export type NewUpdateVehicleSubscriptionVariables = Exact<{
  vehicles: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type NewUpdateVehicleSubscription = {
  __typename?: 'Subscription';
  updateVehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  };
};

export type NotificationActionFragment = {
  __typename?: 'NotificationAction';
  id: string;
  title: string;
  url: string;
};

export type NotificationFragment = {
  __typename?: 'Notification';
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  createdAt: any;
  expiredAt?: any | null | undefined;
  interactedAt?: any | null | undefined;
  readAt?: any | null | undefined;
  actions: Array<{
    __typename?: 'NotificationAction';
    id: string;
    title: string;
    url: string;
  }>;
};

export type NotificationsSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationsSummaryQuery = {
  __typename?: 'Query';
  notificationsSummary: {
    __typename?: 'NotificationSummary';
    read: number;
    unRead: number;
  };
};

export type NotificationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notifications: Array<{
    __typename?: 'Notification';
    id: string;
    title: string;
    description: string;
    imageUrl: string;
    createdAt: any;
    expiredAt?: any | null | undefined;
    interactedAt?: any | null | undefined;
    readAt?: any | null | undefined;
    actions: Array<{
      __typename?: 'NotificationAction';
      id: string;
      title: string;
      url: string;
    }>;
  }>;
};

export type OrdersQueryVariables = Exact<{
  filter?: InputMaybe<FilterOrder>;
}>;

export type OrdersQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'Order';
    id: string;
    invoiceNumber: number;
    amount: number;
    value: number;
    occurredAt: any;
    tank?:
      | {
          __typename?: 'Tank';
          id: string;
          name: string;
          initialVolume?: number | null | undefined;
          capacity?: number | null | undefined;
          balance: number;
        }
      | null
      | undefined;
  }>;
};

export type UpdateOrganizationMutationVariables = Exact<{
  payload: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null | undefined;
    disabledAt?: any | null | undefined;
    createdAt: any;
    trialAt?: any | null | undefined;
    contractEndedAt?: any | null | undefined;
    address?: string | null | undefined;
    phone?: string | null | undefined;
    settings: any;
    signUpFee?: number | null | undefined;
    monthlyPayment?: number | null | undefined;
    capacity?: OrganizationCapacity | null | undefined;
    category?: string | null | undefined;
    sector?: string | null | undefined;
    contractBeganAt?: any | null | undefined;
    contractMonthDuration?: number | null | undefined;
  };
};

export type PartsQueryVariables = Exact<{ [key: string]: never }>;

export type PartsQuery = {
  __typename?: 'Query';
  parts: Array<{
    __typename?: 'Part';
    id: string;
    name: string;
    standard: boolean;
    createdAt: any;
  }>;
};

export type ProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type ProvidersQuery = {
  __typename?: 'Query';
  providers: Array<{
    __typename?: 'Provider';
    id: string;
    name: string;
    cnpj?: string | null | undefined;
    source: string;
  }>;
};

export type DailyWorkMeasuresQueryVariables = Exact<{
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DailyWorkMeasuresQuery = {
  __typename?: 'Query';
  dailyWorkMeasures: Array<{
    __typename?: 'DailyWorkMeasure';
    id: string;
    initialOdometer: number;
    initialHorimeter: number;
    finalOdometer?: number | null | undefined;
    finalHorimeter?: number | null | undefined;
    beginMeasureOdometerAt: any;
    beginMeasureHorimeterAt: any;
    date: any;
    distanceTraveled?: number | null | undefined;
    timeWithIgnitionOn?: number | null | undefined;
    vehicleId: string;
    vehicle: {
      __typename?: 'Vehicle';
      plate: string;
      displayName?: string | null | undefined;
      odometer: number;
      horimeter: number;
    };
  }>;
};

export type VehiclesSummariesQueryVariables = Exact<{
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']> | Scalars['ID'];
  groupedBy?: InputMaybe<SummaryDateGroupedByEnum>;
}>;

export type VehiclesSummariesQuery = {
  __typename?: 'Query';
  vehiclesSummaries: Array<{
    __typename?: 'VehicleSummary';
    startAt: any;
    endAt: any;
    vehicleId: string;
    distanceTraveled: number;
    averageSpeed: number;
    maximumSpeed: number;
    engineIdle: number;
    timeOverSpeed: number;
    timeStopped: number;
    timeWithIgnitionOn: number;
    timeStoppedWithIgnitionOn: number;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    eventOccurrences: {
      __typename?: 'EventOccurrences';
      boardActivated: number;
      boardDeactivated: number;
      cockpitDoorClose: number;
      cockpitDoorOpen: number;
      deviceMoving: number;
      deviceOverspeed: number;
      deviceOverweight: number;
      deviceStopped: number;
      driverChange: number;
      fault: number;
      geofenceEnter: number;
      geofenceExit: number;
      hardAcceleration: number;
      hardBraking: number;
      hardCornering: number;
      ignitionOff: number;
      ignitionOn: number;
      plugged: number;
      routeOverspeed: number;
      trailerOpen: number;
      unplugged: number;
    };
  }>;
};

export type SetCurrentDriverToVehicleDriverChangeHistoryMutationVariables =
  Exact<{
    id: Scalars['ID'];
    driver: Scalars['ID'];
  }>;

export type SetCurrentDriverToVehicleDriverChangeHistoryMutation = {
  __typename?: 'Mutation';
  setCurrentDriverToVehicleDriverChangeHistory: {
    __typename?: 'VehicleDriverChangeHistory';
    id: string;
    currentDriverToken?: string | null | undefined;
    previousDriverToken?: string | null | undefined;
    currentDriver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    previousDriver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  };
};

export type SetPreviousDriverToVehicleDriverChangeHistoryMutationVariables =
  Exact<{
    id: Scalars['ID'];
    driver: Scalars['ID'];
  }>;

export type SetPreviousDriverToVehicleDriverChangeHistoryMutation = {
  __typename?: 'Mutation';
  setPreviousDriverToVehicleDriverChangeHistory: {
    __typename?: 'VehicleDriverChangeHistory';
    id: string;
    currentDriverToken?: string | null | undefined;
    previousDriverToken?: string | null | undefined;
    currentDriver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    previousDriver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  };
};

export type SetVehicleDriverMutationVariables = Exact<{
  vehicleId: Scalars['ID'];
  driverId?: InputMaybe<Scalars['ID']>;
}>;

export type SetVehicleDriverMutation = {
  __typename?: 'Mutation';
  setVehicleDriver: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
};

export type SetVehicleMeasureMutationVariables = Exact<{
  vehicle: Scalars['ID'];
  odometer?: InputMaybe<Scalars['Float']>;
  horimeter?: InputMaybe<Scalars['Float']>;
}>;

export type SetVehicleMeasureMutation = {
  __typename?: 'Mutation';
  setVehicleMeasure: boolean;
};

export type ServicesQueryVariables = Exact<{
  filter?: InputMaybe<FilterServiceInput>;
}>;

export type ServicesQuery = {
  __typename?: 'Query';
  services: Array<{
    __typename?: 'Service';
    id: string;
    name: string;
    organization?:
      | { __typename?: 'Organization'; id: string }
      | null
      | undefined;
    category: { __typename?: 'Category'; id: string; name: string };
  }>;
};

export type TanksQueryVariables = Exact<{ [key: string]: never }>;

export type TanksQuery = {
  __typename?: 'Query';
  tanks: Array<{
    __typename?: 'Tank';
    id: string;
    name: string;
    initialVolume?: number | null | undefined;
    capacity?: number | null | undefined;
    balance: number;
  }>;
};

export type TasksQueryVariables = Exact<{
  filter?: InputMaybe<FilterTaskInput>;
}>;

export type TasksQuery = {
  __typename?: 'Query';
  tasks: Array<{
    __typename?: 'Task';
    id: string;
    createdAt: any;
    updatedAt: any;
    completedAt?: any | null | undefined;
    description: string;
    type: TaskTypeEnum;
    odometer?: number | null | undefined;
    horimeter?: number | null | undefined;
    amount: number;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    service: {
      __typename?: 'Service';
      id: string;
      name: string;
      organization?:
        | { __typename?: 'Organization'; id: string }
        | null
        | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
    };
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
    parts: Array<{
      __typename?: 'TaskPart';
      id: string;
      price: number;
      quantity: number;
      subTotal: number;
      part: {
        __typename?: 'Part';
        id: string;
        name: string;
        standard: boolean;
        createdAt: any;
      };
    }>;
    prices: Array<{
      __typename?: 'TaskCost';
      id: string;
      type: CostTypeEnum;
      amount: number;
    }>;
  }>;
};

export type CreateTrafficInfractionMutationVariables = Exact<{
  payload: CreateTrafficInfractionInput;
}>;

export type CreateTrafficInfractionMutation = {
  __typename?: 'Mutation';
  createTrafficInfraction: {
    __typename?: 'TrafficInfraction';
    id: string;
    cost?: number | null | undefined;
    paidCost?: number | null | undefined;
    referenceDocument?: string | null | undefined;
    location?: any | null | undefined;
    observation?: string | null | undefined;
    paidAt?: any | null | undefined;
    dueDate?: any | null | undefined;
    occurredAt: any;
    address?: string | null | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    infraction: {
      __typename?: 'Infraction';
      id: string;
      code: string;
      cost?: number | null | undefined;
      points?: number | null | undefined;
      description: string;
    };
  };
};

export type DeleteTrafficInfractionMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteTrafficInfractionMutation = {
  __typename?: 'Mutation';
  deleteTrafficInfraction: boolean;
};

export type GetInfractionsQueryVariables = Exact<{
  payload: FindInfractionInput;
}>;

export type GetInfractionsQuery = {
  __typename?: 'Query';
  findInfractions: Array<{
    __typename?: 'Infraction';
    id: string;
    cost?: number | null | undefined;
    description: string;
    code: string;
    points?: number | null | undefined;
  }>;
};

export type GetTrafficInfractionsQueryVariables = Exact<{
  payload: ListTrafficInfractionInput;
}>;

export type GetTrafficInfractionsQuery = {
  __typename?: 'Query';
  trafficInfractions: Array<{
    __typename?: 'TrafficInfraction';
    id: string;
    paidCost?: number | null | undefined;
    cost?: number | null | undefined;
    observation?: string | null | undefined;
    paidAt?: any | null | undefined;
    deletedAt?: any | null | undefined;
    dueDate?: any | null | undefined;
    occurredAt: any;
    address?: string | null | undefined;
    location?: any | null | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    infraction: {
      __typename?: 'Infraction';
      id: string;
      code: string;
      cost?: number | null | undefined;
      points?: number | null | undefined;
      description: string;
    };
  }>;
};

export type PayTrafficInfractionMutationVariables = Exact<{
  payload: PayTrafficInfractionInput;
}>;

export type PayTrafficInfractionMutation = {
  __typename?: 'Mutation';
  payTrafficInfraction: {
    __typename?: 'TrafficInfraction';
    id: string;
    cost?: number | null | undefined;
    paidCost?: number | null | undefined;
    referenceDocument?: string | null | undefined;
    location?: any | null | undefined;
    observation?: string | null | undefined;
    paidAt?: any | null | undefined;
    dueDate?: any | null | undefined;
    occurredAt: any;
    address?: string | null | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    infraction: {
      __typename?: 'Infraction';
      id: string;
      code: string;
      cost?: number | null | undefined;
      points?: number | null | undefined;
      description: string;
    };
  };
};

export type UpdateTrafficInfractionMutationVariables = Exact<{
  payload: UpdateTrafficInfractionInput;
}>;

export type UpdateTrafficInfractionMutation = {
  __typename?: 'Mutation';
  updateTrafficInfraction: {
    __typename?: 'TrafficInfraction';
    id: string;
    cost?: number | null | undefined;
    paidCost?: number | null | undefined;
    referenceDocument?: string | null | undefined;
    location?: any | null | undefined;
    observation?: string | null | undefined;
    paidAt?: any | null | undefined;
    dueDate?: any | null | undefined;
    occurredAt: any;
    address?: string | null | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    infraction: {
      __typename?: 'Infraction';
      id: string;
      code: string;
      cost?: number | null | undefined;
      points?: number | null | undefined;
      description: string;
    };
  };
};

export type UpdateDriverMutationVariables = Exact<{
  payload: UpdateDriverInput;
}>;

export type UpdateDriverMutation = {
  __typename?: 'Mutation';
  updateDriver: {
    __typename?: 'Driver';
    id: string;
    name?: string | null | undefined;
    cpf?: string | null | undefined;
    token?: string | null | undefined;
    organization: { __typename?: 'Organization'; id: string; name: string };
    user?: { __typename?: 'User'; id: string } | null | undefined;
  };
};

export type UpdateFuellingMutationVariables = Exact<{
  payload: UpdateFuellingInput;
}>;

export type UpdateFuellingMutation = {
  __typename?: 'Mutation';
  updateFuelling: {
    __typename?: 'Fuelling';
    id: string;
    amount: number;
    occurredAt: any;
    cost?: number | null | undefined;
    fuelType: string;
    attributes: any;
    odometer?: number | null | undefined;
    horimeter?: number | null | undefined;
    description?: string | null | undefined;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
    tank?:
      | {
          __typename?: 'Tank';
          id: string;
          name: string;
          initialVolume?: number | null | undefined;
          capacity?: number | null | undefined;
          balance: number;
        }
      | null
      | undefined;
    invoiceEvidence?:
      | {
          __typename?: 'DocumentFile';
          category: string;
          createdAt: any;
          fileName: string;
          id: string;
          mimeType: string;
          organizationId: string;
          size: number;
          updatedAt: any;
          url: string;
        }
      | null
      | undefined;
    odometerEvidence?:
      | {
          __typename?: 'DocumentFile';
          category: string;
          createdAt: any;
          fileName: string;
          id: string;
          mimeType: string;
          organizationId: string;
          size: number;
          updatedAt: any;
          url: string;
        }
      | null
      | undefined;
  };
};

export type UpdateMaintenanceReminderMutationVariables = Exact<{
  payload: UpdateMaintenanceReminderInput;
}>;

export type UpdateMaintenanceReminderMutation = {
  __typename?: 'Mutation';
  updateMaintenanceReminder: {
    __typename?: 'MaintenanceReminder';
    id: string;
    createdAt: any;
    updatedAt: any;
    frequencyTime?: number | null | undefined;
    thresholdTime?: number | null | undefined;
    frequencyMeasure?: number | null | undefined;
    thresholdMeasure?: number | null | undefined;
    lastOccurrence: any;
    lastMeasure: number;
    remainingMeasure: number;
    remainingDays: number;
    service: {
      __typename?: 'Service';
      id: string;
      name: string;
      organization?:
        | { __typename?: 'Organization'; id: string }
        | null
        | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
    };
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
  };
};

export type UpdateOrderMutationVariables = Exact<{
  payload: UpdateOrderInput;
}>;

export type UpdateOrderMutation = {
  __typename?: 'Mutation';
  updateOrder: {
    __typename?: 'Order';
    id: string;
    invoiceNumber: number;
    amount: number;
    value: number;
    occurredAt: any;
    tank?:
      | {
          __typename?: 'Tank';
          id: string;
          name: string;
          initialVolume?: number | null | undefined;
          capacity?: number | null | undefined;
          balance: number;
        }
      | null
      | undefined;
  };
};

export type UpdateServiceMutationVariables = Exact<{
  payload: UpdateMaintenanceServiceInput;
}>;

export type UpdateServiceMutation = {
  __typename?: 'Mutation';
  updateService: {
    __typename?: 'Service';
    id: string;
    name: string;
    organization?:
      | { __typename?: 'Organization'; id: string }
      | null
      | undefined;
    category: { __typename?: 'Category'; id: string; name: string };
  };
};

export type UpdateTankMutationVariables = Exact<{
  payload: UpdateTankInput;
}>;

export type UpdateTankMutation = {
  __typename?: 'Mutation';
  updateTank: {
    __typename?: 'Tank';
    id: string;
    name: string;
    initialVolume?: number | null | undefined;
    capacity?: number | null | undefined;
    balance: number;
  };
};

export type UpdateTaskMutationVariables = Exact<{
  payload: UpdateMaintenanceTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: {
    __typename?: 'Task';
    id: string;
    createdAt: any;
    updatedAt: any;
    completedAt?: any | null | undefined;
    description: string;
    type: TaskTypeEnum;
    odometer?: number | null | undefined;
    horimeter?: number | null | undefined;
    amount: number;
    vehicle: {
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    };
    service: {
      __typename?: 'Service';
      id: string;
      name: string;
      organization?:
        | { __typename?: 'Organization'; id: string }
        | null
        | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
    };
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
    provider?:
      | {
          __typename?: 'Provider';
          id: string;
          name: string;
          cnpj?: string | null | undefined;
          source: string;
        }
      | null
      | undefined;
    parts: Array<{
      __typename?: 'TaskPart';
      id: string;
      price: number;
      quantity: number;
      subTotal: number;
      part: {
        __typename?: 'Part';
        id: string;
        name: string;
        standard: boolean;
        createdAt: any;
      };
    }>;
    prices: Array<{
      __typename?: 'TaskCost';
      id: string;
      type: CostTypeEnum;
      amount: number;
    }>;
  };
};

export type UpdateUserMutationVariables = Exact<{
  user: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    name?: string | null | undefined;
    cpf?: string | null | undefined;
    email?: string | null | undefined;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    settings: any;
    modules: any;
    permissions: any;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null | undefined;
      disabledAt?: any | null | undefined;
      createdAt: any;
      trialAt?: any | null | undefined;
      contractEndedAt?: any | null | undefined;
      address?: string | null | undefined;
      phone?: string | null | undefined;
      settings: any;
      signUpFee?: number | null | undefined;
      monthlyPayment?: number | null | undefined;
      capacity?: OrganizationCapacity | null | undefined;
      category?: string | null | undefined;
      sector?: string | null | undefined;
      contractBeganAt?: any | null | undefined;
      contractMonthDuration?: number | null | undefined;
    };
    vehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
  };
};

export type UserWithVehiclesQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserWithVehiclesQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    name?: string | null | undefined;
    cpf?: string | null | undefined;
    email?: string | null | undefined;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    settings: any;
    modules: any;
    permissions: any;
    availableVehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null | undefined;
      disabledAt?: any | null | undefined;
      createdAt: any;
      trialAt?: any | null | undefined;
      contractEndedAt?: any | null | undefined;
      address?: string | null | undefined;
      phone?: string | null | undefined;
      settings: any;
      signUpFee?: number | null | undefined;
      monthlyPayment?: number | null | undefined;
      capacity?: OrganizationCapacity | null | undefined;
      category?: string | null | undefined;
      sector?: string | null | undefined;
      contractBeganAt?: any | null | undefined;
      contractMonthDuration?: number | null | undefined;
    };
    vehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
  };
};

export type UserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    permissions: any;
    role: UserRole;
    settings: any;
    cpf?: string | null | undefined;
    email?: string | null | undefined;
    modules: any;
    name?: string | null | undefined;
    id: string;
    organization: { __typename?: 'Organization'; id: string; name: string };
  };
};

export type UsersWithVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type UsersWithVehiclesQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null | undefined;
    cpf?: string | null | undefined;
    email?: string | null | undefined;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    settings: any;
    modules: any;
    permissions: any;
    availableVehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null | undefined;
      disabledAt?: any | null | undefined;
      createdAt: any;
      trialAt?: any | null | undefined;
      contractEndedAt?: any | null | undefined;
      address?: string | null | undefined;
      phone?: string | null | undefined;
      settings: any;
      signUpFee?: number | null | undefined;
      monthlyPayment?: number | null | undefined;
      capacity?: OrganizationCapacity | null | undefined;
      category?: string | null | undefined;
      sector?: string | null | undefined;
      contractBeganAt?: any | null | undefined;
      contractMonthDuration?: number | null | undefined;
    };
    vehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
  }>;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    permissions: any;
    role: UserRole;
    settings: any;
    cpf?: string | null | undefined;
    email?: string | null | undefined;
    id: string;
    modules: any;
    name?: string | null | undefined;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null | undefined;
      disabledAt?: any | null | undefined;
      createdAt: any;
      trialAt?: any | null | undefined;
      contractEndedAt?: any | null | undefined;
      address?: string | null | undefined;
      phone?: string | null | undefined;
      settings: any;
      signUpFee?: number | null | undefined;
      monthlyPayment?: number | null | undefined;
      capacity?: OrganizationCapacity | null | undefined;
      category?: string | null | undefined;
      sector?: string | null | undefined;
      contractBeganAt?: any | null | undefined;
      contractMonthDuration?: number | null | undefined;
    };
  }>;
};

export type CreateGroupMutationVariables = Exact<{
  payload: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createGroup: {
    __typename?: 'Group';
    id: string;
    name: string;
    vehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
  };
};

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;

export type DeleteGroupMutation = {
  __typename?: 'Mutation';
  deleteGroup: boolean;
};

export type GroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsQuery = {
  __typename?: 'Query';
  groups: Array<{
    __typename?: 'Group';
    id: string;
    name: string;
    vehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
  }>;
};

export type UpdateGroupMutationVariables = Exact<{
  payload: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
  __typename?: 'Mutation';
  updateGroup: {
    __typename?: 'Group';
    id: string;
    name: string;
    vehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null | undefined;
      model?: string | null | undefined;
      brand?: string | null | undefined;
      type: VehicleTypeEnum;
      year?: string | null | undefined;
      fuelType?: FuelTypeEnum | null | undefined;
      document?: string | null | undefined;
      observation?: string | null | undefined;
      avatarType: VehicleTypeEnum;
      price?: number | null | undefined;
      lastDriverToken?: string | null | undefined;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null | undefined;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null | undefined;
      min_speed?: number | null | undefined;
      tank_size?: number | null | undefined;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null | undefined;
      plugged_status_at?: any | null | undefined;
    }>;
  };
};

export type VehicleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type VehicleQuery = {
  __typename?: 'Query';
  vehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateVehicleMutationVariables = Exact<{
  payload: UpdateVehicleInput;
}>;

export type UpdateVehicleMutation = {
  __typename?: 'Mutation';
  updateVehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  };
};

export type VehicleReportQueryVariables = Exact<{
  payload: VehicleReportInput;
}>;

export type VehicleReportQuery = {
  __typename?: 'Query';
  vehicleReport: Array<{
    __typename?: 'VehicleReport';
    expenditure: number;
    totalCost: number;
    costPerHour: number;
    distanceTraveled?: number | null | undefined;
    timeTraveled?: number | null | undefined;
    costPerKM: number;
    maintenance: number;
    maintenancePrice: number;
    fuelConsumedLiters?: number | null | undefined;
    fuelConsumedPrice?: number | null | undefined;
    startAt: string;
    tripFuelConsumedLiters?: number | null | undefined;
    tripFuelConsumedPrice?: number | null | undefined;
    autonomy?: number | null | undefined;
    tripAutonomy?: number | null | undefined;
  }>;
};

export type VehicleTypesQueryVariables = Exact<{ [key: string]: never }>;

export type VehicleTypesQuery = {
  __typename?: 'Query';
  vehicleTypes: Array<{
    __typename?: 'VehicleType';
    slugName: VehicleTypeEnum;
    name: string;
  }>;
};

export type VehiclesWithBasicInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type VehiclesWithBasicInfoQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
  }>;
};

export type VehiclesWithLastInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type VehiclesWithLastInfoQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
    location?:
      | {
          __typename?: 'Location';
          id: string;
          vehicleId: string;
          driverId?: string | null | undefined;
          latitude: number;
          longitude: number;
          course: number;
          ignition?: boolean | null | undefined;
          status: VehicleStatus;
          speed: number;
          address?: string | null | undefined;
          fixTime: any;
          attributes: any;
        }
      | null
      | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type VehiclesWithLocationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type VehiclesWithLocationQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
    location?:
      | {
          __typename?: 'Location';
          id: string;
          vehicleId: string;
          driverId?: string | null | undefined;
          latitude: number;
          longitude: number;
          course: number;
          ignition?: boolean | null | undefined;
          status: VehicleStatus;
          speed: number;
          address?: string | null | undefined;
          fixTime: any;
          attributes: any;
        }
      | null
      | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type VehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type VehiclesQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null | undefined;
    model?: string | null | undefined;
    brand?: string | null | undefined;
    type: VehicleTypeEnum;
    year?: string | null | undefined;
    fuelType?: FuelTypeEnum | null | undefined;
    document?: string | null | undefined;
    observation?: string | null | undefined;
    avatarType: VehicleTypeEnum;
    price?: number | null | undefined;
    lastDriverToken?: string | null | undefined;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null | undefined;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null | undefined;
    min_speed?: number | null | undefined;
    tank_size?: number | null | undefined;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null | undefined;
    plugged_status_at?: any | null | undefined;
    driver?:
      | {
          __typename?: 'Driver';
          id: string;
          name?: string | null | undefined;
          cpf?: string | null | undefined;
          cnh?: string | null | undefined;
          token?: string | null | undefined;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export const VehicleFragmentDoc = gql`
  fragment Vehicle on Vehicle {
    id
    plate
    displayName
    model
    brand
    type
    year
    fuelType
    document
    observation
    avatarType
    price
    lastDriverToken
    odometer
    horimeter
    fuelLevel
    max_speed: maxSpeed
    min_speed: minSpeed
    tank_size: tankSize
    brand
    settings
    useObdData
    created_at: createdAt
    updated_at: updatedAt
    plugged_status: pluggedStatus
    plugged_status_at: pluggedStatusAt
  }
`;
export const DriverFragmentDoc = gql`
  fragment Driver on Driver {
    id
    name
    cpf
    cnh
    token
    user {
      id
    }
  }
`;
export const GeofenceFragmentDoc = gql`
  fragment Geofence on Geofence {
    id
    name
    type
    color
    area
    isEnabled
    parking
  }
`;
export const VehicleDriverChangeHistoryFragmentDoc = gql`
  fragment VehicleDriverChangeHistory on VehicleDriverChangeHistory {
    id
    currentDriverToken
    currentDriver {
      ...Driver
    }
    previousDriverToken
    previousDriver {
      ...Driver
    }
  }
  ${DriverFragmentDoc}
`;
export const EventFragmentDoc = gql`
  fragment Event on Event {
    id
    reportedAt
    slugName
    latitude
    longitude
    address
    attributes
    origin
    vehicle {
      ...Vehicle
    }
    driver {
      ...Driver
    }
    geofence {
      ...Geofence
    }
    vehicleDriverChangeHistory {
      ...VehicleDriverChangeHistory
    }
  }
  ${VehicleFragmentDoc}
  ${DriverFragmentDoc}
  ${GeofenceFragmentDoc}
  ${VehicleDriverChangeHistoryFragmentDoc}
`;
export const FuelTypeFragmentDoc = gql`
  fragment FuelType on FuelType {
    slugName
    description
  }
`;
export const ProviderFragmentDoc = gql`
  fragment Provider on Provider {
    id
    name
    cnpj
    source
  }
`;
export const TankFragmentDoc = gql`
  fragment Tank on Tank {
    id
    name
    initialVolume
    capacity
    balance
  }
`;
export const DocumentFileFragmentDoc = gql`
  fragment DocumentFile on DocumentFile {
    category
    createdAt
    fileName
    id
    mimeType
    organizationId
    size
    updatedAt
    url
  }
`;
export const FuellingFragmentDoc = gql`
  fragment Fuelling on Fuelling {
    id
    amount
    occurredAt
    cost
    fuelType
    attributes
    odometer
    horimeter
    cost
    description
    vehicle {
      ...Vehicle
    }
    provider {
      ...Provider
    }
    tank {
      ...Tank
    }
    invoiceEvidence {
      ...DocumentFile
    }
    odometerEvidence {
      ...DocumentFile
    }
  }
  ${VehicleFragmentDoc}
  ${ProviderFragmentDoc}
  ${TankFragmentDoc}
  ${DocumentFileFragmentDoc}
`;
export const HorimeterHistoryFragmentDoc = gql`
  fragment HorimeterHistory on HorimeterHistory {
    id
    current
  }
`;
export const MaintenanceCostPerTypeReportFragmentDoc = gql`
  fragment MaintenanceCostPerTypeReport on MaintenanceCostPerTypeReport {
    date
    quantity
    correctiveMaintenanceCost
    preventiveMaintenanceCost
  }
`;
export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    id
    name
  }
`;
export const ServiceFragmentDoc = gql`
  fragment Service on Service {
    id
    name
    organization {
      id
    }
    category {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`;
export const MaintenanceReminderFragmentDoc = gql`
  fragment MaintenanceReminder on MaintenanceReminder {
    id
    createdAt
    updatedAt
    service {
      ...Service
    }
    vehicle {
      ...Vehicle
    }
    frequencyTime
    thresholdTime
    frequencyMeasure
    thresholdMeasure
    lastOccurrence
    lastMeasure
    remainingMeasure
    remainingDays
  }
  ${ServiceFragmentDoc}
  ${VehicleFragmentDoc}
`;
export const OdometerHistoryFragmentDoc = gql`
  fragment OdometerHistory on OdometerHistory {
    id
    current
  }
`;
export const OrderFragmentDoc = gql`
  fragment Order on Order {
    id
    invoiceNumber
    amount
    value
    occurredAt
    tank {
      ...Tank
    }
  }
  ${TankFragmentDoc}
`;
export const PositionFragmentDoc = gql`
  fragment Position on Position {
    id
    latitude
    longitude
    altitude
    course
    ignition
    speed
    address
    fixTime
    attributes
  }
`;
export const DailyWorkerMeasureFragmentDoc = gql`
  fragment DailyWorkerMeasure on DailyWorkMeasure {
    id
    initialOdometer
    initialHorimeter
    finalOdometer
    finalHorimeter
    beginMeasureOdometerAt
    beginMeasureHorimeterAt
    date
    distanceTraveled
    timeWithIgnitionOn
    vehicleId
    vehicle {
      plate
      displayName
      odometer
      horimeter
    }
  }
`;
export const EventOccurrencesFragmentDoc = gql`
  fragment EventOccurrences on EventOccurrences {
    boardActivated
    boardDeactivated
    cockpitDoorClose
    cockpitDoorOpen
    deviceMoving
    deviceOverspeed
    deviceOverweight
    deviceStopped
    driverChange
    fault
    geofenceEnter
    geofenceExit
    hardAcceleration
    hardBraking
    hardCornering
    ignitionOff
    ignitionOn
    plugged
    routeOverspeed
    trailerOpen
    unplugged
  }
`;
export const VehicleSummaryFragmentDoc = gql`
  fragment VehicleSummary on VehicleSummary {
    startAt
    endAt
    vehicleId
    vehicle {
      ...Vehicle
    }
    distanceTraveled
    averageSpeed
    maximumSpeed
    engineIdle
    distanceTraveled
    timeOverSpeed
    timeStopped
    timeWithIgnitionOn
    timeStoppedWithIgnitionOn
    eventOccurrences {
      ...EventOccurrences
    }
  }
  ${VehicleFragmentDoc}
  ${EventOccurrencesFragmentDoc}
`;
export const PartFragmentDoc = gql`
  fragment Part on Part {
    id
    name
    standard
    createdAt
  }
`;
export const TaskPartFragmentDoc = gql`
  fragment TaskPart on TaskPart {
    id
    price
    quantity
    subTotal
    part {
      ...Part
    }
  }
  ${PartFragmentDoc}
`;
export const TaskFragmentDoc = gql`
  fragment Task on Task {
    id
    createdAt
    updatedAt
    completedAt
    vehicle {
      ...Vehicle
    }
    service {
      ...Service
    }
    driver {
      ...Driver
    }
    provider {
      ...Provider
    }
    description
    type
    odometer
    horimeter
    parts {
      ...TaskPart
    }
    amount
    prices {
      id
      type
      amount
    }
  }
  ${VehicleFragmentDoc}
  ${ServiceFragmentDoc}
  ${DriverFragmentDoc}
  ${ProviderFragmentDoc}
  ${TaskPartFragmentDoc}
`;
export const InfractionFragmentDoc = gql`
  fragment Infraction on Infraction {
    id
    code
    cost
    points
    description
  }
`;
export const TrafficInfractionFragmentDoc = gql`
  fragment TrafficInfraction on TrafficInfraction {
    id
    cost
    paidCost
    referenceDocument
    vehicle {
      ...Vehicle
    }
    driver {
      ...Driver
    }
    infraction {
      ...Infraction
    }
    location
    observation
    paidAt
    dueDate
    occurredAt
    referenceDocument
    address
  }
  ${VehicleFragmentDoc}
  ${DriverFragmentDoc}
  ${InfractionFragmentDoc}
`;
export const StopFragmentDoc = gql`
  fragment Stop on Stop {
    id
    startedAt
    finishedAt
    latitude
    longitude
  }
`;
export const TripFragmentDoc = gql`
  fragment Trip on Trip {
    id
    initialStop {
      ...Stop
    }
    finalStop {
      ...Stop
    }
    timeAboveMaxConfSpeed
    maximumSpeed
    distanceTraveled
    averageSpeed
    startedAt
    finishedAt
    fuelConsumedLiters
    fuelConsumedPrice
    eventsJson
  }
  ${StopFragmentDoc}
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    cnpj
    disabledAt
    createdAt
    trialAt
    contractEndedAt
    disabledAt
    address
    phone
    settings
    signUpFee
    monthlyPayment
    capacity
    category
    sector
    contractBeganAt
    contractMonthDuration
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    name
    cpf
    email
    role
    createdAt
    updatedAt
    settings
    modules
    permissions
    organization {
      ...Organization
    }
    vehicles {
      ...Vehicle
    }
  }
  ${OrganizationFragmentDoc}
  ${VehicleFragmentDoc}
`;
export const UserWithVehiclesFragmentDoc = gql`
  fragment UserWithVehicles on User {
    ...User
    availableVehicles {
      ...Vehicle
    }
  }
  ${UserFragmentDoc}
  ${VehicleFragmentDoc}
`;
export const GroupFragmentDoc = gql`
  fragment Group on Group {
    id
    name
    vehicles {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;
export const VehicleReportFragmentDoc = gql`
  fragment VehicleReport on VehicleReport {
    expenditure
    totalCost
    costPerHour
    distanceTraveled
    timeTraveled
    costPerKM
    maintenance
    maintenancePrice
    fuelConsumedLiters
    fuelConsumedPrice
    startAt
    fuelConsumedLiters
    tripFuelConsumedLiters
    tripFuelConsumedPrice
    autonomy
    tripAutonomy
  }
`;
export const VehicleWithDriverFragmentDoc = gql`
  fragment VehicleWithDriver on Vehicle {
    ...Vehicle
    driver {
      ...Driver
    }
  }
  ${VehicleFragmentDoc}
  ${DriverFragmentDoc}
`;
export const LocationFragmentDoc = gql`
  fragment Location on Location {
    id
    vehicleId
    driverId
    latitude
    longitude
    course
    ignition
    status
    speed
    address
    fixTime
    attributes
  }
`;
export const VehicleWithLocationAndDriverFragmentDoc = gql`
  fragment VehicleWithLocationAndDriver on Vehicle {
    ...VehicleWithDriver
    location {
      ...Location
    }
  }
  ${VehicleWithDriverFragmentDoc}
  ${LocationFragmentDoc}
`;
export const VehicleWithLastInfoFragmentDoc = gql`
  fragment VehicleWithLastInfo on Vehicle {
    ...VehicleWithLocationAndDriver
  }
  ${VehicleWithLocationAndDriverFragmentDoc}
`;
export const DriverMappingFragmentDoc = gql`
  fragment DriverMapping on DriverMapping {
    id
    name
    integrationId
    valid
    externalId
    driverId
    driver {
      ...Driver
    }
  }
  ${DriverFragmentDoc}
`;
export const FuelTypeMappingFragmentDoc = gql`
  fragment FuelTypeMapping on FuelTypeMapping {
    id
    name
    fuelType
    integrationId
    valid
    externalId
  }
`;
export const IntegrationFragmentDoc = gql`
  fragment Integration on Integration {
    id
    source
    pendingDriverMappings
    pendingFuelTypeMappings
    pendingProviderMappings
    pendingVehicleMappings
    hasPendency
    setupProgress
    setupErrorMessage
    setupCompletedAt
    enabledAt
  }
`;
export const IntegrationIntentFragmentDoc = gql`
  fragment IntegrationIntent on IntegrationIntent {
    id
    integrationId
    progress
    attributes
    error
    createdAt
    completed
  }
`;
export const IntegrationWithIntentsFragmentDoc = gql`
  fragment IntegrationWithIntents on Integration {
    ...Integration
    intents {
      ...IntegrationIntent
    }
  }
  ${IntegrationFragmentDoc}
  ${IntegrationIntentFragmentDoc}
`;
export const ProviderMappingFragmentDoc = gql`
  fragment ProviderMapping on ProviderMapping {
    id
    name
    integrationId
    valid
    externalId
    providerId
    provider {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;
export const VehicleMappingFragmentDoc = gql`
  fragment VehicleMapping on VehicleMapping {
    id
    name
    integrationId
    valid
    externalId
    vehicleId
    disabledAt
    vehicle {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;
export const NotificationActionFragmentDoc = gql`
  fragment NotificationAction on NotificationAction {
    id
    title
    url
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    title
    description
    imageUrl
    createdAt
    expiredAt
    interactedAt
    readAt
    actions {
      ...NotificationAction
    }
  }
  ${NotificationActionFragmentDoc}
`;
export const LoginWithCredentialsDocument = gql`
  mutation loginWithCredentials($email: String!, $password: String!) {
    login(username: $email, password: $password) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type LoginWithCredentialsMutationFn = Apollo.MutationFunction<
  LoginWithCredentialsMutation,
  LoginWithCredentialsMutationVariables
>;

/**
 * __useLoginWithCredentialsMutation__
 *
 * To run a mutation, you first call `useLoginWithCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithCredentialsMutation, { data, loading, error }] = useLoginWithCredentialsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginWithCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithCredentialsMutation,
    LoginWithCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginWithCredentialsMutation,
    LoginWithCredentialsMutationVariables
  >(LoginWithCredentialsDocument, options);
}
export type LoginWithCredentialsMutationHookResult = ReturnType<
  typeof useLoginWithCredentialsMutation
>;
export type LoginWithCredentialsMutationResult =
  Apollo.MutationResult<LoginWithCredentialsMutation>;
export type LoginWithCredentialsMutationOptions = Apollo.BaseMutationOptions<
  LoginWithCredentialsMutation,
  LoginWithCredentialsMutationVariables
>;
export const RequestPasswordRecoveryDocument = gql`
  mutation requestPasswordRecovery($username: String!) {
    requestPasswordRecovery(username: $username)
  }
`;
export type RequestPasswordRecoveryMutationFn = Apollo.MutationFunction<
  RequestPasswordRecoveryMutation,
  RequestPasswordRecoveryMutationVariables
>;

/**
 * __useRequestPasswordRecoveryMutation__
 *
 * To run a mutation, you first call `useRequestPasswordRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordRecoveryMutation, { data, loading, error }] = useRequestPasswordRecoveryMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRequestPasswordRecoveryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordRecoveryMutation,
    RequestPasswordRecoveryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordRecoveryMutation,
    RequestPasswordRecoveryMutationVariables
  >(RequestPasswordRecoveryDocument, options);
}
export type RequestPasswordRecoveryMutationHookResult = ReturnType<
  typeof useRequestPasswordRecoveryMutation
>;
export type RequestPasswordRecoveryMutationResult =
  Apollo.MutationResult<RequestPasswordRecoveryMutation>;
export type RequestPasswordRecoveryMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordRecoveryMutation,
  RequestPasswordRecoveryMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const CategoriesDocument = gql`
  query categories {
    categories {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options,
  );
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoriesQuery,
    CategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(
    CategoriesDocument,
    options,
  );
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<
  typeof useCategoriesLazyQuery
>;
export type CategoriesQueryResult = Apollo.QueryResult<
  CategoriesQuery,
  CategoriesQueryVariables
>;
export const ChangeUserPasswordDocument = gql`
  mutation changeUserPassword($user: ID!, $password: String!) {
    changeUserPassword(user: $user, password: $password)
  }
`;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >(ChangeUserPasswordDocument, options);
}
export type ChangeUserPasswordMutationHookResult = ReturnType<
  typeof useChangeUserPasswordMutation
>;
export type ChangeUserPasswordMutationResult =
  Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>;
export const ChangeUserVehiclesDocument = gql`
  mutation changeUserVehicles($change: ChangeUserVehiclesInput!) {
    changeUserVehicles(payload: $change)
  }
`;
export type ChangeUserVehiclesMutationFn = Apollo.MutationFunction<
  ChangeUserVehiclesMutation,
  ChangeUserVehiclesMutationVariables
>;

/**
 * __useChangeUserVehiclesMutation__
 *
 * To run a mutation, you first call `useChangeUserVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserVehiclesMutation, { data, loading, error }] = useChangeUserVehiclesMutation({
 *   variables: {
 *      change: // value for 'change'
 *   },
 * });
 */
export function useChangeUserVehiclesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserVehiclesMutation,
    ChangeUserVehiclesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserVehiclesMutation,
    ChangeUserVehiclesMutationVariables
  >(ChangeUserVehiclesDocument, options);
}
export type ChangeUserVehiclesMutationHookResult = ReturnType<
  typeof useChangeUserVehiclesMutation
>;
export type ChangeUserVehiclesMutationResult =
  Apollo.MutationResult<ChangeUserVehiclesMutation>;
export type ChangeUserVehiclesMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserVehiclesMutation,
  ChangeUserVehiclesMutationVariables
>;
export const ChangeVehicleMeasureDocument = gql`
  mutation changeVehicleMeasure(
    $vehicleId: ID!
    $measure: Float!
    $measurement: MeasurementTypeEnum!
    $measuredAt: DateTime!
  ) {
    changeVehicleMeasure(
      payload: {
        vehicleId: $vehicleId
        measure: $measure
        measurement: $measurement
        measuredAt: $measuredAt
      }
    ) {
      id
      vehicleId
    }
  }
`;
export type ChangeVehicleMeasureMutationFn = Apollo.MutationFunction<
  ChangeVehicleMeasureMutation,
  ChangeVehicleMeasureMutationVariables
>;

/**
 * __useChangeVehicleMeasureMutation__
 *
 * To run a mutation, you first call `useChangeVehicleMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVehicleMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVehicleMeasureMutation, { data, loading, error }] = useChangeVehicleMeasureMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      measure: // value for 'measure'
 *      measurement: // value for 'measurement'
 *      measuredAt: // value for 'measuredAt'
 *   },
 * });
 */
export function useChangeVehicleMeasureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeVehicleMeasureMutation,
    ChangeVehicleMeasureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeVehicleMeasureMutation,
    ChangeVehicleMeasureMutationVariables
  >(ChangeVehicleMeasureDocument, options);
}
export type ChangeVehicleMeasureMutationHookResult = ReturnType<
  typeof useChangeVehicleMeasureMutation
>;
export type ChangeVehicleMeasureMutationResult =
  Apollo.MutationResult<ChangeVehicleMeasureMutation>;
export type ChangeVehicleMeasureMutationOptions = Apollo.BaseMutationOptions<
  ChangeVehicleMeasureMutation,
  ChangeVehicleMeasureMutationVariables
>;
export const CreateDriverDocument = gql`
  mutation createDriver($payload: CreateDriverInput!) {
    createDriver(payload: $payload) {
      id
      name
      cpf
      token
      organization {
        id
        name
      }
      user {
        id
      }
    }
  }
`;
export type CreateDriverMutationFn = Apollo.MutationFunction<
  CreateDriverMutation,
  CreateDriverMutationVariables
>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDriverMutation,
    CreateDriverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDriverMutation,
    CreateDriverMutationVariables
  >(CreateDriverDocument, options);
}
export type CreateDriverMutationHookResult = ReturnType<
  typeof useCreateDriverMutation
>;
export type CreateDriverMutationResult =
  Apollo.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<
  CreateDriverMutation,
  CreateDriverMutationVariables
>;
export const CreateFuellingDocument = gql`
  mutation createFuelling($payload: CreateFuellingInput!) {
    createFuelling(payload: $payload) {
      ...Fuelling
    }
  }
  ${FuellingFragmentDoc}
`;
export type CreateFuellingMutationFn = Apollo.MutationFunction<
  CreateFuellingMutation,
  CreateFuellingMutationVariables
>;

/**
 * __useCreateFuellingMutation__
 *
 * To run a mutation, you first call `useCreateFuellingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFuellingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFuellingMutation, { data, loading, error }] = useCreateFuellingMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateFuellingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFuellingMutation,
    CreateFuellingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFuellingMutation,
    CreateFuellingMutationVariables
  >(CreateFuellingDocument, options);
}
export type CreateFuellingMutationHookResult = ReturnType<
  typeof useCreateFuellingMutation
>;
export type CreateFuellingMutationResult =
  Apollo.MutationResult<CreateFuellingMutation>;
export type CreateFuellingMutationOptions = Apollo.BaseMutationOptions<
  CreateFuellingMutation,
  CreateFuellingMutationVariables
>;
export const CreateMaintenanceReminderDocument = gql`
  mutation createMaintenanceReminder(
    $reminders: [CreateMaintenanceReminderInput!]!
  ) {
    createMaintenanceReminders(reminders: $reminders) {
      ...MaintenanceReminder
    }
  }
  ${MaintenanceReminderFragmentDoc}
`;
export type CreateMaintenanceReminderMutationFn = Apollo.MutationFunction<
  CreateMaintenanceReminderMutation,
  CreateMaintenanceReminderMutationVariables
>;

/**
 * __useCreateMaintenanceReminderMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceReminderMutation, { data, loading, error }] = useCreateMaintenanceReminderMutation({
 *   variables: {
 *      reminders: // value for 'reminders'
 *   },
 * });
 */
export function useCreateMaintenanceReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMaintenanceReminderMutation,
    CreateMaintenanceReminderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMaintenanceReminderMutation,
    CreateMaintenanceReminderMutationVariables
  >(CreateMaintenanceReminderDocument, options);
}
export type CreateMaintenanceReminderMutationHookResult = ReturnType<
  typeof useCreateMaintenanceReminderMutation
>;
export type CreateMaintenanceReminderMutationResult =
  Apollo.MutationResult<CreateMaintenanceReminderMutation>;
export type CreateMaintenanceReminderMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMaintenanceReminderMutation,
    CreateMaintenanceReminderMutationVariables
  >;
export const CreateOrderDocument = gql`
  mutation createOrder($order: CreateOrderInput!) {
    createOrder(payload: $order) {
      ...Order
    }
  }
  ${OrderFragmentDoc}
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    CreateOrderDocument,
    options,
  );
}
export type CreateOrderMutationHookResult = ReturnType<
  typeof useCreateOrderMutation
>;
export type CreateOrderMutationResult =
  Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const CreatePartDocument = gql`
  mutation createPart($payload: CreateMaintenanceParteInput!) {
    createPart(payload: $payload) {
      ...Part
    }
  }
  ${PartFragmentDoc}
`;
export type CreatePartMutationFn = Apollo.MutationFunction<
  CreatePartMutation,
  CreatePartMutationVariables
>;

/**
 * __useCreatePartMutation__
 *
 * To run a mutation, you first call `useCreatePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartMutation, { data, loading, error }] = useCreatePartMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreatePartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePartMutation,
    CreatePartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartMutation, CreatePartMutationVariables>(
    CreatePartDocument,
    options,
  );
}
export type CreatePartMutationHookResult = ReturnType<
  typeof useCreatePartMutation
>;
export type CreatePartMutationResult =
  Apollo.MutationResult<CreatePartMutation>;
export type CreatePartMutationOptions = Apollo.BaseMutationOptions<
  CreatePartMutation,
  CreatePartMutationVariables
>;
export const CreateProviderDocument = gql`
  mutation createProvider($provider: CreateProviderInput!) {
    createProvider(payload: $provider) {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;
export type CreateProviderMutationFn = Apollo.MutationFunction<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useCreateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >(CreateProviderDocument, options);
}
export type CreateProviderMutationHookResult = ReturnType<
  typeof useCreateProviderMutation
>;
export type CreateProviderMutationResult =
  Apollo.MutationResult<CreateProviderMutation>;
export type CreateProviderMutationOptions = Apollo.BaseMutationOptions<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;
export const CreateServiceDocument = gql`
  mutation createService($payload: CreateMaintenanceServiceInput!) {
    createService(payload: $payload) {
      ...Service
    }
  }
  ${ServiceFragmentDoc}
`;
export type CreateServiceMutationFn = Apollo.MutationFunction<
  CreateServiceMutation,
  CreateServiceMutationVariables
>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceMutation,
    CreateServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceMutation,
    CreateServiceMutationVariables
  >(CreateServiceDocument, options);
}
export type CreateServiceMutationHookResult = ReturnType<
  typeof useCreateServiceMutation
>;
export type CreateServiceMutationResult =
  Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceMutation,
  CreateServiceMutationVariables
>;
export const CreateTankDocument = gql`
  mutation createTank($tank: CreateTankInput!) {
    createTank(payload: $tank) {
      ...Tank
    }
  }
  ${TankFragmentDoc}
`;
export type CreateTankMutationFn = Apollo.MutationFunction<
  CreateTankMutation,
  CreateTankMutationVariables
>;

/**
 * __useCreateTankMutation__
 *
 * To run a mutation, you first call `useCreateTankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTankMutation, { data, loading, error }] = useCreateTankMutation({
 *   variables: {
 *      tank: // value for 'tank'
 *   },
 * });
 */
export function useCreateTankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTankMutation,
    CreateTankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTankMutation, CreateTankMutationVariables>(
    CreateTankDocument,
    options,
  );
}
export type CreateTankMutationHookResult = ReturnType<
  typeof useCreateTankMutation
>;
export type CreateTankMutationResult =
  Apollo.MutationResult<CreateTankMutation>;
export type CreateTankMutationOptions = Apollo.BaseMutationOptions<
  CreateTankMutation,
  CreateTankMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation createTask($payload: CreateMaintenanceTaskInput!) {
    createTask(payload: $payload) {
      id
      createdAt
      updatedAt
      completedAt
      vehicle {
        ...Vehicle
      }
      service {
        ...Service
      }
      driver {
        ...Driver
      }
      provider {
        ...Provider
      }
      description
      type
      odometer
      horimeter
      parts {
        id
        quantity
        price
        part {
          ...Part
        }
      }
      prices {
        id
        type
        amount
      }
    }
  }
  ${VehicleFragmentDoc}
  ${ServiceFragmentDoc}
  ${DriverFragmentDoc}
  ${ProviderFragmentDoc}
  ${PartFragmentDoc}
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskMutation,
    CreateTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options,
  );
}
export type CreateTaskMutationHookResult = ReturnType<
  typeof useCreateTaskMutation
>;
export type CreateTaskMutationResult =
  Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(payload: $user) {
      id
      name
      email
      cpf
      role
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteDriverDocument = gql`
  mutation deleteDriver($id: ID!) {
    deleteDriver(id: $id)
  }
`;
export type DeleteDriverMutationFn = Apollo.MutationFunction<
  DeleteDriverMutation,
  DeleteDriverMutationVariables
>;

/**
 * __useDeleteDriverMutation__
 *
 * To run a mutation, you first call `useDeleteDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverMutation, { data, loading, error }] = useDeleteDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDriverMutation,
    DeleteDriverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDriverMutation,
    DeleteDriverMutationVariables
  >(DeleteDriverDocument, options);
}
export type DeleteDriverMutationHookResult = ReturnType<
  typeof useDeleteDriverMutation
>;
export type DeleteDriverMutationResult =
  Apollo.MutationResult<DeleteDriverMutation>;
export type DeleteDriverMutationOptions = Apollo.BaseMutationOptions<
  DeleteDriverMutation,
  DeleteDriverMutationVariables
>;
export const DeleteFuellingDocument = gql`
  mutation deleteFuelling($id: ID!) {
    deleteFuelling(id: $id)
  }
`;
export type DeleteFuellingMutationFn = Apollo.MutationFunction<
  DeleteFuellingMutation,
  DeleteFuellingMutationVariables
>;

/**
 * __useDeleteFuellingMutation__
 *
 * To run a mutation, you first call `useDeleteFuellingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFuellingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFuellingMutation, { data, loading, error }] = useDeleteFuellingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFuellingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFuellingMutation,
    DeleteFuellingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFuellingMutation,
    DeleteFuellingMutationVariables
  >(DeleteFuellingDocument, options);
}
export type DeleteFuellingMutationHookResult = ReturnType<
  typeof useDeleteFuellingMutation
>;
export type DeleteFuellingMutationResult =
  Apollo.MutationResult<DeleteFuellingMutation>;
export type DeleteFuellingMutationOptions = Apollo.BaseMutationOptions<
  DeleteFuellingMutation,
  DeleteFuellingMutationVariables
>;
export const DeleteReminderDocument = gql`
  mutation deleteReminder($id: ID!) {
    deleteMaintenanceReminder(id: $id)
  }
`;
export type DeleteReminderMutationFn = Apollo.MutationFunction<
  DeleteReminderMutation,
  DeleteReminderMutationVariables
>;

/**
 * __useDeleteReminderMutation__
 *
 * To run a mutation, you first call `useDeleteReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReminderMutation, { data, loading, error }] = useDeleteReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReminderMutation,
    DeleteReminderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReminderMutation,
    DeleteReminderMutationVariables
  >(DeleteReminderDocument, options);
}
export type DeleteReminderMutationHookResult = ReturnType<
  typeof useDeleteReminderMutation
>;
export type DeleteReminderMutationResult =
  Apollo.MutationResult<DeleteReminderMutation>;
export type DeleteReminderMutationOptions = Apollo.BaseMutationOptions<
  DeleteReminderMutation,
  DeleteReminderMutationVariables
>;
export const DeleteOrderDocument = gql`
  mutation deleteOrder($id: ID!) {
    deleteOrder(id: $id)
  }
`;
export type DeleteOrderMutationFn = Apollo.MutationFunction<
  DeleteOrderMutation,
  DeleteOrderMutationVariables
>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrderMutation,
    DeleteOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(
    DeleteOrderDocument,
    options,
  );
}
export type DeleteOrderMutationHookResult = ReturnType<
  typeof useDeleteOrderMutation
>;
export type DeleteOrderMutationResult =
  Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrderMutation,
  DeleteOrderMutationVariables
>;
export const DeletePartDocument = gql`
  mutation deletePart($id: ID!) {
    deletePart(id: $id)
  }
`;
export type DeletePartMutationFn = Apollo.MutationFunction<
  DeletePartMutation,
  DeletePartMutationVariables
>;

/**
 * __useDeletePartMutation__
 *
 * To run a mutation, you first call `useDeletePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartMutation, { data, loading, error }] = useDeletePartMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePartMutation,
    DeletePartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePartMutation, DeletePartMutationVariables>(
    DeletePartDocument,
    options,
  );
}
export type DeletePartMutationHookResult = ReturnType<
  typeof useDeletePartMutation
>;
export type DeletePartMutationResult =
  Apollo.MutationResult<DeletePartMutation>;
export type DeletePartMutationOptions = Apollo.BaseMutationOptions<
  DeletePartMutation,
  DeletePartMutationVariables
>;
export const DeleteProviderDocument = gql`
  mutation deleteProvider($id: ID!) {
    deleteProvider(id: $id)
  }
`;
export type DeleteProviderMutationFn = Apollo.MutationFunction<
  DeleteProviderMutation,
  DeleteProviderMutationVariables
>;

/**
 * __useDeleteProviderMutation__
 *
 * To run a mutation, you first call `useDeleteProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProviderMutation, { data, loading, error }] = useDeleteProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProviderMutation,
    DeleteProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProviderMutation,
    DeleteProviderMutationVariables
  >(DeleteProviderDocument, options);
}
export type DeleteProviderMutationHookResult = ReturnType<
  typeof useDeleteProviderMutation
>;
export type DeleteProviderMutationResult =
  Apollo.MutationResult<DeleteProviderMutation>;
export type DeleteProviderMutationOptions = Apollo.BaseMutationOptions<
  DeleteProviderMutation,
  DeleteProviderMutationVariables
>;
export const DeleteServiceDocument = gql`
  mutation deleteService($id: ID!) {
    deleteService(id: $id)
  }
`;
export type DeleteServiceMutationFn = Apollo.MutationFunction<
  DeleteServiceMutation,
  DeleteServiceMutationVariables
>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceMutation,
    DeleteServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceMutation,
    DeleteServiceMutationVariables
  >(DeleteServiceDocument, options);
}
export type DeleteServiceMutationHookResult = ReturnType<
  typeof useDeleteServiceMutation
>;
export type DeleteServiceMutationResult =
  Apollo.MutationResult<DeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceMutation,
  DeleteServiceMutationVariables
>;
export const DeleteTankDocument = gql`
  mutation deleteTank($id: ID!) {
    deleteTank(id: $id)
  }
`;
export type DeleteTankMutationFn = Apollo.MutationFunction<
  DeleteTankMutation,
  DeleteTankMutationVariables
>;

/**
 * __useDeleteTankMutation__
 *
 * To run a mutation, you first call `useDeleteTankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTankMutation, { data, loading, error }] = useDeleteTankMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTankMutation,
    DeleteTankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTankMutation, DeleteTankMutationVariables>(
    DeleteTankDocument,
    options,
  );
}
export type DeleteTankMutationHookResult = ReturnType<
  typeof useDeleteTankMutation
>;
export type DeleteTankMutationResult =
  Apollo.MutationResult<DeleteTankMutation>;
export type DeleteTankMutationOptions = Apollo.BaseMutationOptions<
  DeleteTankMutation,
  DeleteTankMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options,
  );
}
export type DeleteTaskMutationHookResult = ReturnType<
  typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
  Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const DriversDocument = gql`
  query drivers {
    drivers {
      ...Driver
    }
  }
  ${DriverFragmentDoc}
`;

/**
 * __useDriversQuery__
 *
 * To run a query within a React component, call `useDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriversQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriversQuery(
  baseOptions?: Apollo.QueryHookOptions<DriversQuery, DriversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriversQuery, DriversQueryVariables>(
    DriversDocument,
    options,
  );
}
export function useDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriversQuery,
    DriversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriversQuery, DriversQueryVariables>(
    DriversDocument,
    options,
  );
}
export type DriversQueryHookResult = ReturnType<typeof useDriversQuery>;
export type DriversLazyQueryHookResult = ReturnType<typeof useDriversLazyQuery>;
export type DriversQueryResult = Apollo.QueryResult<
  DriversQuery,
  DriversQueryVariables
>;
export const FindGeocodeDocument = gql`
  query findGeocode($latitude: Float!, $longitude: Float!) {
    findGeocode(latitude: $latitude, longitude: $longitude) {
      description
    }
  }
`;

/**
 * __useFindGeocodeQuery__
 *
 * To run a query within a React component, call `useFindGeocodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindGeocodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindGeocodeQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useFindGeocodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindGeocodeQuery,
    FindGeocodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindGeocodeQuery, FindGeocodeQueryVariables>(
    FindGeocodeDocument,
    options,
  );
}
export function useFindGeocodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindGeocodeQuery,
    FindGeocodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindGeocodeQuery, FindGeocodeQueryVariables>(
    FindGeocodeDocument,
    options,
  );
}
export type FindGeocodeQueryHookResult = ReturnType<typeof useFindGeocodeQuery>;
export type FindGeocodeLazyQueryHookResult = ReturnType<
  typeof useFindGeocodeLazyQuery
>;
export type FindGeocodeQueryResult = Apollo.QueryResult<
  FindGeocodeQuery,
  FindGeocodeQueryVariables
>;
export const FindPositionsDocument = gql`
  query findPositions(
    $vehicle: ID!
    $startAt: DateTime!
    $endAt: DateTime!
    $limit: Int = 40000
  ) {
    findPositions(
      vehicle: $vehicle
      startAt: $startAt
      endAt: $endAt
      limit: $limit
    ) {
      ...Position
    }
  }
  ${PositionFragmentDoc}
`;

/**
 * __useFindPositionsQuery__
 *
 * To run a query within a React component, call `useFindPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPositionsQuery({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindPositionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindPositionsQuery,
    FindPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPositionsQuery, FindPositionsQueryVariables>(
    FindPositionsDocument,
    options,
  );
}
export function useFindPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPositionsQuery,
    FindPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPositionsQuery, FindPositionsQueryVariables>(
    FindPositionsDocument,
    options,
  );
}
export type FindPositionsQueryHookResult = ReturnType<
  typeof useFindPositionsQuery
>;
export type FindPositionsLazyQueryHookResult = ReturnType<
  typeof useFindPositionsLazyQuery
>;
export type FindPositionsQueryResult = Apollo.QueryResult<
  FindPositionsQuery,
  FindPositionsQueryVariables
>;
export const TripsDocument = gql`
  query trips($vehicle: ID!, $startedAt: DateTime!, $finishedAt: DateTime!) {
    trips: findTripsByVehicle(
      vehicle: $vehicle
      startedAt: $startedAt
      finishedAt: $finishedAt
    ) {
      ...Trip
    }
  }
  ${TripFragmentDoc}
`;

/**
 * __useTripsQuery__
 *
 * To run a query within a React component, call `useTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsQuery({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *      startedAt: // value for 'startedAt'
 *      finishedAt: // value for 'finishedAt'
 *   },
 * });
 */
export function useTripsQuery(
  baseOptions: Apollo.QueryHookOptions<TripsQuery, TripsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripsQuery, TripsQueryVariables>(
    TripsDocument,
    options,
  );
}
export function useTripsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TripsQuery, TripsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripsQuery, TripsQueryVariables>(
    TripsDocument,
    options,
  );
}
export type TripsQueryHookResult = ReturnType<typeof useTripsQuery>;
export type TripsLazyQueryHookResult = ReturnType<typeof useTripsLazyQuery>;
export type TripsQueryResult = Apollo.QueryResult<
  TripsQuery,
  TripsQueryVariables
>;
export const FuelTypesDocument = gql`
  query fuelTypes {
    fuelTypes {
      ...FuelType
    }
  }
  ${FuelTypeFragmentDoc}
`;

/**
 * __useFuelTypesQuery__
 *
 * To run a query within a React component, call `useFuelTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuelTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuelTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFuelTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FuelTypesQuery,
    FuelTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FuelTypesQuery, FuelTypesQueryVariables>(
    FuelTypesDocument,
    options,
  );
}
export function useFuelTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FuelTypesQuery,
    FuelTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FuelTypesQuery, FuelTypesQueryVariables>(
    FuelTypesDocument,
    options,
  );
}
export type FuelTypesQueryHookResult = ReturnType<typeof useFuelTypesQuery>;
export type FuelTypesLazyQueryHookResult = ReturnType<
  typeof useFuelTypesLazyQuery
>;
export type FuelTypesQueryResult = Apollo.QueryResult<
  FuelTypesQuery,
  FuelTypesQueryVariables
>;
export const GetFuellingReferenceDocument = gql`
  query getFuellingReference($vehicle: ID!, $date: DateTime!) {
    getFuellingReference(vehicle: $vehicle, date: $date) {
      minOdometer
      maxOdometer
      minHorimeter
      maxHorimeter
    }
  }
`;

/**
 * __useGetFuellingReferenceQuery__
 *
 * To run a query within a React component, call `useGetFuellingReferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuellingReferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuellingReferenceQuery({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetFuellingReferenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFuellingReferenceQuery,
    GetFuellingReferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFuellingReferenceQuery,
    GetFuellingReferenceQueryVariables
  >(GetFuellingReferenceDocument, options);
}
export function useGetFuellingReferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFuellingReferenceQuery,
    GetFuellingReferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFuellingReferenceQuery,
    GetFuellingReferenceQueryVariables
  >(GetFuellingReferenceDocument, options);
}
export type GetFuellingReferenceQueryHookResult = ReturnType<
  typeof useGetFuellingReferenceQuery
>;
export type GetFuellingReferenceLazyQueryHookResult = ReturnType<
  typeof useGetFuellingReferenceLazyQuery
>;
export type GetFuellingReferenceQueryResult = Apollo.QueryResult<
  GetFuellingReferenceQuery,
  GetFuellingReferenceQueryVariables
>;
export const DriversRankingDocument = gql`
  query driversRanking(
    $drivers: [ID!]!
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    driversRanking(drivers: $drivers, startAt: $startAt, endAt: $endAt) {
      ... on DriverRanking {
        driverId
        avgSpeed
        maxSpeed
        telemetrySummary
        totalTelemetryEvents
      }
    }
  }
`;

/**
 * __useDriversRankingQuery__
 *
 * To run a query within a React component, call `useDriversRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriversRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriversRankingQuery({
 *   variables: {
 *      drivers: // value for 'drivers'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useDriversRankingQuery(
  baseOptions: Apollo.QueryHookOptions<
    DriversRankingQuery,
    DriversRankingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriversRankingQuery, DriversRankingQueryVariables>(
    DriversRankingDocument,
    options,
  );
}
export function useDriversRankingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriversRankingQuery,
    DriversRankingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriversRankingQuery, DriversRankingQueryVariables>(
    DriversRankingDocument,
    options,
  );
}
export type DriversRankingQueryHookResult = ReturnType<
  typeof useDriversRankingQuery
>;
export type DriversRankingLazyQueryHookResult = ReturnType<
  typeof useDriversRankingLazyQuery
>;
export type DriversRankingQueryResult = Apollo.QueryResult<
  DriversRankingQuery,
  DriversRankingQueryVariables
>;
export const VehiclesRankingDocument = gql`
  query vehiclesRanking(
    $vehicles: [ID!]!
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    vehiclesRanking(vehicles: $vehicles, startAt: $startAt, endAt: $endAt) {
      ... on VehicleRanking {
        avgSpeed
        engineIdleByDay
        maxSpeed
        percentageTimeEngineIdle
        telemetrySummary
        totalTelemetryEvents
        totalTraveled
        vehicleId
      }
    }
  }
`;

/**
 * __useVehiclesRankingQuery__
 *
 * To run a query within a React component, call `useVehiclesRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesRankingQuery({
 *   variables: {
 *      vehicles: // value for 'vehicles'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useVehiclesRankingQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehiclesRankingQuery,
    VehiclesRankingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehiclesRankingQuery, VehiclesRankingQueryVariables>(
    VehiclesRankingDocument,
    options,
  );
}
export function useVehiclesRankingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesRankingQuery,
    VehiclesRankingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehiclesRankingQuery,
    VehiclesRankingQueryVariables
  >(VehiclesRankingDocument, options);
}
export type VehiclesRankingQueryHookResult = ReturnType<
  typeof useVehiclesRankingQuery
>;
export type VehiclesRankingLazyQueryHookResult = ReturnType<
  typeof useVehiclesRankingLazyQuery
>;
export type VehiclesRankingQueryResult = Apollo.QueryResult<
  VehiclesRankingQuery,
  VehiclesRankingQueryVariables
>;
export const CreateIntegrationDocument = gql`
  mutation createIntegration($payload: CreateIntegrationInput!) {
    createIntegration(payload: $payload) {
      ...Integration
    }
  }
  ${IntegrationFragmentDoc}
`;
export type CreateIntegrationMutationFn = Apollo.MutationFunction<
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables
>;

/**
 * __useCreateIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationMutation, { data, loading, error }] = useCreateIntegrationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIntegrationMutation,
    CreateIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIntegrationMutation,
    CreateIntegrationMutationVariables
  >(CreateIntegrationDocument, options);
}
export type CreateIntegrationMutationHookResult = ReturnType<
  typeof useCreateIntegrationMutation
>;
export type CreateIntegrationMutationResult =
  Apollo.MutationResult<CreateIntegrationMutation>;
export type CreateIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables
>;
export const CreateUnmappedDriversDocument = gql`
  mutation createUnmappedDrivers($mappings: [ID!]!) {
    createUnmappedDrivers(mappings: $mappings) {
      ...DriverMapping
    }
  }
  ${DriverMappingFragmentDoc}
`;
export type CreateUnmappedDriversMutationFn = Apollo.MutationFunction<
  CreateUnmappedDriversMutation,
  CreateUnmappedDriversMutationVariables
>;

/**
 * __useCreateUnmappedDriversMutation__
 *
 * To run a mutation, you first call `useCreateUnmappedDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnmappedDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnmappedDriversMutation, { data, loading, error }] = useCreateUnmappedDriversMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useCreateUnmappedDriversMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUnmappedDriversMutation,
    CreateUnmappedDriversMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUnmappedDriversMutation,
    CreateUnmappedDriversMutationVariables
  >(CreateUnmappedDriversDocument, options);
}
export type CreateUnmappedDriversMutationHookResult = ReturnType<
  typeof useCreateUnmappedDriversMutation
>;
export type CreateUnmappedDriversMutationResult =
  Apollo.MutationResult<CreateUnmappedDriversMutation>;
export type CreateUnmappedDriversMutationOptions = Apollo.BaseMutationOptions<
  CreateUnmappedDriversMutation,
  CreateUnmappedDriversMutationVariables
>;
export const CreateUnmappedProvidersDocument = gql`
  mutation createUnmappedProviders($mappings: [ID!]!) {
    createUnmappedProviders(mappings: $mappings) {
      ...ProviderMapping
    }
  }
  ${ProviderMappingFragmentDoc}
`;
export type CreateUnmappedProvidersMutationFn = Apollo.MutationFunction<
  CreateUnmappedProvidersMutation,
  CreateUnmappedProvidersMutationVariables
>;

/**
 * __useCreateUnmappedProvidersMutation__
 *
 * To run a mutation, you first call `useCreateUnmappedProvidersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnmappedProvidersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnmappedProvidersMutation, { data, loading, error }] = useCreateUnmappedProvidersMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useCreateUnmappedProvidersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUnmappedProvidersMutation,
    CreateUnmappedProvidersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUnmappedProvidersMutation,
    CreateUnmappedProvidersMutationVariables
  >(CreateUnmappedProvidersDocument, options);
}
export type CreateUnmappedProvidersMutationHookResult = ReturnType<
  typeof useCreateUnmappedProvidersMutation
>;
export type CreateUnmappedProvidersMutationResult =
  Apollo.MutationResult<CreateUnmappedProvidersMutation>;
export type CreateUnmappedProvidersMutationOptions = Apollo.BaseMutationOptions<
  CreateUnmappedProvidersMutation,
  CreateUnmappedProvidersMutationVariables
>;
export const CreateUnmappedVehiclesDocument = gql`
  mutation createUnmappedVehicles($mappings: [ID!]!) {
    createUnmappedVehicles(mappings: $mappings) {
      ...VehicleMapping
    }
  }
  ${VehicleMappingFragmentDoc}
`;
export type CreateUnmappedVehiclesMutationFn = Apollo.MutationFunction<
  CreateUnmappedVehiclesMutation,
  CreateUnmappedVehiclesMutationVariables
>;

/**
 * __useCreateUnmappedVehiclesMutation__
 *
 * To run a mutation, you first call `useCreateUnmappedVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnmappedVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnmappedVehiclesMutation, { data, loading, error }] = useCreateUnmappedVehiclesMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useCreateUnmappedVehiclesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUnmappedVehiclesMutation,
    CreateUnmappedVehiclesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUnmappedVehiclesMutation,
    CreateUnmappedVehiclesMutationVariables
  >(CreateUnmappedVehiclesDocument, options);
}
export type CreateUnmappedVehiclesMutationHookResult = ReturnType<
  typeof useCreateUnmappedVehiclesMutation
>;
export type CreateUnmappedVehiclesMutationResult =
  Apollo.MutationResult<CreateUnmappedVehiclesMutation>;
export type CreateUnmappedVehiclesMutationOptions = Apollo.BaseMutationOptions<
  CreateUnmappedVehiclesMutation,
  CreateUnmappedVehiclesMutationVariables
>;
export const DeleteIntegrationDocument = gql`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id)
  }
`;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIntegrationMutation,
    DeleteIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteIntegrationMutation,
    DeleteIntegrationMutationVariables
  >(DeleteIntegrationDocument, options);
}
export type DeleteIntegrationMutationHookResult = ReturnType<
  typeof useDeleteIntegrationMutation
>;
export type DeleteIntegrationMutationResult =
  Apollo.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>;
export const DriverMappingsDocument = gql`
  query driverMappings($integrationId: ID!) {
    driverMappings(integrationId: $integrationId) {
      ...DriverMapping
    }
  }
  ${DriverMappingFragmentDoc}
`;

/**
 * __useDriverMappingsQuery__
 *
 * To run a query within a React component, call `useDriverMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverMappingsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useDriverMappingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DriverMappingsQuery,
    DriverMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriverMappingsQuery, DriverMappingsQueryVariables>(
    DriverMappingsDocument,
    options,
  );
}
export function useDriverMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriverMappingsQuery,
    DriverMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriverMappingsQuery, DriverMappingsQueryVariables>(
    DriverMappingsDocument,
    options,
  );
}
export type DriverMappingsQueryHookResult = ReturnType<
  typeof useDriverMappingsQuery
>;
export type DriverMappingsLazyQueryHookResult = ReturnType<
  typeof useDriverMappingsLazyQuery
>;
export type DriverMappingsQueryResult = Apollo.QueryResult<
  DriverMappingsQuery,
  DriverMappingsQueryVariables
>;
export const FuelTypeMappingsDocument = gql`
  query fuelTypeMappings($integrationId: ID!) {
    fuelTypeMappings(integrationId: $integrationId) {
      ...FuelTypeMapping
    }
  }
  ${FuelTypeMappingFragmentDoc}
`;

/**
 * __useFuelTypeMappingsQuery__
 *
 * To run a query within a React component, call `useFuelTypeMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuelTypeMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuelTypeMappingsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useFuelTypeMappingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FuelTypeMappingsQuery,
    FuelTypeMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FuelTypeMappingsQuery, FuelTypeMappingsQueryVariables>(
    FuelTypeMappingsDocument,
    options,
  );
}
export function useFuelTypeMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FuelTypeMappingsQuery,
    FuelTypeMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FuelTypeMappingsQuery,
    FuelTypeMappingsQueryVariables
  >(FuelTypeMappingsDocument, options);
}
export type FuelTypeMappingsQueryHookResult = ReturnType<
  typeof useFuelTypeMappingsQuery
>;
export type FuelTypeMappingsLazyQueryHookResult = ReturnType<
  typeof useFuelTypeMappingsLazyQuery
>;
export type FuelTypeMappingsQueryResult = Apollo.QueryResult<
  FuelTypeMappingsQuery,
  FuelTypeMappingsQueryVariables
>;
export const IntegrationDocument = gql`
  query integration($id: ID!) {
    integration(id: $id) {
      ...IntegrationWithIntents
    }
  }
  ${IntegrationWithIntentsFragmentDoc}
`;

/**
 * __useIntegrationQuery__
 *
 * To run a query within a React component, call `useIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntegrationQuery,
    IntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IntegrationQuery, IntegrationQueryVariables>(
    IntegrationDocument,
    options,
  );
}
export function useIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationQuery,
    IntegrationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IntegrationQuery, IntegrationQueryVariables>(
    IntegrationDocument,
    options,
  );
}
export type IntegrationQueryHookResult = ReturnType<typeof useIntegrationQuery>;
export type IntegrationLazyQueryHookResult = ReturnType<
  typeof useIntegrationLazyQuery
>;
export type IntegrationQueryResult = Apollo.QueryResult<
  IntegrationQuery,
  IntegrationQueryVariables
>;
export const IntegrationsDocument = gql`
  query integrations {
    integrations {
      ...Integration
    }
  }
  ${IntegrationFragmentDoc}
`;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IntegrationsQuery,
    IntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IntegrationsQuery, IntegrationsQueryVariables>(
    IntegrationsDocument,
    options,
  );
}
export function useIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationsQuery,
    IntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IntegrationsQuery, IntegrationsQueryVariables>(
    IntegrationsDocument,
    options,
  );
}
export type IntegrationsQueryHookResult = ReturnType<
  typeof useIntegrationsQuery
>;
export type IntegrationsLazyQueryHookResult = ReturnType<
  typeof useIntegrationsLazyQuery
>;
export type IntegrationsQueryResult = Apollo.QueryResult<
  IntegrationsQuery,
  IntegrationsQueryVariables
>;
export const ProviderMappingsDocument = gql`
  query providerMappings($integrationId: ID!) {
    providerMappings(integrationId: $integrationId) {
      ...ProviderMapping
    }
  }
  ${ProviderMappingFragmentDoc}
`;

/**
 * __useProviderMappingsQuery__
 *
 * To run a query within a React component, call `useProviderMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderMappingsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useProviderMappingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderMappingsQuery,
    ProviderMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderMappingsQuery, ProviderMappingsQueryVariables>(
    ProviderMappingsDocument,
    options,
  );
}
export function useProviderMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderMappingsQuery,
    ProviderMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProviderMappingsQuery,
    ProviderMappingsQueryVariables
  >(ProviderMappingsDocument, options);
}
export type ProviderMappingsQueryHookResult = ReturnType<
  typeof useProviderMappingsQuery
>;
export type ProviderMappingsLazyQueryHookResult = ReturnType<
  typeof useProviderMappingsLazyQuery
>;
export type ProviderMappingsQueryResult = Apollo.QueryResult<
  ProviderMappingsQuery,
  ProviderMappingsQueryVariables
>;
export const SyncIntegrationDocument = gql`
  subscription syncIntegration {
    syncIntegration {
      ...Integration
    }
  }
  ${IntegrationFragmentDoc}
`;

/**
 * __useSyncIntegrationSubscription__
 *
 * To run a query within a React component, call `useSyncIntegrationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSyncIntegrationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncIntegrationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSyncIntegrationSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SyncIntegrationSubscription,
    SyncIntegrationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SyncIntegrationSubscription,
    SyncIntegrationSubscriptionVariables
  >(SyncIntegrationDocument, options);
}
export type SyncIntegrationSubscriptionHookResult = ReturnType<
  typeof useSyncIntegrationSubscription
>;
export type SyncIntegrationSubscriptionResult =
  Apollo.SubscriptionResult<SyncIntegrationSubscription>;
export const UpdateDriverMappingsDocument = gql`
  mutation updateDriverMappings($mappings: [UpdateDriverMappingInput!]!) {
    updateDriverMappings(payload: $mappings) {
      ...DriverMapping
    }
  }
  ${DriverMappingFragmentDoc}
`;
export type UpdateDriverMappingsMutationFn = Apollo.MutationFunction<
  UpdateDriverMappingsMutation,
  UpdateDriverMappingsMutationVariables
>;

/**
 * __useUpdateDriverMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMappingsMutation, { data, loading, error }] = useUpdateDriverMappingsMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useUpdateDriverMappingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDriverMappingsMutation,
    UpdateDriverMappingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDriverMappingsMutation,
    UpdateDriverMappingsMutationVariables
  >(UpdateDriverMappingsDocument, options);
}
export type UpdateDriverMappingsMutationHookResult = ReturnType<
  typeof useUpdateDriverMappingsMutation
>;
export type UpdateDriverMappingsMutationResult =
  Apollo.MutationResult<UpdateDriverMappingsMutation>;
export type UpdateDriverMappingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverMappingsMutation,
  UpdateDriverMappingsMutationVariables
>;
export const UpdateFuelTypeMappingsDocument = gql`
  mutation updateFuelTypeMappings($mappings: [UpdateFuelTypeMappingInput!]!) {
    updateFuelTypeMappings(payload: $mappings) {
      ...FuelTypeMapping
    }
  }
  ${FuelTypeMappingFragmentDoc}
`;
export type UpdateFuelTypeMappingsMutationFn = Apollo.MutationFunction<
  UpdateFuelTypeMappingsMutation,
  UpdateFuelTypeMappingsMutationVariables
>;

/**
 * __useUpdateFuelTypeMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateFuelTypeMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFuelTypeMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuelTypeMappingsMutation, { data, loading, error }] = useUpdateFuelTypeMappingsMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useUpdateFuelTypeMappingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFuelTypeMappingsMutation,
    UpdateFuelTypeMappingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFuelTypeMappingsMutation,
    UpdateFuelTypeMappingsMutationVariables
  >(UpdateFuelTypeMappingsDocument, options);
}
export type UpdateFuelTypeMappingsMutationHookResult = ReturnType<
  typeof useUpdateFuelTypeMappingsMutation
>;
export type UpdateFuelTypeMappingsMutationResult =
  Apollo.MutationResult<UpdateFuelTypeMappingsMutation>;
export type UpdateFuelTypeMappingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateFuelTypeMappingsMutation,
  UpdateFuelTypeMappingsMutationVariables
>;
export const UpdateProviderMappingsDocument = gql`
  mutation updateProviderMappings($mappings: [UpdateProviderMappingInput!]!) {
    updateProviderMappings(payload: $mappings) {
      ...ProviderMapping
    }
  }
  ${ProviderMappingFragmentDoc}
`;
export type UpdateProviderMappingsMutationFn = Apollo.MutationFunction<
  UpdateProviderMappingsMutation,
  UpdateProviderMappingsMutationVariables
>;

/**
 * __useUpdateProviderMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMappingsMutation, { data, loading, error }] = useUpdateProviderMappingsMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useUpdateProviderMappingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProviderMappingsMutation,
    UpdateProviderMappingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProviderMappingsMutation,
    UpdateProviderMappingsMutationVariables
  >(UpdateProviderMappingsDocument, options);
}
export type UpdateProviderMappingsMutationHookResult = ReturnType<
  typeof useUpdateProviderMappingsMutation
>;
export type UpdateProviderMappingsMutationResult =
  Apollo.MutationResult<UpdateProviderMappingsMutation>;
export type UpdateProviderMappingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProviderMappingsMutation,
  UpdateProviderMappingsMutationVariables
>;
export const UpdateVehicleMappingsDocument = gql`
  mutation updateVehicleMappings($mappings: [UpdateVehicleMappingInput!]!) {
    updateVehicleMappings(payload: $mappings) {
      ...VehicleMapping
    }
  }
  ${VehicleMappingFragmentDoc}
`;
export type UpdateVehicleMappingsMutationFn = Apollo.MutationFunction<
  UpdateVehicleMappingsMutation,
  UpdateVehicleMappingsMutationVariables
>;

/**
 * __useUpdateVehicleMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMappingsMutation, { data, loading, error }] = useUpdateVehicleMappingsMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useUpdateVehicleMappingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleMappingsMutation,
    UpdateVehicleMappingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVehicleMappingsMutation,
    UpdateVehicleMappingsMutationVariables
  >(UpdateVehicleMappingsDocument, options);
}
export type UpdateVehicleMappingsMutationHookResult = ReturnType<
  typeof useUpdateVehicleMappingsMutation
>;
export type UpdateVehicleMappingsMutationResult =
  Apollo.MutationResult<UpdateVehicleMappingsMutation>;
export type UpdateVehicleMappingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleMappingsMutation,
  UpdateVehicleMappingsMutationVariables
>;
export const UploadFileIntegrationDocument = gql`
  mutation uploadFileIntegration($file: Upload!, $integrationId: ID!) {
    uploadFileIntegration(file: $file, integrationId: $integrationId) {
      ...IntegrationIntent
    }
  }
  ${IntegrationIntentFragmentDoc}
`;
export type UploadFileIntegrationMutationFn = Apollo.MutationFunction<
  UploadFileIntegrationMutation,
  UploadFileIntegrationMutationVariables
>;

/**
 * __useUploadFileIntegrationMutation__
 *
 * To run a mutation, you first call `useUploadFileIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileIntegrationMutation, { data, loading, error }] = useUploadFileIntegrationMutation({
 *   variables: {
 *      file: // value for 'file'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useUploadFileIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileIntegrationMutation,
    UploadFileIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadFileIntegrationMutation,
    UploadFileIntegrationMutationVariables
  >(UploadFileIntegrationDocument, options);
}
export type UploadFileIntegrationMutationHookResult = ReturnType<
  typeof useUploadFileIntegrationMutation
>;
export type UploadFileIntegrationMutationResult =
  Apollo.MutationResult<UploadFileIntegrationMutation>;
export type UploadFileIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UploadFileIntegrationMutation,
  UploadFileIntegrationMutationVariables
>;
export const VehicleMappingsDocument = gql`
  query vehicleMappings($integrationId: ID!) {
    vehicleMappings(integrationId: $integrationId) {
      ...VehicleMapping
    }
  }
  ${VehicleMappingFragmentDoc}
`;

/**
 * __useVehicleMappingsQuery__
 *
 * To run a query within a React component, call `useVehicleMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleMappingsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useVehicleMappingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleMappingsQuery,
    VehicleMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleMappingsQuery, VehicleMappingsQueryVariables>(
    VehicleMappingsDocument,
    options,
  );
}
export function useVehicleMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleMappingsQuery,
    VehicleMappingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehicleMappingsQuery,
    VehicleMappingsQueryVariables
  >(VehicleMappingsDocument, options);
}
export type VehicleMappingsQueryHookResult = ReturnType<
  typeof useVehicleMappingsQuery
>;
export type VehicleMappingsLazyQueryHookResult = ReturnType<
  typeof useVehicleMappingsLazyQuery
>;
export type VehicleMappingsQueryResult = Apollo.QueryResult<
  VehicleMappingsQuery,
  VehicleMappingsQueryVariables
>;
export const ListAllFuellingDocument = gql`
  query listAllFuelling(
    $vehicleIds: [ID!]!
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    fuelling: findVehicleFuellings(
      vehicles: $vehicleIds
      startAt: $startAt
      endAt: $endAt
    ) {
      ...Fuelling
    }
  }
  ${FuellingFragmentDoc}
`;

/**
 * __useListAllFuellingQuery__
 *
 * To run a query within a React component, call `useListAllFuellingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllFuellingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllFuellingQuery({
 *   variables: {
 *      vehicleIds: // value for 'vehicleIds'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useListAllFuellingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListAllFuellingQuery,
    ListAllFuellingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAllFuellingQuery, ListAllFuellingQueryVariables>(
    ListAllFuellingDocument,
    options,
  );
}
export function useListAllFuellingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllFuellingQuery,
    ListAllFuellingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAllFuellingQuery,
    ListAllFuellingQueryVariables
  >(ListAllFuellingDocument, options);
}
export type ListAllFuellingQueryHookResult = ReturnType<
  typeof useListAllFuellingQuery
>;
export type ListAllFuellingLazyQueryHookResult = ReturnType<
  typeof useListAllFuellingLazyQuery
>;
export type ListAllFuellingQueryResult = Apollo.QueryResult<
  ListAllFuellingQuery,
  ListAllFuellingQueryVariables
>;
export const RemindersDocument = gql`
  query reminders {
    findMaintenanceReminders {
      ...MaintenanceReminder
    }
  }
  ${MaintenanceReminderFragmentDoc}
`;

/**
 * __useRemindersQuery__
 *
 * To run a query within a React component, call `useRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemindersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemindersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RemindersQuery,
    RemindersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemindersQuery, RemindersQueryVariables>(
    RemindersDocument,
    options,
  );
}
export function useRemindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemindersQuery,
    RemindersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RemindersQuery, RemindersQueryVariables>(
    RemindersDocument,
    options,
  );
}
export type RemindersQueryHookResult = ReturnType<typeof useRemindersQuery>;
export type RemindersLazyQueryHookResult = ReturnType<
  typeof useRemindersLazyQuery
>;
export type RemindersQueryResult = Apollo.QueryResult<
  RemindersQuery,
  RemindersQueryVariables
>;
export const MeDocument = gql`
  query me {
    me {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CountEventsDocument = gql`
  query countEvents(
    $vehicleId: ID
    $startAt: DateTime!
    $endAt: DateTime!
    $eventTypes: [String!]
    $geofences: [ID!]
  ) {
    countEvents(
      vehicle: $vehicleId
      slugNames: $eventTypes
      startAt: $startAt
      endAt: $endAt
      geofences: $geofences
    ) {
      slugName
      occurrences
    }
  }
`;

/**
 * __useCountEventsQuery__
 *
 * To run a query within a React component, call `useCountEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountEventsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      eventTypes: // value for 'eventTypes'
 *      geofences: // value for 'geofences'
 *   },
 * });
 */
export function useCountEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountEventsQuery,
    CountEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountEventsQuery, CountEventsQueryVariables>(
    CountEventsDocument,
    options,
  );
}
export function useCountEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountEventsQuery,
    CountEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountEventsQuery, CountEventsQueryVariables>(
    CountEventsDocument,
    options,
  );
}
export type CountEventsQueryHookResult = ReturnType<typeof useCountEventsQuery>;
export type CountEventsLazyQueryHookResult = ReturnType<
  typeof useCountEventsLazyQuery
>;
export type CountEventsQueryResult = Apollo.QueryResult<
  CountEventsQuery,
  CountEventsQueryVariables
>;
export const CreateGeofenceDocument = gql`
  mutation createGeofence($payload: CreateGeofenceInput!) {
    createGeofence(payload: $payload) {
      ...Geofence
    }
  }
  ${GeofenceFragmentDoc}
`;
export type CreateGeofenceMutationFn = Apollo.MutationFunction<
  CreateGeofenceMutation,
  CreateGeofenceMutationVariables
>;

/**
 * __useCreateGeofenceMutation__
 *
 * To run a mutation, you first call `useCreateGeofenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeofenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeofenceMutation, { data, loading, error }] = useCreateGeofenceMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateGeofenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGeofenceMutation,
    CreateGeofenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGeofenceMutation,
    CreateGeofenceMutationVariables
  >(CreateGeofenceDocument, options);
}
export type CreateGeofenceMutationHookResult = ReturnType<
  typeof useCreateGeofenceMutation
>;
export type CreateGeofenceMutationResult =
  Apollo.MutationResult<CreateGeofenceMutation>;
export type CreateGeofenceMutationOptions = Apollo.BaseMutationOptions<
  CreateGeofenceMutation,
  CreateGeofenceMutationVariables
>;
export const DeleteGeofenceDocument = gql`
  mutation deleteGeofence($organizationId: ID!, $id: ID!) {
    deleteGeofence(organizationId: $organizationId, id: $id)
  }
`;
export type DeleteGeofenceMutationFn = Apollo.MutationFunction<
  DeleteGeofenceMutation,
  DeleteGeofenceMutationVariables
>;

/**
 * __useDeleteGeofenceMutation__
 *
 * To run a mutation, you first call `useDeleteGeofenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeofenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeofenceMutation, { data, loading, error }] = useDeleteGeofenceMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGeofenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGeofenceMutation,
    DeleteGeofenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGeofenceMutation,
    DeleteGeofenceMutationVariables
  >(DeleteGeofenceDocument, options);
}
export type DeleteGeofenceMutationHookResult = ReturnType<
  typeof useDeleteGeofenceMutation
>;
export type DeleteGeofenceMutationResult =
  Apollo.MutationResult<DeleteGeofenceMutation>;
export type DeleteGeofenceMutationOptions = Apollo.BaseMutationOptions<
  DeleteGeofenceMutation,
  DeleteGeofenceMutationVariables
>;
export const FindVehicleEventsDocument = gql`
  query findVehicleEvents(
    $vehicleId: ID
    $startAt: DateTime!
    $endAt: DateTime!
    $eventTypes: [String!]
    $geofences: [ID!]
  ) {
    findVehicleEvents(
      vehicle: $vehicleId
      slugNames: $eventTypes
      startAt: $startAt
      endAt: $endAt
      geofences: $geofences
    ) {
      ...Event
    }
  }
  ${EventFragmentDoc}
`;

/**
 * __useFindVehicleEventsQuery__
 *
 * To run a query within a React component, call `useFindVehicleEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVehicleEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVehicleEventsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      eventTypes: // value for 'eventTypes'
 *      geofences: // value for 'geofences'
 *   },
 * });
 */
export function useFindVehicleEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindVehicleEventsQuery,
    FindVehicleEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindVehicleEventsQuery,
    FindVehicleEventsQueryVariables
  >(FindVehicleEventsDocument, options);
}
export function useFindVehicleEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindVehicleEventsQuery,
    FindVehicleEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindVehicleEventsQuery,
    FindVehicleEventsQueryVariables
  >(FindVehicleEventsDocument, options);
}
export type FindVehicleEventsQueryHookResult = ReturnType<
  typeof useFindVehicleEventsQuery
>;
export type FindVehicleEventsLazyQueryHookResult = ReturnType<
  typeof useFindVehicleEventsLazyQuery
>;
export type FindVehicleEventsQueryResult = Apollo.QueryResult<
  FindVehicleEventsQuery,
  FindVehicleEventsQueryVariables
>;
export const GeofencesDocument = gql`
  query geofences {
    geofences {
      ...Geofence
    }
  }
  ${GeofenceFragmentDoc}
`;

/**
 * __useGeofencesQuery__
 *
 * To run a query within a React component, call `useGeofencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeofencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeofencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeofencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeofencesQuery,
    GeofencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeofencesQuery, GeofencesQueryVariables>(
    GeofencesDocument,
    options,
  );
}
export function useGeofencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeofencesQuery,
    GeofencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeofencesQuery, GeofencesQueryVariables>(
    GeofencesDocument,
    options,
  );
}
export type GeofencesQueryHookResult = ReturnType<typeof useGeofencesQuery>;
export type GeofencesLazyQueryHookResult = ReturnType<
  typeof useGeofencesLazyQuery
>;
export type GeofencesQueryResult = Apollo.QueryResult<
  GeofencesQuery,
  GeofencesQueryVariables
>;
export const GetMaintenanceCostDocument = gql`
  query getMaintenanceCost($payload: MaintenanceCostPerTypeReportInput!) {
    maintenanceCostPerTypeReport(payload: $payload) {
      report {
        ...MaintenanceCostPerTypeReport
      }
    }
  }
  ${MaintenanceCostPerTypeReportFragmentDoc}
`;

/**
 * __useGetMaintenanceCostQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceCostQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetMaintenanceCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMaintenanceCostQuery,
    GetMaintenanceCostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMaintenanceCostQuery,
    GetMaintenanceCostQueryVariables
  >(GetMaintenanceCostDocument, options);
}
export function useGetMaintenanceCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaintenanceCostQuery,
    GetMaintenanceCostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMaintenanceCostQuery,
    GetMaintenanceCostQueryVariables
  >(GetMaintenanceCostDocument, options);
}
export type GetMaintenanceCostQueryHookResult = ReturnType<
  typeof useGetMaintenanceCostQuery
>;
export type GetMaintenanceCostLazyQueryHookResult = ReturnType<
  typeof useGetMaintenanceCostLazyQuery
>;
export type GetMaintenanceCostQueryResult = Apollo.QueryResult<
  GetMaintenanceCostQuery,
  GetMaintenanceCostQueryVariables
>;
export const NewVehicleLocationDocument = gql`
  subscription newVehicleLocation($vehicles: [ID!]!) {
    newVehicleLocation(vehicles: $vehicles) {
      ...Location
    }
  }
  ${LocationFragmentDoc}
`;

/**
 * __useNewVehicleLocationSubscription__
 *
 * To run a query within a React component, call `useNewVehicleLocationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewVehicleLocationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewVehicleLocationSubscription({
 *   variables: {
 *      vehicles: // value for 'vehicles'
 *   },
 * });
 */
export function useNewVehicleLocationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewVehicleLocationSubscription,
    NewVehicleLocationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewVehicleLocationSubscription,
    NewVehicleLocationSubscriptionVariables
  >(NewVehicleLocationDocument, options);
}
export type NewVehicleLocationSubscriptionHookResult = ReturnType<
  typeof useNewVehicleLocationSubscription
>;
export type NewVehicleLocationSubscriptionResult =
  Apollo.SubscriptionResult<NewVehicleLocationSubscription>;
export const RouteDetailsDocument = gql`
  query routeDetails($vehicle: ID!, $startAt: DateTime!, $endAt: DateTime!) {
    routeDetails(vehicle: $vehicle, startAt: $startAt, endAt: $endAt) {
      averageSpeed
      maximumSpeed
      totalDistanceTraveled
      totalTimeStopped
      totalTimeStoppedWithIgnitionOn
      totalTimeWithIgnitionOn
      engineIdle
      geojson
    }
  }
`;

/**
 * __useRouteDetailsQuery__
 *
 * To run a query within a React component, call `useRouteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteDetailsQuery({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useRouteDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RouteDetailsQuery,
    RouteDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RouteDetailsQuery, RouteDetailsQueryVariables>(
    RouteDetailsDocument,
    options,
  );
}
export function useRouteDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RouteDetailsQuery,
    RouteDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RouteDetailsQuery, RouteDetailsQueryVariables>(
    RouteDetailsDocument,
    options,
  );
}
export type RouteDetailsQueryHookResult = ReturnType<
  typeof useRouteDetailsQuery
>;
export type RouteDetailsLazyQueryHookResult = ReturnType<
  typeof useRouteDetailsLazyQuery
>;
export type RouteDetailsQueryResult = Apollo.QueryResult<
  RouteDetailsQuery,
  RouteDetailsQueryVariables
>;
export const UpdateGeofenceDocument = gql`
  mutation updateGeofence($payload: UpdateGeofenceInput!) {
    updateGeofence(payload: $payload) {
      ...Geofence
    }
  }
  ${GeofenceFragmentDoc}
`;
export type UpdateGeofenceMutationFn = Apollo.MutationFunction<
  UpdateGeofenceMutation,
  UpdateGeofenceMutationVariables
>;

/**
 * __useUpdateGeofenceMutation__
 *
 * To run a mutation, you first call `useUpdateGeofenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeofenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeofenceMutation, { data, loading, error }] = useUpdateGeofenceMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateGeofenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGeofenceMutation,
    UpdateGeofenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGeofenceMutation,
    UpdateGeofenceMutationVariables
  >(UpdateGeofenceDocument, options);
}
export type UpdateGeofenceMutationHookResult = ReturnType<
  typeof useUpdateGeofenceMutation
>;
export type UpdateGeofenceMutationResult =
  Apollo.MutationResult<UpdateGeofenceMutation>;
export type UpdateGeofenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateGeofenceMutation,
  UpdateGeofenceMutationVariables
>;
export const NewUpdateVehicleDocument = gql`
  subscription newUpdateVehicle($vehicles: [ID!]!) {
    updateVehicle(vehicles: $vehicles) {
      ...VehicleWithDriver
    }
  }
  ${VehicleWithDriverFragmentDoc}
`;

/**
 * __useNewUpdateVehicleSubscription__
 *
 * To run a query within a React component, call `useNewUpdateVehicleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewUpdateVehicleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewUpdateVehicleSubscription({
 *   variables: {
 *      vehicles: // value for 'vehicles'
 *   },
 * });
 */
export function useNewUpdateVehicleSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewUpdateVehicleSubscription,
    NewUpdateVehicleSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewUpdateVehicleSubscription,
    NewUpdateVehicleSubscriptionVariables
  >(NewUpdateVehicleDocument, options);
}
export type NewUpdateVehicleSubscriptionHookResult = ReturnType<
  typeof useNewUpdateVehicleSubscription
>;
export type NewUpdateVehicleSubscriptionResult =
  Apollo.SubscriptionResult<NewUpdateVehicleSubscription>;
export const NotificationsSummaryDocument = gql`
  query notificationsSummary {
    notificationsSummary {
      read
      unRead
    }
  }
`;

/**
 * __useNotificationsSummaryQuery__
 *
 * To run a query within a React component, call `useNotificationsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsSummaryQuery,
    NotificationsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationsSummaryQuery,
    NotificationsSummaryQueryVariables
  >(NotificationsSummaryDocument, options);
}
export function useNotificationsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsSummaryQuery,
    NotificationsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationsSummaryQuery,
    NotificationsSummaryQueryVariables
  >(NotificationsSummaryDocument, options);
}
export type NotificationsSummaryQueryHookResult = ReturnType<
  typeof useNotificationsSummaryQuery
>;
export type NotificationsSummaryLazyQueryHookResult = ReturnType<
  typeof useNotificationsSummaryLazyQuery
>;
export type NotificationsSummaryQueryResult = Apollo.QueryResult<
  NotificationsSummaryQuery,
  NotificationsSummaryQueryVariables
>;
export const NotificationsDocument = gql`
  query notifications($limit: Int, $offset: Int) {
    notifications(limit: $limit, offset: $offset) {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options,
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const OrdersDocument = gql`
  query orders($filter: FilterOrder) {
    orders(filter: $filter) {
      ...Order
    }
  }
  ${OrderFragmentDoc}
`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options,
  );
}
export function useOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    options,
  );
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<
  OrdersQuery,
  OrdersQueryVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($payload: UpdateOrganizationInput!) {
    updateOrganization(payload: $payload) {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
  Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const PartsDocument = gql`
  query parts {
    parts {
      ...Part
    }
  }
  ${PartFragmentDoc}
`;

/**
 * __usePartsQuery__
 *
 * To run a query within a React component, call `usePartsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartsQuery(
  baseOptions?: Apollo.QueryHookOptions<PartsQuery, PartsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartsQuery, PartsQueryVariables>(
    PartsDocument,
    options,
  );
}
export function usePartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartsQuery, PartsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartsQuery, PartsQueryVariables>(
    PartsDocument,
    options,
  );
}
export type PartsQueryHookResult = ReturnType<typeof usePartsQuery>;
export type PartsLazyQueryHookResult = ReturnType<typeof usePartsLazyQuery>;
export type PartsQueryResult = Apollo.QueryResult<
  PartsQuery,
  PartsQueryVariables
>;
export const ProvidersDocument = gql`
  query providers {
    providers {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProvidersQuery,
    ProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    options,
  );
}
export function useProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProvidersQuery,
    ProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    options,
  );
}
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<
  typeof useProvidersLazyQuery
>;
export type ProvidersQueryResult = Apollo.QueryResult<
  ProvidersQuery,
  ProvidersQueryVariables
>;
export const DailyWorkMeasuresDocument = gql`
  query dailyWorkMeasures(
    $endAt: DateTime!
    $startAt: DateTime!
    $vehicles: [ID!]!
  ) {
    dailyWorkMeasures(startAt: $startAt, endAt: $endAt, vehicles: $vehicles) {
      ...DailyWorkerMeasure
    }
  }
  ${DailyWorkerMeasureFragmentDoc}
`;

/**
 * __useDailyWorkMeasuresQuery__
 *
 * To run a query within a React component, call `useDailyWorkMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyWorkMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyWorkMeasuresQuery({
 *   variables: {
 *      endAt: // value for 'endAt'
 *      startAt: // value for 'startAt'
 *      vehicles: // value for 'vehicles'
 *   },
 * });
 */
export function useDailyWorkMeasuresQuery(
  baseOptions: Apollo.QueryHookOptions<
    DailyWorkMeasuresQuery,
    DailyWorkMeasuresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DailyWorkMeasuresQuery,
    DailyWorkMeasuresQueryVariables
  >(DailyWorkMeasuresDocument, options);
}
export function useDailyWorkMeasuresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DailyWorkMeasuresQuery,
    DailyWorkMeasuresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DailyWorkMeasuresQuery,
    DailyWorkMeasuresQueryVariables
  >(DailyWorkMeasuresDocument, options);
}
export type DailyWorkMeasuresQueryHookResult = ReturnType<
  typeof useDailyWorkMeasuresQuery
>;
export type DailyWorkMeasuresLazyQueryHookResult = ReturnType<
  typeof useDailyWorkMeasuresLazyQuery
>;
export type DailyWorkMeasuresQueryResult = Apollo.QueryResult<
  DailyWorkMeasuresQuery,
  DailyWorkMeasuresQueryVariables
>;
export const VehiclesSummariesDocument = gql`
  query vehiclesSummaries(
    $startAt: DateTime!
    $endAt: DateTime!
    $vehicles: [ID!]!
    $groupedBy: SummaryDateGroupedByEnum
  ) {
    vehiclesSummaries(
      startAt: $startAt
      endAt: $endAt
      vehicles: $vehicles
      groupedBy: $groupedBy
    ) {
      ...VehicleSummary
    }
  }
  ${VehicleSummaryFragmentDoc}
`;

/**
 * __useVehiclesSummariesQuery__
 *
 * To run a query within a React component, call `useVehiclesSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesSummariesQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      vehicles: // value for 'vehicles'
 *      groupedBy: // value for 'groupedBy'
 *   },
 * });
 */
export function useVehiclesSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehiclesSummariesQuery,
    VehiclesSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VehiclesSummariesQuery,
    VehiclesSummariesQueryVariables
  >(VehiclesSummariesDocument, options);
}
export function useVehiclesSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesSummariesQuery,
    VehiclesSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehiclesSummariesQuery,
    VehiclesSummariesQueryVariables
  >(VehiclesSummariesDocument, options);
}
export type VehiclesSummariesQueryHookResult = ReturnType<
  typeof useVehiclesSummariesQuery
>;
export type VehiclesSummariesLazyQueryHookResult = ReturnType<
  typeof useVehiclesSummariesLazyQuery
>;
export type VehiclesSummariesQueryResult = Apollo.QueryResult<
  VehiclesSummariesQuery,
  VehiclesSummariesQueryVariables
>;
export const SetCurrentDriverToVehicleDriverChangeHistoryDocument = gql`
  mutation setCurrentDriverToVehicleDriverChangeHistory(
    $id: ID!
    $driver: ID!
  ) {
    setCurrentDriverToVehicleDriverChangeHistory(
      payload: { id: $id, driver: $driver }
    ) {
      ...VehicleDriverChangeHistory
    }
  }
  ${VehicleDriverChangeHistoryFragmentDoc}
`;
export type SetCurrentDriverToVehicleDriverChangeHistoryMutationFn =
  Apollo.MutationFunction<
    SetCurrentDriverToVehicleDriverChangeHistoryMutation,
    SetCurrentDriverToVehicleDriverChangeHistoryMutationVariables
  >;

/**
 * __useSetCurrentDriverToVehicleDriverChangeHistoryMutation__
 *
 * To run a mutation, you first call `useSetCurrentDriverToVehicleDriverChangeHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentDriverToVehicleDriverChangeHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentDriverToVehicleDriverChangeHistoryMutation, { data, loading, error }] = useSetCurrentDriverToVehicleDriverChangeHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      driver: // value for 'driver'
 *   },
 * });
 */
export function useSetCurrentDriverToVehicleDriverChangeHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCurrentDriverToVehicleDriverChangeHistoryMutation,
    SetCurrentDriverToVehicleDriverChangeHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetCurrentDriverToVehicleDriverChangeHistoryMutation,
    SetCurrentDriverToVehicleDriverChangeHistoryMutationVariables
  >(SetCurrentDriverToVehicleDriverChangeHistoryDocument, options);
}
export type SetCurrentDriverToVehicleDriverChangeHistoryMutationHookResult =
  ReturnType<typeof useSetCurrentDriverToVehicleDriverChangeHistoryMutation>;
export type SetCurrentDriverToVehicleDriverChangeHistoryMutationResult =
  Apollo.MutationResult<SetCurrentDriverToVehicleDriverChangeHistoryMutation>;
export type SetCurrentDriverToVehicleDriverChangeHistoryMutationOptions =
  Apollo.BaseMutationOptions<
    SetCurrentDriverToVehicleDriverChangeHistoryMutation,
    SetCurrentDriverToVehicleDriverChangeHistoryMutationVariables
  >;
export const SetPreviousDriverToVehicleDriverChangeHistoryDocument = gql`
  mutation setPreviousDriverToVehicleDriverChangeHistory(
    $id: ID!
    $driver: ID!
  ) {
    setPreviousDriverToVehicleDriverChangeHistory(
      payload: { id: $id, driver: $driver }
    ) {
      ...VehicleDriverChangeHistory
    }
  }
  ${VehicleDriverChangeHistoryFragmentDoc}
`;
export type SetPreviousDriverToVehicleDriverChangeHistoryMutationFn =
  Apollo.MutationFunction<
    SetPreviousDriverToVehicleDriverChangeHistoryMutation,
    SetPreviousDriverToVehicleDriverChangeHistoryMutationVariables
  >;

/**
 * __useSetPreviousDriverToVehicleDriverChangeHistoryMutation__
 *
 * To run a mutation, you first call `useSetPreviousDriverToVehicleDriverChangeHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPreviousDriverToVehicleDriverChangeHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPreviousDriverToVehicleDriverChangeHistoryMutation, { data, loading, error }] = useSetPreviousDriverToVehicleDriverChangeHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      driver: // value for 'driver'
 *   },
 * });
 */
export function useSetPreviousDriverToVehicleDriverChangeHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPreviousDriverToVehicleDriverChangeHistoryMutation,
    SetPreviousDriverToVehicleDriverChangeHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPreviousDriverToVehicleDriverChangeHistoryMutation,
    SetPreviousDriverToVehicleDriverChangeHistoryMutationVariables
  >(SetPreviousDriverToVehicleDriverChangeHistoryDocument, options);
}
export type SetPreviousDriverToVehicleDriverChangeHistoryMutationHookResult =
  ReturnType<typeof useSetPreviousDriverToVehicleDriverChangeHistoryMutation>;
export type SetPreviousDriverToVehicleDriverChangeHistoryMutationResult =
  Apollo.MutationResult<SetPreviousDriverToVehicleDriverChangeHistoryMutation>;
export type SetPreviousDriverToVehicleDriverChangeHistoryMutationOptions =
  Apollo.BaseMutationOptions<
    SetPreviousDriverToVehicleDriverChangeHistoryMutation,
    SetPreviousDriverToVehicleDriverChangeHistoryMutationVariables
  >;
export const SetVehicleDriverDocument = gql`
  mutation setVehicleDriver($vehicleId: ID!, $driverId: ID) {
    setVehicleDriver(payload: { vehicle: $vehicleId, driver: $driverId }) {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;
export type SetVehicleDriverMutationFn = Apollo.MutationFunction<
  SetVehicleDriverMutation,
  SetVehicleDriverMutationVariables
>;

/**
 * __useSetVehicleDriverMutation__
 *
 * To run a mutation, you first call `useSetVehicleDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVehicleDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVehicleDriverMutation, { data, loading, error }] = useSetVehicleDriverMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useSetVehicleDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVehicleDriverMutation,
    SetVehicleDriverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVehicleDriverMutation,
    SetVehicleDriverMutationVariables
  >(SetVehicleDriverDocument, options);
}
export type SetVehicleDriverMutationHookResult = ReturnType<
  typeof useSetVehicleDriverMutation
>;
export type SetVehicleDriverMutationResult =
  Apollo.MutationResult<SetVehicleDriverMutation>;
export type SetVehicleDriverMutationOptions = Apollo.BaseMutationOptions<
  SetVehicleDriverMutation,
  SetVehicleDriverMutationVariables
>;
export const SetVehicleMeasureDocument = gql`
  mutation setVehicleMeasure(
    $vehicle: ID!
    $odometer: Float
    $horimeter: Float
  ) {
    setVehicleMeasure(
      payload: { vehicle: $vehicle, odometer: $odometer, horimeter: $horimeter }
    )
  }
`;
export type SetVehicleMeasureMutationFn = Apollo.MutationFunction<
  SetVehicleMeasureMutation,
  SetVehicleMeasureMutationVariables
>;

/**
 * __useSetVehicleMeasureMutation__
 *
 * To run a mutation, you first call `useSetVehicleMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVehicleMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVehicleMeasureMutation, { data, loading, error }] = useSetVehicleMeasureMutation({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *      odometer: // value for 'odometer'
 *      horimeter: // value for 'horimeter'
 *   },
 * });
 */
export function useSetVehicleMeasureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVehicleMeasureMutation,
    SetVehicleMeasureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVehicleMeasureMutation,
    SetVehicleMeasureMutationVariables
  >(SetVehicleMeasureDocument, options);
}
export type SetVehicleMeasureMutationHookResult = ReturnType<
  typeof useSetVehicleMeasureMutation
>;
export type SetVehicleMeasureMutationResult =
  Apollo.MutationResult<SetVehicleMeasureMutation>;
export type SetVehicleMeasureMutationOptions = Apollo.BaseMutationOptions<
  SetVehicleMeasureMutation,
  SetVehicleMeasureMutationVariables
>;
export const ServicesDocument = gql`
  query services($filter: FilterServiceInput) {
    services(filter: $filter) {
      ...Service
    }
  }
  ${ServiceFragmentDoc}
`;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(
    ServicesDocument,
    options,
  );
}
export function useServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServicesQuery,
    ServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(
    ServicesDocument,
    options,
  );
}
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<
  typeof useServicesLazyQuery
>;
export type ServicesQueryResult = Apollo.QueryResult<
  ServicesQuery,
  ServicesQueryVariables
>;
export const TanksDocument = gql`
  query tanks {
    tanks {
      ...Tank
    }
  }
  ${TankFragmentDoc}
`;

/**
 * __useTanksQuery__
 *
 * To run a query within a React component, call `useTanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useTanksQuery(
  baseOptions?: Apollo.QueryHookOptions<TanksQuery, TanksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TanksQuery, TanksQueryVariables>(
    TanksDocument,
    options,
  );
}
export function useTanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TanksQuery, TanksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TanksQuery, TanksQueryVariables>(
    TanksDocument,
    options,
  );
}
export type TanksQueryHookResult = ReturnType<typeof useTanksQuery>;
export type TanksLazyQueryHookResult = ReturnType<typeof useTanksLazyQuery>;
export type TanksQueryResult = Apollo.QueryResult<
  TanksQuery,
  TanksQueryVariables
>;
export const TasksDocument = gql`
  query tasks($filter: FilterTaskInput) {
    tasks(filter: $filter) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksQuery, TasksQueryVariables>(
    TasksDocument,
    options,
  );
}
export function useTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(
    TasksDocument,
    options,
  );
}
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<
  TasksQuery,
  TasksQueryVariables
>;
export const CreateTrafficInfractionDocument = gql`
  mutation createTrafficInfraction($payload: CreateTrafficInfractionInput!) {
    createTrafficInfraction(payload: $payload) {
      ...TrafficInfraction
    }
  }
  ${TrafficInfractionFragmentDoc}
`;
export type CreateTrafficInfractionMutationFn = Apollo.MutationFunction<
  CreateTrafficInfractionMutation,
  CreateTrafficInfractionMutationVariables
>;

/**
 * __useCreateTrafficInfractionMutation__
 *
 * To run a mutation, you first call `useCreateTrafficInfractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrafficInfractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrafficInfractionMutation, { data, loading, error }] = useCreateTrafficInfractionMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateTrafficInfractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrafficInfractionMutation,
    CreateTrafficInfractionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTrafficInfractionMutation,
    CreateTrafficInfractionMutationVariables
  >(CreateTrafficInfractionDocument, options);
}
export type CreateTrafficInfractionMutationHookResult = ReturnType<
  typeof useCreateTrafficInfractionMutation
>;
export type CreateTrafficInfractionMutationResult =
  Apollo.MutationResult<CreateTrafficInfractionMutation>;
export type CreateTrafficInfractionMutationOptions = Apollo.BaseMutationOptions<
  CreateTrafficInfractionMutation,
  CreateTrafficInfractionMutationVariables
>;
export const DeleteTrafficInfractionDocument = gql`
  mutation deleteTrafficInfraction($ids: [ID!]!) {
    deleteTrafficInfraction(ids: $ids)
  }
`;
export type DeleteTrafficInfractionMutationFn = Apollo.MutationFunction<
  DeleteTrafficInfractionMutation,
  DeleteTrafficInfractionMutationVariables
>;

/**
 * __useDeleteTrafficInfractionMutation__
 *
 * To run a mutation, you first call `useDeleteTrafficInfractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrafficInfractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrafficInfractionMutation, { data, loading, error }] = useDeleteTrafficInfractionMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTrafficInfractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTrafficInfractionMutation,
    DeleteTrafficInfractionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTrafficInfractionMutation,
    DeleteTrafficInfractionMutationVariables
  >(DeleteTrafficInfractionDocument, options);
}
export type DeleteTrafficInfractionMutationHookResult = ReturnType<
  typeof useDeleteTrafficInfractionMutation
>;
export type DeleteTrafficInfractionMutationResult =
  Apollo.MutationResult<DeleteTrafficInfractionMutation>;
export type DeleteTrafficInfractionMutationOptions = Apollo.BaseMutationOptions<
  DeleteTrafficInfractionMutation,
  DeleteTrafficInfractionMutationVariables
>;
export const GetInfractionsDocument = gql`
  query getInfractions($payload: FindInfractionInput!) {
    findInfractions(payload: $payload) {
      id
      cost
      description
      code
      points
    }
  }
`;

/**
 * __useGetInfractionsQuery__
 *
 * To run a query within a React component, call `useGetInfractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfractionsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetInfractionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInfractionsQuery,
    GetInfractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInfractionsQuery, GetInfractionsQueryVariables>(
    GetInfractionsDocument,
    options,
  );
}
export function useGetInfractionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInfractionsQuery,
    GetInfractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInfractionsQuery, GetInfractionsQueryVariables>(
    GetInfractionsDocument,
    options,
  );
}
export type GetInfractionsQueryHookResult = ReturnType<
  typeof useGetInfractionsQuery
>;
export type GetInfractionsLazyQueryHookResult = ReturnType<
  typeof useGetInfractionsLazyQuery
>;
export type GetInfractionsQueryResult = Apollo.QueryResult<
  GetInfractionsQuery,
  GetInfractionsQueryVariables
>;
export const GetTrafficInfractionsDocument = gql`
  query getTrafficInfractions($payload: ListTrafficInfractionInput!) {
    trafficInfractions(payload: $payload) {
      id
      driver {
        ...Driver
      }
      vehicle {
        ...Vehicle
      }
      paidCost
      cost
      observation
      paidAt
      infraction {
        ...Infraction
      }
      deletedAt
      dueDate
      occurredAt
      address
      location
    }
  }
  ${DriverFragmentDoc}
  ${VehicleFragmentDoc}
  ${InfractionFragmentDoc}
`;

/**
 * __useGetTrafficInfractionsQuery__
 *
 * To run a query within a React component, call `useGetTrafficInfractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrafficInfractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrafficInfractionsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetTrafficInfractionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTrafficInfractionsQuery,
    GetTrafficInfractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTrafficInfractionsQuery,
    GetTrafficInfractionsQueryVariables
  >(GetTrafficInfractionsDocument, options);
}
export function useGetTrafficInfractionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrafficInfractionsQuery,
    GetTrafficInfractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTrafficInfractionsQuery,
    GetTrafficInfractionsQueryVariables
  >(GetTrafficInfractionsDocument, options);
}
export type GetTrafficInfractionsQueryHookResult = ReturnType<
  typeof useGetTrafficInfractionsQuery
>;
export type GetTrafficInfractionsLazyQueryHookResult = ReturnType<
  typeof useGetTrafficInfractionsLazyQuery
>;
export type GetTrafficInfractionsQueryResult = Apollo.QueryResult<
  GetTrafficInfractionsQuery,
  GetTrafficInfractionsQueryVariables
>;
export const PayTrafficInfractionDocument = gql`
  mutation payTrafficInfraction($payload: PayTrafficInfractionInput!) {
    payTrafficInfraction(payload: $payload) {
      ...TrafficInfraction
    }
  }
  ${TrafficInfractionFragmentDoc}
`;
export type PayTrafficInfractionMutationFn = Apollo.MutationFunction<
  PayTrafficInfractionMutation,
  PayTrafficInfractionMutationVariables
>;

/**
 * __usePayTrafficInfractionMutation__
 *
 * To run a mutation, you first call `usePayTrafficInfractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayTrafficInfractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payTrafficInfractionMutation, { data, loading, error }] = usePayTrafficInfractionMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePayTrafficInfractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayTrafficInfractionMutation,
    PayTrafficInfractionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayTrafficInfractionMutation,
    PayTrafficInfractionMutationVariables
  >(PayTrafficInfractionDocument, options);
}
export type PayTrafficInfractionMutationHookResult = ReturnType<
  typeof usePayTrafficInfractionMutation
>;
export type PayTrafficInfractionMutationResult =
  Apollo.MutationResult<PayTrafficInfractionMutation>;
export type PayTrafficInfractionMutationOptions = Apollo.BaseMutationOptions<
  PayTrafficInfractionMutation,
  PayTrafficInfractionMutationVariables
>;
export const UpdateTrafficInfractionDocument = gql`
  mutation updateTrafficInfraction($payload: UpdateTrafficInfractionInput!) {
    updateTrafficInfraction(payload: $payload) {
      ...TrafficInfraction
    }
  }
  ${TrafficInfractionFragmentDoc}
`;
export type UpdateTrafficInfractionMutationFn = Apollo.MutationFunction<
  UpdateTrafficInfractionMutation,
  UpdateTrafficInfractionMutationVariables
>;

/**
 * __useUpdateTrafficInfractionMutation__
 *
 * To run a mutation, you first call `useUpdateTrafficInfractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrafficInfractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrafficInfractionMutation, { data, loading, error }] = useUpdateTrafficInfractionMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateTrafficInfractionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTrafficInfractionMutation,
    UpdateTrafficInfractionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTrafficInfractionMutation,
    UpdateTrafficInfractionMutationVariables
  >(UpdateTrafficInfractionDocument, options);
}
export type UpdateTrafficInfractionMutationHookResult = ReturnType<
  typeof useUpdateTrafficInfractionMutation
>;
export type UpdateTrafficInfractionMutationResult =
  Apollo.MutationResult<UpdateTrafficInfractionMutation>;
export type UpdateTrafficInfractionMutationOptions = Apollo.BaseMutationOptions<
  UpdateTrafficInfractionMutation,
  UpdateTrafficInfractionMutationVariables
>;
export const UpdateDriverDocument = gql`
  mutation updateDriver($payload: UpdateDriverInput!) {
    updateDriver(payload: $payload) {
      id
      name
      cpf
      token
      organization {
        id
        name
      }
      user {
        id
      }
    }
  }
`;
export type UpdateDriverMutationFn = Apollo.MutationFunction<
  UpdateDriverMutation,
  UpdateDriverMutationVariables
>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDriverMutation,
    UpdateDriverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDriverMutation,
    UpdateDriverMutationVariables
  >(UpdateDriverDocument, options);
}
export type UpdateDriverMutationHookResult = ReturnType<
  typeof useUpdateDriverMutation
>;
export type UpdateDriverMutationResult =
  Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverMutation,
  UpdateDriverMutationVariables
>;
export const UpdateFuellingDocument = gql`
  mutation updateFuelling($payload: UpdateFuellingInput!) {
    updateFuelling(payload: $payload) {
      ...Fuelling
    }
  }
  ${FuellingFragmentDoc}
`;
export type UpdateFuellingMutationFn = Apollo.MutationFunction<
  UpdateFuellingMutation,
  UpdateFuellingMutationVariables
>;

/**
 * __useUpdateFuellingMutation__
 *
 * To run a mutation, you first call `useUpdateFuellingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFuellingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFuellingMutation, { data, loading, error }] = useUpdateFuellingMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateFuellingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFuellingMutation,
    UpdateFuellingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFuellingMutation,
    UpdateFuellingMutationVariables
  >(UpdateFuellingDocument, options);
}
export type UpdateFuellingMutationHookResult = ReturnType<
  typeof useUpdateFuellingMutation
>;
export type UpdateFuellingMutationResult =
  Apollo.MutationResult<UpdateFuellingMutation>;
export type UpdateFuellingMutationOptions = Apollo.BaseMutationOptions<
  UpdateFuellingMutation,
  UpdateFuellingMutationVariables
>;
export const UpdateMaintenanceReminderDocument = gql`
  mutation updateMaintenanceReminder(
    $payload: UpdateMaintenanceReminderInput!
  ) {
    updateMaintenanceReminder(payload: $payload) {
      ...MaintenanceReminder
    }
  }
  ${MaintenanceReminderFragmentDoc}
`;
export type UpdateMaintenanceReminderMutationFn = Apollo.MutationFunction<
  UpdateMaintenanceReminderMutation,
  UpdateMaintenanceReminderMutationVariables
>;

/**
 * __useUpdateMaintenanceReminderMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceReminderMutation, { data, loading, error }] = useUpdateMaintenanceReminderMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateMaintenanceReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMaintenanceReminderMutation,
    UpdateMaintenanceReminderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMaintenanceReminderMutation,
    UpdateMaintenanceReminderMutationVariables
  >(UpdateMaintenanceReminderDocument, options);
}
export type UpdateMaintenanceReminderMutationHookResult = ReturnType<
  typeof useUpdateMaintenanceReminderMutation
>;
export type UpdateMaintenanceReminderMutationResult =
  Apollo.MutationResult<UpdateMaintenanceReminderMutation>;
export type UpdateMaintenanceReminderMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMaintenanceReminderMutation,
    UpdateMaintenanceReminderMutationVariables
  >;
export const UpdateOrderDocument = gql`
  mutation updateOrder($payload: UpdateOrderInput!) {
    updateOrder(payload: $payload) {
      ...Order
    }
  }
  ${OrderFragmentDoc}
`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(
    UpdateOrderDocument,
    options,
  );
}
export type UpdateOrderMutationHookResult = ReturnType<
  typeof useUpdateOrderMutation
>;
export type UpdateOrderMutationResult =
  Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;
export const UpdateServiceDocument = gql`
  mutation updateService($payload: UpdateMaintenanceServiceInput!) {
    updateService(payload: $payload) {
      ...Service
    }
  }
  ${ServiceFragmentDoc}
`;
export type UpdateServiceMutationFn = Apollo.MutationFunction<
  UpdateServiceMutation,
  UpdateServiceMutationVariables
>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceMutation,
    UpdateServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceMutation,
    UpdateServiceMutationVariables
  >(UpdateServiceDocument, options);
}
export type UpdateServiceMutationHookResult = ReturnType<
  typeof useUpdateServiceMutation
>;
export type UpdateServiceMutationResult =
  Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceMutation,
  UpdateServiceMutationVariables
>;
export const UpdateTankDocument = gql`
  mutation updateTank($payload: UpdateTankInput!) {
    updateTank(payload: $payload) {
      ...Tank
    }
  }
  ${TankFragmentDoc}
`;
export type UpdateTankMutationFn = Apollo.MutationFunction<
  UpdateTankMutation,
  UpdateTankMutationVariables
>;

/**
 * __useUpdateTankMutation__
 *
 * To run a mutation, you first call `useUpdateTankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTankMutation, { data, loading, error }] = useUpdateTankMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateTankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTankMutation,
    UpdateTankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTankMutation, UpdateTankMutationVariables>(
    UpdateTankDocument,
    options,
  );
}
export type UpdateTankMutationHookResult = ReturnType<
  typeof useUpdateTankMutation
>;
export type UpdateTankMutationResult =
  Apollo.MutationResult<UpdateTankMutation>;
export type UpdateTankMutationOptions = Apollo.BaseMutationOptions<
  UpdateTankMutation,
  UpdateTankMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask($payload: UpdateMaintenanceTaskInput!) {
    updateTask(payload: $payload) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options,
  );
}
export type UpdateTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
  Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(payload: $user) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserWithVehiclesDocument = gql`
  query userWithVehicles($userId: ID!) {
    user(id: $userId) {
      ...UserWithVehicles
    }
  }
  ${UserWithVehiclesFragmentDoc}
`;

/**
 * __useUserWithVehiclesQuery__
 *
 * To run a query within a React component, call `useUserWithVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithVehiclesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserWithVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserWithVehiclesQuery,
    UserWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserWithVehiclesQuery, UserWithVehiclesQueryVariables>(
    UserWithVehiclesDocument,
    options,
  );
}
export function useUserWithVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserWithVehiclesQuery,
    UserWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserWithVehiclesQuery,
    UserWithVehiclesQueryVariables
  >(UserWithVehiclesDocument, options);
}
export type UserWithVehiclesQueryHookResult = ReturnType<
  typeof useUserWithVehiclesQuery
>;
export type UserWithVehiclesLazyQueryHookResult = ReturnType<
  typeof useUserWithVehiclesLazyQuery
>;
export type UserWithVehiclesQueryResult = Apollo.QueryResult<
  UserWithVehiclesQuery,
  UserWithVehiclesQueryVariables
>;
export const UserDocument = gql`
  query user($userId: ID!) {
    user(id: $userId) {
      permissions
      role
      settings
      cpf
      email
      modules
      name
      id
      organization {
        id
        id
        name
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersWithVehiclesDocument = gql`
  query usersWithVehicles {
    users {
      ...UserWithVehicles
    }
  }
  ${UserWithVehiclesFragmentDoc}
`;

/**
 * __useUsersWithVehiclesQuery__
 *
 * To run a query within a React component, call `useUsersWithVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersWithVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersWithVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersWithVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >(UsersWithVehiclesDocument, options);
}
export function useUsersWithVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >(UsersWithVehiclesDocument, options);
}
export type UsersWithVehiclesQueryHookResult = ReturnType<
  typeof useUsersWithVehiclesQuery
>;
export type UsersWithVehiclesLazyQueryHookResult = ReturnType<
  typeof useUsersWithVehiclesLazyQuery
>;
export type UsersWithVehiclesQueryResult = Apollo.QueryResult<
  UsersWithVehiclesQuery,
  UsersWithVehiclesQueryVariables
>;
export const UsersDocument = gql`
  query users {
    users {
      permissions
      role
      settings
      cpf
      email
      id
      modules
      name
      organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const CreateGroupDocument = gql`
  mutation createGroup($payload: CreateGroupInput!) {
    createGroup(payload: $payload) {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options,
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(id: $groupId)
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    options,
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
  Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const GroupsDocument = gql`
  query groups {
    groups {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(
    GroupsDocument,
    options,
  );
}
export function useGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(
    GroupsDocument,
    options,
  );
}
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<
  GroupsQuery,
  GroupsQueryVariables
>;
export const UpdateGroupDocument = gql`
  mutation updateGroup($payload: UpdateGroupInput!) {
    updateGroup(payload: $payload) {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options,
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const VehicleDocument = gql`
  query vehicle($id: ID!) {
    vehicle(id: $id) {
      ...VehicleWithDriver
    }
  }
  ${VehicleWithDriverFragmentDoc}
`;

/**
 * __useVehicleQuery__
 *
 * To run a query within a React component, call `useVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<VehicleQuery, VehicleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleQuery, VehicleQueryVariables>(
    VehicleDocument,
    options,
  );
}
export function useVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleQuery,
    VehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleQuery, VehicleQueryVariables>(
    VehicleDocument,
    options,
  );
}
export type VehicleQueryHookResult = ReturnType<typeof useVehicleQuery>;
export type VehicleLazyQueryHookResult = ReturnType<typeof useVehicleLazyQuery>;
export type VehicleQueryResult = Apollo.QueryResult<
  VehicleQuery,
  VehicleQueryVariables
>;
export const UpdateVehicleDocument = gql`
  mutation updateVehicle($payload: UpdateVehicleInput!) {
    updateVehicle(payload: $payload) {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >(UpdateVehicleDocument, options);
}
export type UpdateVehicleMutationHookResult = ReturnType<
  typeof useUpdateVehicleMutation
>;
export type UpdateVehicleMutationResult =
  Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>;
export const VehicleReportDocument = gql`
  query vehicleReport($payload: VehicleReportInput!) {
    vehicleReport(payload: $payload) {
      ...VehicleReport
    }
  }
  ${VehicleReportFragmentDoc}
`;

/**
 * __useVehicleReportQuery__
 *
 * To run a query within a React component, call `useVehicleReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleReportQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useVehicleReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleReportQuery,
    VehicleReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleReportQuery, VehicleReportQueryVariables>(
    VehicleReportDocument,
    options,
  );
}
export function useVehicleReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleReportQuery,
    VehicleReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleReportQuery, VehicleReportQueryVariables>(
    VehicleReportDocument,
    options,
  );
}
export type VehicleReportQueryHookResult = ReturnType<
  typeof useVehicleReportQuery
>;
export type VehicleReportLazyQueryHookResult = ReturnType<
  typeof useVehicleReportLazyQuery
>;
export type VehicleReportQueryResult = Apollo.QueryResult<
  VehicleReportQuery,
  VehicleReportQueryVariables
>;
export const VehicleTypesDocument = gql`
  query vehicleTypes {
    vehicleTypes {
      slugName
      name
    }
  }
`;

/**
 * __useVehicleTypesQuery__
 *
 * To run a query within a React component, call `useVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VehicleTypesQuery,
    VehicleTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleTypesQuery, VehicleTypesQueryVariables>(
    VehicleTypesDocument,
    options,
  );
}
export function useVehicleTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleTypesQuery,
    VehicleTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleTypesQuery, VehicleTypesQueryVariables>(
    VehicleTypesDocument,
    options,
  );
}
export type VehicleTypesQueryHookResult = ReturnType<
  typeof useVehicleTypesQuery
>;
export type VehicleTypesLazyQueryHookResult = ReturnType<
  typeof useVehicleTypesLazyQuery
>;
export type VehicleTypesQueryResult = Apollo.QueryResult<
  VehicleTypesQuery,
  VehicleTypesQueryVariables
>;
export const VehiclesWithBasicInfoDocument = gql`
  query vehiclesWithBasicInfo {
    vehicles {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;

/**
 * __useVehiclesWithBasicInfoQuery__
 *
 * To run a query within a React component, call `useVehiclesWithBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesWithBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesWithBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesWithBasicInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VehiclesWithBasicInfoQuery,
    VehiclesWithBasicInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VehiclesWithBasicInfoQuery,
    VehiclesWithBasicInfoQueryVariables
  >(VehiclesWithBasicInfoDocument, options);
}
export function useVehiclesWithBasicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesWithBasicInfoQuery,
    VehiclesWithBasicInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehiclesWithBasicInfoQuery,
    VehiclesWithBasicInfoQueryVariables
  >(VehiclesWithBasicInfoDocument, options);
}
export type VehiclesWithBasicInfoQueryHookResult = ReturnType<
  typeof useVehiclesWithBasicInfoQuery
>;
export type VehiclesWithBasicInfoLazyQueryHookResult = ReturnType<
  typeof useVehiclesWithBasicInfoLazyQuery
>;
export type VehiclesWithBasicInfoQueryResult = Apollo.QueryResult<
  VehiclesWithBasicInfoQuery,
  VehiclesWithBasicInfoQueryVariables
>;
export const VehiclesWithLastInfoDocument = gql`
  query vehiclesWithLastInfo {
    vehicles {
      ...VehicleWithLastInfo
    }
  }
  ${VehicleWithLastInfoFragmentDoc}
`;

/**
 * __useVehiclesWithLastInfoQuery__
 *
 * To run a query within a React component, call `useVehiclesWithLastInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesWithLastInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesWithLastInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesWithLastInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VehiclesWithLastInfoQuery,
    VehiclesWithLastInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VehiclesWithLastInfoQuery,
    VehiclesWithLastInfoQueryVariables
  >(VehiclesWithLastInfoDocument, options);
}
export function useVehiclesWithLastInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesWithLastInfoQuery,
    VehiclesWithLastInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehiclesWithLastInfoQuery,
    VehiclesWithLastInfoQueryVariables
  >(VehiclesWithLastInfoDocument, options);
}
export type VehiclesWithLastInfoQueryHookResult = ReturnType<
  typeof useVehiclesWithLastInfoQuery
>;
export type VehiclesWithLastInfoLazyQueryHookResult = ReturnType<
  typeof useVehiclesWithLastInfoLazyQuery
>;
export type VehiclesWithLastInfoQueryResult = Apollo.QueryResult<
  VehiclesWithLastInfoQuery,
  VehiclesWithLastInfoQueryVariables
>;
export const VehiclesWithLocationDocument = gql`
  query vehiclesWithLocation {
    vehicles {
      ...VehicleWithLocationAndDriver
    }
  }
  ${VehicleWithLocationAndDriverFragmentDoc}
`;

/**
 * __useVehiclesWithLocationQuery__
 *
 * To run a query within a React component, call `useVehiclesWithLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesWithLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesWithLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesWithLocationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VehiclesWithLocationQuery,
    VehiclesWithLocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VehiclesWithLocationQuery,
    VehiclesWithLocationQueryVariables
  >(VehiclesWithLocationDocument, options);
}
export function useVehiclesWithLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesWithLocationQuery,
    VehiclesWithLocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehiclesWithLocationQuery,
    VehiclesWithLocationQueryVariables
  >(VehiclesWithLocationDocument, options);
}
export type VehiclesWithLocationQueryHookResult = ReturnType<
  typeof useVehiclesWithLocationQuery
>;
export type VehiclesWithLocationLazyQueryHookResult = ReturnType<
  typeof useVehiclesWithLocationLazyQuery
>;
export type VehiclesWithLocationQueryResult = Apollo.QueryResult<
  VehiclesWithLocationQuery,
  VehiclesWithLocationQueryVariables
>;
export const VehiclesDocument = gql`
  query vehicles {
    vehicles {
      ...VehicleWithDriver
    }
  }
  ${VehicleWithDriverFragmentDoc}
`;

/**
 * __useVehiclesQuery__
 *
 * To run a query within a React component, call `useVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<VehiclesQuery, VehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options,
  );
}
export function useVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesQuery,
    VehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options,
  );
}
export type VehiclesQueryHookResult = ReturnType<typeof useVehiclesQuery>;
export type VehiclesLazyQueryHookResult = ReturnType<
  typeof useVehiclesLazyQuery
>;
export type VehiclesQueryResult = Apollo.QueryResult<
  VehiclesQuery,
  VehiclesQueryVariables
>;
