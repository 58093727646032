import { Tabs } from 'antd';
import React from 'react';
import Drawer from '../../../../components/Drawer/Drawer';
import { TrafficInfractionFragment } from '../../../../generated/graphqlV2';
import { TrafficInfractionFormValues } from '../../types/Forms.types';
import TrafficInfractionStepOne from '../Forms/TrafficInfraction/TrafficInfractionStepOne';
import TrafficInfractionStepTwo from '../Forms/TrafficInfraction/TrafficInfractionStepTwo';
import { useTrafficInfractionFormContext } from '../Tabs/TrafficInfractionTab/contexts/TrafficInfractionFormContext';
import { TabsCustom } from './styles';

type DrawerProps = {
  open: boolean;
  close: () => void;
  data?: TrafficInfractionFragment;
};

export default function TrafficInfractionDrawer(props: DrawerProps) {
  const { open, close, data } = props;
  const { currentStep, toStep, setFormValues } =
    useTrafficInfractionFormContext();
  const onChangeSteps = (key: string) => toStep(Number(key));

  const handleClose = () => {
    setFormValues({} as TrafficInfractionFormValues);
    close();
    toStep(1);
  };

  return (
    <Drawer
      isOpen={open}
      onClose={handleClose}
      goBack={handleClose}
      title={!!data ? 'Editar multa' : 'Nova multa'}
    >
      <TabsCustom
        size="middle"
        onChange={onChangeSteps}
        activeKey={currentStep.toString()}
      >
        <Tabs.TabPane tab="1. Informações" key="1">
          <TrafficInfractionStepOne data={data} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="2. Complemento"
          key="2"
          destroyInactiveTabPane={false}
          disabled
        >
          <TrafficInfractionStepTwo data={data} />
        </Tabs.TabPane>
      </TabsCustom>
    </Drawer>
  );
}
