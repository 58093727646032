import React from 'react';
import Page from '../../components/Page/Page';
import SettingsActions from './components/SettingsActions';
import SettingsContent from './components/SettingsContent';
import { Container } from './components/styles';
import OrganizationContextProvider from './contexts/OrganizationContextProvider';

function SettingsPage() {
  return (
    <OrganizationContextProvider>
      <Page title="Configurações">
        <Container>
          <SettingsActions />
          <SettingsContent />
        </Container>
      </Page>
    </OrganizationContextProvider>
  );
}

export default SettingsPage;
