import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../../components/Button/Button';
import TextArea from '../../../../../components/TextArea';
import { TrafficInfractionFragment } from '../../../../../generated/graphqlV2';
import { TrafficInfractionFormValues } from '../../../types/Forms.types';
import { useTrafficInfractionFormContext } from '../../Tabs/TrafficInfractionTab/contexts/TrafficInfractionFormContext';
import {
  Field,
  FieldsContainer,
  FinishStep,
  Label,
  TrafficInfractionForm,
} from '../styles';

interface TrafficInfractionStepOneProps {
  data?: TrafficInfractionFragment;
}

function TrafficInfractionStepTwo(props: TrafficInfractionStepOneProps) {
  const { data } = props;

  const {
    toStep,
    formValues,
    createTrafficInfractionHandler,
    updateTrafficInfractionHandler,
    loading,
  } = useTrafficInfractionFormContext();

  const { control, handleSubmit } = useForm<TrafficInfractionFormValues>({
    defaultValues: {
      observation: data?.observation || undefined,
    },
  });

  const onSubmit = (formData: TrafficInfractionFormValues) => {
    const payload = {
      cost: formValues.cost,
      occurredAt: formValues.occurredAt,
      driver: formValues.driver?.id,
      location: {
        latitude: formValues.location?.coordinates.lat,
        longitude: formValues.location?.coordinates.lng,
      },
      dueDate: formValues.dueDate,
      paidAt: formValues.paidAt || null,
      paidCost: formValues.paidCost || null,
      infraction: formValues.infraction?.id,
      address: formValues.location?.formattedAddress,
      vehicle: formValues.vehicle.id,
      observation: formData.observation,
    };

    !!data
      ? updateTrafficInfractionHandler({ id: data.id, ...payload })
      : createTrafficInfractionHandler(payload);
  };

  return (
    <>
      <TrafficInfractionForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <Field>
            <Label weight={500}>Observações</Label>
            <Controller
              name="observation"
              control={control}
              render={({ field }) => (
                <TextArea
                  label=""
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  css={{ minHeight: '250px' }}
                />
              )}
            />
          </Field>

          {/* <UploadFiles
            {...register('files')}
            name="files"
            setValue={setValue}
          /> */}
        </FieldsContainer>
        <FinishStep>
          <Button
            $fullWidth
            type="button"
            $variant="secondary"
            onClick={() => toStep(1)}
            disabled={loading}
          >
            Anterior
          </Button>
          <Button $fullWidth type="submit" disabled={loading}>
            {loading
              ? 'Salvando...'
              : !!data
              ? 'Editar multa'
              : 'Adicionar multa'}
          </Button>
        </FinishStep>
      </TrafficInfractionForm>
    </>
  );
}

export default TrafficInfractionStepTwo;
