import moment, { Moment } from 'moment';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { AppModuleEnum } from '../../../constants/app-modules-enum';
import {
  useVehicleQuery,
  useVehicleReportQuery,
  VehicleFragment,
  VehicleReportFragment,
} from '../../../generated/graphqlV2';
import { useUserTracker } from '../../../hooks/use-user-tracker';
import { VehicleProfileWidgetsType } from '../types/VehicleProfile.types';

type VehicleProfileContextValue = {
  vehicleInfo: VehicleFragment | undefined;
  setVehicleInfo: (vehicleInfo: VehicleFragment) => void;
  dateInterval: RangeValue<Moment | null>;
  setDateInterval: (dataInterval: [Moment, Moment]) => void;
  widgetsData: VehicleProfileWidgetsType;
  setWidgetsData: (widgetsData: VehicleProfileWidgetsType) => void;
  vehicleReportData: VehicleReportFragment[];
  vehicleReportLoading: boolean;
  endAt: Date;
  startAt: Date;
  setDateRange(startAt: Date, endAt: Date): void;
  gettingVehicleData: boolean;
};

type VehicleProfileContextProps = {
  children: ReactNode;
};

const VehicleProfileContext = createContext<VehicleProfileContextValue>(
  {} as any,
);

type RangeValue<Moment> = [Moment, Moment] | null;

function VehicleProfileContextProvider(props: VehicleProfileContextProps) {
  const [vehicleInfo, setVehicleInfo] = useState<VehicleFragment>();
  const [vehicleReportData, setVehicleReportData] = useState<
    VehicleReportFragment[]
  >([]);

  const [dateInterval, setDateInterval] = useState<RangeValue<Moment | null>>([
    moment(),
    moment(),
  ]);

  const [widgetsData, setWidgetsData] = useState<VehicleProfileWidgetsType>(
    {} as VehicleProfileWidgetsType,
  );

  const [startAt, setStartAt] = useState(() =>
    moment().subtract(1, 'months').startOf('month').toDate(),
  );
  const [endAt, setEndAt] = useState(() => moment().endOf('month').toDate());

  const setDateRange = (startAt: Date, endAt: Date) => {
    setStartAt(startAt);
    setEndAt(endAt);
  };

  const { emitEvent } = useUserTracker();

  const params = useParams();

  const { data: vehicleData, loading } = useVehicleQuery({
    variables: {
      id: params.vehicleId!,
    },
  });

  const { data, loading: vehicleReportLoading } = useVehicleReportQuery({
    variables: {
      payload: {
        vehicleId: params.vehicleId!,
        startAt: moment(startAt).format('YYYY-MM-DD HH:mm:ss'),
        endAt: moment(endAt).format('YYYY-MM-DD HH:mm:ss'),
      },
    },
  });

  useEffect(() => {
    if (!loading && vehicleData) {
      setVehicleInfo(vehicleData.vehicle);
      emitEvent({
        action: 'renderVehicleProfilePage',
        module: AppModuleEnum.vehicles,
        extra: { vehicle: vehicleData?.vehicle.plate },
      });
    }

    if (!vehicleReportLoading && data) {
      setVehicleReportData(data.vehicleReport);
    }
  }, [data, loading, vehicleData, vehicleReportLoading, emitEvent]);

  const value: VehicleProfileContextValue = {
    vehicleInfo,
    setVehicleInfo,
    dateInterval,
    setDateInterval,
    widgetsData,
    setWidgetsData,
    vehicleReportData,
    vehicleReportLoading,
    startAt,
    endAt,
    setDateRange,
    gettingVehicleData: loading,
  };

  return (
    <VehicleProfileContext.Provider value={value}>
      {props.children}
    </VehicleProfileContext.Provider>
  );
}

export function useVehicleProfileContext() {
  const context = useContext(VehicleProfileContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useVehicleProfileContext must be used within a VehicleProfileContext',
    );
  }

  return context;
}

export default VehicleProfileContextProvider;
