import * as React from 'react';

export default function useModalControl(initialIsOpen: boolean = false) {
  const [isOpen, setIsOpen] = React.useState(initialIsOpen);

  const close = React.useCallback(() => setIsOpen(false), []);
  const open = React.useCallback(() => setIsOpen(true), []);
  const toggle = React.useCallback(() => setIsOpen((prev) => !prev), []);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}
