import moment from 'moment';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TrafficInfractionFragment,
  useGetTrafficInfractionsQuery,
} from '../../../../../../generated/graphqlV2';
import { useVehicleProfileContext } from '../../../../contexts/VehicleProfileContext';

type TrafficInfractionTableContextValue = {
  selectedData: TrafficInfractionFragment[];
  setSelectedData: Dispatch<React.SetStateAction<TrafficInfractionFragment[]>>;
  trafficInfractions: TrafficInfractionFragment[];
  setDriver: (driver: string) => void;
  orderBy: string;
  setOrderBy: (orderBy: string) => void;
  filteredData: TrafficInfractionFragment[] | undefined;
  driver: string;
  fetching: boolean;
  setDateRange: Dispatch<React.SetStateAction<{ startAt: Date; endAt: Date }>>;
  dateRange: { startAt: Date; endAt: Date };
};

type TrafficInfractionTableContextProps = {
  children: ReactNode;
};

const TrafficInfractionTableContext =
  createContext<TrafficInfractionTableContextValue>({} as any);

function TrafficInfractionTableContextProvider(
  props: TrafficInfractionTableContextProps,
) {
  const { children } = props;

  const [trafficInfractions, setTrafficInfractions] = useState<
    TrafficInfractionFragment[]
  >([]);
  const [selectedData, setSelectedData] = useState<TrafficInfractionFragment[]>(
    [],
  );

  const [driver, setDriver] = useState('');
  const [orderBy, setOrderBy] = useState('date');
  const [dateRange, setDateRange] = useState<{ startAt: Date; endAt: Date }>({
    startAt: moment().startOf('month').toDate(),
    endAt: moment().endOf('month').toDate(),
  });

  const { vehicleInfo } = useVehicleProfileContext();

  const { data: { trafficInfractions: response } = {}, loading: fetching } =
    useGetTrafficInfractionsQuery({
      variables: {
        payload: {
          vehicle: vehicleInfo?.id || '',
          startAt: dateRange.startAt,
          endAt: dateRange.endAt,
        },
      },
    });

  useEffect(() => {
    if (!response) return;

    const filteredData = response.filter(
      (trafficInfraction) => !trafficInfraction.deletedAt,
    );

    setTrafficInfractions(filteredData);
  }, [response]);

  const filteredData = useMemo(() => {
    if (!trafficInfractions?.length) return;

    return trafficInfractions
      .filter((trafficInfraction) => {
        if (!trafficInfraction?.driver?.name) return true;

        return (
          trafficInfraction.driver.name
            .toUpperCase()
            .indexOf(driver.toUpperCase()) > -1
        );
      })
      .slice()
      .sort((a, b) => {
        if (orderBy === 'price') {
          return b.paidCost! - a.paidCost!;
        }
        return b?.occurredAt
          ?.toString()
          .localeCompare(a?.occurredAt?.toString());
      });
  }, [driver, orderBy, trafficInfractions]);

  const value: TrafficInfractionTableContextValue = {
    selectedData,
    setSelectedData,
    trafficInfractions,
    orderBy,
    setOrderBy,
    setDriver,
    filteredData,
    driver,
    fetching,
    setDateRange,
    dateRange,
  };

  return (
    <TrafficInfractionTableContext.Provider value={value}>
      {children}
    </TrafficInfractionTableContext.Provider>
  );
}

export function useTrafficInfractionTableContext() {
  const context = useContext(TrafficInfractionTableContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useTrafficInfractionTableContext must be used within a TrafficInfractionTableContext',
    );
  }

  return context;
}

export default TrafficInfractionTableContextProvider;
