import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

type LayoutContextValue = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (collapsed: boolean) => void;
  toggleSidebar: () => void;
  setTitle: (title?: string) => void;
  title?: string;
};

const LayoutContext = createContext<LayoutContextValue>({} as any);

type LayoutProviderProps = {
  children: ReactNode;
};

export default function LayoutContextProvider(props: LayoutProviderProps) {
  const { children } = props;
  const [title, setTitle] = useState<string | undefined>();
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);

  const toggleSidebar = useCallback(() => {
    setSidebarCollapsed((collapsed: boolean) => !collapsed);

    let text = ' ';

    if (sidebarCollapsed) {
      text = 'Preciso de ajuda';
    }

    // @ts-ignore
    if (typeof window.zE !== 'function') {
      return;
    }
    // @ts-ignore
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        launcher: {
          label: {
            '*': text,
          },
        },
      },
    });
  }, [sidebarCollapsed]);

  useEffect(() => {
    document.title = ['Infleet', title].filter((text) => !!text).join(' - ');
  }, [title]);

  useLayoutEffect(() => {
    if (window.innerWidth <= 1440) {
      setSidebarCollapsed(true);
    }
  }, []);

  const value = useMemo(
    () => ({
      sidebarCollapsed,
      setSidebarCollapsed,
      toggleSidebar,
      setTitle,
      title,
    }),
    [sidebarCollapsed, title, toggleSidebar],
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}

export function useLayoutContext() {
  const context = useContext(LayoutContext);

  if (typeof context === 'undefined') {
    throw new Error(`useLayoutContext must be used within a LayoutContext`);
  }

  return context;
}
