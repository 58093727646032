import * as React from 'react';
import styled, { css } from 'styled-components';

export interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  $fullWidth?: boolean;
  $variant?: 'primary' | 'secondary' | 'delete';
  outlined?: boolean;
}

const handleColorVariant = (props: ButtonProps) => {
  switch (props.$variant) {
    case 'primary':
      return css`
        ${(props) => props.theme.colors.white}
      `;
    case 'secondary':
      return css`
        ${(props) => props.theme.colors.purpleDark}
      `;
    case 'delete':
      return css`
        ${(props) => props.theme.colors.error}
      `;
  }
};

const handleBackgroundVariant = ({ $variant }: ButtonProps) => {
  switch ($variant) {
    case 'primary':
      return css`
        ${(props) => props.theme.colors.primaryLight}
      `;
    case 'secondary':
      return css`
        ${(props) => props.theme.colors.secondary}
      `;
    case 'delete':
      return css`
        ${(props) => props.theme.colors.error}
      `;
  }
};

const Button = styled.button<ButtonProps>`
  height: 38px;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  padding: 0 20px;
  border-radius: 4px;
  border: 0;
  transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  color: ${(props) =>
    props.outlined
      ? props.$variant
        ? handleColorVariant(props)
        : props.theme.colors.purple
      : props.$variant
      ? handleColorVariant(props)
      : props.theme.colors.white};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.375rem;
  text-align: center;
  cursor: pointer;

  ${(props) =>
    props.outlined &&
    css`
      border: 1px solid
        ${props.$variant
          ? handleColorVariant(props)
          : props.theme.colors.purple};
    `}

  background-color: ${(props) =>
    props.outlined
      ? 'transparent'
      : props.$variant
      ? props.$variant === 'primary'
        ? props.theme.colors.purple
        : props.theme.colors.purpleLight
      : props.theme.colors.purple};

  ${({ outlined, $variant }) =>
    outlined &&
    $variant === 'secondary' &&
    css`
      border: 1px solid #eaecf0;
      color: #667085;
    `}

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: ${(props) =>
      props.$variant
        ? handleBackgroundVariant(props)
        : props.outlined
        ? props.theme.colors.purple
        : props.theme.colors.primaryLight};

    ${({ outlined, $variant, theme }) =>
      outlined &&
      $variant === 'secondary' &&
      css`
        border-color: ${theme.colors.purple};
        color: ${theme.colors.purple};
        background-color: transparent;
      `}
  }

  &:disabled,
  &.disabled,
  &[disabled],
  &:disabled:hover,
  &.disabled:hover,
  &[disabled]:hover {
    ${(props) => {
      if (props.outlined) {
        return css`
          border: 1px solid ${props.theme.colors.greyLight};
          color: ${props.theme.colors.grey};
          background-color: transparent;
        `;
      }
      return css`
        background-color: #5a5a5a;
        color: rgba(255, 255, 255, 0.5);
      `;
    }}
    cursor: default;
  }

  ${(props) =>
    props.$fullWidth
      ? css`
          width: 100%;
        `
      : void 0};
`;

export default Button;
