import styled from 'styled-components';

type ContainerProps = {
  className?: 'collapsed';
};

const Container = styled.nav<ContainerProps>`
  display: block;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  grid-area: sidebar;
  padding-top: 0.65625rem;
  transition: width var(--sidebar-collapse-duration) ease-in-out,
    background-color var(--sidebar-collapse-duration) ease-in-out;
  color: var(--sidebar-menu-color-open);
  width: var(--sidebar-width-open);
  background-color: var(--sidebar-background-open);
  box-shadow: 0 2px 10px 0 var(--box-shadow-color);
  user-select: none; /* disable user selection */
  &.collapsed {
    color: var(--sidebar-menu-color-close);
    width: var(--sidebar-width-close);
    background-color: var(--sidebar-background-close);
  }
`;

export default Container;
