export enum DrawerType {
  add = 'add',
  pay = 'pay',
  edit = 'edit',
}

export type TrafficInfractionsTableProps = {
  id: string;
  date?: string;
  amount: number;
  driver: string;
  place: string;
  hour: string;
  infraction: string;
};
