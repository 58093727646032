import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-inter';
import App from './App';
import AppProvider from './contexts/AppProvider';
import * as serviceWorker from './serviceWorker';
import './warn-user-about-console';

Sentry.init({
  dsn: 'https://2c4e768a550c4a70a906c136a8bbf991@o445943.ingest.sentry.io/5952403',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

moment.locale('pt-br');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
