import { Drawer as DrawerContainer } from 'antd';
import React from 'react';
import Icon from '../Icon/Icon';
import ArrowLeft from '../icons/ArrowLeft';
import { ActionsContainer, DrawerHead, DrawerTitle, Header } from './styles';

interface DrawerProps {
  onClose: () => void;
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  action?: React.ReactNode;
  headerAction?: React.ReactNode;
  goBack?: () => void;
  width?: number;
}

function Drawer(props: DrawerProps) {
  const {
    onClose,
    title,
    isOpen,
    children,
    action,
    goBack,
    headerAction,
    width,
  } = props;

  return (
    <DrawerContainer
      placement="right"
      destroyOnClose
      closable={false}
      width={width ?? 480}
      visible={isOpen}
      headerStyle={{ border: 'none' }}
      onClose={onClose}
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid="drawer"
    >
      <DrawerHead>
        <Header>
          <Icon onClick={goBack ?? onClose}>
            <ArrowLeft />
          </Icon>
          <DrawerTitle>{title}</DrawerTitle>
        </Header>
        {headerAction}
      </DrawerHead>
      {children}
      {action && <ActionsContainer>{action}</ActionsContainer>}
    </DrawerContainer>
  );
}

export default Drawer;
