import { rgba } from 'polished';
import React from 'react';
import { useToggle } from 'react-use';
import Button from '../../../../../../components/Button/Button';
import DropdownMenu, {
  DropdownMenuProps,
} from '../../../../../../components/DropdownMenu/DropdownMenu';
import Icon from '../../../../../../components/Icon/Icon';
import ChevronDown from '../../../../../../components/icons/ChevronDown';
import Filter from '../../../../../../components/icons/Filter';
import Search from '../../../../../../components/icons/Search';
import Input from '../../../../../../components/Input';
import { useTrafficInfractionFormContext } from '../contexts/TrafficInfractionFormContext';
import { useTrafficInfractionTableContext } from '../contexts/TrafficInfractionTableContext';
import { OrderByLabel } from '../TrafficInfractionTable/styles';
import {
  BasicFilters,
  Container,
  ExpandedIcon,
  SearchBar,
  TableActions,
} from './styles';

const actions = [
  { key: 'date', label: 'Data' },
  { key: 'price', label: 'Valor' },
];

type ActionsType = typeof actions[number]['key'];

function GlobalFilter() {
  const [expanded] = useToggle(false);

  const { selectedData, driver, setDriver, setOrderBy } =
    useTrafficInfractionTableContext();
  const { deleteTrafficInfractionHandler } = useTrafficInfractionFormContext();
  const handleDropDownAction: DropdownMenuProps<ActionsType>['onClick'] = (
    action,
  ) => {
    switch (action.key) {
      case 'date':
        setOrderBy('date');
        break;
      case 'price':
        setOrderBy('price');
        break;
    }
  };

  const handleDelete = () => {
    const trafficInfractionIds = selectedData.map((data) => data.id);
    deleteTrafficInfractionHandler(trafficInfractionIds);
  };

  return (
    <Container>
      <SearchBar>
        <Input
          label="Pesquise por condutor"
          placeholder={'Pesquise por condutor'}
          value={driver}
          onChange={(e) => setDriver(e ?? '')}
          rightIcon={
            <Icon $size="16px" $color={rgba('#545454', 0.8)}>
              <Search />
            </Icon>
          }
        />
      </SearchBar>
      <TableActions>
        {selectedData?.length ? (
          <Button outlined $variant="delete" onClick={handleDelete}>
            Excluir
          </Button>
        ) : null}
        <BasicFilters>
          <DropdownMenu actions={actions} onClick={handleDropDownAction}>
            <Button outlined>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon>
                  <Filter />
                </Icon>
                <OrderByLabel>Ordernar por</OrderByLabel>
                <ExpandedIcon $expanded={expanded}>
                  <ChevronDown />
                </ExpandedIcon>
              </div>
            </Button>
          </DropdownMenu>
        </BasicFilters>
      </TableActions>
    </Container>
  );
}

export default GlobalFilter;
