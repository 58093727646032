import styled from 'styled-components';

const SpeedmeterModalContainer = styled.div`
  display: grid;
  grid-template-columns: 222px 347px;
  column-gap: 24px;
  margin: 32px 0 24px;
`;

const ModalContainer = styled.form`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const VehicleDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const FooterContainer = styled.footer`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
`;

const VehicleInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 34px;
`;

const VehicleMeasure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VehicleImageContainer = styled.div`
  width: 64px;
  height: 64px;
  margin: auto;
`;

const ImageComponent = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Heading = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #1d2939;
  margin: 0;
`;

const StyledParagraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin: 0;
`;

const CancelButton = styled.button`
  margin-right: 2rem;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #898989;
  padding: 0 10px;
  cursor: pointer;
  height: 38px;
  background: transparent;
`;

const ModalForm = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
`;

const Warn = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background: #fffaeb;
  column-gap: 8px;
  border-radius: 4px;
  margin-bottom: 32px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #f79009;
    margin: 0;
  }
`;

const DateContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 8px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-picker {
      height: 100%;
      border-radius: 8px;
    }

    .ant-picker-focused {
      border-color: #d6bbfb;
      box-shadow: none;
    }
  }
`;

export {
  ModalContainer,
  SpeedmeterModalContainer,
  Header,
  EditContainer,
  VehicleDataContainer,
  VehicleImageContainer,
  ImageComponent,
  Heading,
  StyledParagraph,
  VehicleMeasure,
  VehicleInfo,
  FooterContainer,
  CancelButton,
  ModalForm,
  DateContainer,
  Warn,
};
