import React from 'react';
import { Container, SwitchContainer } from './styles';

export type SwitchProps = {
  value: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  name?: string;
  size?: 'small' | 'default';
  activeColor?: string;
};

function Switch(props: SwitchProps) {
  const { value, disabled, onChange, name, size, activeColor } = props;

  return (
    <Container>
      <input
        type="checkbox"
        name={name}
        id={name}
        hidden
        {...{ disabled }}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />
      <SwitchContainer
        $size={size}
        $isActive={value}
        $disabled={disabled}
        $activeColor={activeColor}
      />
    </Container>
  );
}

export default Switch;
