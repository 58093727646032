import React, { useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { DriverFragment, useDriversQuery } from '../../generated/graphqlV2';
import SelectSearch, { SelectDataType } from '../SelectSearch';

export type SelectDriverProps = {
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  value: DriverFragment | undefined | null;
  onChange: (value: DriverFragment | null) => void;
  name?: string;
  getDisabledOption?: (option: SelectDataType) => boolean;
  label?: string;
};

function defaultGetOptionDisabled<Item>(_option: Item) {
  return false;
}

function SelectDriver(props: SelectDriverProps) {
  const {
    name = 'driver',
    onChange,
    disabled,
    value,
    error,
    label,
    getDisabledOption = defaultGetOptionDisabled,
  } = props;
  const [selectedDriver, setSelectedDriver] = useState<SelectDataType>();

  const { data, loading } = useDriversQuery({
    fetchPolicy: 'cache-and-network',
  });

  const parsedDrivers = useMemo(() => {
    if (!data) return [];

    const parsedData = data?.drivers.map((driver) => {
      return {
        id: driver.id,
        text: driver.name ?? 'Nome não informado',
      };
    });

    return parsedData;
  }, [data]);

  function handleChange(value: SelectDataType) {
    const foundValue = data?.drivers?.find((driver) => driver.id === value?.id);

    if (!foundValue) return;

    onChange(foundValue);
  }

  useEffectOnce(() => {
    if (!value) return;
    const foundValue = parsedDrivers?.find(
      (vehicle) => vehicle.id === value?.id,
    );

    setSelectedDriver(foundValue);
    onChange(value);
  });

  return (
    <SelectSearch
      data={parsedDrivers}
      label={label ?? 'Motorista'}
      placeholder={'Selecione um motorista'}
      onChange={handleChange}
      value={selectedDriver}
      loading={loading}
      disabled={disabled}
      name={name}
      error={error}
      getDisabledOption={getDisabledOption}
    />
  );
}

export default SelectDriver;
