import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import Button from '../../../../../../components/Button/Button';
import CustomScrollbar from '../../../../../../components/CustomScrollbar/CustomScrollbar';
import Input from '../../../../../../components/Input';
import RadioButton from '../../../../../../components/RadioButton/RadioButton';
import TimePicker from '../../../../../../components/TimePicker/TimePicker';
import { useVehicleProfileContext } from '../../../../contexts/VehicleProfileContext';
import { useVehicleProfileFormContext } from '../../../../contexts/VehicleProfileFormContext';
import { getDateSeconds } from '../../../../helpers/getDateSeconds';
import { getHoursAndMinutesFromSeconds } from '../../../../helpers/getHoursAndMinutesFromSeconds';

import {
  ButtonContainer,
  Col,
  Form,
  FormContainer,
} from '../VehicleInfo/styles';

const Info = styled.div`
  h3 {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #667085;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #98a2b3;
  }
`;

const Row = styled.section`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 16px;
`;

const vehicleSchema = yup.object().shape({
  startWork: yup.date().nullable(),
  endWork: yup.date().nullable(),
  workOnWeekends: yup.boolean().nullable(),
  maxSpeed: yup.string().nullable(),
  timeDisconnectionAlert: yup.date().nullable(),
  timeIddle: yup.date().nullable(),
});

type FormValues = {
  startWorkAt: Date;
  endWorkAt: Date;
  workOnWeekends: boolean;
  maxSpeed: string;
  timeDisconnectionAlert: Date;
  timeStoppedWithIgnitionOn: Date;
};

const choices = [
  { id: 1, name: 'Sim' },
  { id: 2, name: 'Não' },
];

function VehicleSettings() {
  const { vehicleInfo: vehicle, gettingVehicleData } =
    useVehicleProfileContext();
  const { loading, updateVehicle } = useVehicleProfileFormContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(vehicleSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const {
      endWorkAt,
      maxSpeed,
      startWorkAt,
      timeDisconnectionAlert,
      timeStoppedWithIgnitionOn,
      workOnWeekends,
    } = data;

    const formattedEndWorkAt = new Date(endWorkAt);
    const formattedStartWorkAt = new Date(startWorkAt);

    try {
      const payload = {
        maxSpeed: Number(maxSpeed),
        settings: {
          ...vehicle?.settings,
          startWorkAt: formattedStartWorkAt,
          endWorkAt: formattedEndWorkAt,
          timeDisconnectionAlert: getDateSeconds(timeDisconnectionAlert),
          timeStoppedWithIgnitionOn: getDateSeconds(timeStoppedWithIgnitionOn),
          workOnWeekends,
        },
      };

      updateVehicle(payload);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!vehicle) return;

    const { max_speed, settings } = vehicle;

    const [disconnectionHours, disconnectioMinutes] =
      settings?.timeDisconnectionAlert
        ? getHoursAndMinutesFromSeconds(settings?.timeDisconnectionAlert)
        : [1, 0];
    const [ignitionHours, ignitionMinutes] = settings?.timeDisconnectionAlert
      ? getHoursAndMinutesFromSeconds(settings?.timeStoppedWithIgnitionOn)
      : [0, 30];

    const startWorkAt =
      settings?.startWorkAt ?? new Date().setHours(8, 30, 0, 0);
    const endWorkAt = settings?.endWorkAt ?? new Date().setHours(17, 30, 0, 0);
    const workOnWeekends = settings?.workOnWeekends ?? true;
    const timeDisconnectionAlert = moment()
      .set({
        hour: disconnectionHours,
        minutes: disconnectioMinutes,
        seconds: 0,
      })
      .toDate();
    const timeStoppedWithIgnitionOn = moment()
      .set({ hours: ignitionHours, minutes: ignitionMinutes, seconds: 0 })
      .toDate();

    setValue('maxSpeed', String(max_speed) ?? 80);
    setValue('startWorkAt', startWorkAt);
    setValue('endWorkAt', endWorkAt);
    setValue('workOnWeekends', workOnWeekends);
    setValue('timeDisconnectionAlert', timeDisconnectionAlert);
    setValue('timeStoppedWithIgnitionOn', timeStoppedWithIgnitionOn);
  }, [setValue, vehicle]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <CustomScrollbar>
          <Section>
            <Info>
              <h3>Jornada de trabalho</h3>
              <p>
                As configurações da jornada de trabalho são utilizadas para
                gerar relatórios e consolidar dados para indicadores.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="startWorkAt"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Início do expediente dos motoristas"
                      format="HH:mm"
                      allowClear={false}
                      loading={gettingVehicleData}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="endWorkAt"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Final do expediente dos motoristas"
                      format="HH:mm"
                      allowClear={false}
                      loading={gettingVehicleData}
                    />
                  )}
                />
              </Col>
            </Row>
          </Section>

          <Section>
            <Row>
              <RadioButtonContainer>
                <Info>
                  <h3>O veículo opera nos finais de semana?</h3>
                  <p>
                    Use essa configuração caso o veículo opere aos finais de
                    semana.
                  </p>
                </Info>
                <div>
                  <Controller
                    name="workOnWeekends"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <RadioButton
                        choices={choices}
                        value={value ? 0 : 1}
                        onChange={(event) => onChange(event.index === 0)}
                        maxWidth="192px"
                        minWidth="192px"
                      />
                    )}
                  />
                </div>
              </RadioButtonContainer>
            </Row>
            <Row>
              {/* <Col>
                <Controller
                  name="maxSpeed"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <MaskedNumberInput
                      formatter={(value) => value}
                      label="Marca"
                      value={value}
                      onChange={onChange}
                      error={errors.maxSpeed?.message}
                      name={name}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="timeDisconnectionAlert"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Tempo minímo (hh:mm)"
                    />
                  )}
                />
              </Col> */}
            </Row>
          </Section>

          <Section>
            <Info>
              <h3>Velocidade máxima configurada</h3>
              <p>
                Velocidade máxima configurada é utilizado para gerar eventos
                caso o motorista exceda essa velocidade pré estabelecida.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="maxSpeed"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Input
                      type="number"
                      name={name}
                      onChange={onChange}
                      value={value}
                      label="Km"
                      error={errors.maxSpeed?.message}
                    />
                  )}
                />
              </Col>
              <Col />
            </Row>
          </Section>

          <Section>
            <Info>
              <h3>Alerta de desconexão</h3>
              <p>
                Defina qual o tempo minimo que o rastreador pode ficar
                desconectado antes de gerar um alerta.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="timeDisconnectionAlert"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Tempo minímo (hh:mm)"
                      format="HH:mm"
                      allowClear={false}
                      loading={gettingVehicleData}
                    />
                  )}
                />
              </Col>
              <Col />
            </Row>
          </Section>

          {/* <Section>
            <Info>
              <h3>Tempo ocioso</h3>
              <p>
                Propriedade que será utilizada para mandar alerta em caso de
                início de ociosidade.
              </p>
            </Info>
            <Row>
              <Col>
                <Controller
                  name="timeStoppedWithIgnitionOn"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={value}
                      label="Tempo minímo (hh:mm)"
                      format="HH:mm"
                      allowClear={false}
                      loading={gettingVehicleData}
                    />
                  )}
                />
              </Col>
              <Col />
            </Row>
          </Section> */}
        </CustomScrollbar>
      </FormContainer>

      <ButtonContainer>
        <Button disabled={loading}>{loading ? 'Salvando...' : 'Salvar'}</Button>
      </ButtonContainer>
    </Form>
  );
}

export default VehicleSettings;
