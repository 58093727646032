import React from 'react';
import Drawer from '../../../../components/Drawer/Drawer';
import { TrafficInfractionFragment } from '../../../../generated/graphqlV2';
import PayTrafficInfraction from '../Forms/TrafficInfraction/PayTrafficInfraction';

type DrawerProps = {
  open: boolean;
  close: () => void;
  data: TrafficInfractionFragment;
};

export default function PayTrafficInfractionDrawer(props: DrawerProps) {
  const { open, close, data } = props;

  return (
    <Drawer isOpen={open} onClose={close} title="Pagar multa">
      <PayTrafficInfraction onClose={close} data={data} />
    </Drawer>
  );
}
