import { Exchange, Operation } from 'urql';
import { pipe, mergeMap, fromPromise, fromValue, map } from 'wonka';

export default function fetchOptionsExchange(fn: any): Exchange {
  return ({ forward }) =>
    (ops$) => {
      return pipe(
        ops$,
        mergeMap((operation: Operation) => {
          const result = fn(operation.context.fetchOptions);
          return pipe(
            (typeof result.then === 'function'
              ? fromPromise(result)
              : fromValue(result)) as any,
            map((fetchOptions: RequestInit | (() => RequestInit)) => ({
              ...operation,
              context: { ...operation.context, fetchOptions },
            })),
          );
        }),
        forward,
      );
    };
}
